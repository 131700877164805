import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { MobileTemplate } from '@hems/component';
import { useLanguage } from '@hems/util';
export default defineComponent({
    name: 'MobileTemplateContainer',
    components: { MobileTemplate },
    setup() {
        const { locale: i18nLocale } = useI18n();
        const { languageCode } = useLanguage();
        i18nLocale.value = languageCode.value;
        if (!window.appToWebCall)
            window.appToWebCall = {};
    },
});
