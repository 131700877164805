/** Copyright © 2024 Qcells. All rights reserved.
 *
 * This software is proprietary and confidential. Unauthorized use,
 * duplication, or distribution of software is strictly prohibited.
 */
// 날짜 관련 값 (number)

import type { ValueType } from 'hems/common/utils';

/** 1440 : 분 단위의 하루 값 */
export const ONE_DAY_VALUE_IN_MINUTES = 24 * 60;
/** 60000 : 밀리초 단위의 1분 값 */
export const ONE_MINUTE_VALUE_IN_MILLISECONDS = 60 * 1000;
/** 3600000 : 밀리초 단위의 1시간 값 */
export const ONE_HOUR_VALUE_IN_MILLISECONDS = ONE_MINUTE_VALUE_IN_MILLISECONDS * 60;
/** 밀리초 단위 하루 값 */
export const ONE_DAY_VALUE_IN_MILLISECONDS = 1000 * 3600 * 24;

// 날짜 관련 값 (string)
export const START_TIME_VALUE = '00:00:00';
export const END_TIME_VALUE = '23:59:59';

// 날짜 관련 포맷
export const GENERAL_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const NORMAL_YEAR_FORMAT = 'YYYY';
export const NORMAL_DATE_FORMAT = 'YYYYMMDD';
export const NORMAL_DATE_HOUR_MIN_FORMAT = 'YYYYMMDDHHmm';
export const DASH_DATE_FORMAT = 'YYYY-MM-DD';
export const COLON_HOUR_MIN_FORMAT = 'HH:mm';
export const COLON_TIME_FORMAT = 'HH:mm:ss';
export const TOU_SCHEDULE_DATE_FORMAT = 'MM-DD';
export const DASH_DATE_HOUR_MIN_FORMAT = 'YYYY-MM-DD HH:mm';

// TIMEZONE
export const UTC_TIME_ZONE = 'GMT';

export const DAY_RANGE_31 = 31;

export const DATE_UNIT = {
  MINS: 'minutes',
  DAYS: 'days',
  MONTHS: 'months',
  YEARS: 'years',
} as const;

export type DateUnitValue = ValueType<typeof DATE_UNIT>;

/** 50000: 밀리초 단위의 50초 값 */
export const FIFTY_SECONDS_VALUE_IN_MILLISECONDS = 50 * 1000;

/** 300000: 밀리초 단위의 5분 값 */
export const FIVE_MINUTES_VALUE_IN_MILLISECONDS = 5 * ONE_MINUTE_VALUE_IN_MILLISECONDS;

/** 10000: 밀리초 단위의 10초 값 */
export const TEN_SECONDS_VALUE_IN_MILLISECONDS = 10 * 1000;

export const DAYS_OF_WEEK = {
  SUNDAY: 'sunday',
  MONDAY: 'monday',
  TUESDAY: 'tuesday',
  WEDNESDAY: 'wednesday',
  THURSDAY: 'thursday',
  FRIDAY: 'friday',
  SATURDAY: 'saturday',
} as const;

export type DaysOfWeekValues = ValueType<typeof DAYS_OF_WEEK>;

export const DAY_MONTH_NAME_FORMAT = 'DD MMM' as const;

/** 2000: 밀리초 단위의 2초 값 */
export const TWO_SECONDS_VALUE_IN_MILLISECONDS = 2 * 1000;

/** 60: 초 단위의 1분 값 */
export const ONE_MINUTE_VALUE_IN_SECONDS = 60;

/** 24: 시간 단위의 1일 값 */
export const ONE_DAY_VALUE_IN_HOUR = 24;

/** 60: 분 단위의 1시간 값 */
export const ONE_HOUR_VALUE_IN_MINUTE = 60;
