/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import type { AxiosInstance } from 'axios';

import { DeviceService } from '@hems/service/src/api/device/DeviceService';
import { apiWrapper, getApiVersionHeader } from '@hems/service/src/util/helper';
import type { UserInstallationInfoResponse, UserSettingInfoACSystem } from '@hems/user/src/types/device';
import { Helper } from '@hems/util';
import { getEnvLocale } from '@hems/util/src/helper/helper';

import type { CommonResponseWrapper } from 'hems';

import type { SiteId } from 'hems/device';

export default class DeviceServiceUser extends DeviceService {
  constructor(axios: AxiosInstance) {
    super(axios);
  }

  public async getUserInstallationInfo(): Promise<UserInstallationInfoResponse> {
    const isUS = getEnvLocale() === 'US';
    const { data } = await this.get<CommonResponseWrapper<UserInstallationInfoResponse>>(
      apiWrapper.managerApi('/devices/profiles/information'),
      undefined,
      {
        headers: getApiVersionHeader(isUS),
      }
    );

    if (Array.isArray(data.result)) {
      if (data.result.length >= 2) {
        return data;
      }

      return {
        ...data,
        result: Helper.isEmptyArray(data.result) ? null : data.result[0],
      };
    }

    return data;
  }

  public async getSiteId(): Promise<SiteId> {
    const { data } = await this.get<CommonResponseWrapper<SiteId>>(apiWrapper.managerApi('/devices/profiles/site-id'));

    return data;
  }

  /** AC System Configuration Monitoring */
  public async getUserACSystemSettingInfo(siteId: number): Promise<UserSettingInfoACSystem> {
    const { data } = await this.get<CommonResponseWrapper<UserSettingInfoACSystem>>(
      apiWrapper.managerApi(`/devices/profiles/ac/user/${siteId}/configurations`)
    );

    return data;
  }
}
