import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, vModelText as _vModelText, withDirectives as _withDirectives, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-11aeacb0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-button-container" }
const _hoisted_2 = { class: "user-layout-root tb_fm line" }
const _hoisted_3 = { class: "user-layout left" }
const _hoisted_4 = { class: "fm_wrap" }
const _hoisted_5 = { class: "label" }
const _hoisted_6 = { class: "user-layout right" }
const _hoisted_7 = { class: "search_opts" }
const _hoisted_8 = { class: "opt w5" }
const _hoisted_9 = { class: "opt w5" }
const _hoisted_10 = { class: "opt w5" }
const _hoisted_11 = { class: "opt w5" }
const _hoisted_12 = { class: "opt w5" }
const _hoisted_13 = { class: "opt w5" }
const _hoisted_14 = { class: "userInfo-button-container" }
const _hoisted_15 = { style: {"width":"80%"} }
const _hoisted_16 = { style: {"width":"100%","margin-top":"1rem"} }
const _hoisted_17 = { class: "fm_wrap" }
const _hoisted_18 = { class: "label" }
const _hoisted_19 = ["readonly"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GoogleMap = _resolveComponent("GoogleMap")!
  const _component_BasicInput = _resolveComponent("BasicInput")!
  const _component_SettingTableRow = _resolveComponent("SettingTableRow")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_SettingTable = _resolveComponent("SettingTable")!
  const _component_Accordion = _resolveComponent("Accordion")!
  const _component_ImageUploader = _resolveComponent("ImageUploader")!
  const _component_LabelInput = _resolveComponent("LabelInput")!
  const _component_TruncateInput = _resolveComponent("TruncateInput")!
  const _component_SignPadViewer = _resolveComponent("SignPadViewer")!
  const _component_RadioGroup = _resolveComponent("RadioGroup")!
  const _component_ToggleInput = _resolveComponent("ToggleInput")!
  const _component_SettingButtonGroup = _resolveComponent("SettingButtonGroup")!
  const _component_Form = _resolveComponent("Form")!
  const _component_ChangeInstallerPopup = _resolveComponent("ChangeInstallerPopup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.installationInfo)
      ? (_openBlock(), _createBlock(_component_Form, {
          key: 0,
          "validation-schema": _ctx.schema,
          onSubmit: _ctx.onSave
        }, {
          default: _withCtx(({ handleReset }) => [
            _createVNode(_component_GoogleMap, {
              installationInfo: _ctx.installationInfo,
              "onUpdate:installationInfo": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.installationInfo) = $event)),
              "is-editable": _ctx.isEditable
            }, null, 8, ["installationInfo", "is-editable"]),
            _createVNode(_component_Accordion, {
              title: _ctx.$t('account.installer_info'),
              active: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_SettingTable, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_SettingTableRow, {
                      title: _ctx.$t('account.id')
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_1, [
                          _createVNode(_component_BasicInput, {
                            modelValue: _ctx.installationInfo.instl_id,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.installationInfo.instl_id) = $event)),
                            name: "instl_id",
                            readonly: ""
                          }, null, 8, ["modelValue"]),
                          (_ctx.isDevOrAdminOrService)
                            ? (_openBlock(), _createElementBlock("button", {
                                key: 0,
                                type: "button",
                                class: "btn_t btn_c_3",
                                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openChangeInstallerPopup(true)))
                              }, _toDisplayString(_ctx.$t('common.change')), 1))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _: 1
                    }, 8, ["title"]),
                    _createVNode(_component_SettingTableRow, {
                      title: _ctx.$t('common.company')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BasicInput, {
                          modelValue: _ctx.installationInfo.instl_comn_nm,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.installationInfo.instl_comn_nm) = $event)),
                          name: "instl_comn_nm",
                          readonly: ""
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["title"]),
                    _createVNode(_component_SettingTableRow, {
                      title: _ctx.$t('account.telephone')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BasicInput, {
                          modelValue: _ctx.installationInfo.instl_mpn_no,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.installationInfo.instl_mpn_no) = $event)),
                          name: "instl_mpn_no",
                          readonly: ""
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["title"]),
                    _createVNode(_component_SettingTableRow, {
                      title: _ctx.$t('account.email')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BasicInput, {
                          modelValue: _ctx.installationInfo.instl_email,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.installationInfo.instl_email) = $event)),
                          name: "instl_email",
                          readonly: ""
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["title"]),
                    _createVNode(_component_SettingTableRow, {
                      title: _ctx.$t('common.activation_code')
                    }, {
                      tooltip: _withCtx(() => [
                        _createVNode(_component_Tooltip, {
                          "tooltip-position": ['bottom'],
                          "tooltip-text": _ctx.$t('message.used_customer_registers'),
                          "image-base-url": "svg",
                          "image-name": "warning_circle",
                          "image-extension": "svg",
                          width: "28rem"
                        }, null, 8, ["tooltip-text"])
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_BasicInput, {
                          modelValue: _ctx.installationInfo.instl_pin_code,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.installationInfo.instl_pin_code) = $event)),
                          name: "instl_pin_code",
                          readonly: !_ctx.isEditable
                        }, null, 8, ["modelValue", "readonly"])
                      ]),
                      _: 1
                    }, 8, ["title"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["title"]),
            _createVNode(_component_Accordion, {
              title: _ctx.$t('account.user_info'),
              active: ""
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("label", _hoisted_4, [
                      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('common.image')), 1)
                    ]),
                    _createVNode(_component_ImageUploader, {
                      modelValue: _ctx.installationInfo.file,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.installationInfo.file) = $event)),
                      name: "file",
                      "blob-img": _ctx.siteImage,
                      editable: _ctx.isEditable,
                      accept: ['image/gif', 'image/jpeg', 'image/png']
                    }, null, 8, ["modelValue", "blob-img", "editable"])
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("ul", _hoisted_7, [
                      _createElementVNode("li", _hoisted_8, [
                        _createVNode(_component_LabelInput, {
                          modelValue: _ctx.installationInfo.user_id,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.installationInfo.user_id) = $event)),
                          label: _ctx.$t('account.user_id'),
                          name: "user_id",
                          readonly: ""
                        }, null, 8, ["modelValue", "label"])
                      ]),
                      _createElementVNode("li", _hoisted_9, [
                        _createVNode(_component_TruncateInput, {
                          modelValue: _ctx.installationInfo.user_nm,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.installationInfo.user_nm) = $event)),
                          label: _ctx.$t('account.user_name'),
                          name: "user_nm",
                          "tooltip-text": _ctx.installationInfo.user_nm,
                          "tooltip-position": ['bottom'],
                          readonly: ""
                        }, null, 8, ["modelValue", "label", "tooltip-text"])
                      ]),
                      _createElementVNode("li", _hoisted_10, [
                        _createVNode(_component_LabelInput, {
                          modelValue: _ctx.installationInfo.mpn_no,
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.installationInfo.mpn_no) = $event)),
                          label: _ctx.$t('account.user_tel'),
                          name: "mpn_no",
                          readonly: ""
                        }, null, 8, ["modelValue", "label"])
                      ]),
                      _createElementVNode("li", _hoisted_11, [
                        _createVNode(_component_LabelInput, {
                          modelValue: _ctx.installationInfo.email,
                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.installationInfo.email) = $event)),
                          label: _ctx.$t('account.user_email'),
                          name: "email",
                          readonly: ""
                        }, null, 8, ["modelValue", "label"])
                      ]),
                      _createElementVNode("li", _hoisted_12, [
                        _createVNode(_component_LabelInput, {
                          modelValue: _ctx.installationInfo.homeowner_nm,
                          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.installationInfo.homeowner_nm) = $event)),
                          label: _ctx.$t('account.owner_name'),
                          name: "owner_name",
                          readonly: !_ctx.isEditable,
                          "validate-mode": "blur"
                        }, null, 8, ["modelValue", "label", "readonly"])
                      ]),
                      _createElementVNode("li", _hoisted_13, [
                        _createVNode(_component_LabelInput, {
                          modelValue: _ctx.installationInfo.homeowner_pn,
                          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.installationInfo.homeowner_pn) = $event)),
                          label: _ctx.$t('account.owner_contact'),
                          name: "owner_contact",
                          readonly: !_ctx.isEditable,
                          "validate-mode": "blur"
                        }, null, 8, ["modelValue", "label", "readonly"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, [
                        _createVNode(_component_LabelInput, {
                          modelValue: _ctx.installationInfo.homeowner_email,
                          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.installationInfo.homeowner_email) = $event)),
                          label: _ctx.$t('account.owner_email'),
                          name: "owner_email",
                          readonly: !_ctx.isEditable,
                          "validate-mode": "blur"
                        }, null, 8, ["modelValue", "label", "readonly"])
                      ]),
                      _createElementVNode("button", {
                        type: "button",
                        class: "btn_s_2 btn_c_3 cap",
                        style: {"height":"40px","line-height":"1","margin-top":"1.5rem"},
                        onClick: _cache[15] || (_cache[15] = 
//@ts-ignore
(...args) => (_ctx.onClickEmail && _ctx.onClickEmail(...args)))
                      }, _toDisplayString(_ctx.$t('common.send_email')), 1)
                    ]),
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("label", _hoisted_17, [
                        _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.$t('account.signature')), 1)
                      ]),
                      _createVNode(_component_SignPadViewer, {
                        "agree-sign": _ctx.installationInfo.agree_sign,
                        "agree-sign-binary": _ctx.installationInfo.agree_sign_binary,
                        width: 650,
                        "min-height": 176
                      }, null, 8, ["agree-sign", "agree-sign-binary"])
                    ])
                  ])
                ])
              ]),
              _: 1
            }, 8, ["title"]),
            _createVNode(_component_Accordion, {
              title: _ctx.$t('common.comments'),
              active: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_SettingTable, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_SettingTableRow, {
                      title: _ctx.$t('common.comments')
                    }, {
                      default: _withCtx(() => [
                        _withDirectives(_createElementVNode("textarea", {
                          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.installationInfo.memo) = $event)),
                          class: "comments",
                          name: "comments",
                          readonly: !_ctx.isEditable
                        }, null, 8, _hoisted_19), [
                          [_vModelText, _ctx.installationInfo.memo]
                        ])
                      ]),
                      _: 1
                    }, 8, ["title"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["title"]),
            _createVNode(_component_Accordion, {
              title: "Off-Grid",
              active: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_SettingTable, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_SettingTableRow, { title: "Off-Grid" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_RadioGroup, {
                          modelValue: _ctx.installationInfo.off_grid,
                          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.installationInfo.off_grid) = $event)),
                          name: "off_grid",
                          options: _ctx.yesOrNoToggleOptions,
                          readonly: !_ctx.isEditable
                        }, null, 8, ["modelValue", "options", "readonly"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_Accordion, {
              title: _ctx.$t('device.alarm_setting'),
              active: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_SettingTable, {
                  caption: _ctx.$t('message.alarm_info')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_SettingTableRow, {
                      title: _ctx.alarmSettingInfo.alarmCd
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ToggleInput, {
                          modelValue: _ctx.alarmSettingInfo.enabled,
                          "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.alarmSettingInfo.enabled) = $event)),
                          name: _ctx.alarmSettingInfo.alarmCd,
                          toggle: _ctx.onOffToggleOptions,
                          readonly: !_ctx.isEditable
                        }, null, 8, ["modelValue", "name", "toggle", "readonly"])
                      ]),
                      _: 1
                    }, 8, ["title"])
                  ]),
                  _: 1
                }, 8, ["caption"])
              ]),
              _: 1
            }, 8, ["title"]),
            _createVNode(_component_Accordion, {
              title: _ctx.$t('common.test_site'),
              active: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_SettingTable, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_SettingTableRow, {
                      title: _ctx.$t('common.test_site')
                    }, {
                      tooltip: _withCtx(() => [
                        _createVNode(_component_Tooltip, {
                          "tooltip-position": ['bottom'],
                          "tooltip-text": _ctx.$t('message.testing_device'),
                          "image-base-url": "svg",
                          "image-name": "warning_circle",
                          "image-extension": "svg",
                          style: {"display":"inline-block"}
                        }, null, 8, ["tooltip-text"])
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_RadioGroup, {
                          modelValue: _ctx.installationInfo.is_test,
                          "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.installationInfo.is_test) = $event)),
                          name: "is_test",
                          readonly: !_ctx.isEditable,
                          options: _ctx.yesOrNoToggleOptions
                        }, null, 8, ["modelValue", "readonly", "options"])
                      ]),
                      _: 1
                    }, 8, ["title"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["title"]),
            _createVNode(_component_SettingButtonGroup, {
              "is-editable": _ctx.isEditable,
              onGoToList: _ctx.goToList,
              onEdit: _ctx.onEdit,
              onCancel: ($event: any) => (_ctx.onCancel(handleReset))
            }, null, 8, ["is-editable", "onGoToList", "onEdit", "onCancel"])
          ]),
          _: 1
        }, 8, ["validation-schema", "onSubmit"]))
      : _createCommentVNode("", true),
    (_ctx.showChangeInstallerPopup && _ctx.siteId)
      ? (_openBlock(), _createBlock(_component_ChangeInstallerPopup, {
          key: 1,
          "site-id": _ctx.siteId,
          onClose: _cache[20] || (_cache[20] = ($event: any) => (_ctx.openChangeInstallerPopup(false))),
          onAfterSuccess: _ctx.onAfterChangeInstaller
        }, null, 8, ["site-id", "onAfterSuccess"]))
      : _createCommentVNode("", true)
  ], 64))
}