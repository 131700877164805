/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import forEach from 'lodash-es/forEach';
import isNil from 'lodash-es/isNil';
import type { ColumnOptions } from 'tui-grid';

import { DateHelper } from '@hems/util';
import type { GraphGroupCodeValue, GraphTermUnitValue } from '@hems/util/src/constant';
import { GRAPH_GROUP_CODE, GRAPH_TERM_UNIT, GRID_STATISTICS, UNIT } from '@hems/util/src/constant';
import { calculatePowerFactor } from '@hems/util/src/helper/dashboardHelper';
import { getConvertedGraphDateString } from '@hems/util/src/helper/dateHelper';
import { convertCelsiusToFahrenheit } from '@hems/util/src/helper/numberformatHelper';
import { getGraphGroupCodeTypeValue, getGraphGroupCodeListTypeValue } from '@hems/util/src/helper/tsguardHelper';

import type { i18nTranslation } from 'hems';

import type {
  FilterGroup,
  SimpleSeries,
  SuperFilterItem,
  StatisticsFilterGroup,
  StatisticsMinuteData,
  StatisticsHourData,
  StatisticsAllDataKey,
  StatisticsDataACSystem,
} from 'hems/device/statistics/common';

export type SuperStatisticsData = { stdDt: string };
export type ChartData = Record<GraphGroupCodeValue, SimpleSeries[]>;
export type SuperFilterGroup = FilterGroup & {
  filterList: SuperFilterItem[];
};

export const clearChartData = (chartData: ChartData) => {
  forEach(chartData, (_value, key) => {
    const chartDataKey: GraphGroupCodeValue | null = getGraphGroupCodeTypeValue(key);
    if (chartDataKey) {
      chartData[chartDataKey]?.forEach((item) => (item.data = []));
    }
  });
};

export const getChartTemplateAndData = <T extends SuperFilterGroup>(chartConfig: T[], t: i18nTranslation) => {
  const chartDataTemplate: Partial<Record<GraphGroupCodeValue, T>> = {};
  const chartData = chartConfig.reduce<ChartData>((prev, curr) => {
    chartDataTemplate[curr.filterGroupCode] = curr;

    return {
      ...prev,
      [curr.filterGroupCode]: curr.filterList.map<SimpleSeries>((item) => {
        return {
          id: item.code,
          name: `${t(item.name)}${item.postfix ? ` (${item.postfix})` : ''}${item.unit ? ` [${item.unit}]` : ''}`,
          yAxis: item.yAxis,
          data: [],
          hidden: item.hidden,
        };
      }),
    };
  }, {} as ChartData);

  return {
    chartDataTemplate,
    chartData,
  };
};

export const getXAxisCategories = <T extends SuperStatisticsData>(
  data: T[],
  termUnit: GraphTermUnitValue = GRAPH_TERM_UNIT.MINUTE
): string[] => {
  return data.map((item) => DateHelper.getConvertedGraphDateString(item.stdDt, termUnit));
};

type GraphKeys = { [K in GraphGroupCodeValue]?: StatisticsAllDataKey[] };
type GraphData = { [K in StatisticsAllDataKey]?: number | null };

export const updateChartData = (
  chartData: ChartData,
  statisticsDataList: StatisticsDataACSystem[],
  graphKeys: GraphKeys
) => {
  clearChartData(chartData);
  const groupKeys: GraphGroupCodeValue[] | null = getGraphGroupCodeListTypeValue(Object.keys(graphKeys));

  statisticsDataList.forEach((_data, index) => {
    groupKeys?.forEach((groupKey) => {
      graphKeys[groupKey]?.forEach((key) => {
        const graphData: GraphData = statisticsDataList[index];
        const graphDataByKey = graphData[key] ?? null;
        chartData[groupKey]?.find((item) => item.id === key)?.data.push(graphDataByKey);
      });
    });
  });
};

export const getColumns = (config: StatisticsFilterGroup[], t: i18nTranslation): ColumnOptions[] => {
  const columnArr: ColumnOptions[] = [{ header: '', name: 'stdDt' }];
  config.forEach((item) => {
    item.filterList.forEach(
      (item: SuperFilterItem & { code: keyof StatisticsMinuteData | keyof StatisticsHourData }) => {
        columnArr.push({
          header: `${t(`${item.name}`)}${item.postfix ? ` (${item.postfix})` : ''}`,
          name: item.code,
        });
      }
    );
  });

  return columnArr;
};

export const convertFormatDate = (data: StatisticsDataACSystem[]): StatisticsDataACSystem[] => {
  return data.map((item) => ({
    ...item,
    stdDt: getConvertedGraphDateString(item.stdDt, GRAPH_TERM_UNIT.HOUR),
  }));
};

export const getGraphDataByGroupCodes = (groupCodes: GraphGroupCodeValue[], chartData: ChartData) => {
  return groupCodes.reduce(
    (accumulator: SimpleSeries[], groupCode: GraphGroupCodeValue) => [...accumulator, ...chartData[groupCode]],
    []
  );
};

export const getGraphDataByGroupCodesACSystem = (groupCodes: GraphGroupCodeValue[], chartData: ChartData) => {
  if (groupCodes.includes(GRAPH_GROUP_CODE.BATTERY)) {
    chartData[GRAPH_GROUP_CODE.BATTERY] = getConvertedBatteryData(chartData[GRAPH_GROUP_CODE.BATTERY]);
  }

  if (groupCodes.includes(GRAPH_GROUP_CODE.GRID)) {
    chartData[GRAPH_GROUP_CODE.GRID] = getACMeterData(chartData[GRAPH_GROUP_CODE.GRID]);
  }

  return getGraphDataByGroupCodes(groupCodes, chartData);
};

const calculateGraphPowerFactor = (
  meterActivePowerList: (number | null)[],
  meterReactivePowerList: (number | null)[]
) => {
  return meterActivePowerList.map((meterActivePower, index) => {
    const meterReactivePower = meterReactivePowerList[index];

    return calculatePowerFactor(meterActivePower ?? 0, meterReactivePower ?? 0);
  });
};

export const getACMeterData = (meterDataList: SimpleSeries[]) => {
  const { meterActivePowerList, meterReactivePowerList } = meterDataList.reduce<{
    meterActivePowerList: (number | null)[];
    meterReactivePowerList: (number | null)[];
  }>(
    (acc, meterData) => {
      if (meterData.id === GRID_STATISTICS.ACTIVE_POWER) {
        acc.meterActivePowerList = meterData.data;
      } else if (meterData.id === GRID_STATISTICS.REACTIVE_POWER) {
        acc.meterReactivePowerList = meterData.data;
      }

      return acc;
    },
    {
      meterActivePowerList: [],
      meterReactivePowerList: [],
    }
  );

  return meterDataList.map((meterData) => {
    if (meterData.id === GRID_STATISTICS.POWER_FACTOR) {
      return { ...meterData, data: calculateGraphPowerFactor(meterActivePowerList, meterReactivePowerList) };
    }

    return meterData;
  });
};

export const getConvertedBatteryData = (batteryDataList: SimpleSeries[]) =>
  batteryDataList.map((batteryData) => {
    if (batteryData.yAxis === UNIT.FAHRENHEIT) {
      return {
        ...batteryData,
        data: batteryData.data.map((data) => (!isNil(data) ? convertCelsiusToFahrenheit(data) : null)),
      };
    }

    return batteryData;
  });
