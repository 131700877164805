import type { AxiosInstance } from 'axios';

import { Helper } from '@hems/util';

import type { CommonResponseWrapper, InstallerAccount } from 'hems';

import { InstallerAccountService } from './InstallerAccountService';
import { apiWrapper } from '../../util/helper';

export default class InstallerAccountServiceAdmin extends InstallerAccountService {
  constructor(axios: AxiosInstance) {
    super(axios);
  }

  public async getInstallerAccountList(
    params: InstallerAccount.InstallerAccountSearchOptions
  ): Promise<InstallerAccount.InstallerAccountListItem[]> {
    const { data } = await this.get<CommonResponseWrapper<InstallerAccount.InstallerAccountListItem[]>>(
      apiWrapper.managerApi('/managements/users/installers'),
      params
    );

    return data;
  }

  public async getInstallerAccountDetails(
    installerId: InstallerAccount.InstallerId
  ): Promise<InstallerAccount.InstallerAccountDetailsItem> {
    const { data } = await this.get<CommonResponseWrapper<InstallerAccount.InstallerAccountDetailsItem>>(
      apiWrapper.managerApi('/managements/users/installer-profiles'),
      { userId: installerId }
    );

    return data;
  }

  public async putInstallerAccountDetails(
    installerId: InstallerAccount.InstallerId,
    params: InstallerAccount.InstallerAccountDetailsParams
  ): Promise<Response> {
    const _params = { ...params, installer_id: installerId };
    const { data } = await this.put<CommonResponseWrapper<Response>>(
      apiWrapper.managerApi('/managements/users/installers/profiles'),
      Helper.keyToCamelCase(_params)
    );

    return data;
  }

  public async deleteInstallerAccountDetails(
    installerId: InstallerAccount.InstallerId
  ): Promise<InstallerAccount.InstallerAccountDetailsItem> {
    const { data } = await this.delete<CommonResponseWrapper<InstallerAccount.InstallerAccountDetailsItem>>(
      apiWrapper.managerApi('/managements/users/profiles'),
      undefined,
      { data: { userId: installerId } }
    );

    return data;
  }
}
