import { computed } from 'vue';
import { useStore } from 'vuex';
import { THEME_CLASS, THEME_DARK, THEME_LIGHT } from '@hems/util/src/constant/mapper';
/* eslint-disable import/prefer-default-export */
export const useTheme = () => {
    const store = useStore();
    const themeClass = computed(() => {
        const theme = store.state.appCtx.theme;
        switch (theme) {
            case THEME_DARK:
                return THEME_CLASS.DARK_THEME;
            case THEME_LIGHT:
                return THEME_CLASS.LIGHT_THEME;
            default:
                return THEME_CLASS.DEFAULT_THEME;
        }
    });
    return {
        themeClass,
    };
};
