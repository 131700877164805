/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import { rest } from 'msw';
import { apiWrapper } from '@hems/service/src/util/helper';
import { ACSystemHistoryGraphData } from '@hems/util/src/mocks/data/historyData';
import { getCommonResponseData } from '@hems/util/src/mocks/util';
const API_BASE_URL = process.env.VUE_APP_API_URL;
const HISTORY_API_URL = `${API_BASE_URL}${apiWrapper.managerApi('/histories')}`;
const ACSystemHistoryGraphHandler = rest.get(`${HISTORY_API_URL}/graphs/:siteId/ac-system`, (req, res, ctx) => {
    const data = {
        context: {
            pageIndex: 1,
            pageSize: 1,
            totalCount: 1,
        },
        body: ACSystemHistoryGraphData,
    };
    const response = getCommonResponseData(data, req.url.pathname);
    return res(ctx.status(200), ctx.json(response));
});
export const historyHandlers = [ACSystemHistoryGraphHandler];
