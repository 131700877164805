import type { AxiosInstance, AxiosRequestConfig } from 'axios';

import { Exceptions } from '@hems/util';
import { API_METHOD_TYPE, CONTENT_TYPE_HEADER } from '@hems/util/src/constant';
const { AxiosErrorException } = Exceptions;

export class Service {
  protected axios: AxiosInstance;
  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  protected async download(
    url: string,
    params?: Record<string, any>,
    defaultFileName?: string
  ): Promise<{ data: Blob; filename: string }> {
    try {
      const { data, headers } = await this.axios.get<Blob>(url, {
        params,
        headers: {
          Accept: '*/*',
        },
        responseType: 'blob',
      });

      const items: string[] = headers['content-disposition']?.split(';');
      const filename = items
        .map<{ key: string; value: string }>((item) => {
          const temp = item.split('=');

          return { key: temp?.[0]?.trim(), value: temp?.[1]?.replace(/[" ]/gi, '') };
        })
        .find((item) => item.key === 'filename');

      return { data, filename: filename?.value || defaultFileName || 'unknown' };
    } catch (e) {
      throw new AxiosErrorException(url, params, e);
    }
  }

  protected async get<T = any>(url: string, params?: Record<string, any>, config?: AxiosRequestConfig): Promise<T> {
    try {
      const { data } = await this.axios.get<T>(url, { params, ...config });

      return data;
    } catch (e) {
      throw new AxiosErrorException(url, params, e);
    }
  }

  protected async put<T = any>(url: string, params?: Record<string, any>, config?: AxiosRequestConfig): Promise<T> {
    try {
      const { data } = await this.axios.put<T>(url, params, config);

      return data;
    } catch (e) {
      throw new AxiosErrorException(url, params, e);
    }
  }

  protected async post<T = any>(url: string, params?: Record<string, any>, config?: AxiosRequestConfig): Promise<T> {
    try {
      const { data } = await this.axios.post<T>(url, params, config);

      return data;
    } catch (e) {
      throw new AxiosErrorException(url, params, e);
    }
  }

  protected async delete<T = any>(url: string, params?: Record<string, any>, config?: AxiosRequestConfig): Promise<T> {
    try {
      const { data } = await this.axios.delete<T>(url, { params, ...config });

      return data;
    } catch (e) {
      throw new AxiosErrorException(url, params, e);
    }
  }

  protected async patch<T = any>(url: string, params?: Record<string, any>, config?: AxiosRequestConfig): Promise<T> {
    try {
      const { data } = await this.axios.patch<T>(url, params, config);

      return data;
    } catch (e) {
      throw new AxiosErrorException(url, params, e);
    }
  }

  protected setContentTypeHeaderInterceptor = () => {
    this.axios.interceptors.request.use(
      (config) => {
        const methodType = config.method?.toLowerCase();
        if (methodType === API_METHOD_TYPE.GET || methodType === API_METHOD_TYPE.DELETE) {
          /* GET METHOD에 대해서 Content-Type 헤더를 설정하려면 body가 있어야 함
          Axios가 body 없는 요청에 대해서는 Content-Type 헤더를 삭제하고 전달하기 때문인데
          GET의 경우 기본적으로 body를 설정하지 않기 때문에 임시처리로 빈 객체를 넣어준 상태이며
          백엔드 파트에 요청하여 GET METHOD API에 대해서는 Content-Type 헤더 제거 요청을 드렸음
          FIXME: 추후 수정 예정
          */
          config.data = {};
        }
        config.headers[CONTENT_TYPE_HEADER.KEY] = CONTENT_TYPE_HEADER.APPLICATION_JSON;

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };
}
