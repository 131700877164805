class LocalStorageService {
  get<T>(key: string): T | null {
    const items = window.localStorage.getItem(key);

    if (!items || items === 'null') {
      return null;
    }

    try {
      return JSON.parse(items);
    } catch (e) {
      console.log(e);
    }

    return null;
  }

  set(key: string, value: any): boolean {
    if (value === undefined) {
      value = null;
    } else {
      value = JSON.stringify(value);
    }
    try {
      window.localStorage.setItem(key, value);
    } catch (e) {
      console.log(e);
    }

    return false;
  }

  remove(key: string) {
    window.localStorage.removeItem(key);
  }

  /* 아래 메서드들은 항목을 배열로 저장할 때 사용 */
  getOneItem<T>(key: string, id: string): T | null {
    const items = window.localStorage.getItem(key);

    if (!items || items === 'null') {
      return null;
    }

    try {
      const parsedItems: any[] = JSON.parse(items);

      return parsedItems.filter((item: any) => item.id === id)[0] ?? null;
    } catch (e) {
      console.log(e);
    }

    return null;
  }

  insertOneItem(key: string, newItem: any): boolean {
    const items: any[] | null = this.get(key);
    if (items) {
      this.set(key, [...items, newItem]);
    } else {
      this.set(key, [newItem]);
    }

    return true;
  }

  updateOneItem(key: string, id: string, updatedItem: any): boolean {
    const items: any[] | null = this.get(key);
    if (items) {
      const updateditems = items.map((item: any) => (item.id === id ? { ...item, ...updatedItem } : item));
      this.set(key, updateditems);
    }

    return true;
  }

  removeOneItem(key: string, id: string) {
    const items: any[] | null = this.get(key);
    if (items) {
      const updatedItems = items.filter((item) => item.id !== id);
      this.set(key, updatedItems);
    }
  }
}

export default new LocalStorageService();
