/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import type { ValueType } from 'hems/common/utils';

export const BANNER_TYPE = {
  DEFAULT: 'default',
  SUCCESS: 'success',
  WARNING: 'warning',
  NOTICE: 'notice',
} as const;

export type BannerType = ValueType<typeof BANNER_TYPE>;

export const BANNER_ICON_MAP: { [bannerType in BannerType]: string } = {
  [BANNER_TYPE.DEFAULT]: 'icons/ic_re_info',
  [BANNER_TYPE.SUCCESS]: 'icons/ic_check_circle_line_primary',
  [BANNER_TYPE.WARNING]: 'icons/ic_error',
  [BANNER_TYPE.NOTICE]: 'icons/ic_info',
} as const;
