/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
// eslint-disable-next-line import/prefer-default-export
export const SITE_DETAIL_ROUTE = {
  SITE_INFO: {
    PATH: 'dashboard',
    NAME: 'DeviceDashboard',
  },
  SITE_ENERGY: {
    PATH: 'siteEnergy',
    NAME: 'SiteEnergy',
  },
  ENERGY_STATISTICS: {
    PATH: 'graph',
    NAME: 'DeviceGraph',
  },
  ARRAY_MAPPING: {
    PATH: 'smartmodule',
    NAME: 'SmartModuleWeb',
  },
  SYSTEM_SETTING: {
    PATH: 'setting',
    NAME: 'Installation',
  },
  CONTROL: {
    PATH: 'control',
    NAME: 'Control',
  },
  ERROR_HISTORY: {
    PATH: 'event',
    NAME: 'DeviceEvent',
  },
} as const;
