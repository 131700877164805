
import { defineComponent } from 'vue';

import { Image } from '@hems/component';

export default defineComponent({
  name: 'Header',
  components: {
    Image,
  },
});
