/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import { ref, onUnmounted } from 'vue';
import { Client } from '@stomp/stompjs';
import { useAppMode } from '@hems/util/src/composable/appMode';
// eslint-disable-next-line import/prefer-default-export
export const useStomp = () => {
    const { isLocal } = useAppMode();
    const stompClient = ref(null);
    const isStompConnected = ref(false);
    const stompConnect = (config) => {
        const websocket = new WebSocket(config.brokerURL, ['v01.stomp']);
        return new Promise((resolve, reject) => {
            stompClient.value = new Client({
                webSocketFactory: () => websocket,
                connectHeaders: config.connectHeaders,
                reconnectDelay: 0,
                debug: (msg) => {
                    // local 환경에서만 debugging 로그 출력
                    if (isLocal) {
                        console.log('[STOMP DEBUG]', msg);
                    }
                },
                onConnect: () => {
                    isStompConnected.value = true;
                    resolve(isStompConnected.value);
                },
                onDisconnect: () => {
                    isStompConnected.value = false;
                },
                onStompError: (frame) => {
                    console.error(frame.headers.message);
                    reject(new Error(frame.body));
                    stompDisconnect();
                },
            });
            stompClient.value.activate();
        });
    };
    const stompDisconnect = async () => {
        if (stompClient.value && stompClient.value.connected) {
            await stompClient.value.deactivate();
            isStompConnected.value = false;
        }
        return isStompConnected.value;
    };
    const stompSubscribe = (subscribeTopic, callback) => {
        if (stompClient.value && stompClient.value.connected) {
            return stompClient.value.subscribe(subscribeTopic, callback);
        }
        return null;
    };
    const stompUnsubscribe = (subscription) => {
        if (stompClient.value) {
            stompClient.value.unsubscribe(subscription.id);
        }
    };
    const stompPublish = (publishConfig) => {
        if (stompClient.value && stompClient.value.connected) {
            stompClient.value.publish(publishConfig);
        }
    };
    onUnmounted(() => {
        stompDisconnect();
    });
    return {
        isStompConnected,
        stompConnect,
        stompDisconnect,
        stompSubscribe,
        stompUnsubscribe,
        stompPublish,
    };
};
