import type { AxiosInstance } from 'axios';

import type { CommonResponseWrapper } from 'hems';

import type { WebHmiPasswordInfo, WebHmiPasswordSearchOptions } from 'hems/webHmiManagement';

import { apiWrapper } from '../../util/helper';
import { Service } from '../Service';

export default class WebHmiManagementService extends Service {
  constructor(axios: AxiosInstance) {
    super(axios);
  }

  public async getWebHmiPassword(params: WebHmiPasswordSearchOptions): Promise<WebHmiPasswordInfo> {
    const { data } = await this.get<CommonResponseWrapper<WebHmiPasswordInfo>>(
      apiWrapper.managerApi('/managements/security/ems/web/hmi/passwords'),
      params
    );

    return data;
  }
}

export { WebHmiManagementService };
