/* eslint-disable @typescript-eslint/no-var-requires */
import { fabric } from 'fabric';
import cloneDeep from 'lodash-es/cloneDeep';
import range from 'lodash-es/range';
import { Helper, MapperConstant as Constant, SmartModuleHelperPro, DateHelper } from '@hems/util';
import { UNIT } from '@hems/util/src/constant';
import { ARRAY_EDIT_MODULE_COLOR, AC_COMBINER_BG_COLOR, COMMON_MODULE_COLOR, LIST_MATCH_MODULE_COLOR, TEXTBOX_COLOR, BACKGROUND_COLOR, BASIC_GRAY_COLOR, } from '@hems/util/src/constant/color';
import { MAPPER_BUTTON_TYPE, MAPPER_MODULE_COLOR_CODE, MAPPER_PERIOD_DATA, MAPPER_POWER_PHASE_THRESHOLD, MAPPER_STATUS, getMapperRoundedRectColor, ARRAY_REVERSE_ANGLE, ARRAY_SHAPE, MAPPER_POWER_DEFAULT_VALUE, } from '@hems/util/src/constant/mapper';
import { getMapperDisplaySerialNumberText } from '@hems/util/src/helper/mapper/mapperFormatHelper';
import { isTextBoxType } from '@hems/util/src/helper/mapper/smartmoduleHelperPro';
import { formatUnitNumber } from '@hems/util/src/helper/numberformatHelper';
// FIXME: 커스텀 RoundedRect 속성에 대한 타입 구현
// createClass 함수 리턴 값이 any이므로 추후 리팩토링
const Fabric = fabric;
Fabric.RoundedRect = fabric.util.createClass(fabric.Rect, {
    type: 'roundedRect',
    initialize(options) {
        this.callSuper('initialize', options);
        this.topLeft = 0;
        this.topRight = 0;
        this.bottomLeft = 2;
        this.bottomRight = 2;
    },
    _render(ctx) {
        const w = this.width;
        const h = this.height;
        const x = -this.width / 2;
        const y = -this.height / 2;
        const k = 1 - 0.5522847498;
        ctx.beginPath();
        // top left
        ctx.moveTo(x + this.topLeft, y);
        // line to top right
        ctx.lineTo(x + w - this.topRight, y);
        ctx.bezierCurveTo(x + w - k * this.topRight, y, x + w, y + k * this.topRight, x + w, y + this.topRight);
        // line to bottom right
        ctx.lineTo(x + w, y + h - this.bottomRight);
        ctx.bezierCurveTo(x + w, y + h - k * this.bottomRight, x + w - k * this.bottomRight, y + h, x + w - this.bottomRight, y + h);
        // line to bottom left
        ctx.lineTo(x + this.bottomLeft, y + h);
        ctx.bezierCurveTo(x + k * this.bottomLeft, y + h, x, y + h - k * this.bottomLeft, x, y + h - this.bottomLeft);
        // line to top left
        ctx.lineTo(x, y + this.topLeft);
        ctx.bezierCurveTo(x, y + k * this.topLeft, x + k * this.topLeft, y, x + this.topLeft, y);
        ctx.closePath();
        this._renderPaintInOrder(ctx);
    },
});
// FIXME: 커스텀 RoundedRect 속성에 대한 타입 구현
// fromObject 함수 리턴 값이 any이므로 추후 리팩토링
Fabric.RoundedRect.fromObject = (object, callback) => {
    const roundedRect = new Fabric.RoundedRect(object);
    callback?.(roundedRect);
    return roundedRect;
};
/**
 * 모듈 선택 해제
 * @param canvas
 * @param object
 */
export function unSelectActiveObject(canvas) {
    canvas.discardActiveObject();
    const objects = canvas.getObjects();
    objects.forEach((object) => {
        if (SmartModuleHelperPro.isArrayType(object) || SmartModuleHelperPro.isInverterType(object)) {
            object._objects.forEach((module) => {
                if (SmartModuleHelperPro.isStringTextType(module))
                    return;
                if (SmartModuleHelperPro.isArraySelectionType(module)) {
                    module.set({ visible: false });
                }
                if (SmartModuleHelperPro.isInverterType(object) && SmartModuleHelperPro.isInverterType(module)) {
                    module._objects.forEach((object) => {
                        if (object.type == 'rect') {
                            object.set({ fill: AC_COMBINER_BG_COLOR.POWER_BG_COLOR });
                        }
                        else if (String(object.fill).toLowerCase() === AC_COMBINER_BG_COLOR.IMAGE_LIGHT_BG_COLOR) {
                            object.set({ fill: AC_COMBINER_BG_COLOR.IMAGE_BG_COLOR, stroke: AC_COMBINER_BG_COLOR.IMAGE_BG_COLOR });
                        }
                        else if (String(object.fill).toLowerCase() === AC_COMBINER_BG_COLOR.SEQ_LIGHT_BG_COLOR) {
                            object.set({ fill: AC_COMBINER_BG_COLOR.SEQ_BG_COLOR });
                        }
                    });
                }
                else {
                    module.set({ opacity: 1 });
                }
            });
        }
        else {
            // Logical Layout Only
            if (object.type == 'line') {
                object.set({ opacity: 1 });
            }
            if (object.qtype == 'count') {
                object._objects.forEach((module) => {
                    if (module.type == 'rect') {
                        // count box color
                        module.set({ fill: AC_COMBINER_BG_COLOR.COUNT_BOX_BG_COLOR });
                    }
                    else {
                        // count icon, text
                        module.set({ opacity: 1 });
                    }
                });
            }
        }
    });
    canvas.requestRenderAll();
}
// EDITOR ONLY
export function onSelectModule(canvas, object, array) {
    if (canvas.mode === MAPPER_STATUS.ARRAY_EDIT && canvas.editArrayId === array.qid) {
        // array 편집 상태일 때 모듈 선택 시
        const moduleRect = object._objects.find((child) => child.get('type') === 'rect');
        if (!moduleRect)
            return;
        toggleModule(object, moduleRect, object.qvisible);
    }
}
const toggleModule = (module, moduleRect, qvisible) => {
    const isShowModule = !qvisible;
    module.set({ qvisible: isShowModule });
    const moduleColors = {
        stroke: isShowModule ? ARRAY_EDIT_MODULE_COLOR.SHOW_BORDER : ARRAY_EDIT_MODULE_COLOR.HIDE_BORDER,
        fill: isShowModule ? ARRAY_EDIT_MODULE_COLOR.SHOW_FILL : ARRAY_EDIT_MODULE_COLOR.HIDE_FILL,
    };
    moduleRect.set(moduleColors);
    const showIcon = isShowModule ? MAPPER_STATUS.HIDE_MODULE : MAPPER_STATUS.SHOW_MODULE;
    getIconImageFromModule(module, MAPPER_STATUS.HIDE_MODULE)?.set({ visible: showIcon === MAPPER_STATUS.HIDE_MODULE });
    getIconImageFromModule(module, MAPPER_STATUS.SHOW_MODULE)?.set({ visible: showIcon === MAPPER_STATUS.SHOW_MODULE });
};
export function onSelectModuleForListMatch(canvas, object) {
    if (!canvas.mode)
        return;
    const mapperMode = canvas.mode;
    const mapperModeSelected = mapperMode === MAPPER_STATUS.LIST_MATCH ? MAPPER_STATUS.LIST_MATCH_SELECT : MAPPER_STATUS.QR_SCAN_SELECT;
    canvas.discardActiveObject();
    if (object.qserial === '') {
        getIconImageFromModule(object, mapperMode)?.set({ visible: false });
        getIconImageFromModule(object, mapperModeSelected)?.set({ visible: true });
    }
    else {
        const moduleText = object._objects.find((child) => child.get('type') === 'text');
        moduleText?.set({ fill: LIST_MATCH_MODULE_COLOR.SELECT_TEXT });
    }
    const moduleRect = object._objects.find((child) => child.get('type') === 'rect');
    moduleRect?.set({ stroke: LIST_MATCH_MODULE_COLOR.SELECT_BORDER, fill: LIST_MATCH_MODULE_COLOR.SELECT_FILL });
    canvas.requestRenderAll();
}
/**
 * 모듈 단일 선택
 * @param canvas
 * @param object
 */
export function setActiveObject(canvas, activeObject) {
    if (!activeObject || (!activeObject.qserial && !activeObject.inverterSerial))
        return;
    canvas.setActiveObject(activeObject);
    // 다른 object들 모두 회색 or blur 로 변경
    const objects = canvas.getObjects();
    objects.forEach((object) => {
        if (SmartModuleHelperPro.isArrayType(object) || SmartModuleHelperPro.isInverterType(object)) {
            object._objects.forEach((module) => {
                if (SmartModuleHelperPro.isStringTextType(module))
                    return;
                if (activeObject.qserial !== module.qserial || activeObject.inverterSerial !== module.inverterSerial) {
                    if (SmartModuleHelperPro.isInverterType(object) && SmartModuleHelperPro.isInverterType(module)) {
                        module._objects.forEach((object) => {
                            if (object.type == 'rect') {
                                object.set({ fill: AC_COMBINER_BG_COLOR.POWER_LIGHT_BG_COLOR });
                            }
                            else if (String(object.fill).toLowerCase() === AC_COMBINER_BG_COLOR.IMAGE_BG_COLOR) {
                                object.set({
                                    fill: AC_COMBINER_BG_COLOR.IMAGE_LIGHT_BG_COLOR,
                                    stroke: AC_COMBINER_BG_COLOR.IMAGE_LIGHT_BG_COLOR,
                                });
                            }
                            else if (String(object.fill).toLowerCase() === AC_COMBINER_BG_COLOR.SEQ_BG_COLOR) {
                                object.set({ fill: AC_COMBINER_BG_COLOR.SEQ_LIGHT_BG_COLOR });
                            }
                        });
                    }
                    else {
                        module.set({ opacity: 0.3 });
                    }
                }
                else {
                    module.set({ opacity: 1 });
                }
            });
        }
        else {
            // Logical Layout Only
            if (object.type == 'line') {
                object.set({ opacity: 0.3 });
            }
            else {
                if (object.qtype == 'count') {
                    object._objects.forEach((module) => {
                        if (module.type == 'rect') {
                            // count box color
                            module.set({ fill: AC_COMBINER_BG_COLOR.COUNT_BOX_LIGHT_BG_COLOR });
                        }
                        else {
                            // count icon, text
                            module.set({ opacity: 0.3 });
                        }
                    });
                }
            }
        }
    });
    canvas.requestRenderAll();
}
/**
 * serial number를 통해 일치하는 모듈을 찾아 active
 * @param canvas
 * @param serial
 */
export function setActiveObjectFromSerial(canvas, serial) {
    const objects = canvas.getObjects();
    objects.forEach((_object) => {
        if (SmartModuleHelperPro.isArrayType(_object)) {
            _object._objects.forEach((module) => {
                if (SmartModuleHelperPro.isStringTextType(module))
                    return;
                if (serial === module.qserial) {
                    canvas.setActiveObject(module);
                    module.set({ opacity: 1 });
                }
                else {
                    module.set({ opacity: 0.3 });
                }
            });
        }
    });
    canvas.requestRenderAll();
}
/**
 * serial number를 통해 일치하는 모듈을 찾아 mapping clear
 * @param canvas
 * @param serial
 */
export function clearMappingFromSerial(canvas, serial) {
    if (serial) {
        const objects = canvas.getObjects();
        objects.forEach((_object) => {
            if (SmartModuleHelperPro.isArrayType(_object)) {
                _object._objects.filter((module) => {
                    if (serial === module.qserial) {
                        clearModuleInfo(canvas, module);
                    }
                });
            }
        });
    }
    canvas.requestRenderAll();
}
export function clearModuleInfo(canvas, module) {
    module._objects.map((child) => {
        if (child.get('type') === 'text' && module.qserial.includes(child.get('text').slice(-3))) {
            module.set({ qtype: 'module' });
            child.set({ text: '' });
            child.set({ fill: COMMON_MODULE_COLOR.TEXT_WHITE });
        }
    });
    module.set({ qserial: '' });
    if (canvas.mode === 'list_match' && module.qserial === '') {
        getIconImageFromModule(module, 'list_match')?.set({ visible: true });
    }
    else if (canvas.mode === 'qr_scan' && module.qserial === '') {
        getIconImageFromModule(module, 'qr_scan')?.set({ visible: true });
    }
}
/**
 * 선택한 모듈 unmapping
 * @param canvas
 * @param module
 */
export function unmappingFromSerial(canvas, module) {
    // qserial 및 text 초기화
    clearModuleInfo(canvas, module);
    const moduleRect = module._objects.find((child) => child.get('type') === 'rect');
    moduleRect?.set({ stroke: COMMON_MODULE_COLOR.DEFAULT_BORDER, fill: COMMON_MODULE_COLOR.DEFAULT_FILL });
    canvas.requestRenderAll();
}
export function getActiveModule(canvas) {
    const active = canvas?.getActiveObject();
    if (SmartModuleHelperPro.isModuleType(active)) {
        return active;
    }
    return null;
}
/**
 * Array 선택
 * EDITOR
 * @param canvas
 * @param object
 */
export const setActiveArray = (canvas, object) => {
    unSelectActiveObject(canvas);
    if (canvas.mode === MAPPER_STATUS.ARRAY_EDIT)
        return;
    canvas.setActiveObject(object);
    object?._objects?.forEach((child) => {
        if (SmartModuleHelperPro.isArraySelectionType(child)) {
            child.set({ visible: true });
        }
    });
};
export const setActiveComment = (canvas, object, placeholderText, callback) => {
    const objects = canvas.getObjects();
    hideArraySelectionObject(objects);
    if (SmartModuleHelperPro.isTextBoxType(object)) {
        addTextEvent(object, placeholderText, callback);
        setControlShow(object);
    }
};
/**
 * array 생성 함수
 * @param info
 * @param canvas
 */
export function createArray(info, canvas) {
    const arrayId = Helper.getUUID();
    let arrayWidth = (info.col ?? 0) * (Constant.MODULE_LANDSCAPE_WIDTH + Constant.MODULE_MARGIN) - Constant.MODULE_MARGIN;
    let arrayHeight = (info.row ?? 0) * (Constant.MODULE_LANDSCAPE_HEIGHT + Constant.MODULE_MARGIN) -
        Constant.MODULE_MARGIN +
        Constant.ARRAY_TEXT_HEIGHT;
    if (info.shape === ARRAY_SHAPE.PORTRAIT) {
        arrayWidth = (info.col ?? 0) * (Constant.MODULE_PORTRAIT_WIDTH + Constant.MODULE_MARGIN) - Constant.MODULE_MARGIN;
        arrayHeight =
            (info.row ?? 0) * (Constant.MODULE_PORTRAIT_HEIGHT + Constant.MODULE_MARGIN) -
                Constant.MODULE_MARGIN +
                Constant.ARRAY_TEXT_HEIGHT;
    }
    const viewRotate = setViewRotate(info.shape, info.rotate);
    const arrayOption = {
        qid: arrayId,
        qtype: 'array',
        qshape: info.shape,
        qtilt: info.tilt,
        qrotate: info.rotate,
        qcol: info.col,
        qrow: info.row,
        qname: info.arrayName,
        angle: viewRotate,
        width: arrayWidth,
        height: arrayHeight,
        cornerSize: Constant.CONTROL_ICON_SIZE,
        touchCornerSize: Constant.CONTROL_ICON_SIZE,
    };
    // FIXME: new fabric.Group(): Object 이므로 부득이하게 타입 지정으로 처리. 추후 개선 필요
    const array = new fabric.Group();
    array.set(Constant.arrayDefaultOptions);
    array.set(arrayOption);
    setControlShow(array);
    // array selection 생성
    createArraySelectionObject(array);
    // module 생성
    createModules(array, info);
    // string id text 생성
    createStringIdText(array);
    // canvas.add(array);
    addObjectToCanvas(canvas, array);
    alignmentToCenter(canvas);
    unLockDragObject(canvas);
    setActiveArray(canvas, array);
    insertToAutoScanArrayOrder(array, canvas);
}
export function insertToAutoScanArrayOrder(array, canvas) {
    if (canvas.arrayOrder) {
        console.log(canvas.arrayOrder);
        if (canvas.arrayOrder.length === 1 && canvas.arrayOrder[0] === array.qid) {
            return;
        }
        const index = canvas.arrayOrder.findIndex((arr) => arr === array.qid);
        if (index !== -1) {
            sortArrayWithCoordinate(canvas);
        }
        else {
            canvas.arrayOrder.push(array.qid);
            sortArrayWithCoordinate(canvas);
        }
    }
    else {
        canvas.arrayOrder = [array.qid];
    }
}
function sortArrayWithCoordinate(canvas) {
    const objects = canvas.getObjects();
    if (canvas.arrayOrder && objects) {
        canvas.arrayOrder = canvas.arrayOrder.sort((a, b) => {
            const firstArray = objects.find((object) => SmartModuleHelperPro.isArrayType(object) && object.qid === a);
            const secondArray = objects.find((object) => SmartModuleHelperPro.isArrayType(object) && object.qid === b);
            if (firstArray && secondArray) {
                if (firstArray.top === secondArray.top) {
                    return firstArray.left - secondArray.left;
                }
                return firstArray.top - secondArray.top;
            }
            return 0;
        });
    }
}
function createArraySelectionObject(array) {
    if (!array.width || !array.height)
        return;
    const modulePadding = 8;
    const width = SmartModuleHelperPro.isInverterType(array) ? array.width - 1.5 : array.width + modulePadding;
    const height = SmartModuleHelperPro.isInverterType(array) ? array.height + 4.5 : array.height + modulePadding;
    const left = SmartModuleHelperPro.isInverterType(array) ? (array.width / 2) * -1 + 0.5 : (array.width / 2) * -1 - 4;
    const top = SmartModuleHelperPro.isInverterType(array) ? (array.height / 2) * -1 - 14 : (array.height / 2) * -1 - 22;
    const arraySelectionOption = {
        qtype: 'array-selection',
        visible: false,
        selectable: false,
        hasControls: false,
        lockMovementX: false,
        lockMovementY: false,
        width,
        height,
        left,
        top,
    };
    const arraySelectionObject = new fabric.Group();
    arraySelectionObject.set(arraySelectionOption);
    const fillRect = new fabric.Rect({
        left,
        top: top + 26,
        width,
        height: height - 6,
        strokeWidth: 0,
        rx: 2,
        ry: 2,
        fill: COMMON_MODULE_COLOR.SELECT_FILL,
        opacity: 0.1,
        hasControls: false,
        visible: true,
    });
    const StrokeRect = new fabric.Rect({
        left,
        top: top + 26,
        width,
        height: height - 6,
        strokeWidth: 0.7,
        stroke: COMMON_MODULE_COLOR.SELECT_BORDER,
        rx: 2,
        ry: 2,
        fill: 'transparent',
        hasControls: false,
        visible: true,
    });
    arraySelectionObject.add(fillRect);
    arraySelectionObject.add(StrokeRect);
    array.add(arraySelectionObject);
}
const hideArraySelectionObject = (objects) => {
    if (!objects)
        return;
    objects
        .filter((object) => SmartModuleHelperPro.isArrayType(object) || SmartModuleHelperPro.isInverterType(object))
        .forEach((object) => {
        object._objects
            .filter((module) => !SmartModuleHelperPro.isStringTextType(module) && SmartModuleHelperPro.isArraySelectionType(module))
            .forEach((module) => module.set({ visible: false }));
    });
};
/**
 * Array 가독성을 위한 설정 각도 변환 함수
 * @param inputRotate 설정(입력)한 각도
 * @returns Array 표현 각도
 */
function setViewRotate(shape, inputRotate = 0) {
    const isLandscape = shape === ARRAY_SHAPE.LANDSCAPE;
    const reverseMinRange = isLandscape ? ARRAY_REVERSE_ANGLE.LANDSCAPE_MIN : ARRAY_REVERSE_ANGLE.PORTRAIT_MIN;
    const reverseMaxRange = isLandscape ? ARRAY_REVERSE_ANGLE.LANDSCAPE_MAX : ARRAY_REVERSE_ANGLE.PORTRAIT_MAX;
    let viewRotate = inputRotate;
    // 역방향(Reverse) 전환 필요 여부
    if (reverseMinRange <= inputRotate && inputRotate < reverseMaxRange) {
        viewRotate =
            inputRotate >= ARRAY_REVERSE_ANGLE.REVERSE_ANGLE
                ? inputRotate - ARRAY_REVERSE_ANGLE.REVERSE_ANGLE
                : inputRotate + ARRAY_REVERSE_ANGLE.REVERSE_ANGLE;
    }
    return viewRotate;
}
export function modifyArray(info, canvas) {
    const activeObject = canvas.getActiveObject();
    if (activeObject && SmartModuleHelperPro.isArrayType(activeObject)) {
        const viewRotate = setViewRotate(info.shape, info.rotate);
        activeObject.set({
            qname: info.arrayName,
            qtilt: info.tilt,
            qrotate: info.rotate,
            angle: viewRotate,
        });
        if (info.arrayName) {
            activeObject._objects.forEach((object) => {
                if (SmartModuleHelperPro.isStringTextType(object)) {
                    object.set({
                        text: info.arrayName,
                    });
                }
            });
        }
    }
    canvas.requestRenderAll();
}
const svgObjects = [[], []];
const svgOptions = [{}, {}];
export function initSVGElement() {
    const sourceList = [
        require('@hems/component/resources/images/smartmodule/ic_module_pattern_landscape.svg'),
        require('@hems/component/resources/images/smartmodule/ic_module_pattern_portrait.svg'),
    ];
    sourceList.forEach((source, index) => {
        fabric.loadSVGFromURL(source, (objects, options) => {
            svgObjects[index] = objects;
            svgOptions[index] = options;
        });
    });
}
/**
 * array 내부 빈 pannel들 생성 함수
 * @param array
 * @param info
 */
// FIXME:
// eslint-disable-next-line complexity
function createModules(array, info) {
    const isLandscape = info.shape === ARRAY_SHAPE.LANDSCAPE;
    const arrayWidth = array.width ?? 0;
    const arrayHeight = array.height ?? 0;
    let moduleLeft = (arrayWidth / 2) * -1;
    let moduleTop = (arrayHeight / 2) * -1;
    for (let i = 1; i <= info.row; i++) {
        for (let j = 1; j <= info.col; j++) {
            let object = [];
            let option = {};
            object = isLandscape ? cloneDeep(svgObjects[0]) : cloneDeep(svgObjects[1]);
            option = isLandscape ? cloneDeep(svgOptions[0]) : cloneDeep(svgOptions[1]);
            // FIXME: groupSVGElements(): Object 이므로 부득이하게 타입 지정으로 처리. 추후 개선 필요
            const module = fabric.util.groupSVGElements(object, option);
            const moduleId = Helper.getUUID();
            module.set(Constant.moduleDefaultOptions);
            const options = {
                qid: moduleId,
                qtype: 'module',
                qserial: '',
                left: moduleLeft,
                top: moduleTop,
                qvisible: true,
                width: isLandscape ? Constant.MODULE_LANDSCAPE_WIDTH : Constant.MODULE_PORTRAIT_WIDTH,
                height: isLandscape ? Constant.MODULE_LANDSCAPE_HEIGHT : Constant.MODULE_PORTRAIT_HEIGHT,
                qcol: j,
                qrow: i,
            };
            module.set(options);
            module.setCoords();
            array.add(module);
            const moduleWidth = module.width ?? 0;
            const moduleHeight = module.height ?? 0;
            // Add module object for physical layout
            module._objects?.map((object) => {
                if (object.type === 'path') {
                    object.set({
                        visible: false,
                    });
                }
            });
            const powerText = new fabric.Textbox('00.00', Constant.getPowerDataTextDefaultOptions(info.shape));
            const unitText = new fabric.Text('kWh', Constant.getUnitTextDefaultOptions(info.shape));
            const serialText = new fabric.Text('...132', Constant.getSerialTextDefaultOptions(info.shape));
            const textShadow = new Fabric.RoundedRect(Constant.getSerialTextRectDefaultOptions(info.shape));
            module.add(powerText);
            module.add(unitText);
            module.add(serialText);
            module.add(textShadow);
            textShadow.sendToBack();
            // end of physical layout object create
            const rect = new fabric.Rect(Constant.rectDefaultOptions);
            rect.set({
                fill: COMMON_MODULE_COLOR.DEFAULT_FILL,
                width: moduleWidth,
                height: moduleHeight,
                left: -moduleWidth / 2,
                top: -moduleHeight / 2,
            });
            module.add(rect);
            rect.sendToBack();
            createSerialTextToModule(module, info.shape);
            createImageToModule(module);
            const newPosition = calcPannelPosition(moduleLeft, moduleTop, arrayWidth, info);
            moduleLeft = newPosition.left;
            moduleTop = newPosition.top;
        }
    }
}
function createSerialTextToModule(module, shape) {
    const lastSerialChar = getMapperDisplaySerialNumberText(module.qserial);
    const serialText = new fabric.Text(lastSerialChar, Constant.getSerialTextDefaultOptionsForEditor());
    const option = shape === ARRAY_SHAPE.LANDSCAPE ? { angle: 0, top: 0, left: 15 } : { angle: 270, top: -15, left: 0 };
    serialText.set(option);
    module.add(serialText);
    serialText.bringToFront();
}
function createImageToModule(module) {
    const iconImageUrlList = [
        { src: minusImg.src, type: 'minus' },
        { src: plusImg.src, type: 'plus' },
        { src: linkImg.src, type: 'list_match' },
        { src: linkSelectImg.src, type: 'list_match_select' },
        { src: qrImg.src, type: 'qr_scan' },
        { src: qrSelectImg.src, type: 'qr_scan_select' },
    ];
    iconImageUrlList.forEach((icon) => {
        fabric.Image.fromURL(icon.src, (img) => {
            // FIXME: Custom 속성이 없어 부득이하게 타입 지정으로 처리. 추후 개선 필요
            const newImage = img;
            newImage.set({
                width: 20,
                height: 20,
                left: -7,
                top: -6,
                scaleX: 0.7,
                scaleY: 0.7,
                visible: false,
                qtype: icon.type,
            });
            module.add(newImage);
        });
    });
}
/**
 * 이전 패널을 기준으로 새로운 패널의 left, top 좌표 계산 함수
 * @param left 이전에 그린 left값
 * @param top 이전에 그린 top값
 * @param arrayWidth 패널이 위치할 array의 width
 * @returns 새로운 left, top
 */
function calcPannelPosition(left, top, arrayWidth, info) {
    const width = info.shape === ARRAY_SHAPE.LANDSCAPE ? Constant.MODULE_LANDSCAPE_WIDTH : Constant.MODULE_PORTRAIT_WIDTH;
    const height = info.shape === ARRAY_SHAPE.LANDSCAPE ? Constant.MODULE_LANDSCAPE_HEIGHT : Constant.MODULE_PORTRAIT_HEIGHT;
    left = left + width + Constant.MODULE_MARGIN;
    if (left > arrayWidth / 2 - width) {
        left = (arrayWidth / 2) * -1;
        top = top + height + Constant.MODULE_MARGIN;
    }
    return { left, top };
}
/**
 * comment box 생성
 * @param info
 * @param canvas
 */
export const createComment = (canvas, placeholderText, callback) => {
    // FIXME: Custom 속성이 없어 부득이하게 타입 지정으로 처리. 추후 개선 필요
    const commentBox = new fabric.Textbox('', Constant.textDefaultOptions);
    commentBox.set({
        editable: true,
        borderColor: COMMON_MODULE_COLOR.SELECT_BORDER,
        editingBorderColor: COMMON_MODULE_COLOR.SELECT_BORDER,
        cursorColor: TEXTBOX_COLOR.SELECT_CURSOR,
        lockScalingY: true,
        fill: 'gray',
        cornerSize: 12,
        cornerStyle: 'circle',
    });
    commentBox.set(Constant.textDefaultOptions);
    const regExp = /[a-zA-Z0-9`~!@#$%^&*()+\-_=,.?"':;/{}}|[\]\\<>\s]/;
    // 특수문자 제한
    $('body').on('keydown', (event) => {
        if (!regExp.test(event.key)) {
            event.preventDefault();
        }
    });
    addObjectToCanvas(canvas, commentBox);
    addTextEvent(commentBox, placeholderText, callback);
    enterEditing(commentBox);
    commentBox?.hiddenTextarea?.focus();
    setControlShow(commentBox);
    commentBox.set('backgroundColor', BACKGROUND_COLOR.MAPPER_COMMENT_BOX);
    alignmentToCenter(canvas);
    unLockDragObject(canvas);
    canvas.setActiveObject(commentBox);
};
let isTextBoxMode;
const setPlaceholderText = (box, color, placeholderText) => {
    box.set('text', placeholderText);
    box.set('fill', color);
};
const handleEmptyText = (box, placeholderText) => {
    if (box.text === placeholderText) {
        box.set('text', '');
    }
};
const enterEditing = (box) => {
    if (!box.isEditing) {
        box.enterEditing();
    }
};
const exitEditing = (box) => {
    if (box.isEditing) {
        box.exitEditing();
    }
};
const mouseUpHandler = (option, commentBox, placeholderText) => {
    if (!commentBox.evented) {
        commentBox.off('mouseup', () => mouseUpHandler(option, commentBox, placeholderText));
        return;
    }
    commentBox.setCursorByClick(option.e);
    enterEditing(commentBox);
    commentBox?.hiddenTextarea?.focus();
    setControlShow(commentBox);
    commentBox.set('backgroundColor', BACKGROUND_COLOR.MAPPER_COMMENT_BOX);
    /* onfocus */
    if (!commentBox.text) {
        setPlaceholderText(commentBox, TEXTBOX_COLOR.TEXT_GRAY, placeholderText);
    }
    isTextBoxMode = true;
};
export const addTextEvent = (commentBox, placeholderText, callback) => {
    commentBox.on('editing:entered', () => {
        if (!commentBox.text) {
            setPlaceholderText(commentBox, TEXTBOX_COLOR.TEXT_GRAY, placeholderText);
        }
        isTextBoxMode = true;
    });
    commentBox.on('mouseup', (event) => mouseUpHandler(event, commentBox, placeholderText));
    commentBox.on('editing:exited', () => {
        commentBox.set('backgroundColor', 'transparent');
        // onblur
        handleEmptyText(commentBox, placeholderText);
        // set history
        if (!isTextBoxMode && callback) {
            callback();
        }
    });
    commentBox.on('changed', () => {
        if (commentBox.text !== undefined) {
            commentBox?.hiddenTextarea?.setAttribute('maxlength', '100');
            if (!commentBox.text || commentBox.text === placeholderText) {
                setPlaceholderText(commentBox, TEXTBOX_COLOR.TEXT_GRAY, placeholderText);
            }
            else {
                commentBox.set('fill', TEXTBOX_COLOR.TEXT_BLACK);
            }
        }
        isTextBoxMode = false;
    });
    ['moving', 'scaling', 'rotating'].forEach((eventType) => {
        commentBox.on(eventType, () => {
            exitEditing(commentBox);
        });
    });
};
/**
 * canvas에 객체 추가 함수
 * @param canvas
 * @param object
 */
function addObjectToCanvas(canvas, object) {
    canvas.add(object);
    object.viewportCenter();
    if (object.top && object.left) {
        object.set({ top: object.top + 20, left: object.left + 20 });
    }
    object.setCoords();
    canvas.requestRenderAll();
}
export function getJsonData(canvas) {
    return JSON.stringify(canvas.toJSON(Constant.extraAttribute));
}
/**
 * 선택 객체 삭제
 * @param canvas
 */
export function deleteObject(canvas) {
    const activeObjects = canvas.getActiveObjects();
    canvas.discardActiveObject();
    if (activeObjects) {
        activeObjects.forEach((object) => {
            if (object.group) {
                if (object.group._objects.length <= 2) {
                    canvas.remove(object.group);
                }
                else {
                    object.group.remove(object);
                }
            }
            else {
                if (isTextBoxType(object)) {
                    object.evented = false;
                }
                canvas.remove(object);
                canvas.arrayOrder = canvas.arrayOrder?.filter((qid) => {
                    qid === object.qid;
                });
            }
        });
    }
    canvas.requestRenderAll();
}
export function changeModuleUnlinked(module, canvas, mode) {
    if (!module)
        return false;
    if (module.qserial === '') {
        getIconImageFromModule(module, mode)?.set({ visible: true });
    }
    getIconImageFromModule(module, `${mode}_select`)?.set({ visible: false });
    const moduleRect = module._objects.find((child) => child.get('type') === 'rect');
    moduleRect?.set({ stroke: 'transparent', fill: COMMON_MODULE_COLOR.DEFAULT_FILL });
    const moduleText = module._objects.find((child) => child.get('type') === 'text');
    moduleText?.set({ fill: COMMON_MODULE_COLOR.TEXT_WHITE });
    canvas.requestRenderAll();
}
/**
 * 모듈에 시리얼 번호 맵핑
 * @param serial
 * @param module
 * @param canvas
 * @returns
 */
export function mappingSerialToObject(serial, module, canvas) {
    if (!module)
        return false;
    if (serial === '') {
        return false;
    }
    // TODO: s/n validation check 로직 추가
    console.log('serial number', serial);
    getIconImageFromModule(module, 'list_match')?.set({ visible: false });
    getIconImageFromModule(module, 'qr_scan')?.set({ visible: false });
    module.set({ qserial: serial });
    // set text
    const lastSerialChar = getMapperDisplaySerialNumberText(module.qserial);
    const moduleText = module._objects.find((child) => child.qtype === 'edit-serial-text');
    moduleText?.set({ text: lastSerialChar });
    canvas.requestRenderAll();
    return true;
}
function findNextModulePosition(col, row, arrayCol) {
    let nextRow = row;
    let nextCol = col;
    if (row % 2 != 0) {
        if (arrayCol === col) {
            nextRow += 1;
        }
        else {
            nextCol += 1;
        }
    }
    else {
        if (col === 1) {
            nextRow += 1;
        }
        else {
            nextCol -= 1;
        }
    }
    return { nextCol, nextRow };
}
function isPossibleModuleForMapping(col, row, array) {
    const module = array._objects.find((module) => module.qcol === col && module.qrow === row);
    if (module && module.qvisible && !module.qserial) {
        return false;
    }
    return true;
}
// FIXME:
// eslint-disable-next-line complexity
export function findNextModuleForAutoScan(canvas, module) {
    const col = module.qcol || 1;
    const row = module.qrow || 1;
    let array = module.group;
    let newCol = col;
    let newRow = row;
    const maxModuleCount = getArrayObjects(canvas).reduce((prev, curr) => prev + curr._objects.length - 2, 0);
    let curModuleCount = 0;
    // TODO: 삭제 예정. 현재 저장된 데이터들에 arrayOrder 속성 넣어주기 위함.
    insertToAutoScanArrayOrder(array, canvas);
    while (isPossibleModuleForMapping(newCol, newRow, array)) {
        curModuleCount += 1;
        if (curModuleCount === maxModuleCount) {
            newCol = -1;
            newRow = -1;
            break;
        }
        const { nextCol, nextRow } = findNextModulePosition(newCol, newRow, array.qcol);
        newCol = nextCol;
        newRow = nextRow;
        if (newCol > array.qcol || newRow > array.qrow) {
            console.log('go to next array');
            if (!canvas.arrayOrder || canvas.arrayOrder.length <= 1) {
                break;
            }
            let nextArrayIndex = canvas.arrayOrder.findIndex((qid) => array.qid === qid);
            if (nextArrayIndex + 1 === canvas.arrayOrder.length) {
                nextArrayIndex = 0;
            }
            else {
                nextArrayIndex += 1;
            }
            const nextArray = canvas.arrayOrder[nextArrayIndex];
            const findArray = getArrayObjects(canvas).find((object) => object.qid === nextArray);
            if (!findArray)
                break;
            array = findArray;
            newCol = 1;
            newRow = 1;
        }
        console.log(`${newCol},${newRow}`);
    }
    const nextModule = array._objects.find((module) => module.qcol === newCol && module.qrow === newRow) ?? null;
    return nextModule;
}
function getArrayObjects(canvas) {
    const objects = canvas.getObjects();
    return objects.filter((object) => SmartModuleHelperPro.isArrayType(object));
}
/**
 * Canvas 중앙 정렬
 * @param canvas
 * @param width
 * @param height
 * @returns
 */
export function alignmentToCenter(canvas, width, height) {
    const objects = canvas.getObjects();
    if (objects.length < 1) {
        return;
    }
    canvas.discardActiveObject();
    const seletion = new fabric.ActiveSelection(objects, {
        canvas,
    });
    canvas.setActiveObject(seletion);
    const activeObject = canvas.getActiveObject();
    if (!activeObject) {
        return;
    }
    const objWidth = activeObject.getScaledWidth();
    const objHeight = activeObject.getScaledHeight();
    let ratioW = 0;
    let ratioH = 0;
    let newZoom = 1;
    const additionRatio = objects.length === 1 ? 1.5 : 1;
    ratioW = (width || window.innerWidth) / objWidth;
    ratioH = (height || window.innerHeight) / objHeight / additionRatio;
    if (ratioW > ratioH) {
        newZoom = ratioH;
    }
    else {
        newZoom = ratioW;
    }
    if (newZoom > 1) {
        newZoom = newZoom * 0.5;
    }
    canvas.setZoom(newZoom);
    const zoom = canvas.getZoom();
    renderIconForZoom(newZoom);
    const panX = (canvas.getWidth() / zoom / 2 - activeObject._getLeftTopCoords().x - objWidth / 2) * zoom;
    const panY = (canvas.getHeight() / zoom / 2 - activeObject._getLeftTopCoords().y - objHeight / 2) * zoom;
    canvas.setViewportTransform([zoom, 0, 0, zoom, panX, panY]);
    unSelectActiveObject(canvas);
}
const getCoordinateForZoom = (point, event, canvas) => {
    if (point) {
        return { x: point.x, y: point.y };
    }
    if (event && event instanceof WheelEvent) {
        return { x: event.offsetX, y: event.offsetY };
    }
    return { x: canvas?.getCenter().left ?? 0, y: canvas?.getCenter().top ?? 0 };
};
/**
 * Canvas 줌 인/아웃
 * @param canvas
 * @param delta
 * @param event
 */
export const setZoomInOut = (canvas, delta, event, point) => {
    const originZoom = canvas.getZoom();
    const { x, y } = getCoordinateForZoom(point, event, canvas);
    let newZoom = canvas.getZoom() * Constant.SCALE_FACTOR ** delta;
    if (newZoom > Constant.MAX_ZOOM) {
        newZoom = Constant.MAX_ZOOM;
    }
    else if (newZoom < Constant.MIN_ZOOM) {
        newZoom = Constant.MIN_ZOOM;
    }
    canvas.zoomToPoint({ x, y }, newZoom);
    renderIconForZoom(newZoom);
    cornerSizeForZoom(newZoom, canvas);
    if ((originZoom <= 0.5 && newZoom > 0.5) || (originZoom > 0.5 && newZoom <= 0.5)) {
        hidePannelInfo(canvas);
    }
    canvas.renderTop();
    unSelectActiveObject(canvas);
    if (event) {
        event.preventDefault();
        event.stopPropagation();
    }
};
export const zoomInOutCanvas = (canvas, event, pinchZoom) => {
    let newPinchZoom = pinchZoom;
    if (newPinchZoom > Constant.MAX_ZOOM) {
        newPinchZoom = Constant.MAX_ZOOM;
    }
    else if (newPinchZoom < Constant.MIN_ZOOM) {
        newPinchZoom = Constant.MIN_ZOOM;
    }
    canvas.zoomToPoint({
        x: event?.pointer?.x ?? event.e.targetTouches[0].clientX,
        y: event?.pointer?.y ?? event.e.targetTouches[0].clientY,
    }, newPinchZoom);
    renderIconForZoom(newPinchZoom);
    cornerSizeForZoom(newPinchZoom, canvas);
    event.e.preventDefault();
    event.e.stopPropagation();
};
export const dragCanvas = (canvas, clientX, clientY, prevClientX, prevClientY) => {
    const deltaX = prevClientX !== 0 ? clientX - prevClientX : 0;
    const deltaY = prevClientY !== 0 ? clientY - prevClientY : 0;
    if (prevClientX !== 0 && prevClientX !== 0) {
        canvas.relativePan(new fabric.Point(deltaX, deltaY));
    }
};
// zoom<=1일 경우 모듈 내 text 숨김처리
function hidePannelInfo(canvas) {
    const objects = canvas.getObjects();
    objects.forEach((object) => {
        if (SmartModuleHelperPro.isArrayType(object)) {
            object._objects.map((pannel) => {
                if (SmartModuleHelperPro.isPannelType(pannel)) {
                    pannel._objects.forEach((text) => {
                        if (SmartModuleHelperPro.isLogicalTextType(text)) {
                            if (canvas.getZoom() <= 0.5) {
                                text.set({ visible: false });
                            }
                            else {
                                text.set({ visible: true });
                            }
                        }
                    });
                }
            });
        }
    });
}
/**
 * Canvas 내의 Array / Module의 드래그 잠금
 * @param canvas
 */
export function lockDragObject(canvas) {
    // only monitoring mode
    const objects = canvas.getObjects();
    const setting = {
        lockMovementX: true,
        lockMovementY: true,
        hasControls: false,
    };
    objects.forEach((object) => {
        if (SmartModuleHelperPro.isArrayType(object) ||
            SmartModuleHelperPro.isInverterType(object) ||
            SmartModuleHelperPro.isTextBoxType(object)) {
            object.set(setting);
            object._objects?.forEach((module) => {
                setControlHidden(module);
                module.set(setting);
            });
        }
    });
}
/**
 * Canvas 내의 Array / Module의 드래그 해제
 * @param canvas
 */
export function unLockDragObject(canvas) {
    // only editor mode
    const objects = canvas.getObjects();
    const setting = {
        lockMovementX: true,
        lockMovementY: true,
        hasControls: false,
    };
    objects.forEach((object) => {
        if (SmartModuleHelperPro.isArrayType(object)) {
            setControlShow(object);
            object._objects.forEach((module) => {
                setControlHidden(module);
                module.set(setting);
            });
        }
        else if (SmartModuleHelperPro.isTextType(object)) {
            setControlHidden(object);
        }
    });
}
// EDITOR ONLY
// 추후 네이밍 save -> saveCanvas
export function save(canvas) {
    const unLinkedList = [];
    if (canvas.mode === MAPPER_STATUS.ARRAY_EDIT) {
        const objects = canvas.getObjects();
        objects.forEach((_object) => {
            if (SmartModuleHelperPro.isArrayType(_object) && canvas.editArrayId === _object.qid) {
                _object._objects.forEach((module) => {
                    if (SmartModuleHelperPro.isModuleType(module)) {
                        getSerialTextFromModule(module)?.set({ visible: true });
                        getIconImageFromModule(module, 'minus')?.set({ visible: false });
                        getIconImageFromModule(module, 'plus')?.set({ visible: false });
                        const moduleRect = module._objects.find((child) => child.get('type') === 'rect');
                        if (module.qvisible) {
                            moduleRect?.set({ stroke: 'transparent' });
                        }
                        else {
                            if (module.qserial !== '') {
                                unLinkedList.push(module.qserial);
                            }
                            module.set({ visible: false, qserial: '' });
                            const moduleText = module._objects.find((child) => child.get('type') === 'text');
                            moduleText?.set({ text: '' });
                        }
                    }
                });
            }
        });
    }
    canvas.mode = '';
    canvas.requestRenderAll();
    return unLinkedList;
}
export function loadCanvas(canvas, newDrawingData) {
    canvas.clear();
    canvas.loadFromJSON(newDrawingData, () => {
        canvas.requestRenderAll();
    });
}
/**
 * 객체의 Control 숨기기
 * @param object
 */
export function setControlHidden(object) {
    object.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false,
        tr: false,
        tl: false,
        br: false,
        bl: false,
        mtr: false,
    });
}
/**
 * 객체의 Control 표시하기
 * @param object
 */
export function setControlShow(object) {
    const textBoxObject = object;
    textBoxObject.set({
        lockMovementX: false,
        lockMovementY: false,
        hasControls: true,
        borderColor: 'transparent',
    });
    if (textBoxObject.type == 'textbox') {
        textBoxObject.setControlsVisibility({
            mt: true,
            mb: true,
            ml: true,
            mr: true,
            tr: false,
            tl: true,
            br: false,
            bl: false,
            mtr: false,
        });
        textBoxObject.set({
            transparentCorners: false,
            cornerColor: 'white',
            cornerStrokeColor: COMMON_MODULE_COLOR.SELECT_BORDER,
            borderColor: COMMON_MODULE_COLOR.SELECT_BORDER,
            strokeWidth: 12,
            borderScaleFactor: 1.6,
            cursorColor: TEXTBOX_COLOR.SELECT_CURSOR,
            editingBorderColor: COMMON_MODULE_COLOR.SELECT_BORDER,
        });
    }
    else {
        textBoxObject.setControlsVisibility({
            mt: false,
            mb: false,
            ml: false,
            mr: false,
            tr: true,
            tl: false,
            br: true,
            bl: false,
            mtr: false,
        });
        textBoxObject.set({
            lockMovementX: false,
            lockMovementY: false,
            // hasControls: false,
            borderColor: 'transparent',
        });
    }
}
export function createStringIdText(object) {
    object.set({
        width: object.width + Constant.ARRAY_TEXT_PADDING,
        height: object.height + Constant.ARRAY_TEXT_PADDING,
    });
    const idText = object.qname ?? '';
    const stringText = new fabric.IText(idText, Constant.stringTextDetaultOptions);
    stringText.set({
        fontSize: 10,
        fill: 'black',
        left: -object.width / 2 + 16,
        top: -object.height / 2 + 5,
    });
    object.add(stringText);
}
/**
 * 버튼 기능 분기 함수
 * @param canvas
 * @param event
 * @param width
 * @param height
 */
export function mobileButtonEvent(canvas, event, width, height) {
    const zoomFlag = event === MAPPER_BUTTON_TYPE.ZOOM_IN ? -1 : 1;
    switch (event) {
        case MAPPER_BUTTON_TYPE.ZOOM_AUTO:
            alignmentToCenter(canvas, width, height);
            break;
        case MAPPER_BUTTON_TYPE.ZOOM_OUT:
        case MAPPER_BUTTON_TYPE.ZOOM_IN:
            setZoomInOut(canvas, Constant.DEFAULT_ZOOM_DELTA * zoomFlag);
            break;
        case MAPPER_BUTTON_TYPE.DELETE_ARRAY:
            deleteObject(canvas);
            break;
        default:
            break;
    }
}
/**
 * AC COMBINER 의 파워(Power), 에너지 단위(Unit) 값 세팅
 * @param object
 * @param ACCombinerPower
 */
export function setACCombinerText(object, ACCombinerPower) {
    const pw = ACCombinerPower?.formattedNumber;
    const powerTextObject = object._objects.find((child) => child.get('type') === 'textbox');
    powerTextObject?.set({ text: pw?.toString() });
    const unitTextObject = object._objects.find((child) => child.get('type') === 'text');
    unitTextObject?.set({ text: ACCombinerPower?.unit });
}
/**
 * 데이터 타입에 따른 모듈 패널 상의 pw값 및 color값 세팅
 * 함수명 변경 필요
 * @param canvas
 * @param dataList
 * @param type timeline, day, month, year
 */
export function setPowerText(canvas, dataList, errorModules = [], type = MAPPER_PERIOD_DATA.DAY, colorCodeRange, ACCombinerPower) {
    // TODO: 2세대 에디터 개발 후에는 json 파일 읽어온 후 초기 수행하도록 이동해야함
    const errorList = errorModules?.map((module) => module.module_id);
    const objects = canvas.getObjects();
    objects.forEach((object) => {
        if (SmartModuleHelperPro.isInverterType(object)) {
            setACCombinerText(object, ACCombinerPower);
        }
        else if (SmartModuleHelperPro.isArrayType(object)) {
            object._objects.forEach((arraySelection) => {
                const array = arraySelection.group;
                array?._objects?.map((module) => {
                    if (SmartModuleHelperPro.isStringTextType(module)) {
                        return;
                    }
                    if (module.qserial || module.inverterSerial) {
                        const serial = module.get('inverterSerial') ?? module.get('qserial');
                        const pw = getPowerValue(dataList, serial);
                        const { unit: modulePowerUnitText } = formatUnitNumber(pw, UNIT.WATT);
                        const { moduleEnergyValueText, moduleEnergyUnitText } = formatEnergyWithUnit(pw);
                        if (errorList.includes(serial)) {
                            // error module
                            // Todo: set color
                            // const colorRectObject = module._objects.find((child: ModuleChild) => child.get('type') === 'rect');
                            // colorRectObject.set({ fill: Constant.phase.G0 });
                            // set text
                            const powerTextObject = module._objects.find((child) => child.get('type') === 'textbox');
                            powerTextObject?.set({ text: '0' });
                            // set rounded rect
                            const roundedRectObject = module._objects.find((child) => child.get('type') === 'roundedRect');
                            roundedRectObject?.set({ fill: getMapperRoundedRectColor(true) });
                            // set unit
                            const unitTextObject = module._objects.find((child) => child.get('type') === 'text');
                            unitTextObject?.set({ visible: true, text: 'W' });
                        }
                        else {
                            if (pw !== undefined) {
                                // set color
                                const colorRectObject = module._objects.find((child) => child.get('type') === 'rect');
                                colorRectObject?.set({ fill: getColorCode(pw, colorCodeRange, false) });
                                // set unit
                                const unitTextObject = module._objects.find((child) => child.get('type') === 'text');
                                if (type === 'timeline') {
                                    unitTextObject?.set({ visible: true, text: modulePowerUnitText });
                                }
                                else
                                    unitTextObject?.set({ visible: true, text: moduleEnergyUnitText });
                                // set text
                                const powerTextObject = module._objects.find((child) => child.get('type') === 'textbox');
                                powerTextObject?.set({ text: moduleEnergyValueText });
                            }
                            else {
                                // 데이터 없거나 맵핑 안된 상태
                                // Todo: set color
                                // const colorRectObject = module._objects.find((child: ModuleChild) => child.get('type') === 'rect');
                                // colorRectObject.set({ fill: Constant.phase.G0 });
                                // set text
                                const powerTextObject = module._objects.find((child) => child.get('type') === 'textbox');
                                powerTextObject?.set({ text: '' });
                                // set unit
                                const unitTextObject = module._objects.find((child) => child.get('type') === 'text');
                                unitTextObject?.set({ visible: true });
                                if (serial === '') {
                                    // 맵핑이 안된 모듈
                                    // set text
                                    const powerTextObject = module._objects.find((child) => child.get('type') === 'textbox');
                                    powerTextObject?.set({ text: '' });
                                    // set unit
                                    const unitTextObject = module._objects.find((child) => child.get('type') === 'text');
                                    unitTextObject?.set({ visible: false });
                                }
                            }
                        }
                    }
                });
            });
        }
    });
    canvas.requestRenderAll();
}
/**
 * 데이터 타입 및 값에 따른 color code getter
 * @param value
 * @param type
 * @param date
 * @returns
 */
export function getColorCode(value, colorCodeRange, isError = true) {
    const colorIndex = isError ? 0 : classifyValueToColorIndex(value, colorCodeRange);
    return MAPPER_MODULE_COLOR_CODE[colorIndex];
}
export const classifyValueToColorIndex = (value, colorCodeRange) => {
    return colorCodeRange.find((element) => value >= element.min && value <= element.max)?.colorIndex ?? 0;
};
export const getColorPhaseRangeArray = (type, date) => {
    const dateValue = date ?? DateHelper.formatDate(new Date());
    /** Mapper 모니터링 데이터 타입에 따른 기준값 계산 결과 */
    const thresholdValue = getThresholdValueByMapperDataType(type, dateValue);
    const colorCodeRange = [];
    range(0, 20, 2).forEach((value, index) => {
        const min = value * thresholdValue;
        const max = index === 9 ? Infinity : (value + 2) * thresholdValue;
        const colorIndex = index + 1;
        colorCodeRange.push({ min, max, colorIndex });
    });
    return colorCodeRange;
};
const getThresholdValueByMapperDataType = (type, date) => {
    let thresholdValue = MAPPER_POWER_PHASE_THRESHOLD.DAY;
    switch (type) {
        case MAPPER_PERIOD_DATA.TIMELINE:
            thresholdValue = MAPPER_POWER_PHASE_THRESHOLD.TIMELINE;
            break;
        case MAPPER_PERIOD_DATA.MONTH:
            thresholdValue = DateHelper.getNumberOfDaysByMonth(date) * thresholdValue;
            break;
        case MAPPER_PERIOD_DATA.YEAR:
            thresholdValue = 365 * thresholdValue;
            break;
        case MAPPER_PERIOD_DATA.LIFETIME:
            thresholdValue = DateHelper.getDiffDate(date) * thresholdValue;
            break;
    }
    return thresholdValue;
};
export function getPowerValue(dataList, serial) {
    return dataList?.find((data) => data.device_id === serial)?.total_power ?? -1;
}
export const formatEnergyWithUnit = (energy) => {
    if (energy < 0) {
        return { moduleEnergyValueText: '', moduleEnergyUnitText: '' };
    }
    const { formattedNumber: moduleEnergyValueText, unit: moduleEnergyUnitText } = formatUnitNumber(energy, UNIT.WATT_HOUR);
    return { moduleEnergyValueText, moduleEnergyUnitText };
};
/*
 * Editor 관련 함수
 */
export function getMappedSerialList(canvas) {
    const objects = canvas.getObjects();
    const mappedList = [];
    objects.forEach((object) => {
        if (SmartModuleHelperPro.isArrayType(object)) {
            object._objects.forEach((module) => {
                if (SmartModuleHelperPro.isModuleType(module)) {
                    const serial = module.get('qserial');
                    if (serial !== '')
                        mappedList.push(serial);
                }
            });
        }
    });
    return mappedList;
}
const plusImg = new Image();
const minusImg = new Image();
const linkImg = new Image();
const linkSelectImg = new Image();
const qrImg = new Image();
const qrSelectImg = new Image();
const rotateImg = new Image();
const settingImg = new Image();
const modifyImg = new Image();
const rotateBarImg = new Image();
const trashCanImg = new Image();
export const initIconImage = () => {
    plusImg.src = require('@hems/component/resources/images/smartmodule/pro/ic_plus_default.svg');
    minusImg.src = require('@hems/component/resources/images/smartmodule/pro/ic_minus_gray100.svg');
    linkImg.src = require('@hems/component/resources/images/smartmodule/pro/ic_unlinked_gray100.svg');
    linkSelectImg.src = require('@hems/component/resources/images/smartmodule/pro/ic_unlinked_p2.svg');
    qrImg.src = require('@hems/component/resources/images/smartmodule/pro/ic_scan_gray100.svg');
    qrSelectImg.src = require('@hems/component/resources/images/smartmodule/pro/ic_scan_p2.svg');
    rotateImg.src = require('@hems/component/resources/images/smartmodule/pro/ic_refresh_p2.svg');
    settingImg.src = require('@hems/component/resources/images/smartmodule/pro/ic_edit_button_lt.svg');
    modifyImg.src = require('@hems/component/resources/images/smartmodule/pro/ic_setting_button_lt.svg');
    rotateBarImg.src = require('@hems/component/resources/images/smartmodule/pro/ic_bar.svg');
    trashCanImg.src = require('@hems/component/resources/images/smartmodule/button_mappertext_delete.svg');
};
export function checkChildObjectToModule(canvas) {
    getArrayObjects(canvas).forEach((array) => {
        array._objects.forEach((module) => {
            if (SmartModuleHelperPro.isModuleType(module)) {
                if (module._objects.length <= 1) {
                    createSerialTextToModule(module, array.qshape);
                }
                if (module._objects.length <= 2) {
                    createImageToModule(module);
                }
            }
        });
    });
}
export function checkArrayOrder(canvas) {
    canvas.arrayOrder = canvas.arrayOrder?.filter((qid) => {
        return getArrayObjects(canvas).find((object) => object.qid === qid);
    });
}
function getSerialTextFromModule(module) {
    if (module._objects.length <= 1) {
        const array = module.group;
        createSerialTextToModule(module, array.qshape || ARRAY_SHAPE.LANDSCAPE);
    }
    return module._objects.find((object) => object.qtype === 'edit-serial-text');
}
function getIconImageFromModule(module, icon) {
    return module._objects.find((object) => object.qtype === icon);
}
export const initControllerIcon = (canvas) => {
    const zoom = canvas.getZoom();
    fabric.Object.prototype.controls.br = new fabric.Control({
        x: 0.5,
        y: -0.5,
        cursorStyle: 'pointer',
        mouseDownHandler: modifyArrayFromController,
        render: renderIcon(Constant.CONTROL_ICON_SIZE, modifyImg, zoom),
    });
    fabric.Object.prototype.controls.tr = new fabric.Control({
        x: 0.5,
        y: -0.5,
        offsetY: 125,
        offsetX: 16,
        cursorStyle: 'pointer',
        mouseDownHandler: editArrayFromController,
        render: renderIcon(Constant.CONTROL_ICON_SIZE, settingImg, zoom),
    });
};
const renderIcon = (size, img, zoom) => (ctx, left, top, styleOverride, fabricObject) => {
    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle ?? 0));
    ctx.scale(zoom / 2, zoom / 2);
    ctx.drawImage(img, -size / 2, -size / 2, size, size);
    ctx.restore();
};
const deleteCommentFromController = (eventData, transformData) => {
    const canvas = transformData.target.canvas;
    canvas.fire('custom:event', {
        event: 'Delete Comment',
    });
    return true;
};
const customControlCursorHandler = () => 'pointer';
export const renderIconForZoom = (zoom) => {
    const originalControlRotate = fabric.Object.prototype.controls.mtr;
    fabric.Object.prototype.controls.tr.render = renderIcon(Constant.CONTROL_ICON_SIZE, settingImg, zoom);
    fabric.Object.prototype.controls.tr.offsetY = 7 * zoom;
    fabric.Object.prototype.controls.tr.offsetX = 0.3 * zoom;
    fabric.Object.prototype.controls.br.render = renderIcon(Constant.CONTROL_ICON_SIZE, modifyImg, zoom);
    fabric.Object.prototype.controls.br.offsetY = 23 * zoom;
    fabric.Object.prototype.controls.br.offsetX = 0.3 * zoom;
    fabric.Object.prototype.controls.mb.render = renderIcon(Constant.CONTROL_ICON_SIZE, rotateImg, zoom);
    fabric.Object.prototype.controls.mb.offsetY = 36 * zoom;
    fabric.Object.prototype.controls.mb.actionName = 'rotate';
    fabric.Object.prototype.controls.mb.actionHandler = originalControlRotate.actionHandler;
    fabric.Object.prototype.controls.mb.withConnection = false;
    fabric.Object.prototype.controls.mb.cursorStyleHandler = customControlCursorHandler;
    fabric.Object.prototype.controls.mt.render = renderIcon(Constant.CONTROL_ICON_SIZE, rotateBarImg, zoom);
    fabric.Object.prototype.controls.mt.offsetY = 30 * zoom;
    fabric.Object.prototype.controls.mt.actionName = 'rotate';
    fabric.Object.prototype.controls.mt.actionHandler = originalControlRotate.actionHandler;
    fabric.Object.prototype.controls.mt.y = 0.5;
    fabric.Object.prototype.controls.mt.withConnection = true;
    fabric.Object.prototype.controls.mt.cursorStyleHandler = customControlCursorHandler;
    fabric.Object.prototype.controls.tl.render = renderIcon(Constant.CONTROL_TRASH_CAN_SIZE, trashCanImg, zoom);
    fabric.Object.prototype.controls.tl.x = -0.5;
    fabric.Object.prototype.controls.tl.y = -0.5;
    fabric.Object.prototype.controls.tl.offsetY = -8 * zoom;
    fabric.Object.prototype.controls.tl.offsetX = 7.5 * zoom;
    fabric.Object.prototype.controls.tl.mouseDownHandler = deleteCommentFromController;
    fabric.Object.prototype.controls.tl.cursorStyleHandler = customControlCursorHandler;
};
export function cornerSizeForZoom(zoom, canvas) {
    const corner = (Constant.CONTROL_ICON_SIZE / 2) * zoom;
    const objects = canvas.getObjects();
    objects.forEach((object) => {
        if (SmartModuleHelperPro.isArrayType(object)) {
            object.set({ cornerSize: corner, touchCornerSize: corner });
        }
        else if (object.type == 'textbox') {
            object.set({ cornerSize: (12 * zoom) / 2, touchCornerSize: corner });
        }
    });
}
function editArrayFromController(eventData, transformData) {
    const target = transformData.target;
    const canvas = target.canvas;
    canvas.mode = 'array_edit';
    canvas.editArrayId = target.qid;
    target._objects.forEach((module) => {
        if (SmartModuleHelperPro.isStringTextType(module)) {
            return;
        }
        if (SmartModuleHelperPro.isArraySelectionType(module)) {
            module.set({ visible: false });
        }
        if (SmartModuleHelperPro.isModuleType(module)) {
            module.set({ visible: true });
            getSerialTextFromModule(module)?.set({ visible: false });
            if (module.qvisible) {
                getIconImageFromModule(module, 'minus')?.set({ visible: true });
            }
            else {
                getIconImageFromModule(module, 'plus')?.set({ visible: true });
            }
            module._objects.forEach((object) => {
                if (SmartModuleHelperPro.isRectType(object)) {
                    if (module.qvisible) {
                        object.set({ strokeWidth: 0.7, stroke: COMMON_MODULE_COLOR.SELECT_BORDER });
                    }
                    else {
                        object.set({ strokeWidth: 0.7, stroke: COMMON_MODULE_COLOR.RECT_BORDER });
                    }
                }
            });
        }
    });
    canvas.requestRenderAll();
    return true;
}
function modifyArrayFromController(eventData, transformData) {
    const target = transformData.target;
    const canvas = target.canvas;
    const arrayInfo = {
        arrayName: target.qname,
        shape: target.qshape,
        col: target.qcol,
        row: target.qrow,
        tilt: target.qtilt,
        rotate: target.qrotate,
    };
    canvas.fire('custom:event', {
        event: 'Modify Array',
        info: arrayInfo,
    });
    return true;
}
export const activeCanvasMode = (canvas, mode) => {
    canvas.mode = mode;
    unSelectActiveObject(canvas);
    getArrayObjects(canvas).forEach((object) => {
        object._objects.forEach((module) => {
            if (SmartModuleHelperPro.isModuleType(module)) {
                if (module.qserial === '') {
                    getIconImageFromModule(module, mode)?.set({ visible: true });
                }
            }
        });
    });
    canvas.requestRenderAll();
};
export const disableCanvasMode = (canvas, mode) => {
    canvas.mode = '';
    unSelectActiveObject(canvas);
    getArrayObjects(canvas).forEach((object) => {
        object._objects.forEach((module) => {
            if (SmartModuleHelperPro.isModuleType(module)) {
                getIconImageFromModule(module, mode)?.set({ visible: false });
            }
        });
    });
    canvas.requestRenderAll();
};
const inverterImg = new Image();
export function inverterAlignmentToCenter(canvas) {
    const objects = canvas.getObjects();
    if (objects.length < 1) {
        return;
    }
    canvas.discardActiveObject();
    const seletion = new fabric.ActiveSelection(objects, {
        canvas,
    });
    canvas.setActiveObject(seletion);
    const activeObject = canvas.getActiveObject();
    if (!activeObject) {
        return;
    }
    const objWidth = activeObject.getScaledWidth();
    const objHeight = activeObject.getScaledHeight();
    const zoom = 2;
    const panX = (canvas.getWidth() / zoom / 2 - activeObject._getLeftTopCoords().x - objWidth / 2) * zoom;
    const panY = (canvas.getHeight() / zoom / 2 - activeObject._getLeftTopCoords().y - objHeight / 2) * zoom - 170;
    canvas.setViewportTransform([zoom, 0, 0, 2, panX, panY]);
    unSelectActiveObject(canvas);
}
// Physical Layout Mode Load
export function loadModuleForPhysicalLayout(canvas, appType, moduleDayPwData, colorCodeRange, errorModules, totalPowerValue) {
    const objects = canvas.getObjects();
    objects.forEach((object) => {
        if (SmartModuleHelperPro.isInverterType(object) || SmartModuleHelperPro.isTextBoxType(object)) {
            if (appType === 'home') {
                object.set({ visible: false });
            }
            else {
                if (SmartModuleHelperPro.isInverterType(object))
                    setACCombinerText(object, totalPowerValue);
            }
        }
        else if (SmartModuleHelperPro.isArrayType(object)) {
            object._objects.forEach((module) => {
                if (appType === 'home' && SmartModuleHelperPro.isStringTextType(module)) {
                    module.set({ visible: false });
                }
                else if (SmartModuleHelperPro.isModuleType(module)) {
                    const mappingSerial = module.qserial;
                    const isMapping = mappingSerial !== '';
                    const isError = appType === 'pro' && errorModules.some((errorModule) => errorModule.module_id === mappingSerial);
                    const moduleEnergyValue = getPowerValue(moduleDayPwData, mappingSerial);
                    const { moduleEnergyValueText, moduleEnergyUnitText } = formatEnergyWithUnit(moduleEnergyValue);
                    const moduleSerialNumberText = getMapperDisplaySerialNumberText(mappingSerial);
                    const moduleColor = getColorCode(moduleEnergyValue, colorCodeRange, isError);
                    module._objects.map((object) => {
                        switch (object.qtype) {
                            case 'edit-serial-text':
                                object.set({ visible: false });
                                break;
                            case 'power-text':
                                object.set({ visible: isMapping, text: moduleEnergyValueText, fill: BASIC_GRAY_COLOR.GRAY_950 });
                                break;
                            case 'unit-text':
                                object.set({ visible: isMapping, text: moduleEnergyUnitText, fill: BASIC_GRAY_COLOR.GRAY_950 });
                                break;
                            case 'serial-text':
                                object.set({ visible: isMapping, text: moduleSerialNumberText, fill: BASIC_GRAY_COLOR.GRAY_50 });
                                break;
                            case 'text-rect':
                                object.set({ visible: true, fill: getMapperRoundedRectColor(isError) });
                                break;
                            default:
                                break;
                        }
                        if (object.type === 'path') {
                            object.set({ visible: true });
                        }
                        else if (object.type === 'rect') {
                            object.set({ fill: moduleColor });
                        }
                    });
                }
            });
        }
    });
}
/** Editor Load 시점에 Serial Number를 업데이트 */
export const loadMicroInverterSerialNumberForEditor = (canvas) => {
    const objects = canvas.getObjects();
    objects.forEach((object) => {
        if (SmartModuleHelperPro.isArrayType(object)) {
            object._objects.forEach((module) => {
                if (SmartModuleHelperPro.isModuleType(module)) {
                    module._objects.forEach((object) => {
                        if (object.qtype === 'edit-serial-text') {
                            object.set({ text: getMapperDisplaySerialNumberText(module.qserial) });
                        }
                    });
                }
            });
        }
    });
};
export function createACCombinerGroup(canvas) {
    inverterImg.src = require('@hems/component/resources/images/smartmodule/pro/ic_ac_combiner.svg');
    const InverterImageUrl = inverterImg.src;
    const totalPowerValue = MAPPER_POWER_DEFAULT_VALUE;
    fabric.loadSVGFromURL(InverterImageUrl, (objects, options) => {
        const img = fabric.util.groupSVGElements(objects, options);
        img.set({
            stroke: 'transparent',
            scaleX: 0.7,
            scaleY: 0.7,
            height: 100,
            originX: 'center',
            originY: 'center',
            hasControls: false,
        });
        const { formattedNumber, unit } = totalPowerValue;
        const powerText = new fabric.Textbox(formattedNumber, {
            fontSize: 4,
            fill: COMMON_MODULE_COLOR.TEXT_WHITE,
            originX: 'center',
            originY: 'bottom',
            textAlign: 'center',
            fontFamily: 'Pretendard',
            fontWeight: 600,
            top: 6,
        });
        const unitText = new fabric.Text(unit, {
            fontSize: 3,
            fill: COMMON_MODULE_COLOR.TEXT_WHITE,
            originX: 'center',
            originY: 'bottom',
            fontFamily: 'Pretendard',
            fontWeight: 600,
            top: 9.5,
        });
        const group = new fabric.Group([img, powerText, unitText], {
            left: Math.floor(canvas.getWidth() / 2),
            top: 310,
            borderColor: 'transparent',
            width: 33,
            height: 42,
            originX: 'center',
            originY: 'center',
            hasControls: false,
        });
        const inverterOption = {
            qtype: 'inverter',
            hasControls: false,
        };
        group.set(inverterOption);
        createArraySelectionObject(group);
        canvas.add(group);
        inverterAlignmentToCenter(canvas);
    });
    canvas.requestRenderAll();
}
export function updateHighlightFlagFromSerial(canvas, serial, isHighLight) {
    const [textColor, bgColor] = isHighLight
        ? [LIST_MATCH_MODULE_COLOR.SELECT_TEXT, LIST_MATCH_MODULE_COLOR.SELECT_FILL]
        : [COMMON_MODULE_COLOR.TEXT_WHITE, COMMON_MODULE_COLOR.DEFAULT_FILL];
    const objects = canvas.getObjects();
    objects.forEach((_object) => {
        if (SmartModuleHelperPro.isArrayType(_object)) {
            _object._objects.forEach((module) => {
                if (SmartModuleHelperPro.isStringTextType(module)) {
                    return;
                }
                if (SmartModuleHelperPro.isModuleType(module)) {
                    if (serial === module.qserial) {
                        // module highlight on
                        const moduleRect = module._objects.find((child) => child.get('type') === 'rect');
                        moduleRect?.set({ stroke: textColor, fill: bgColor });
                        // text highlight on
                        const moduleText = module._objects.find((child) => child.get('type') === 'text' && serial.includes(child.get('text').slice(-3)));
                        if (moduleText)
                            moduleText.set({ fill: textColor });
                    }
                }
            });
        }
    });
    canvas.requestRenderAll();
}
