/** Copyright © 2024 Qcells. All rights reserved.
 *
 * This software is proprietary and confidential. Unauthorized use,
 * duplication, or distribution of software is strictly prohibited.
 */
import { createStore, createLogger } from 'vuex';

import appCtx from '@hems/portal/src/store/modules/appCtx';
import user from '@hems/portal/src/store/modules/user';
import { Helper } from '@hems/util';
import loading from '@hems/util/src/stores/modules/loading';
import toast from '@hems/util/src/stores/modules/toast';

import type { CommonRootState } from 'hems/common/store';

import type { AppCtxState } from './modules/appCtx';
import type { UserInfoState } from './modules/user';

export interface RootState extends CommonRootState {
  appCtx: AppCtxState;
  user: UserInfoState;
}
export default createStore({
  plugins: Helper.getAppEnv() === 'local' ? [createLogger()] : [],
  modules: {
    appCtx,
    user,
    toast,
    loading,
  },
});
