/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import isEmpty from 'lodash-es/isEmpty';

import { checkDateRange } from '@hems/container/src/siteEnergy/value/energyIndendenceChartOptions';
import { Helper } from '@hems/util';
import type { EnergyProductValueType } from '@hems/util/src/constant';
import { ENERGY_PRODUCT_TYPE, ENERGY_VALUE_IMAGE, UNIT, NUMBER_UNIT, METRIC_PREFIX } from '@hems/util/src/constant';
import { getBrowserTimezone, getCurrentHourByTimezone } from '@hems/util/src/helper/dateHelper';
import { formatUnitNumber } from '@hems/util/src/helper/numberformatHelper';

import type { FormattedUnitNumberData, i18nTranslation } from 'hems';

import type { RealtimeMonitoringInfo, EnergyValueDetail, HomeEnergyData, SiteEnergyData } from 'hems/energyFlow';
import type { TodayEnergyChartData } from 'hems/report';

export const DEFAULT_REALTIME_MONITORING_DATA: RealtimeMonitoringInfo = {
  grid_fault: false,
  cons_pw: 0,
  load_main_pw: 0,
  load_sub_pw: 0,
  bt_pw: 0,
  grid_pw: 0,
  pv_pw: 0,
  grid_status: true,
  time: '',
  ems_opmode: '',
  pv_fault: false,
  storage_fault: false,
} as const;

export const DEFAULT_SITE_ENERGY_DATA: SiteEnergyData = {
  eventTime: '',
  energyControl: 1,
  networkType: 0,
  pvFault: false,
  pvPower: 0,
  externalPvPower: 0,
  batteryFault: false,
  batteryPower: 0,
  batteryUserSoc: 0,
  batteryRealSoc: 0,
  loadPower: 0,
  coreLoadPower: 0,
  gridFault: false,
  gridPower: 0,
  gridStatus: true,
  generatorPower: 0,
  consumptionPower: 0,
} as const;

export const getDetail = (
  valueType: EnergyProductValueType | null = null,
  data: SiteEnergyData,
  disconnect = false,
  t: i18nTranslation
): EnergyValueDetail => {
  switch (valueType) {
    case ENERGY_PRODUCT_TYPE.BATTERY: {
      if (isEmpty(data)) {
        return { text: t('device.battery'), highlight: false };
      }
      if (disconnect) {
        return { text: t('device.battery'), highlight: false };
      }
      if (Helper.isNull(data.batteryUserSoc)) {
        return { text: t('device.battery'), highlight: false };
      }

      const { formattedNumber, unit } = formatUnitNumber(data.batteryUserSoc, UNIT.PERCENT);

      return {
        text: `${t('device.battery')} ${formattedNumber}${unit}`,
        highlight: true,
      };
    }
    case ENERGY_PRODUCT_TYPE.PV:
      return { text: t('common.solar'), highlight: false };
    case ENERGY_PRODUCT_TYPE.LOAD:
      return { text: t('device.load'), highlight: false };
    default:
      return { text: '', highlight: false };
  }
};

export const getHomeEnergyDetail = (): EnergyValueDetail => ({ text: '', highlight: false });

export const getStatus = (
  valueType: EnergyProductValueType | null = null,
  data: SiteEnergyData,
  disconnect = false,
  t: i18nTranslation
): EnergyValueDetail => {
  if (disconnect) {
    return getDisconnectStatus(t);
  }
  if (isEmpty(data)) {
    return getStandbyStatus(t);
  }
  switch (valueType) {
    case ENERGY_PRODUCT_TYPE.BATTERY:
      return getBatteryStatus(data, t);
    case ENERGY_PRODUCT_TYPE.PV:
      return getPVStatus(data, t);
    case ENERGY_PRODUCT_TYPE.LOAD:
      return getLoadStatus(data, t);
    default:
      return { text: '', highlight: false, icon: '' };
  }
};

export const getHomeEnergyStatus = (t: i18nTranslation, data?: HomeEnergyData): EnergyValueDetail => {
  if (data && isEmpty(data)) {
    return { text: '', highlight: false, icon: '', isHomeAppliance: data.isHomeAppliance };
  }

  return { text: t('common.current_energy_use'), highlight: false, icon: '', isHomeAppliance: data?.isHomeAppliance };
};

export const convertWattToKilowatt = (targetNumber = 0.0): FormattedUnitNumberData => ({
  origin: targetNumber,
  formattedNumber: formatSiteEnergyPower(targetNumber),
  unit: `${METRIC_PREFIX.KILO}${UNIT.WATT}`,
});

export const transformNegativeNumber = (num: number): number => {
  if (num >= 0 || num <= -100) return num;

  return 0;
};

const getBatteryStatus = (data: SiteEnergyData, t: i18nTranslation): EnergyValueDetail => {
  if (data.batteryPower > 0) {
    return {
      text: t('device.discharging'),
      highlight: true,
      icon: ENERGY_VALUE_IMAGE.BATTERY_DISCHARGING_STATUS_IMAGE_PATH,
    };
  }
  if (data.batteryPower < 0) {
    return { text: t('device.charging'), highlight: true, icon: ENERGY_VALUE_IMAGE.BATTERY_CHARGING_STATUS_IMAGE_PATH };
  }
  if (data.batteryUserSoc === 100) {
    return {
      text: t('common.fully_charged'),
      highlight: true,
      icon: ENERGY_VALUE_IMAGE.BATTERY_FULL_CHARGED_STATUS_IMAGE_PATH,
    };
  }

  return getStandbyStatus(t);
};

const getPVStatus = (data: SiteEnergyData, t: i18nTranslation): EnergyValueDetail => {
  if (data.pvPower > 0) {
    return { text: t('common.producing'), highlight: true, icon: ENERGY_VALUE_IMAGE.PRODUCING_STATUS_IMAGE_PATH };
  }

  return getStandbyStatus(t);
};

const getLoadStatus = (data: SiteEnergyData, t: i18nTranslation): EnergyValueDetail => {
  if (data.consumptionPower > 0) {
    return { text: t('common.consuming'), highlight: true, icon: ENERGY_VALUE_IMAGE.CONSUMING_STATUS_IMAGE_PATH };
  }

  return getStandbyStatus(t);
};

const getDisconnectStatus = (t: i18nTranslation): EnergyValueDetail => ({
  text: t('common.disconnected'),
  highlight: false,
  icon: ENERGY_VALUE_IMAGE.DISCONNECTED_STATUS_IMAGE_PATH,
});

const getStandbyStatus = (t: i18nTranslation) => ({
  text: t('device.standby'),
  highlight: false,
  icon: ENERGY_VALUE_IMAGE.STANDBY_STATUS_IMAGE_PATH,
});

export const createChartData = (
  totalEnergyData: number,
  energyDataList: (number | null)[],
  collectedTimeDataList: string[],
  timezone?: string
): TodayEnergyChartData[] => {
  const currentHourByTimezone = getCurrentHourByTimezone(timezone ?? getBrowserTimezone());

  const accumulatedDataList = energyDataList.reduce(
    (prevAccumulatedList: (number | null)[], data: number | null, index) => {
      if (currentHourByTimezone < index) {
        prevAccumulatedList.push(null);

        return prevAccumulatedList;
      }
      if (data !== null) {
        prevAccumulatedList.push(data + (prevAccumulatedList[index - 1] || 0));

        return prevAccumulatedList;
      }
      prevAccumulatedList.push(prevAccumulatedList[index - 1] ?? null);

      return prevAccumulatedList;
    },
    []
  );

  const chartData = collectedTimeDataList.reduce((filteredList: { x: string; y: number | null }[], time, index) => {
    if (checkDateRange(time)) {
      filteredList.push({
        x: time,
        y: totalEnergyData > 0 && accumulatedDataList[index] !== null ? accumulatedDataList[index] : null,
      });
    }

    return filteredList;
  }, []);

  return chartData;
};

export const formatToZeroByRange = (value: number): number => (value > -100 && value < 100 ? 0 : value);

export const formatSiteEnergyPower = (value: number): string => (value / NUMBER_UNIT.THOUSAND).toFixed(1);
