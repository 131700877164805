import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "image" };
const _hoisted_2 = { class: "title" };
const _hoisted_3 = { class: "contents" };
const _hoisted_4 = { class: "time-wrap" };
const _hoisted_5 = { class: "time from" };
const _hoisted_6 = { class: "time to" };
const _hoisted_7 = { class: "button-wrap" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Image2 = _resolveComponent("Image2");
    const _component_PopupWrapper = _resolveComponent("PopupWrapper");
    return (_openBlock(), _createBlock(_component_PopupWrapper, { class: "notice-popup" }, {
        default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_Image2, {
                    src: "notification/ic_re_server_setting_glass_img",
                    extension: "svg",
                    width: "52",
                    height: "52"
                })
            ]),
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.state.title), 1),
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.state.contents), 1),
            _createElementVNode("div", _hoisted_4, [
                _withDirectives(_createElementVNode("span", _hoisted_5, [
                    _createElementVNode("strong", null, _toDisplayString(_ctx.$t('common.from')) + ": ", 1),
                    _createTextVNode(_toDisplayString(_ctx.state.startTime), 1)
                ], 512), [
                    [_vShow, _ctx.state.startTime]
                ]),
                _withDirectives(_createElementVNode("span", _hoisted_6, [
                    _createElementVNode("strong", null, _toDisplayString(_ctx.$t('common.to')) + ": ", 1),
                    _createTextVNode(_toDisplayString(_ctx.state.endTime), 1)
                ], 512), [
                    [_vShow, _ctx.state.endTime]
                ])
            ]),
            _createElementVNode("div", _hoisted_7, [
                _createElementVNode("button", {
                    class: "close-btn",
                    onClick: _cache[0] || (_cache[0] =
                        //@ts-ignore
                        (...args) => (_ctx.onClose && _ctx.onClose(...args)))
                }, _toDisplayString(_ctx.$t('common.close')), 1)
            ])
        ]),
        _: 1
    }));
}
