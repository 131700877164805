import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-a8120e08"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "header" };
const _hoisted_2 = {
    id: "gnb",
    class: "header_con"
};
const _hoisted_3 = { class: "navi_icon bor_none fl" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Image = _resolveComponent("Image");
    const _component_router_link = _resolveComponent("router-link");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_router_link, { to: "/" }, {
                    default: _withCtx(() => [
                        _createVNode(_component_Image, { src: "new2021/new_btn_home" })
                    ]),
                    _: 1
                })
            ]),
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ])
    ]));
}
