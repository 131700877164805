import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "is_edit"
}
const _hoisted_2 = {
  key: 1,
  class: "is_default"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["btn_wrap_m", { editorble: _ctx.isEditable }])
  }, [
    (_ctx.isEditable)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("button", {
            type: "button",
            class: _normalizeClass(`${_ctx.buttonClass} btn_c_2`),
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onCancel && _ctx.onCancel(...args)))
          }, _toDisplayString(_ctx.$t('common.cancel')), 3),
          _createElementVNode("button", {
            type: "submit",
            class: _normalizeClass(`${_ctx.buttonClass} ${_ctx.applyButtonClass}`)
          }, _toDisplayString(_ctx.$t('common.apply')), 3)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("button", {
            type: "button",
            class: _normalizeClass(`${_ctx.buttonClass} btn_c_2`),
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.goToList && _ctx.goToList(...args)))
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(`${_ctx.goToListSpanTagClass}`)
            }, _toDisplayString(_ctx.$t('message.go_to_list')), 3)
          ], 2),
          _createElementVNode("button", {
            type: "button",
            class: _normalizeClass(`${_ctx.buttonClass} ${_ctx.editButtonClass}`),
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onEdit && _ctx.onEdit(...args)))
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(`${_ctx.editSpanTagClass}`)
            }, _toDisplayString(_ctx.$t('common.edit')), 3)
          ], 2)
        ]))
  ], 2))
}