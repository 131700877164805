import { computed, defineAsyncComponent, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import { Accordion, LabelInput, TruncateInput, BasicInput, ToggleInput, ImageUploader, SettingTable, SettingTableRow, Tooltip, RadioGroup, SignPadViewer, } from '@hems/component';
import SettingButtonGroup from '@hems/container/src/forms/device/settings/_shared/SettingButtonGroup.vue';
import GoogleMap from '@hems/container/src/forms/device/settings/installation/GoogleMap.vue';
import { useSiteId, useLanguage, useRole, useSuccessModal } from '@hems/util';
import { ALARM_CODE, INPUT_TYPE, ON_OFF_CODE, SETTING_YES_NO_CODE } from '@hems/util/src/constant';
export default defineComponent({
    name: 'InstallationContainer',
    components: {
        Accordion,
        LabelInput,
        TruncateInput,
        BasicInput,
        ToggleInput,
        Form,
        ImageUploader,
        SignPadViewer,
        SettingTable,
        SettingTableRow,
        Tooltip,
        RadioGroup,
        GoogleMap,
        SettingButtonGroup,
        ChangeInstallerPopup: defineAsyncComponent(() => import('@hems/component/src/popup/ChangeInstallerPopup.vue')),
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        siteImage: {
            type: Object,
            required: true,
        },
    },
    emits: ['goToList', 'save', 'sendEmail'],
    setup(props, { emit }) {
        const { t } = useI18n();
        const isEditable = ref(false);
        const { isDevOrAdminOrService } = useRole();
        const showChangeInstallerPopup = ref(false);
        const store = useStore();
        const apiKey = store.state.appCtx.googleApiKey ?? '';
        const installationInfo = ref(props.data ? { ...props.data } : null);
        const alarmSettingInfo = ref({
            enabled: installationInfo.value?.enabled_noti_alarms?.includes(ALARM_CODE.C128)
                ? ON_OFF_CODE.ON
                : ON_OFF_CODE.OFF,
            alarmCd: ALARM_CODE.C128,
        });
        const { languageCode } = useLanguage();
        const { siteId } = useSiteId();
        const onClickEmail = () => {
            emit('sendEmail');
        };
        const onOffToggleOptions = computed(() => [
            [ON_OFF_CODE.ON, t('common.on')],
            [ON_OFF_CODE.OFF, t('common.off')],
        ]);
        const yesOrNoToggleOptions = computed(() => [
            {
                text: t('common.yes'),
                value: SETTING_YES_NO_CODE.YES,
            },
            {
                text: t('common.no'),
                value: SETTING_YES_NO_CODE.NO,
            },
        ]);
        const getInstallationSaveParam = () => {
            return {
                ...installationInfo.value,
                enabled_noti_alarms: alarmSettingInfo.value.enabled === ON_OFF_CODE.ON ? alarmSettingInfo.value.alarmCd : '',
            };
        };
        const onCancel = (handleReset) => {
            isEditable.value = false;
            if (handleReset)
                handleReset();
        };
        const onSave = () => {
            emit('save', getInstallationSaveParam());
            onCancel();
        };
        const onEdit = () => {
            isEditable.value = true;
        };
        const goToList = () => {
            emit('goToList');
        };
        const schema = yup.object().shape({
            instl_pin_code: yup
                .string()
                .matches(/^(\d{4}|\d{6})$/, { message: { key: 'message.invalid_format' } })
                .required(),
            owner_email: yup.string().email().nullable().required(),
            owner_name: yup.string().trim().min(1).nullable().required(),
            owner_contact: yup
                .string()
                .matches(/^[0-9+\-()]+$/, { message: { key: 'message.invalid_phone_number' } })
                .nullable()
                .required(),
        });
        const successModal = useSuccessModal({
            callback: () => {
                window.location.reload();
            },
        });
        const onAfterChangeInstaller = () => {
            successModal.open();
        };
        const openChangeInstallerPopup = (isOpen) => {
            showChangeInstallerPopup.value = isOpen;
        };
        return {
            schema,
            goToList,
            onEdit,
            onCancel,
            onSave,
            onClickEmail,
            isEditable,
            INPUT_TYPE,
            installationInfo,
            languageCode,
            apiKey,
            alarmSettingInfo,
            yesOrNoToggleOptions,
            isDevOrAdminOrService,
            showChangeInstallerPopup,
            openChangeInstallerPopup,
            siteId,
            onAfterChangeInstaller,
            onOffToggleOptions,
        };
    },
});
