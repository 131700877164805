import type { AxiosInstance } from 'axios';

import { Service } from '@hems/service/src/api/Service';
import { apiWrapper } from '@hems/service/src/util/helper';
import type { LanguageValue } from '@hems/util/src/constant';

import type { CommonResponseWrapper } from 'hems';

import type { ErrorCodeInfo, ErrorCodeResponse, ErrorHistoryParams, ErrorHistoryResponse } from 'hems/event';

export default class ErrorCodeService extends Service {
  constructor(axios: AxiosInstance) {
    super(axios);
  }

  public async getAllErrorCodes(language: LanguageValue): Promise<ErrorCodeInfo[]> {
    const { data } = await this.get<CommonResponseWrapper<ErrorCodeResponse>>(apiWrapper.alarmApi('/error-codes'), {
      language,
    });

    return data.data;
  }

  public async getErrorCodes(language: LanguageValue, errorCode: string): Promise<ErrorCodeInfo[]> {
    const { data } = await this.get<CommonResponseWrapper<ErrorCodeResponse>>(
      apiWrapper.alarmApi(`/error-codes/${errorCode}`),
      { language }
    );

    return data.data;
  }

  public async getErrorHistory(params: ErrorHistoryParams): Promise<ErrorHistoryResponse> {
    const { data } = await this.get<CommonResponseWrapper<ErrorHistoryResponse>>(
      apiWrapper.alarmApi('/error-history'),
      params
    );

    return data;
  }
}

export { ErrorCodeService };
