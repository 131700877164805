import type { AxiosInstance } from 'axios';

import { Service } from '@hems/service/src/api/Service';
import { apiWrapper } from '@hems/service/src/util/helper';

import type { CommonResponseWrapper } from 'hems';

import type {
  ApplianceItemList,
  ApplianceCommandsParams,
  ApplianceChartParams,
  ApplianceChartResponse,
  ApplianceRefreshTokenResponse,
  SmartThingsComponents,
  SmartThingsDeviceInfo,
  SmartThingsHealthStatusInfo,
  SmartThingsLocationInfo,
  SmartThingsLocationListResponse,
  SmartThingsRoomInfo,
  SmartThingsRoomListResponse,
  SmartThingsControlResponse,
} from 'hems/device';

export default class HomeApplianceService extends Service {
  constructor(axios: AxiosInstance) {
    super(axios);
  }

  getApplianceList = async (siteId: number): Promise<ApplianceItemList> => {
    const { data } = await this.get<CommonResponseWrapper<ApplianceItemList>>(
      apiWrapper.managerApi(`/smart-things/${siteId}`)
    );

    return data;
  };

  getApplianceStatus = async (deviceId: string): Promise<SmartThingsComponents> => {
    return await this.get<SmartThingsComponents>(`/devices/${deviceId}/status`);
  };

  getApplianceInfo = async (deviceId: string): Promise<SmartThingsDeviceInfo> => {
    return await this.get<SmartThingsDeviceInfo>(`/devices/${deviceId}`);
  };

  getApplianceHealth = async (deviceId: string): Promise<SmartThingsHealthStatusInfo> => {
    return await this.get<SmartThingsHealthStatusInfo>(`/devices/${deviceId}/health`);
  };

  getApplianceLocations = async (): Promise<SmartThingsLocationInfo[]> => {
    const { items } = await this.get<SmartThingsLocationListResponse>('/locations');

    return items;
  };

  getApplianceRooms = async (locationId: string): Promise<SmartThingsRoomInfo[]> => {
    const { items } = await this.get<SmartThingsRoomListResponse>(`/locations/${locationId}/rooms`);

    return items;
  };

  controlAppliance = async (deviceId: string, params: ApplianceCommandsParams): Promise<SmartThingsControlResponse> => {
    return await this.post<SmartThingsControlResponse>(`/devices/${deviceId}/commands`, params);
  };

  refreshSmartThingsToken = async (token: string): Promise<ApplianceRefreshTokenResponse> => {
    const { data } = await this.post<CommonResponseWrapper<ApplianceRefreshTokenResponse>>(
      apiWrapper.managerApi('/smart-things/tokens/refresh'),
      { token }
    );

    return data;
  };

  getApplianceChart = async (siteId: number, params: ApplianceChartParams): Promise<ApplianceChartResponse> => {
    const { data } = await this.get<CommonResponseWrapper<ApplianceChartResponse>>(
      apiWrapper.managerApi(`/smart-things/charts/${siteId}`),
      params
    );

    return data;
  };

  getChartByAppliance = async (
    applianceDeivceId: string,
    params: ApplianceChartParams
  ): Promise<ApplianceChartResponse> => {
    const { data } = await this.get<CommonResponseWrapper<ApplianceChartResponse>>(
      apiWrapper.managerApi(`/smart-things/charts/${applianceDeivceId}/powers`),
      params
    );

    return data;
  };
}
