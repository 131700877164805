import pickBy from 'lodash-es/pickBy';

import type { SettingStormCover } from '@hems/user/src/types/device';

import type { Gen2 as PvModuleGen2, Gen3 as PvModuleGen3, Fox as PvModuleFox } from 'hems/device/settings/pvmodule';
import type { ACSys as SmartModuleACSystem } from 'hems/device/settings/smartmodule';

import { Helper } from '..';
import { UnsupportedGenType } from '../exception/exception';

type SettingsKeys<T extends Record<string, any>> = (keyof T extends string ? keyof T : never)[];

type ConfigParam = {
  readonly GEN2: {
    readonly basic: SettingsKeys<PvModuleGen2.BasicSettings>;
    readonly advanced: SettingsKeys<PvModuleGen2.AdvancedSettings>;
  };
  readonly GEN3: {
    readonly basic: SettingsKeys<PvModuleGen3.BasicSettings>;
    readonly engineer: SettingsKeys<PvModuleGen3.EngineerSettings>;
    readonly advanced: SettingsKeys<PvModuleGen3.AdvancedSettings>;
    readonly pricing: SettingsKeys<PvModuleGen3.PricingSettings>;
  };
  readonly FOX: {
    readonly basic: SettingsKeys<PvModuleFox.BasicSettings>;
    readonly engineer: SettingsKeys<PvModuleFox.EngineerSettings>;
    readonly pricing: SettingsKeys<PvModuleFox.PricingSettings>;
  };
  readonly AC_SYS: {
    // FIXME: R05 제거 시 수정
    readonly basic: SettingsKeys<
      SmartModuleACSystem.ACSystemBasicSettings & SettingStormCover & SmartModuleACSystem.ACSystemBasicSettingsForR05
    >;
    readonly engineer: SettingsKeys<SmartModuleACSystem.ACSystemEngineeringSettings>;
    readonly advanced: SettingsKeys<SmartModuleACSystem.ACSystemAdvancedSettings>;
    readonly pricing: SettingsKeys<SmartModuleACSystem.PricingSettings>;
  };
};

const settingsParam: ConfigParam = {
  GEN2: {
    basic: [
      'pv_max_pwr1',
      'pv_max_pwr2',
      'feed_in_limit',
      'pem_mode',
      'smeterd0id',
      'max_inverter_pw_cd',
      'basicmode_cd',
      'backup_soc',
    ],
    advanced: [
      'base_profile_cd',
      'gc_over_voltage_level1',
      'gc_over_voltage_time1',
      'gc_over_voltage_level2',
      'gc_over_voltage_time2',
      'gc_over_voltage_level3',
      'gc_over_voltage_time3',
      'gc_over_voltage_level4',
      'gc_over_voltage_time4',
      'gc_under_voltage_level1',
      'gc_under_voltage_time1',
      'gc_under_voltage_level2',
      'gc_under_voltage_time2',
      'gc_10min_voltage_in',
      'gc_10min_voltage_out',
      'gc_over_frequency_in',
      'gc_over_frequency_out',
      'gc_under_frequency_in',
      'gc_under_frequency_out',
      'gc_active_power_setpoint_enable',
      'gc_active_power_setpoint_value',
      'gc_active_power_frequency_enable',
      'gc_active_power_frequency_battery_frequency',
      'gc_active_power_frequency_sl',
      'gc_active_power_frequency_x1',
      'gc_active_power_frequency_x2',
      'gc_active_power_frequency_sh',
      'gc_active_power_frequency_x3',
      'gc_active_power_frequency_x4',
      'gc_active_power_frequency_fstop_ch',
      'gc_active_power_frequency_ftransition',
      'gc_active_power_voltage_enable',
      'gc_active_power_voltage_battery_voltage',
      'gc_active_power_voltage_x1',
      'gc_active_power_voltage_y1',
      'gc_active_power_voltage_x2',
      'gc_active_power_voltage_y2',
      'gc_active_power_voltage_x3',
      'gc_active_power_voltage_y3',
      'gc_active_power_voltage_x4',
      'gc_active_power_voltage_y4',
      'gc_reactive_pt1_filter_tau',
      'gc_reactive_power_setpoint_enable',
      'gc_reactive_power_setpoint_excited',
      'gc_reactive_power_setpoint_value',
      'gc_reactive_power_cospi_enable',
      'gc_reactive_power_cospi_excited',
      'gc_reactive_power_cospi_x1',
      'gc_reactive_power_cospi_y1',
      'gc_reactive_power_cospi_x2',
      'gc_reactive_power_cospi_y2',
      'gc_reactive_power_cospi_x3',
      'gc_reactive_power_cospi_y3',
      'gc_reactive_power_q_setpoint_enable',
      'gc_reactive_power_q_setpoint_excited',
      'gc_reactive_power_q_setpoint_value',
      'gc_reactive_power_q_u_enable',
      'gc_reactive_power_q_u_x1',
      'gc_reactive_power_q_u_y1',
      'gc_reactive_power_q_u_x2',
      'gc_reactive_power_q_u_y2',
      'gc_reactive_power_q_u_x3',
      'gc_reactive_power_q_u_y3',
      'gc_reactive_power_q_u_x4',
      'gc_reactive_power_q_u_y4',
      'gc_dc_injection_control_enable',
      'gc_dc_injection_control_faultlevel1',
      'gc_dc_injection_control_faulttime1',
      'gc_dc_injection_control_faultlevel2',
      'gc_dc_injection_control_faulttime2',
      'gc_rcmu_control_sudden_enable',
      'gc_rcmu_control_sudden_level1',
      'gc_rcmu_control_sudden_time1',
      'gc_rcmu_control_sudden_level2',
      'gc_rcmu_control_sudden_time2',
      'gc_rcmu_control_sudden_level3',
      'gc_rcmu_control_sudden_time3',
      'gc_rcmu_control_continuous_enable',
      'gc_rcmu_control_continuous_level1',
      'gc_rcmu_control_continuous_time1',
      'gc_pv_insulation_control_enable',
      'gc_pv_insulation_control_faultlevel',
      'gc_pv_insulation_control_checkcnt',
      'gc_antiislanding_control_enable',
      'gc_antiislanding_control_time',
      'gc_gradient_control_enable',
      'gc_gradient_control_energy_source_change_enable',
      'gc_gradient_control_timelevel',
      'gc_derating_control_enable',
      'gc_derating_control_start_temprature',
      'gc_derating_control_value',
      'gc_battery_option_frequency',
      'gc_battery_option_voltage',
      'gc_battery_option_derating',
      'limitctrl_exporthard_flag',
      'limitctrl_exporthard_level',
      'limitctrl_exporthard_time',
      'limitctrl_exportsoft_flag',
      'limitctrl_exportsoft_level',
      'limitctrl_exportsoft_time',
      'limitctrl_generationhard_flag',
      'limitctrl_generationhard_level',
      'limitctrl_generationhard_time',
      'limitctrl_generationsoft_flag',
      'limitctrl_generationsoft_level',
      'limitctrl_generationsoft_time',
    ],
  },
  GEN3: {
    basic: [
      'installer_page_temp_password_password',
      'pv_feed_in_limit',
      'pv_feed_in_limit_w',
      'external_control_connection',
      'external_control_meter_load',
      'external_control_pv_meter_load',
      'external_control_modbus_tcp_port',
      'external_control_modbus_rtu_dev',
      'external_control_modbus_rtu_baudrate',
      'external_control_modbus_rtu_parity',
      'external_control_modbus_rtu_data',
      'external_control_modbus_rtu_stop',
      'energy_policy',
      'pv_capacity_calc_option',
      'pv_type',
      'pv_string_count',
      'pv_string_power1',
      'pv_string_power2',
      'pv_string_power3',
      'pv_module_count',
      'pv_module_power',
      'third_party_inverter_exist',
      'meter_model',
      'meter_connection',
      'meter_modbus_tcp_ip',
      'meter_modbus_tcp_port',
      'meter_modbus_rtu_dev',
      'meter_modbus_rtu_baudrate',
      'meter_modbus_rtu_parity',
      'meter_modbus_rtu_data',
      'meter_modbus_rtu_stop',
      'pv_connection_type',
      'pv_meter_model',
      'pv_meter_connection',
      'pv_meter_modbus_tcp_ip',
      'pv_meter_modbus_tcp_port',
      'pv_meter_modbus_rtu_dev',
      'pv_meter_modbus_rtu_baudrate',
      'pv_meter_modbus_rtu_parity',
      'pv_meter_modbus_rtu_data',
      'pv_meter_modbus_rtu_stop',
      'battery_backup_soc',
      'inverter_multiple_earthed_neutral_system',
      'battery_installed_rack_count',
      'ul1741_flag',
      'web_page_password_engineer',
      'web_page_password_service',
    ],
    engineer: [
      'external_control_connection',
      'eos_flag',
      'eos_power_sampling_interval',
      'eos_default_sampling_interval',
      'sg_ready_flag',
      'sg_ready_heat_pump_consumption_power',
      'inverter_max_output_power',
      'inverter_grid_target_frequency',
      'inverter_external_generator_mode',
      'inverter_permit_service_mode_select',
      'inverter_enter_service_randomized_delay',
      'inverter_import_power_limit',
      'inverter_export_power_limit',
      'battery_charge_limit',
      'battery_discharge_limit',
      'inverter_logic_interface',
      'fcas_flag',
      'fcas_response_capacity_limit',
      'fcas_droop_rate_raise',
      'fcas_droop_rate_lower',
      'fcas_oftb_max',
      'fcas_oftb_min',
      'fcas_fr_max',
      'fcas_fr_min',
      'fcas_nofb_max',
      'fcas_nofb_min',
      'fcas_test_mode',
      'ul1741_flag',
      'afci_arc_energy_region',
      'afci_ch1_flag',
      'afci_ch1_time_area_threshold_gain',
      'afci_ch1_frequency_area_threshold_gain',
      'afci_ch1_frequency_area_threshold_percent',
      'afci_ch2_flag',
      'afci_ch2_time_area_threshold_gain',
      'afci_ch2_frequency_area_threshold_gain',
      'afci_ch2_frequency_area_threshold_percent',
      'afci_ch3_flag',
      'afci_ch3_time_area_threshold_gain',
      'afci_ch3_frequency_area_threshold_gain',
      'afci_ch3_frequency_area_threshold_percent',
      'secondary_ems_modbus_tcp_ip',
      'secondary_ems_modbus_tcp_port',
      'secondary_ems_server_id',

      'external_ems_connection',
      'pcs_connection_mode',
      'pcs_debug_mode',
      'external_ems_model',
      'external_ems_modbus_tcp_ip',
      'external_ems_modbus_tcp_port',
      'external_ems_modbus_rtu_dev',
      'external_ems_modbus_rtu_baudrate',
      'external_ems_modbus_rtu_parity',
      'external_ems_modbus_rtu_data',
      'external_ems_modbus_rtu_stop',
      'external_ems_server_id',
      'external_ems_meter1',
      'external_ems_meter2',
      'battery_hysteresis_low',
      'battery_hysteresis_high',
      'battery_user_soc_min',
      'battery_user_soc_max',
      'log_level_process_mgr',
      'log_level_system_log',
      'log_level_fota',
      'log_level_power_control',
      'log_level_algorithm_mgr',
      'log_level_ess_mgr',
      'log_level_dc_source_mgr',
      'log_level_cloud_mgr',
      'log_level_meter_mgr',
      'log_level_gateway_mgr',
      'log_level_data_accumulator',
      'log_level_db_mgr',
      'log_level_web_engine',
      'log_level_gqa',
      'log_level_mlpe_mgr',
      'log_level_sys_mgr',
      'log_level_energy_link',
      'log_level_diag_monitor',
      'log_level_csip_mgr',
      'log_level_energy_optimizer',
      'debug_mode_mqtt',
      'debug_mode_modbus',
      'auto_fw_update_flag',
    ],
    advanced: [
      'grid_code',
      'inverter_tau_coefficient',
      'inverter_control_grid_target_voltage',
      'inverter_control_grid_target_voltage_offset',
      'inverter_control_pcs_max_apparent_power_limit',
      'inverter_control_v_ar_act',
      'inverter_control_pcs_var_max_q1',
      'inverter_control_pcs_var_max_q2',
      'inverter_control_pcs_var_max_q3',
      'inverter_control_pcs_var_max_q4',
      'inverter_control_pf_min_q1',
      'inverter_control_pf_min_q2',
      'inverter_control_pf_min_q3',
      'inverter_control_pf_min_q4',
      'inverter_control_charge_rate_setting',
      'inverter_control_discharge_rate_setting',
      'inverter_control_va_setting',
      'inverter_control_active_power_setting',
      'inverter_control_active_power_over_excited_setting',
      'inverter_control_active_power_under_excited_setting',
      'inverter_control_export_pmin',
      'inverter_control_import_pmin',
      'frt_hvrt',
      'frt_start_level_hvrt',
      'grid_voltage_over_voltage_detection_level1',
      'grid_voltage_over_voltage_detection_time1',
      'grid_voltage_over_voltage_detection_level2',
      'grid_voltage_over_voltage_detection_time2',
      'grid_voltage_over_voltage_detection_level3',
      'grid_voltage_over_voltage_detection_time3',
      'grid_voltage_over_voltage_detection_level4',
      'grid_voltage_over_voltage_detection_time4',
      'grid_voltage_over_voltage_detection_level5',
      'grid_voltage_over_voltage_detection_time5',
      'grid_voltage_over_voltage_release_level',
      'grid_voltage_over_voltage_release_time',
      'grid_voltage_ten_min_voltage_detection_level',
      'frt_lvrt',
      'frt_start_level_lvrt',
      'grid_voltage_under_voltage_detection_level1',
      'grid_voltage_under_voltage_detection_time1',
      'grid_voltage_under_voltage_detection_level2',
      'grid_voltage_under_voltage_detection_time2',
      'grid_voltage_under_voltage_detection_level3',
      'grid_voltage_under_voltage_detection_time3',
      'grid_voltage_under_voltage_detection_level4',
      'grid_voltage_under_voltage_detection_time4',
      'grid_voltage_under_voltage_detection_level5',
      'grid_voltage_under_voltage_detection_time5',
      'grid_voltage_under_voltage_release_level',
      'grid_voltage_under_voltage_release_time',
      'frt_hfrt',
      'frt_start_level_hfrt',
      'grid_frequency_over_frequency_detection_level1',
      'grid_frequency_over_frequency_detection_time1',
      'grid_frequency_over_frequency_detection_level2',
      'grid_frequency_over_frequency_detection_time2',
      'grid_frequency_over_frequency_detection_level3',
      'grid_frequency_over_frequency_detection_time3',
      'grid_frequency_over_frequency_detection_level4',
      'grid_frequency_over_frequency_detection_time4',
      'grid_frequency_over_frequency_detection_level5',
      'grid_frequency_over_frequency_detection_time5',
      'grid_frequency_over_frequency_release_level',
      'grid_frequency_over_frequency_release_time',
      'frt_lfrt',
      'frt_start_level_lfrt',
      'grid_frequency_under_frequency_detection_level1',
      'grid_frequency_under_frequency_detection_time1',
      'grid_frequency_under_frequency_detection_level2',
      'grid_frequency_under_frequency_detection_time2',
      'grid_frequency_under_frequency_detection_level3',
      'grid_frequency_under_frequency_detection_time3',
      'grid_frequency_under_frequency_detection_level4',
      'grid_frequency_under_frequency_detection_time4',
      'grid_frequency_under_frequency_detection_level5',
      'grid_frequency_under_frequency_detection_time5',
      'grid_frequency_under_frequency_release_level',
      'grid_frequency_under_frequency_release_time',
      'active_power_flag',
      'active_power_set_point_value',
      'active_power_frequency_flag',
      'active_power_frequency_of_pref_select',
      'active_power_frequency_uf_pref_select',
      'active_power_frequency_slope_select',
      'active_power_frequency_hysteresis_select',
      'active_power_frequency_sl',
      'active_power_frequency_sh',
      'active_power_frequency_x1',
      'active_power_frequency_x2',
      'active_power_frequency_x3',
      'active_power_frequency_x4',
      'active_power_frequency_x5',
      'active_power_frequency_x6',
      'active_power_frequency_uf_hysteresis_frequency',
      'active_power_frequency_uf_hysteresis_time',
      'active_power_frequency_of_hysteresis_frequency',
      'active_power_frequency_of_hysteresis_time',
      'active_power_frequency_response_time',
      'active_power_frequency_droop_kof',
      'active_power_frequency_droop_kuf',
      'active_power_voltage_flag',
      'active_power_voltage_x1',
      'active_power_voltage_y1',
      'active_power_voltage_x2',
      'active_power_voltage_y2',
      'active_power_voltage_x3',
      'active_power_voltage_y3',
      'active_power_voltage_x4',
      'active_power_voltage_y4',
      'active_power_voltage_response_time',
      'reactive_power_cospi_set_point_flag',
      'reactive_power_cospi_set_point_excited_select',
      'reactive_power_cospi_set_point_value',
      'reactive_power_cospi_set_point_response_time',
      'reactive_power_cospi_set_point_apparent_power_priority',
      'reactive_power_cospi_p_flag',
      'reactive_power_cospi_p_excited_select',
      'reactive_power_cospi_p_x1',
      'reactive_power_cospi_p_y1',
      'reactive_power_cospi_p_x2',
      'reactive_power_cospi_p_y2',
      'reactive_power_cospi_p_x3',
      'reactive_power_cospi_p_y3',
      'reactive_power_cospi_p_response_time',
      'reactive_power_q_p_flag',
      'reactive_power_q_p_gen_x1',
      'reactive_power_q_p_gen_y1',
      'reactive_power_q_p_gen_x2',
      'reactive_power_q_p_gen_y2',
      'reactive_power_q_p_gen_x3',
      'reactive_power_q_p_gen_y3',
      'reactive_power_q_p_load_x1',
      'reactive_power_q_p_load_y1',
      'reactive_power_q_p_load_x2',
      'reactive_power_q_p_load_y2',
      'reactive_power_q_p_load_x3',
      'reactive_power_q_p_load_y3',
      'reactive_power_q_p_response_time',
      'reactive_power_q_p_reactive_power_mode',
      'reactive_power_q_p_apparent_power_priority',
      'reactive_power_q_set_point_flag',
      'reactive_power_q_set_point_excited_select',
      'reactive_power_q_set_point_value',
      'reactive_power_q_set_point_response_time',
      'reactive_power_q_set_point_reactive_power_mode',
      'reactive_power_q_set_point_apparent_power_priority',
      'reactive_power_q_u_flag',
      'reactive_power_q_u_fixed_vref_select',
      'reactive_power_q_u_vref',
      'reactive_power_auto_vref_avg_time',
      'reactive_power_q_u_x1',
      'reactive_power_q_u_y1',
      'reactive_power_q_u_x2',
      'reactive_power_q_u_y2',
      'reactive_power_q_u_x3',
      'reactive_power_q_u_y3',
      'reactive_power_q_u_x4',
      'reactive_power_q_u_y4',
      'reactive_power_q_u_response_time',
      'reactive_power_q_u_reactive_power_mode',
      'reactive_power_q_u_apparent_power_priority',
      'dynamic_reactive_current_flag',
      'dynamic_reactive_current_ar_gra_mod',
      'dynamic_reactive_current_db_v_min',
      'dynamic_reactive_current_db_v_max',
      'dynamic_reactive_current_ar_gra_sag',
      'dynamic_reactive_current_ar_gra_swell',
      'voltage_rise_suppression_flag',
      'voltage_rise_suppression_vref',
      'voltage_rise_suppression_delay_time',
      'voltage_rise_suppression_min_pf',
      'voltage_rise_suppression_pf_response_time',
      'voltage_rise_suppression_min_active_power',
      'voltage_rise_suppression_active_power_response_time',
      'voltage_rise_suppression_release_time',
      'dc_injection_flag',
      'dc_injection_detection_level1',
      'dc_injection_detection_time1',
      'dc_injection_detection_level2',
      'dc_injection_detection_time2',
      'dc_injection_control_flag',
      'dc_injection_control_level',
      'rcmu_sudden_flag',
      'rcmu_sudden_detection_level1',
      'rcmu_sudden_detection_time1',
      'rcmu_sudden_detection_level2',
      'rcmu_sudden_detection_time2',
      'rcmu_sudden_detection_level3',
      'rcmu_sudden_detection_time3',
      'rcmu_continuous_flag',
      'rcmu_continuous_detection_level',
      'rcmu_continuous_detection_time',
      'pv_insulation_flag',
      'pv_insulation_fault_level',
      'pv_insulation_check_cnt',
      'pv_insulation_time',
      'anti_islanding_active_method_detection',
      'anti_islanding_passive_method_detection',
      'anti_islanding_freq_feedback_function',
      'anti_islanding_step_injection_function',
      'anti_islanding_reactive_power_suppression',
      'anti_islanding_gain1',
      'anti_islanding_gain2',
      'anti_islanding_freq_feedback_qmax',
      'anti_islanding_step_injection_qmax',
      'gradient_flag',
      'gradient_energy_source_change',
      'gradient_unit_reference_select',
      'gradient_active_power_gradient',
      'gradient_active_power_set_point_gradient',
      'derating_flag',
      'derating_start_temp',
      'derating_value',
      'inverter_reconnection_time',
      'feedin_relay_flag',
      'feedin_relay_attach_level_relay1',
      'feedin_relay_detach_level_relay1',
      'feedin_relay_attach_level_relay2',
      'feedin_relay_detach_level_relay2',
      'feedin_relay_attach_level_relay3',
      'feedin_relay_detach_level_relay3',
      'feedin_relay_attach_level_relay4',
      'feedin_relay_detach_level_relay4',
      'limit_control_export_hard_limit_flag',
      'limit_control_export_hard_limit_level',
      'limit_control_export_hard_limit_time',
      'limit_control_export_soft_limit_flag',
      'limit_control_export_soft_limit_level',
      'limit_control_export_soft_limit_time',
      'limit_control_generation_hard_limit_flag',
      'limit_control_generation_hard_limit_level',
      'limit_control_generation_hard_limit_time',
      'limit_control_generation_soft_limit_flag',
      'limit_control_generation_soft_limit_level',
      'limit_control_generation_soft_limit_time',
      'cls_export_limit',
      'cls_import_limit',
    ],
    pricing: [
      'tou_start_date',
      'tou_end_date',
      'tou_weekday_count',
      'tou_weekday_section',
      'tou_weekend_count',
      'tou_weekend_section',
    ],
  },
  FOX: {
    basic: [
      'installer_page_temp_password_password',
      'pv_feed_in_limit',
      'pv_feed_in_limit_w',
      'energy_policy',
      'battery_backup_soc',
      'ul1741_flag',
      'web_page_password_engineer',
      'web_page_password_service',
    ],
    engineer: [
      'external_control_connection',
      'eos_flag',
      'eos_power_sampling_interval',
      'eos_default_sampling_interval',
      'sg_ready_flag',
      'sg_ready_heat_pump_consumption_power',
      'fcas_flag',
      'fcas_response_capacity_limit',
      'fcas_droop_rate_raise',
      'fcas_droop_rate_lower',
      'fcas_oftb_max',
      'fcas_oftb_min',
      'fcas_fr_max',
      'fcas_fr_min',
      'fcas_nofb_max',
      'fcas_nofb_min',
      'fcas_test_mode',

      'external_ems_connection',
      'pcs_connection_mode',
      'pcs_debug_mode',
      'external_ems_model',
      'external_ems_modbus_tcp_ip',
      'external_ems_modbus_tcp_port',
      'external_ems_modbus_rtu_dev',
      'external_ems_modbus_rtu_baudrate',
      'external_ems_modbus_rtu_parity',
      'external_ems_modbus_rtu_data',
      'external_ems_modbus_rtu_stop',
      'external_ems_server_id',
      'external_ems_grid_code',
      'external_ems_meter1',
      'external_ems_meter2',
      'battery_user_soc_min',
      'battery_user_soc_max',
      'log_level_process_mgr',
      'log_level_system_log',
      'log_level_fota',
      'log_level_power_control',
      'log_level_algorithm_mgr',
      'log_level_ess_mgr',
      'log_level_dc_source_mgr',
      'log_level_cloud_mgr',
      'log_level_meter_mgr',
      'log_level_gateway_mgr',
      'log_level_data_accumulator',
      'log_level_db_mgr',
      'log_level_web_engine',
      'log_level_gqa',
      'log_level_mlpe_mgr',
      'log_level_sys_mgr',
      'log_level_energy_link',
      'log_level_diag_monitor',
      'log_level_csip_mgr',
      'log_level_energy_optimizer',
      'debug_mode_mqtt',
      'debug_mode_modbus',
      'auto_fw_update_flag',
    ],
    pricing: [
      'tou_start_date',
      'tou_end_date',
      'tou_weekday_count',
      'tou_weekday_section',
      'tou_weekend_count',
      'tou_weekend_section',
    ],
  },
  AC_SYS: {
    basic: [
      'accb_backfeed_limit_flag',
      'msp_busbar_protection_flag',
      'accb_backfeed_limit',
      'msp_breaker_rating',
      'msp_busbar_rating',
      'msp_msp_breaker_rating',
      'msp_msp_busbar_rating',
      'msp_der_breaker_rating',
      'msp_pv_breaker_rating',
      'msp_ess_breaker_rating',
      'battery_backup_soc',
      'pv_feed_in_limit_flag',
      'pv_feed_in_limit_w',
      'accb_consumption_ct_installed_point',
      'accb_external_ct_installed_point',
      'accb_extended_production_ct_flag',
      'accb_polarity_reverse_production_ct',
      'accb_polarity_reverse_consumption_l1_ct',
      'accb_polarity_reverse_consumption_l2_ct',
      'accb_polarity_reverse_ext_production_ct',
      'energy_policy',
      'storm_cover_flag',
      'storm_cover_charge_type',
      'pv_inverter_nameplate_power',
      'site_info_backup_config_type',
    ],
    engineer: [
      'external_control_connection',
      'inverter_max_output_power',
      'inverter_grid_target_frequency',
      'inverter_external_generator_mode',
      'inverter_permit_service_mode_select',
      'inverter_enter_service_randomized_delay',
      'inverter_import_power_limit',
      'inverter_export_power_limit',
      'battery_charge_limit',
      'battery_discharge_limit',
      'ul3141_ess_mode',
      'ul1741_flag',
      'pcs_debug_mode',
      'battery_hysteresis_low',
      'battery_hysteresis_high',
      'battery_user_soc_min',
      'battery_user_soc_max',
      'log_level_process_mgr',
      'log_level_system_log',
      'log_level_fota',
      'log_level_power_control',
      'log_level_ess_mgr',
      'log_level_cloud_mgr',
      'log_level_meter_mgr',
      'log_level_gateway_mgr',
      'log_level_data_accumulator',
      'log_level_db_mgr',
      'log_level_web_engine',
      'log_level_mlpe_mgr',
      'log_level_sys_mgr',
      'log_level_energy_link',
      'log_level_diag_monitor',
      'log_level_csip_mgr',
      'log_level_energy_monitor',
      'log_level_energy_scheduler',
      'log_level_energy_optimizer',
      'debug_mode_mqtt',
      'debug_mode_modbus',
      'auto_fw_update_flag',
      'hub_external_inverter_flag',
      'hub_external_inverter2_flag',
      'hub_external_inverter_nameplate_power',
      'hub_external_inverter2_nameplate_power',
      'hub_msp_current_limit',
      'site_configuration_disable_mode',
      'log_level_ipc_bridge',
      'hub_generator_flag',
      'hub_generator_remote_on',
      'hub_generator_nameplate_power',
      'hub_external_ct_installed_point',
    ],
    advanced: [
      'grid_code',
      'inverter_tau_coefficient',
      'inverter_control_grid_target_voltage',
      'inverter_control_grid_target_voltage_offset',
      'inverter_control_pcs_max_apparent_power_limit',
      'inverter_control_pcs_var_max_q1',
      'inverter_control_pcs_var_max_q2',
      'inverter_control_pcs_var_max_q3',
      'inverter_control_pcs_var_max_q4',
      'inverter_control_pf_min_q1',
      'inverter_control_pf_min_q2',
      'inverter_control_pf_min_q3',
      'inverter_control_pf_min_q4',
      'inverter_control_charge_rate_setting',
      'inverter_control_discharge_rate_setting',
      'inverter_control_va_setting',
      'inverter_control_active_power_setting',
      'inverter_control_active_power_over_excited_setting',
      'inverter_control_active_power_under_excited_setting',
      'inverter_control_export_pmin',
      'inverter_control_import_pmin',
      'frt_hvrt',
      'frt_start_level_hvrt',
      'grid_voltage_over_voltage_detection_level1',
      'grid_voltage_over_voltage_detection_time1',
      'grid_voltage_over_voltage_detection_level2',
      'grid_voltage_over_voltage_detection_time2',
      'grid_voltage_over_voltage_detection_level3',
      'grid_voltage_over_voltage_detection_time3',
      'grid_voltage_over_voltage_detection_level4',
      'grid_voltage_over_voltage_detection_time4',
      'grid_voltage_over_voltage_detection_level5',
      'grid_voltage_over_voltage_detection_time5',
      'grid_voltage_over_voltage_release_level',
      'grid_voltage_over_voltage_release_time',
      'grid_voltage_ten_min_voltage_detection_level',
      'micro_inverter_der_trip_hv_crv_mom_cess_pt',
      'micro_inverter_der_trip_lv_crv_mom_cess_pt',
      'micro_inverter_der_freq_droop_freq_watt_pmax',
      'micro_inverter_der_freq_droop_ctl_pmin',
      'frt_lvrt',
      'frt_start_level_lvrt',
      'grid_voltage_under_voltage_detection_level1',
      'grid_voltage_under_voltage_detection_time1',
      'grid_voltage_under_voltage_detection_level2',
      'grid_voltage_under_voltage_detection_time2',
      'grid_voltage_under_voltage_detection_level3',
      'grid_voltage_under_voltage_detection_time3',
      'grid_voltage_under_voltage_detection_level4',
      'grid_voltage_under_voltage_detection_time4',
      'grid_voltage_under_voltage_detection_level5',
      'grid_voltage_under_voltage_detection_time5',
      'grid_voltage_under_voltage_release_level',
      'grid_voltage_under_voltage_release_time',
      'frt_hfrt',
      'frt_start_level_hfrt',
      'grid_frequency_over_frequency_detection_level1',
      'grid_frequency_over_frequency_detection_time1',
      'grid_frequency_over_frequency_detection_level2',
      'grid_frequency_over_frequency_detection_time2',
      'grid_frequency_over_frequency_detection_level3',
      'grid_frequency_over_frequency_detection_time3',
      'grid_frequency_over_frequency_detection_level4',
      'grid_frequency_over_frequency_detection_time4',
      'grid_frequency_over_frequency_detection_level5',
      'grid_frequency_over_frequency_detection_time5',
      'grid_frequency_over_frequency_release_level',
      'grid_frequency_over_frequency_release_time',
      'frt_lfrt',
      'frt_start_level_lfrt',
      'grid_frequency_under_frequency_detection_level1',
      'grid_frequency_under_frequency_detection_time1',
      'grid_frequency_under_frequency_detection_level2',
      'grid_frequency_under_frequency_detection_time2',
      'grid_frequency_under_frequency_detection_level3',
      'grid_frequency_under_frequency_detection_time3',
      'grid_frequency_under_frequency_detection_level4',
      'grid_frequency_under_frequency_detection_time4',
      'grid_frequency_under_frequency_detection_level5',
      'grid_frequency_under_frequency_detection_time5',
      'grid_frequency_under_frequency_release_level',
      'grid_frequency_under_frequency_release_time',
      'active_power_flag',
      'active_power_set_point_value',
      'active_power_frequency_flag',
      'active_power_frequency_of_pref_select',
      'active_power_frequency_uf_pref_select',
      'active_power_frequency_slope_select',
      'active_power_frequency_hysteresis_select',
      'active_power_frequency_x3',
      'active_power_frequency_x4',
      'active_power_frequency_response_time',
      'active_power_frequency_droop_kof',
      'active_power_frequency_droop_kuf',
      'active_power_voltage_flag',
      'active_power_voltage_x1',
      'active_power_voltage_y1',
      'active_power_voltage_x2',
      'active_power_voltage_y2',
      'active_power_voltage_x3',
      'active_power_voltage_y3',
      'active_power_voltage_x4',
      'active_power_voltage_y4',
      'active_power_voltage_response_time',
      'reactive_power_cospi_set_point_flag',
      'reactive_power_cospi_set_point_excited_select',
      'reactive_power_cospi_set_point_value',
      'reactive_power_cospi_set_point_response_time',
      'reactive_power_cospi_set_point_apparent_power_priority',
      'reactive_power_q_p_flag',
      'reactive_power_q_p_gen_x1',
      'reactive_power_q_p_gen_y1',
      'reactive_power_q_p_gen_x2',
      'reactive_power_q_p_gen_y2',
      'reactive_power_q_p_gen_x3',
      'reactive_power_q_p_gen_y3',
      'reactive_power_q_p_load_x1',
      'reactive_power_q_p_load_y1',
      'reactive_power_q_p_load_x2',
      'reactive_power_q_p_load_y2',
      'reactive_power_q_p_load_x3',
      'reactive_power_q_p_load_y3',
      'reactive_power_q_p_response_time',
      'reactive_power_q_p_reactive_power_mode',
      'reactive_power_q_p_apparent_power_priority',
      'reactive_power_q_set_point_flag',
      'reactive_power_q_set_point_value',
      'reactive_power_q_set_point_response_time',
      'reactive_power_q_set_point_reactive_power_mode',
      'reactive_power_q_set_point_apparent_power_priority',
      'reactive_power_q_u_flag',
      'reactive_power_q_u_fixed_vref_select',
      'reactive_power_q_u_vref',
      'reactive_power_auto_vref_avg_time',
      'reactive_power_q_u_x1',
      'reactive_power_q_u_y1',
      'reactive_power_q_u_x2',
      'reactive_power_q_u_y2',
      'reactive_power_q_u_x3',
      'reactive_power_q_u_y3',
      'reactive_power_q_u_x4',
      'reactive_power_q_u_y4',
      'reactive_power_q_u_response_time',
      'reactive_power_q_u_reactive_power_mode',
      'reactive_power_q_u_apparent_power_priority',
      'dynamic_reactive_current_flag',
      'dynamic_reactive_current_ar_gra_mod',
      'dynamic_reactive_current_db_v_min',
      'dynamic_reactive_current_db_v_max',
      'dynamic_reactive_current_ar_gra_sag',
      'dynamic_reactive_current_ar_gra_swell',
      'dynamic_reactive_current_hold_tmms',
      'dynamic_reactive_current_filter_tms',
      'dc_injection_flag',
      'dc_injection_detection_level1',
      'dc_injection_detection_time1',
      'dc_injection_detection_level2',
      'dc_injection_detection_time2',
      'dc_injection_control_flag',
      'dc_injection_control_level',
      'rcmu_sudden_flag',
      'rcmu_sudden_detection_level1',
      'rcmu_sudden_detection_time1',
      'rcmu_sudden_detection_level2',
      'rcmu_sudden_detection_time2',
      'rcmu_sudden_detection_level3',
      'rcmu_sudden_detection_time3',
      'rcmu_continuous_flag',
      'rcmu_continuous_detection_level',
      'rcmu_continuous_detection_time',
      'anti_islanding_active_method_detection',
      'anti_islanding_passive_method_detection',
      'anti_islanding_freq_feedback_function',
      'anti_islanding_step_injection_function',
      'anti_islanding_reactive_power_suppression',
      'anti_islanding_gain1',
      'anti_islanding_gain2',
      'anti_islanding_freq_feedback_qmax',
      'anti_islanding_step_injection_qmax',
      'gradient_flag',
      'gradient_energy_source_change',
      'gradient_unit_reference_select',
      'gradient_active_power_gradient',
      'gradient_active_power_set_point_gradient',
      'derating_flag',
      'derating_start_temp',
      'derating_value',
      'inverter_reconnection_time',
      'inverter_randomized_delay_time',
      'micro_inverter_ena_rmp',
      'micro_inverter_ena_tov',
      'micro_inverter_ena_gfdi',
      'micro_inverter_ena_flag_nr_config',
      'micro_inverter_pv_settings_vpv_min',
      'micro_inverter_pv_settings_vpv_max',
      'micro_inverter_pv_settings_ipv_max',
      'micro_inverter_pv_settings_temp_max',
      'micro_inverter_pv_settings_temp_min',
      'micro_inverter_pv_settings_pow_calib',
      'micro_inverter_w_rmp',
    ],
    pricing: [
      'tou_start_date',
      'tou_end_date',
      'tou_weekday_count',
      'tou_weekday_section',
      'tou_weekend_count',
      'tou_weekend_section',
    ],
  },
};

export function getFilterValues(
  genType: 'GEN2' | 'GEN3' | 'FOX' | 'AC_SYS',
  msgType: 'BS' | 'AS' | 'TOU' | 'ES',
  data: any
) {
  if (genType === 'GEN2' && msgType === 'BS') {
    return pickBy(data, (v, k: any) => !Helper.isNull(v) && settingsParam.GEN2.basic.includes(k));
  }
  if (genType === 'GEN2' && msgType === 'AS') {
    return pickBy(data, (v, k: any) => !Helper.isNull(v) && settingsParam.GEN2.advanced.includes(k));
  }
  if (genType === 'GEN3' && msgType === 'BS') {
    return pickBy(data, (v, k: any) => !Helper.isNull(v) && settingsParam.GEN3.basic.includes(k));
  }
  if (genType === 'GEN3' && msgType === 'ES') {
    return pickBy(data, (v, k: any) => !Helper.isNull(v) && settingsParam.GEN3.engineer.includes(k));
  }
  if (genType === 'GEN3' && msgType === 'AS') {
    return pickBy(data, (v, k: any) => !Helper.isNull(v) && settingsParam.GEN3.advanced.includes(k));
  }
  if (genType === 'GEN3' && msgType === 'TOU') {
    return pickBy(data, (v, k: any) => !Helper.isNull(v) && settingsParam.GEN3.pricing.includes(k));
  }
  if (genType === 'FOX' && msgType === 'BS') {
    return pickBy(data, (v, k: any) => !Helper.isNull(v) && settingsParam.FOX.basic.includes(k));
  }
  if (genType === 'FOX' && msgType === 'ES') {
    return pickBy(data, (v, k: any) => !Helper.isNull(v) && settingsParam.FOX.engineer.includes(k));
  }
  if (genType === 'FOX' && msgType === 'TOU') {
    return pickBy(data, (v, k: any) => !Helper.isNull(v) && settingsParam.FOX.pricing.includes(k));
  }
  if (genType === 'AC_SYS' && msgType === 'BS') {
    return pickBy(data, (v, k: any) => !Helper.isNull(v) && settingsParam.AC_SYS.basic.includes(k));
  }
  if (genType === 'AC_SYS' && msgType === 'ES') {
    return pickBy(data, (v, k: any) => !Helper.isNull(v) && settingsParam.AC_SYS.engineer.includes(k));
  }
  if (genType === 'AC_SYS' && msgType === 'AS') {
    return pickBy(data, (v, k: any) => !Helper.isNull(v) && settingsParam.AC_SYS.advanced.includes(k));
  }
  if (genType === 'AC_SYS' && msgType === 'TOU') {
    return pickBy(data, (v, k: any) => !Helper.isNull(v) && settingsParam.AC_SYS.pricing.includes(k));
  }
  throw new UnsupportedGenType(`${genType}, ${msgType}`);
}
