export const HOME_INFO_TAB_NAME = {
    USER: 'user',
    INSTALLATION: 'installation',
    CONTROL: 'control',
};
export const INSTALLATION_TAB = {
    PRODUCT: 'product',
    INSTALLER: 'installer',
    ADDRESS: 'address',
};
