/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import type { Module } from 'vuex';

import { TOAST_MESSAGE_STATUS_TYPE } from '@hems/util/src/constant';
import type { ToastMessageStatusTypeValue } from '@hems/util/src/constant';

import type { CommonRootState } from 'hems/common/store';
import type { ToastState } from 'hems/toast';

export const initState: ToastState = {
  isShow: false,
  message: '',
  type: TOAST_MESSAGE_STATUS_TYPE.SUCCESS,
};

const prefix = 'toast';

export const TOAST_MUTATION = {
  SET_IS_SHOW: `${prefix}/setIsShow`,
  SET_MESSAGE_INFO: `${prefix}/setMessageInfo`,
} as const;

export default {
  namespaced: true,
  state: { ...initState },
  mutations: {
    setIsShow: (state, payload: boolean) => {
      state.isShow = payload;
    },
    setMessageInfo: (state, payload: { message: string; type?: ToastMessageStatusTypeValue }) => {
      state.message = payload.message;
      state.type = payload.type || TOAST_MESSAGE_STATUS_TYPE.SUCCESS;
    },
    clear(state) {
      state = initState;
    },
  },
} as Module<ToastState, CommonRootState>;
