export const STORM_COVER_BUTTON = {
    ON: 1,
    OFF: 0,
};
export const STORM_MESSAGE = {
    TITLE: 'control.storm_cover',
    PROVIDER: 'message.severe_weather_provided',
    DETAIL: 'message.storm_cover_enabled_description',
    ENABLE: 'control.enable_storm_cover',
    DISABLE: 'control.disable_storm_cover',
    CHARGING_FROM_GRID: 'message.charge_from_grid',
    OVERRIDE: 'message.storm_cover_activated_override',
};
export const STORM_COVER_BATTERY_SOURCE = {
    CHARGE_FROM_SOLAR: 0,
    CHARGE_FROM_SOLAR_GRID: 1,
};
/** 악천후 이벤트 읽음 여부 (0: 읽지 않음, 1: 읽음) */
export const STORM_COVER_NOTIFICATION = {
    /** 악천후 이벤트 읽지 않음 (신규 이벤트) */
    ON: 0,
    /** 악천후 이벤트 읽음 */
    OFF: 1,
};
