// https://github.com/vueform/slider
import { defineComponent, ref } from 'vue';
import Slider from '@vueform/slider';
export default defineComponent({
    name: 'BasicSlider',
    components: {
        Slider,
    },
    inheritAttrs: false,
    props: {
        modelValue: {
            type: [Number, Array],
            default: 0,
        },
        tooltipEnable: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const value = ref(props.modelValue ?? 0);
        const updateValue = (value) => {
            emit('update:modelValue', value);
        };
        return {
            value,
            updateValue,
        };
    },
});
