import { defineComponent, reactive, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import pickBy from 'lodash-es/pickBy';
import { LabelInput, Searchbox } from '@hems/component';
import { Helper } from '@hems/util';
export default defineComponent({
    name: 'InstallerAccountSearchBox',
    components: {
        Searchbox,
        LabelInput,
    },
    props: {
        condition: {
            type: Object,
            default: () => ({
                searchFor: null,
            }),
        },
    },
    emits: ['search'],
    async setup(props, { emit }) {
        const { t } = useI18n();
        const state = reactive({
            condition: props.condition,
        });
        const placeholder = computed(() => `${t('message.enter_search_word')} (${t('account.id')}, ${t('account.installer')}, ${t('common.company')} , ${t('account.phone')}, ${t('account.email')})`);
        function onSearch(e) {
            e.preventDefault();
            if (state.condition) {
                state.condition.searchFor = state.condition.searchFor?.trim();
            }
            emit('search', pickBy(state.condition, (v) => !Helper.isNull(v)));
        }
        return {
            state,
            placeholder,
            onSearch,
        };
    },
});
