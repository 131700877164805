import Cookies from 'js-cookie';

import type { RoleCodeValue, RoleNameValue } from '@hems/util/src/constant/role';
import { isRoleCodeType } from '@hems/util/src/helper/tsguardHelper';

import type { Role } from 'hems';

const RoleMap: Readonly<Record<RoleCodeValue, Role>> = {
  10: 'user',
  20: 'installer',
  30: 'operator',
  /** Utility (UI 지원X) */
  40: 'utility',
  50: 'cocompany',
  60: 'dev',
  70: 'service',
  90: 'admin',
  80: 'retailer',
};

export const getRoleNm = (roleCd: RoleCodeValue | string) => {
  if (!isRoleCodeType(roleCd)) {
    return;
  }

  return RoleMap[roleCd];
};

export const parseJwt = (jwt?: string): Record<string, string> | null => {
  if (!jwt) {
    return null;
  }
  try {
    const base64Url = jwt.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => {
          return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  } catch (error) {
    return null;
  }
};

const TOKEN_KEY = 'access_token';

export const saveAccessToken = (token: string): void => {
  Cookies.set(TOKEN_KEY, encodeURIComponent(token), {
    path: '/',
    sameSite: 'strict',
  });
};

export const saveAccessTokenForIframe = (token: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    try {
      Cookies.set(TOKEN_KEY, encodeURIComponent(token), {
        path: '/',
        sameSite: 'none',
        secure: true,
      });

      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const getAccessToken = (): string | undefined => Cookies.get(TOKEN_KEY);

export const removeAccessToken = (): void => {
  Cookies.remove(TOKEN_KEY, { path: '/', sameSite: 'strict' });

  // FIXME: 임시 기능. 기존 access token을 쿠키에 set할 때 window.location.hostname을 domain으로 설정했던 것들을 제거하기 위함.
  Cookies.remove(TOKEN_KEY, {
    domain: window.location.hostname,
    path: '/',
    sameSite: 'strict',
  });
};

export const isExpired = (jwtObject: Record<string, string>) => {
  const expiredDate = new Date(Number(jwtObject.exp) * 1000);
  const now = new Date();

  return now > expiredDate;
};

export const getRoleCdByRoleName = (roleNm: RoleNameValue) => {
  const roleInfo = Object.entries(RoleMap).find(([, roleValue]) => {
    return roleNm === roleValue;
  });

  return roleInfo?.[0];
};
