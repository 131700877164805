/** Copyright © 2024 Qcells. All rights reserved.
 *
 * This software is proprietary and confidential. Unauthorized use,
 * duplication, or distribution of software is strictly prohibited.
 */
// export const passwordPolicy = /(((?=.*[a-z])|(?=.*[A-Z]))(?=.*\d)(?=.*[`~!@#$%^&*|'";:/?\\]).{8,20})/;
export const passwordPolicy = /(((?=.*[a-z])|(?=.*[A-Z]))(?=.*\d)(?=.*[`~!@#$%^&*|'";:/?\\]).{8,20})/;
export const idPolicy = /^[a-zA-Z0-9!@#$*()\-_+,.~<>[\]{}=|]*$/;
export const alpha2CountryList = [
    { country: 'AU', lang: 'en' },
    { country: 'AT', lang: 'de' },
    { country: 'BR', lang: 'pt' },
    { country: 'CA', lang: 'en' },
    { country: 'CL', lang: 'en' },
    { country: 'CN', lang: 'zh' },
    { country: 'FR', lang: 'fr' },
    { country: 'DE', lang: 'de' },
    { country: 'GR', lang: 'el' },
    { country: 'HU', lang: 'hu' },
    { country: 'IE', lang: 'en' },
    { country: 'IT', lang: 'it' },
    { country: 'JP', lang: 'ja' },
    { country: 'KR', lang: 'ko' },
    { country: 'MY', lang: 'ms' },
    { country: 'NL', lang: 'nl' },
    { country: 'PH', lang: 'en' },
    { country: 'PL', lang: 'pl' },
    { country: 'PT', lang: 'pt' },
    { country: 'ES', lang: 'eu' },
    { country: 'CH', lang: 'fr' },
    { country: 'TR', lang: 'tr' },
    { country: 'GB', lang: 'en' },
    { country: 'US', lang: 'en' },
    { country: 'VN', lang: 'vi' },
    { country: 'NZ', lang: 'en' },
    { country: 'BE', lang: 'fr' },
];
export const euAlpha2CountryList = [
    { country: 'DE', lang: 'de' },
    { country: 'AT', lang: 'de' },
    { country: 'BE', lang: 'fr' },
    { country: 'CH', lang: 'fr' },
    { country: 'FR', lang: 'fr' },
    { country: 'GB', lang: 'en' },
    { country: 'IE', lang: 'en' },
    { country: 'PT', lang: 'pt' },
];
export const auAlpha2CountryList = [
    { country: 'AU', lang: 'en' },
    { country: 'NZ', lang: 'en' },
];
export const usAlpha2CountryList = [
    { country: 'US', lang: 'en' },
    { country: 'CA', lang: 'en' },
];
export const ENERGY_POLICY = {
    STAND_BY: '0',
    SELF_CONSUMPTION: '1',
    ZERO_EXPORT: '2',
    TIME_BASED: '3',
    EXTERNAL_GENERATION: '4',
    EOS: '5',
    GATEWAY: '6',
    ENGINEER: '7',
    SMART_SAVING: '8',
    STORM_COVER: '9',
    VPP: '10',
};
export const ENV_LOCALE = {
    EU: 'EU',
    AU: 'AU',
    NZ: 'NZ',
    US: 'US',
};
/** @TODO 추후 State(주) 단위로 변경될 예정 */
export const SITE_COUNTRY_CODE = {
    US: 'US',
    KR: 'KR',
    ETC: 'etc',
};
export const NUMBER_UNIT = {
    MILLION: 1000000,
    TEN_THOUSAND: 10000,
    THOUSAND: 1000,
    HUNDRED: 100,
};
const OPER_STATUS_CD = {
    _40: '40',
    _60: '60',
};
export const DEVICE_CONNECTION_STATUS = {
    [OPER_STATUS_CD._40]: 'incomplete',
    [OPER_STATUS_CD._60]: 'offline',
    ONLINE: 'online',
    ONLINE_DISCONNECTED: 'online-disconnected',
};
export const AC_SYSTEM_FIELD_TYPE_CODE = {
    SITE: 'site',
    ACES: 'aces',
    ACCB: 'accb',
    ACES_INDIVIDUAL: 'aces_individual',
};
export const AC_DEVICE_MODEL_TYPE = {
    AC_COMBINER: 'accb',
    AC_COUPLED: 'aces',
};
export const APP_MODE = {
    LOCAL: 'local',
    DEVELOPMENT: 'development',
    STAGING: 'staging',
    PRODUCTION: 'production',
};
export const SAPN_TYPE = {
    NO: 'NO',
    FAIL: 'FAIL',
    SAPN: 'SAPN',
};
export const FORM_TYPE = {
    NEW: 'new',
    EDIT: 'edit',
    READ: 'read',
};
export const GROUP_MODE = {
    NEW: 'new',
    NEW_COPY: 'newCopy',
    READ: 'read',
};
export const GROUP_READ_TYPE = {
    INFO: 'info',
    TARGET: 'target',
    CONFIG: 'config',
};
export const SORT_ORDER = {
    DESCENDING: 'desc',
    ASCENDING: 'asc',
};
export const CONFIG_MSG_TYPE = {
    AS: 'AS',
    BS: 'BS',
    ES: 'ES',
    TOU: 'TOU',
};
export const CSS_COLUMN = {
    MAX_COLUMN_1: 1,
    MAX_COLUMN_2: 2,
    MAX_COLUMN_3: 3,
    MAX_COLUMN_4: 4,
    MAX_COLUMN_5: 5,
    MAX_COLUMN_6: 6,
};
export const VALIDATION_MESSAGE = {
    DUPLICATE: 'duplicate',
    INVALID: 'invalid',
    VALID: 'valid',
};
export const CSS_VISIBILITY = {
    VISIBLE: 'visible',
    HIDDEN: 'hidden',
};
export const SELECT_ALL = 'ALL';
export const INPUT_VALID_STATUS = {
    DEFAULT: '',
    VALID: 'valid',
    INVALID: 'invalid',
};
export const QOMMAND_WEB_ORIGIN_URL = 'qommand.qcells.com';
export const API_METHOD_TYPE = {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    PATCH: 'patch',
    DELETE: 'delete',
};
export const CONTENT_TYPE_HEADER = {
    KEY: 'Content-Type',
    APPLICATION_JSON: 'application/json',
};
export const CS_CONTACT_NUMBER = '+1 (888) 249 - 7750';
export const INDENT_DEPTH = {
    FIRST_LEVEL_INDENT: 1,
    SECOND_LEVEL_INDENT: 2,
    THIRD_LEVEL_INDENT: 3,
};
export const USER_AGENT_TYPE = {
    ANDROID: 'android',
    IPHONE: 'iphone',
    IPAD: 'ipad',
    IOS: 'ios',
    AOS: 'aos',
    PC: 'pc',
};
export const APP_TYPE = {
    ADMIN: 'ADMIN',
    INSTALLER: 'INSTALLER',
    PORTAL: 'PORTAL',
    USER: 'USER',
};
