import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-bccd19bc"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "list-item-contents" };
const _hoisted_2 = { class: "list-item-main-contents" };
const _hoisted_3 = {
    key: 0,
    class: "list-item-description-container"
};
const _hoisted_4 = { key: 1 };
const _hoisted_5 = { class: "list-item-link-icon-wrapper" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Checkbox = _resolveComponent("Checkbox");
    const _component_Badge = _resolveComponent("Badge");
    const _component_Image2 = _resolveComponent("Image2");
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(_ctx.listItemContainerClassName)
    }, [
        _createElementVNode("div", {
            class: "list-item-contents-container",
            onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('onDetail', $event, _ctx.listItemInfo.id)))
        }, [
            (_ctx.listItemInfo.mode === _ctx.LIST_ITEM_MODE.CHECK)
                ? (_openBlock(), _createBlock(_component_Checkbox, {
                    key: 0,
                    "is-checked": _ctx.listItemInfo.checkStatus,
                    class: "list-item-checkbox",
                    onUpdateCheckedStatus: _ctx.updateCheckedStatus
                }, null, 8, ["is-checked", "onUpdateCheckedStatus"]))
                : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.listItemInfo.mainContents), 1),
                (!_ctx.isEmptyArray(_ctx.listItemDescriptionList))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listItemDescriptionList, (desc) => {
                            return (_openBlock(), _createElementBlock("div", {
                                key: desc,
                                class: "list-item-description"
                            }, [
                                (_ctx.listItemInfo.mode === _ctx.LIST_ITEM_MODE.BADGE && _ctx.listItemInfo.badgeStatus)
                                    ? (_openBlock(), _createBlock(_component_Badge, {
                                        key: 0,
                                        status: _ctx.listItemInfo.badgeStatus
                                    }, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(desc), 1)
                                        ]),
                                        _: 2
                                    }, 1032, ["status"]))
                                    : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(desc), 1))
                            ]));
                        }), 128))
                    ]))
                    : _createCommentVNode("", true)
            ]),
            (_ctx.listItemInfo.iconImageInfo)
                ? (_openBlock(), _createBlock(_component_Image2, {
                    key: 1,
                    class: "list-item-icon",
                    src: _ctx.listItemInfo.iconImageInfo.src,
                    extension: _ctx.listItemInfo.iconImageInfo.extension
                }, null, 8, ["src", "extension"]))
                : _createCommentVNode("", true)
        ]),
        (_ctx.listItemInfo.mode !== _ctx.LIST_ITEM_MODE.CHECK)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "list-item-link",
                onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('onEdit', _ctx.listItemInfo.id)))
            }, [
                _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_Image2, {
                        class: "list-item-link-icon",
                        src: "icons/ic_arrow_right",
                        extension: "svg"
                    })
                ])
            ]))
            : _createCommentVNode("", true)
    ], 2));
}
