import { defineComponent, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { getACMeterData, getChartTemplateAndData, getConvertedBatteryData, getXAxisCategories, updateChartData, } from '@hems/container/src/forms/device/statistics/_shared';
import { getGraphMinuteConfig } from '@hems/container/src/forms/device/statistics/_shared/filterConfig';
import DeviceLineGraph from '@hems/container/src/highcharts/spline/DeviceLineGraph.vue';
import { useACDeviceType, useRole, useSiteInfo } from '@hems/util';
import { AC_COMBINER_STATISTICS_LIST, BATTERY_STATISTICS_LIST, ENERGY_MINUTE_STATISTICS_LIST, GRAPH_GROUP_CODE, UNIT, GRID_STATISTICS_LIST, POWER_STATISTICS_LIST, } from '@hems/util/src/constant';
export default defineComponent({
    name: 'StatisticsMinuteContainer',
    components: {
        DeviceLineGraph,
    },
    props: {
        data: {
            type: Array,
            default: () => [],
        },
    },
    setup(props) {
        const { t } = useI18n();
        const xAxisCategories = ref([]);
        const power = ref([]);
        const energy = ref([]);
        const ACCombiner = ref([]);
        const grid = ref([]);
        const battery = ref([]);
        const { hasACCombiner, isSolarConfig } = useACDeviceType();
        const { isAdminGroup, isInstaller } = useRole();
        const { ESSCount } = useSiteInfo();
        const { chartDataTemplate, chartData } = getChartTemplateAndData(getGraphMinuteConfig(isSolarConfig, isInstaller, ESSCount), t);
        const getFilterList = (filterGroup) => chartDataTemplate[filterGroup]?.filterList;
        const getFilterGroupName = (filterGroup) => chartDataTemplate[filterGroup]?.filterGroupName ?? '';
        watch(() => props.data, (data) => {
            xAxisCategories.value = getXAxisCategories(data);
            updateChartData(chartData, data, {
                power: POWER_STATISTICS_LIST,
                energy: ENERGY_MINUTE_STATISTICS_LIST,
                ACCombiner: AC_COMBINER_STATISTICS_LIST,
                battery: BATTERY_STATISTICS_LIST,
                grid: GRID_STATISTICS_LIST,
            });
            power.value = chartData[GRAPH_GROUP_CODE.POWER];
            energy.value = chartData[GRAPH_GROUP_CODE.ENERGY];
            ACCombiner.value = chartData[GRAPH_GROUP_CODE.AC_COMBINER];
            battery.value = getConvertedBatteryData(chartData[GRAPH_GROUP_CODE.BATTERY]);
            grid.value = getACMeterData(chartData[GRAPH_GROUP_CODE.GRID]);
        });
        return {
            power,
            energy,
            ACCombiner,
            grid,
            battery,
            xAxisCategories,
            hasACCombiner,
            GRAPH_GROUP_CODE,
            UNIT,
            isSolarConfig,
            isAdminGroup,
            getFilterGroupName,
            getFilterList,
        };
    },
});
