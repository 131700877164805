
import { defineComponent } from 'vue';

import { Image2 } from '@hems/component';
import { useSiteConfiguration } from '@hems/util';
import { ENERGY_FLOW_IMAGE } from '@hems/util/src/constant';

export default defineComponent({
  name: 'EnergyFlowACPipeLayer',
  components: {
    Image2,
  },
  props: {
    isOnGrid: {
      type: Boolean,
      default: true,
    },
    showGenerator: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { hasESS, hasPV } = useSiteConfiguration();

    return {
      hasESS,
      hasPV,
      ENERGY_FLOW_IMAGE,
    };
  },
});
