/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import type { CommonCode } from 'hems';

import type { ValueType } from 'hems/common/utils';

export const ERROR_HISTORY_GRID_TYPE = {
  ALL: 'all',
  SITE: 'site',
} as const;

export type ErrorHistoryGridTypeValue = ValueType<typeof ERROR_HISTORY_GRID_TYPE>;

export const ERROR_LEVEL = {
  FAULT: 'F',
  WARNING: 'W',
  ALARM: 'A',
} as const;

export type ErrorLevelValue = ValueType<typeof ERROR_LEVEL>;

export const ERROR_LEVEL_DISPLAY_TEXT = {
  FAULT: 'Fault',
  WARNING: 'Warning',
  ALARM: 'Alarm',
} as const;

export type ErrorLevelDisplayTextValue = ValueType<typeof ERROR_LEVEL_DISPLAY_TEXT>;

export const ERROR_LEVEL_SELECTOR_OPTIONS: CommonCode.CodeMap[] = [
  { code: ERROR_LEVEL_DISPLAY_TEXT.FAULT.toLocaleLowerCase(), name: ERROR_LEVEL_DISPLAY_TEXT.FAULT },
  { code: ERROR_LEVEL_DISPLAY_TEXT.WARNING.toLocaleLowerCase(), name: ERROR_LEVEL_DISPLAY_TEXT.WARNING },
  { code: ERROR_LEVEL_DISPLAY_TEXT.ALARM.toLocaleLowerCase(), name: ERROR_LEVEL_DISPLAY_TEXT.ALARM },
];

export const ERROR_PREFIX = {
  /** Grid 에러 코드 (Dashboard - Grid) */
  GRID: 'G',
  /** Hybrid Inverter - PV 모듈 에러 코드 (Dashboard - Solar) */
  SOLAR: 'S',
  /** Micro Inverter 에러 코드 (Dashboard - Solar) */
  MICRO_INVERTER: 'M',
  /** AC Combiner 에러 코드 (Dashboard - AC Combiner) */
  AC_COMBINER: 'A',
  /** DC Optimizer 에러 코드 (DC Optimizer GEN2 - deprecated) */
  DC_OPTIMIZER: 'O',
  /** ESS - Inverter 에러 코드 (Dashboard - ESS) */
  INVERTER: 'P',
  /** ESS - BDC 에러 코드 (Dashboard - ESS) */
  BDC: 'D',
  /** ESS - EMS 또는 G4 HUB - EMS 에러 코드 (Dashboard - ESS) */
  EMS: 'E',
  /** ESS - BMS 에러 코드 (Dashboard - ESS) */
  BMS: 'B',
  /** HUB 에러 코드 (Dashboard - HUB) */
  HUB: 'H',
  /** Cloud 자체 정의 에러 코드 */
  CLOUD: 'C',
} as const;

export type ErrorPrefixValue = ValueType<typeof ERROR_PREFIX>;

export const ERROR_STATUS_TYPE = {
  ERROR: 'E',
  WARNING: 'W',
} as const;

export type ErrorStatusTypeValue = ValueType<typeof ERROR_STATUS_TYPE>;

export const ERROR_RESOLVED_FILTER = {
  CURRENT: 'current',
  RESOLVED: 'resolved',
} as const;

export type ErrorResolvedFilterValue = ValueType<typeof ERROR_RESOLVED_FILTER>;

export const ERROR_HISTORY_DOWNLOAD_LIST_SIZE = 30000;
