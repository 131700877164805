import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ff088202"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "btn_wrap_m mt50" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PopupWrapper = _resolveComponent("PopupWrapper")!

  return (_openBlock(), _createBlock(_component_PopupWrapper, {
    class: "ty_6",
    style: {"opacity":"1","left":"50%","margin-left":"-360px","top":"185px"}
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('message.realtime_paused')), 1),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          type: "button",
          class: "btn_xs btn_c_3",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('resume')))
        }, _toDisplayString(_ctx.$t('common.resume')), 1)
      ])
    ]),
    _: 1
  }))
}