import { computed, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { onClickOutside } from '@vueuse/core';
import { useLanguage } from '@hems/util/src/composable/language';
import DropdownMenu from '@/components/header/DropdownMenu.vue';
import Image from '@/components/image/Image.vue';
export default defineComponent({
    name: 'NewHeader',
    components: { Image, DropdownMenu },
    props: {
        isLogin: {
            type: Boolean,
            default: false,
        },
        showMenu: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['openAccountInfo', 'logout', 'goLoginPage', 'changeLanguage'],
    setup(props, { emit }) {
        const { t } = useI18n();
        const { languageCode, languageOptionsForManagement } = useLanguage();
        const languageMenuRef = ref(null);
        const accountMenuRef = ref(null);
        const selectedLanguage = ref(languageCode.value);
        const languageDropdownItems = languageOptionsForManagement.value;
        const ACCOUNT_MENU = {
            ACCOUNT_INFO: 'account_info',
            LOGOUT: 'logout',
        };
        const accountDropdownItems = computed(() => [
            { text: t('account.account_setting'), value: ACCOUNT_MENU.ACCOUNT_INFO },
            { text: t('account.logout'), value: ACCOUNT_MENU.LOGOUT },
        ]);
        const isOpenLanguageMenu = ref(false);
        const isOpenAccountMenu = ref(false);
        const toggleLanguageMenu = () => {
            isOpenLanguageMenu.value = !isOpenLanguageMenu.value;
            if (isOpenLanguageMenu.value && isOpenAccountMenu.value) {
                isOpenAccountMenu.value = false;
            }
        };
        const toggleAccountMenu = () => {
            if (!props.isLogin) {
                emit('goLoginPage');
                return;
            }
            isOpenAccountMenu.value = !isOpenAccountMenu.value;
            if (isOpenAccountMenu.value && isOpenLanguageMenu.value) {
                isOpenLanguageMenu.value = false;
            }
        };
        const onClickLanguage = (value) => {
            selectedLanguage.value = value;
            isOpenLanguageMenu.value = false;
            emit('changeLanguage', selectedLanguage.value);
        };
        const onClickAccountMenu = (value) => {
            switch (value) {
                case ACCOUNT_MENU.ACCOUNT_INFO:
                    emit('openAccountInfo');
                    break;
                case ACCOUNT_MENU.LOGOUT:
                    emit('logout');
                    break;
                default:
                    break;
            }
            isOpenAccountMenu.value = false;
        };
        onClickOutside(languageMenuRef, (event) => {
            if (event.target instanceof HTMLImageElement) {
                return;
            }
            isOpenLanguageMenu.value = false;
        });
        onClickOutside(accountMenuRef, (event) => {
            if (event.target instanceof HTMLImageElement) {
                return;
            }
            isOpenAccountMenu.value = false;
        });
        return {
            languageMenuRef,
            accountMenuRef,
            isOpenLanguageMenu,
            isOpenAccountMenu,
            selectedLanguage,
            languageDropdownItems,
            accountDropdownItems,
            toggleLanguageMenu,
            toggleAccountMenu,
            onClickLanguage,
            onClickAccountMenu,
        };
    },
});
