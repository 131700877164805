import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-38545c04"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "toast-message-container" };
const _hoisted_2 = { class: "toast-message" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Image2 = _resolveComponent("Image2");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_Image2, {
                class: "image-icon",
                src: _ctx.iconSrc,
                extension: "svg"
            }, null, 8, ["src"]),
            _createElementVNode("span", {
                class: _normalizeClass(`text ${_ctx.statusClass}`)
            }, _toDisplayString(_ctx.message), 3)
        ])
    ]));
}
