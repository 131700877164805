
import { defineComponent, inject, computed } from 'vue';

import { PROVIDE_INJECT_KEYS } from '@hems/util/src/constant';

export default defineComponent({
  name: 'SettingButtonGroup',
  props: {
    isEditable: {
      type: Boolean,
      default: false,
    },
    isAdvancedTopButton: {
      type: Boolean,
      default: false,
    },
    isDisableApply: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['goToList', 'cancel', 'edit'],
  setup(props, { emit }) {
    const goToList = inject<() => void>(PROVIDE_INJECT_KEYS.GO_TO_LIST);
    const buttonClass = computed(() => (props.isAdvancedTopButton ? 'btn_i_2' : 'btn_m'));
    const editButtonClass = computed(() => (props.isAdvancedTopButton ? 'btn_c_4' : 'btn_c_3'));
    const applyButtonClass = computed(() => (props.isDisableApply ? 'btn_c' : 'btn_c_3'));
    const goToListSpanTagClass = computed(() => (props.isDisableApply ? 'ir_b ico_list' : ''));
    const editSpanTagClass = computed(() => (props.isAdvancedTopButton? 'ir_b ico_edit': ''));

    const onEdit = () => {
      emit('edit');
    };

    const onCancel = () => {
      emit('cancel');
    };

    return {
      goToList,
      onEdit,
      onCancel,
      buttonClass,
      editButtonClass,
      applyButtonClass,
      goToListSpanTagClass,
      editSpanTagClass,
    };
  },
});
