
import type { PropType } from 'vue';
import { defineComponent } from 'vue';

import { useSiteMasterDeviceInfo } from '@hems/util';
import type { EnergyProductValueType } from '@hems/util/src/constant';

import type { EnergyFlowFormattedData } from 'hems/energyFlow';
export default defineComponent({
  name: 'GridInfo',
  props: {
    formattedData: {
      type: Object as PropType<{ [K in EnergyProductValueType]?: EnergyFlowFormattedData }>,
      required: true,
    },
  },
  setup() {
    const { isConsumptionCT } = useSiteMasterDeviceInfo();

    return {
      isConsumptionCT,
    };
  },
});
