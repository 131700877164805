import { defineComponent, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { getChartTemplateAndData, getXAxisCategories, updateChartData, } from '@hems/container/src/forms/device/statistics/_shared';
import { getEnergyHourConfig } from '@hems/container/src/forms/device/statistics/_shared/filterConfig';
import DeviceLineGraph from '@hems/container/src/highcharts/spline/DeviceLineGraph.vue';
import { useACDeviceType, useRole } from '@hems/util';
import { ENERGY_HOUR_STATISTICS_LIST, GRAPH_GROUP_CODE, GRAPH_TERM_UNIT, UNIT } from '@hems/util/src/constant';
export default defineComponent({
    name: 'StatisticsHourContainer',
    components: {
        DeviceLineGraph,
    },
    props: {
        data: {
            type: Array,
            default: () => [],
        },
    },
    setup(props) {
        const { t } = useI18n();
        const xAxisCategories = ref([]);
        const energy = ref([]);
        const { isSolarConfig } = useACDeviceType();
        const { isInstaller } = useRole();
        const config = [getEnergyHourConfig(isSolarConfig, isInstaller)];
        const { chartDataTemplate, chartData } = getChartTemplateAndData(config, t);
        const getFilterList = (filterGroup) => chartDataTemplate[filterGroup]?.filterList;
        const getFilterGroupName = (filterGroup) => chartDataTemplate[filterGroup]?.filterGroupName ?? '';
        watch(() => props.data, (data) => {
            updateChartData(chartData, data, { energy: ENERGY_HOUR_STATISTICS_LIST });
            xAxisCategories.value = getXAxisCategories(data, GRAPH_TERM_UNIT.HOUR);
            energy.value = chartData[GRAPH_GROUP_CODE.ENERGY];
        });
        return {
            xAxisCategories,
            energy,
            GRAPH_TERM_UNIT,
            GRAPH_GROUP_CODE,
            UNIT,
            getFilterList,
            getFilterGroupName,
        };
    },
});
