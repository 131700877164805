/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import { computed } from 'vue';
import { useStore } from 'vuex';
// eslint-disable-next-line import/prefer-default-export
export const useSiteConfiguration = () => {
    const store = useStore();
    const siteConfiguration = computed(() => store.state.device.siteConfiguration ?? null);
    const hasPV = computed(() => siteConfiguration.value?.hasPV ?? false);
    const hasESS = computed(() => siteConfiguration.value?.hasESS ?? false);
    const hasHub = computed(() => siteConfiguration.value?.hasHub ?? false);
    const hasThirdParty = computed(() => siteConfiguration.value?.hasThirdParty ?? false);
    return {
        siteConfiguration,
        hasPV,
        hasESS,
        hasHub,
        hasThirdParty,
    };
};
