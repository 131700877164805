import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5dbbbf68"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pop_tit" }
const _hoisted_2 = { class: "withdrawal-message" }
const _hoisted_3 = { class: "password-layer" }
const _hoisted_4 = { class: "withdrawal-text" }
const _hoisted_5 = { class: "btn_wrap_m" }
const _hoisted_6 = {
  type: "submit",
  class: "btn_m btn_c_3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LabelInput = _resolveComponent("LabelInput")!
  const _component_Form = _resolveComponent("Form")!
  const _component_PopupWrapper = _resolveComponent("PopupWrapper")!

  return (_openBlock(), _createBlock(_component_PopupWrapper, {
    class: "join_wrap",
    style: {"width":"1024px","opacity":"1","left":"50%","margin-left":"-640px","top":"190px","margin-bottom":"50px"}
  }, {
    default: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('account.account_termination')), 1),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", null, [
          _createElementVNode("b", null, _toDisplayString(_ctx.$t('message.thank_using_qcells')), 1)
        ]),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('message.member_info_delete')), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('message.deleted_not_restore')), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('message.withdrawn_not_recover')), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('message.keep_monitoring_data')), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('message.same_account_after24')), 1)
      ]),
      _createVNode(_component_Form, {
        "validation-schema": _ctx.schema,
        class: "",
        onSubmit: _ctx.onWithdrawal
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_LabelInput, {
                modelValue: _ctx.state.confirmWithdrawl,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.confirmWithdrawl) = $event)),
                class: "fm_ipt_2",
                name: "confirmWithdrawl",
                type: "text",
                autocomplete: "off",
                label: _ctx.$t('message.type_delete'),
                placeholder: "DELETE"
              }, null, 8, ["modelValue", "label"])
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              type: "button",
              class: "btn_m btn_c_2",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
            }, _toDisplayString(_ctx.$t('common.cancel')), 1),
            _createElementVNode("button", _hoisted_6, _toDisplayString(_ctx.$t('account.termination')), 1)
          ])
        ]),
        _: 1
      }, 8, ["validation-schema", "onSubmit"]),
      _createElementVNode("button", {
        type: "button",
        class: "close_btn_2",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
      }, _toDisplayString(_ctx.$t('common.close')), 1)
    ]),
    _: 1
  }))
}