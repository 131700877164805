
import type { PropType } from 'vue';
import { defineComponent, computed } from 'vue';

import Badge from '@hems/component/src/badge/Badge.vue';
import Image2 from '@hems/component/src/image/Image2.vue';
import Checkbox from '@hems/component/src/inputs/checkbox/Checkbox.vue';
import { LIST_ITEM_MODE } from '@hems/util/src/constant';
import { isEmptyArray } from '@hems/util/src/helper/helper';

import type { ListItemInfo } from 'hems';

export default defineComponent({
  name: 'ListItem',
  components: {
    Checkbox,
    Badge,
    Image2,
  },
  props: {
    listItemInfo: {
      type: Object as PropType<ListItemInfo>,
      required: true,
    },
  },
  emits: ['updateCheckedStatus', 'onDetail', 'onEdit'],
  setup(props, { emit }) {
    const listItemContainerClassName = computed(
      () =>
        `list-item-container list-item-container-${props.listItemInfo.size} ${
          props.listItemInfo.onStatus ? 'list-item-container-on' : ''
        } ${props.listItemInfo.activeStatus ? 'list-item-container-active' : ''}`
    );

    const listItemDescriptionList = computed(() => {
      const { description } = props.listItemInfo;
      if (Array.isArray(description)) {
        return description;
      }
      if (description) {
        return [description];
      }

      return [];
    });

    const updateCheckedStatus = (isChecked: boolean) => {
      emit('updateCheckedStatus', props.listItemInfo.id, isChecked);
    };

    return {
      listItemContainerClassName,
      listItemDescriptionList,
      LIST_ITEM_MODE,
      updateCheckedStatus,
      isEmptyArray,
    };
  },
});
