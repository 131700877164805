/** Copyright © 2024 Qcells. All rights reserved.
 *
 * This software is proprietary and confidential. Unauthorized use,
 * duplication, or distribution of software is strictly prohibited.
 */
import type { RouteRecordRaw } from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';

import stores from '@hems/portal/src/store';
import { Helper } from '@hems/util';
import { LOADING_ACTION } from '@hems/util/src/stores/modules/loading';

import store from '@/store';
import Login from '@/views/Login.vue';
import NewHome from '@/views/NewHome.vue';
import PageWrapper from '@/views/PageWrapper.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: NewHome,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: (to, from, next) => {
      if (store.getters['user/isAuthenticated'] && !store.getters['user/isTokenExpired']) {
        next('/');
      } else {
        next();
      }
    },
  },
  {
    path: '/pages/terms-and-conditions',
    name: 'TermAndConditionPage',
    component: () => import(/* webpackChunkName: "terms" */ '@/views/terms/TermAndConditionPage.vue'),
  },
  {
    path: '/pages/eu-terms-and-conditions',
    name: 'EUTermsAndConditionPage',
    component: () => import(/* webpackChunkName: "terms" */ '@/views/terms/EUTermsAndConditionPage.vue'),
  },
  {
    path: '/pages/au-terms-and-conditions',
    name: 'AUTermsAndConditionPage',
    component: () => import(/* webpackChunkName: "terms" */ '@/views/terms/AUTermsAndConditionPage.vue'),
  },
  {
    path: '/pages/us-terms-and-conditions',
    name: 'USTermsAndConditionPage',
    component: () => import(/* webpackChunkName: "terms" */ '@/views/terms/USTermsAndConditionPage.vue'),
  },
  {
    path: '/pages/nz-terms-and-conditions',
    name: 'NZTermsAndConditionPage',
    component: () => import(/* webpackChunkName: "terms" */ '@/views/terms/NZTermsAndConditionPage.vue'),
  },
  {
    path: '/.well-known',
    name: 'PageWrapper',
    component: PageWrapper,
    children: [
      {
        path: 'security.txt',
        name: 'SecurityText',
        component: () => import(/* webpackChunkName: "cyberSecurity" */ '@/views/cyberSecurity/SecurityText.vue'),
      },
    ],
  },
  {
    path: '/.well-known',
    name: 'PageWrapper',
    component: PageWrapper,
    children: [
      {
        path: 'security.txt',
        name: 'SecurityText',
        component: () => import(/* webpackChunkName: "cyberSecurity" */ '@/views/cyberSecurity/SecurityText.vue'),
      },
    ],
  },
  {
    path: '/mobile/gen2/terms',
    name: 'TermsMobileGen2',
    component: () => import(/* webpackChunkName: "termsMobile" */ '@/views/terms/TermsMobileGen2.vue'),
  },
  {
    path: '/mobile/gen2/terms/nz',
    name: 'NZTermsMobileGen2',
    component: () => import(/* webpackChunkName: "termsMobile" */ '@/views/terms/NZTermsMobileGen2.vue'),
  },
  {
    path: '/redirect/Unauthorized',
    name: 'Unauthorized',
    component: () => import(/* webpackChunkName: "error" */ '@hems/component/src/redirectPages/UnauthorizedPage.vue'),
  },
  {
    path: '/redirect/PageNotFound',
    name: 'PageNotFound',
    component: () => import(/* webpackChunkName: "error" */ '@hems/component/src/redirectPages/NotFoundPage.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/redirect/PageNotFound',
  },
];

const router = createRouter({
  history: createWebHistory(Helper.getAppEnv() === 'local' ? process.env.BASE_URL : undefined),
  routes,
});

router.onError((error) => {
  if (/ChunkLoadError:.*failed./i.test(error.message) || error.name === 'ChunkLoadError') {
    window.location.reload();
  }
});

router.afterEach(() => {
  stores.dispatch(LOADING_ACTION.RESET_LOADING);
});

export default router;
