/**
Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import type { AxiosInstance, AxiosRequestConfig } from 'axios';

import { AxiosErrorException } from '@hems/util/src/exception/exception';

import { DownloadService } from './DownloadService';
import { apiWrapper } from '../../util/helper';

type AnnualReportEmailResponse = {
  status: number;
};

export default class DownloadServiceUser extends DownloadService {
  constructor(axios: AxiosInstance) {
    super(axios);
  }

  public async annualReportFileDownload(siteId: string, params: any): Promise<Blob> {
    return await this.get<Blob>(apiWrapper.managerApi(`/histories/annual-reports/${siteId}/file`), params, {
      headers: {
        Accept: '*/*',
      },
      responseType: 'blob',
    });
  }

  public async annualReportSendEmail(
    siteId: string,
    params: any,
    config?: AxiosRequestConfig
  ): Promise<AnnualReportEmailResponse> {
    const url = apiWrapper.managerApi(`/histories/annual-reports/${siteId}/mail`);
    try {
      const { status } = await this.axios.post<AnnualReportEmailResponse>(url, params, config);

      return { status };
    } catch (e) {
      throw new AxiosErrorException(url, params, e);
    }
  }
}
