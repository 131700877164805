import { Helper, pvmoduleSettingsHelper, SettingHelper } from '@hems/util';
import { SETTING_TARGET } from '@hems/util/src/constant';
import { getSettingSaveParams } from '@hems/util/src/helper/settingHelper';
// TODO: packages 내의 파일에서 절대경로, 상대경로 사용에 대한 논의 결과에 따라 path 수정
import { apiWrapper, getApiVersionHeader } from '../../util/helper';
import { Service } from '../Service';
export default class DeviceService extends Service {
    constructor(axios) {
        super(axios);
    }
    async getImageBySiteId(siteId) {
        return await this.get(apiWrapper.managerApi(`/devices/profiles/${siteId}/images`), {}, {
            responseType: 'blob',
        });
    }
    saveImageBySiteId = async (siteId, params) => {
        const { data } = await this.put(apiWrapper.managerApi(`/devices/profiles/${siteId}/images`), params, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return data;
    };
    async checkDeviceId(deviceId) {
        const { data } = await this.get(apiWrapper.managerApi(`/devices/profiles/validations/${deviceId}/ems-device-id`));
        return data;
    }
    // AC System - 모니터링 Site 목록 조회
    async getMonitoringSiteList(params) {
        const { data } = await this.get(apiWrapper.stateApi('/devices/telemetries/multi-inverter/sites'), params);
        return data;
    }
    // AC System - 모니터링 EMS 목록 조회
    async getMonitoringDeviceList(params) {
        const { data } = await this.get(apiWrapper.stateApi('/devices/telemetries/multi-inverter/devices'), params);
        return data;
    }
    // 모니터링 상세 - Dashboard - 프로파일, Inventory
    async getDeviceProfile(siteId) {
        const { data } = await this.get(apiWrapper.managerApi(`/devices/telemetries/${siteId}`));
        return data;
    }
    // 모니터링 상세 - Setting - Inatallation
    async getInstallationInfo(siteId) {
        const { data } = await this.get(apiWrapper.managerApi(`/devices/profiles/${siteId}/installations`));
        return data;
    }
    // Array&Mapping - Basic Device Info
    getBasicDeviceInfo = async (siteId) => {
        const { data } = await this.get(apiWrapper.managerApi(`/resources/sites/${siteId}/devices`));
        return data;
    };
    // 에러 모듈 목록
    async getErrorDeviceList(siteId) {
        const { data } = await this.get(apiWrapper.managerApi(`/devices/alarms/${siteId}/modules`));
        return data;
    }
    // 장비 목록
    async getDeviceList() {
        const { data } = await this.get(apiWrapper.managerApi('/devices/profiles/excel-files'));
        return data;
    }
    async getGridCodeInfo(params) {
        const { data } = await this.get(apiWrapper.managerApi('/devices/profiles/grid-codes'), params);
        return data;
    }
    saveLocationInfo = async (siteId, params) => {
        const { data } = await this.put(apiWrapper.managerApi(`/devices/profiles/${siteId}/locations`), params);
        return data;
    };
    async controlACSystemSetting(deviceId, param) {
        const saveParam = {
            msgType: param.msg_type,
        };
        if (param.msg_type.indexOf('BS') !== -1)
            Object.assign(saveParam, Helper.convertSettingParam(pvmoduleSettingsHelper.getFilterValues('AC_SYS', 'BS', param)));
        if (param.msg_type.indexOf('AS') !== -1) {
            const tempParam = Helper.convertSettingParam(pvmoduleSettingsHelper.getFilterValues('AC_SYS', 'AS', param));
            const installSettingParam = Helper.convertSettingParam(SettingHelper.getFilterInstallSettingValues(param));
            const gridCode = `${tempParam.gridCode}`;
            delete tempParam.gridCode;
            Object.assign(saveParam, { gridCode }, tempParam, installSettingParam);
        }
        if (param.msg_type.indexOf('TOU') !== -1)
            Object.assign(saveParam, Helper.convertSettingParam(pvmoduleSettingsHelper.getFilterValues('AC_SYS', 'TOU', param)));
        if (param.msg_type.indexOf('ES') !== -1) {
            Object.assign(saveParam, Helper.convertSettingParam(pvmoduleSettingsHelper.getFilterValues('AC_SYS', 'ES', param)));
        }
        const { data } = await this.post(apiWrapper.controlApi(`/devices/${deviceId}/configurations/sync`), saveParam);
        return data;
    }
    /** AC System Configuration with SiteId */
    async controlACSystemSettingWithSiteId(siteId, param, target = SETTING_TARGET.SITE) {
        const saveParam = getSettingSaveParams(param, target);
        const { data } = await this.post(apiWrapper.controlApi(`/devices/site/${siteId}/configurations/sync`), saveParam);
        return data;
    }
    saveEnabledNotiAlarms = async (siteId, params) => {
        const { data } = await this.put(apiWrapper.managerApi(`/devices/profiles/${siteId}/alarm-flags`), params);
        return data;
    };
    saveHomeownerInfo = async (siteId, params) => {
        const { data } = await this.put(apiWrapper.managerApi(`/devices/profiles/${siteId}/homeowners`), params);
        return data;
    };
    updateOffGrid = async (siteId, params) => {
        const { data } = await this.put(apiWrapper.managerApi(`/devices/profiles/${siteId}/off-grids`), params);
        return data;
    };
    updateTestSite = async (siteId, params) => {
        const { data } = await this.put(apiWrapper.managerApi(`/devices/profiles/${siteId}/test-sites`), params);
        return data;
    };
    async checkDuplicatedLocation(params) {
        const { data } = await this.get(apiWrapper.managerApi('/devices/profiles/locations/validations'), params);
        return data;
    }
    async updateComments(siteId, params) {
        const { data } = await this.put(apiWrapper.managerApi(`/devices/profiles/${siteId}/comments`), params);
        return data;
    }
    async getInstallationSiteList(params) {
        const { data } = await this.get(apiWrapper.managerApi('/managements/mobiles/pro-apps/sites'), params);
        return data;
    }
    async getInstallationSite(siteId) {
        const { data } = await this.get(apiWrapper.managerApi(`/managements/mobiles/pro-apps/${siteId}/sites`));
        return data;
    }
    async getInstallationSteps(siteId) {
        return await this.get(apiWrapper.managerApi(`/managements/mobiles/pro-apps/${siteId}/steps`)).then((res) => {
            const data = res.data;
            return {
                ...data,
                stepData: data?.stepData ? (Helper.isJSON(data.stepData) ? JSON.parse(data.stepData) : null) : null,
            };
        });
    }
    getSiteNetworkType = async (siteId) => {
        const { data } = await this.get(apiWrapper.stateApi(`/devices/telemetries/network-type/${siteId}`));
        return data;
    };
    /** Basic, Engineering Setting 정보 조회 */
    getSettingInfoByDeviceId = async (deviceId) => {
        const { data } = await this.get(apiWrapper.managerApi(`/devices/profiles/id/${deviceId}/configurations`), undefined, {
            headers: getApiVersionHeader(),
        });
        return data;
    };
    /** Advanced Setting 정보 조회 */
    async getSettingInfo(siteId) {
        const { data } = await this.get(apiWrapper.managerApi(`/devices/profiles/${siteId}/configurations`), undefined, {
            headers: getApiVersionHeader(),
        });
        return data;
    }
}
export { DeviceService };
