import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-70983f8a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "error-msg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.state.errorMessage !== '')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.state.errorMessage), 1))
    : _createCommentVNode("", true)
}