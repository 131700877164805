import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListItem = _resolveComponent("ListItem")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`list-container ${_ctx.isBorder ? 'list-container-border' : ''}`)
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.itemList, (item) => {
      return (_openBlock(), _createBlock(_component_ListItem, {
        key: item.id,
        "list-item-info": item,
        onUpdateCheckedStatus: _ctx.updateCheckedStatus,
        onOnDetail: _ctx.onDetail,
        onOnEdit: _ctx.onEdit
      }, null, 8, ["list-item-info", "onUpdateCheckedStatus", "onOnDetail", "onOnEdit"]))
    }), 128))
  ], 2))
}