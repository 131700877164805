
import type { PropType } from 'vue';
import { defineComponent, computed, onMounted, watch, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import isEmpty from 'lodash-es/isEmpty';

import { EnergyValueBox } from '@hems/component';
import { useLocale, useSiteConfiguration, useSiteMasterDeviceInfo } from '@hems/util';
import type { EnergyProductValueType } from '@hems/util/src/constant';
import { ENERGY_PRODUCT_TYPE, ENERGY_VALUE_IMAGE, UNIT } from '@hems/util/src/constant';
import {
  convertWattToKilowatt,
  getDetail,
  getHomeEnergyDetail,
  getHomeEnergyStatus,
  getStatus,
} from '@hems/util/src/helper/siteEnergyHelper';

import type { EnergyProductCardValue, HomeEnergyData, SiteEnergyData } from 'hems/energyFlow';

export default defineComponent({
  name: 'EnergyValueACContainer',
  components: {
    EnergyValueBox,
  },
  props: {
    realtimeData: {
      type: Object as PropType<SiteEnergyData>,
      required: true,
    },
    disconnect: {
      type: Boolean,
      default: false,
    },
    homeEnergyData: {
      type: Object as PropType<HomeEnergyData>,
      default: () => ({ isHomeAppliance: false, value: 0.0 }),
    },
  },
  emits: ['reloadHomeEnergyData'],
  setup(props) {
    const { t } = useI18n();

    const { intlFormatLocale } = useLocale();

    const { hasPV, hasESS, hasThirdParty } = useSiteConfiguration();

    const { isConsumptionCT } = useSiteMasterDeviceInfo();

    const homeEnergyData = computed(() => props.homeEnergyData);

    const getRealtimeFormattedData = (
      data: SiteEnergyData,
      valueType: EnergyProductValueType | null = null
    ): EnergyProductCardValue | null => {
      const detail = getDetail(valueType, data, props.disconnect, t);
      const status = getStatus(valueType, data, props.disconnect, t);
      if (isEmpty(data)) {
        return { ...convertWattToKilowatt(0.0), detail, status };
      }
      if (valueType === ENERGY_PRODUCT_TYPE.BATTERY) {
        return { ...convertWattToKilowatt(data.batteryPower), detail, status };
      }
      if (valueType === ENERGY_PRODUCT_TYPE.PV) {
        return { ...convertWattToKilowatt(data.pvPower), detail, status };
      }
      if (valueType === ENERGY_PRODUCT_TYPE.LOAD) {
        return {
          ...convertWattToKilowatt(data.consumptionPower),
          detail,
          status,
        };
      }

      return null;
    };

    const getHomeEnergyFormattedData = (data: HomeEnergyData): EnergyProductCardValue => {
      const detail = getHomeEnergyDetail();
      const status = getHomeEnergyStatus(t, data);
      if (data.isHomeAppliance) {
        return { ...convertWattToKilowatt(data.value), detail, status };
      }

      return {
        ...{ origin: 0.0, formattedNumber: '0.0', unit: UNIT.WATT },
        detail: getHomeEnergyDetail(),
        status: getHomeEnergyStatus(t),
      };
    };

    const getAllEnergyFormattedData = () => {
      return {
        [ENERGY_PRODUCT_TYPE.BATTERY]: getRealtimeFormattedData(props.realtimeData, ENERGY_PRODUCT_TYPE.BATTERY),
        [ENERGY_PRODUCT_TYPE.PV]: getRealtimeFormattedData(props.realtimeData, ENERGY_PRODUCT_TYPE.PV),
        [ENERGY_PRODUCT_TYPE.LOAD]: getRealtimeFormattedData(props.realtimeData, ENERGY_PRODUCT_TYPE.LOAD),
        [ENERGY_PRODUCT_TYPE.HOME_ENERGY]: getHomeEnergyFormattedData(homeEnergyData.value),
      };
    };

    const formattedEnergyData = ref(getAllEnergyFormattedData());

    watch([() => intlFormatLocale.value, () => props.realtimeData, () => homeEnergyData.value], () => {
      formattedEnergyData.value = getAllEnergyFormattedData();
    });

    watch(
      () => props.disconnect,
      (disconnect) => {
        if (disconnect) {
          formattedEnergyData.value = getAllEnergyFormattedData();
        }
      }
    );

    onMounted(() => {
      formattedEnergyData.value = getAllEnergyFormattedData();
    });

    return {
      formattedEnergyData,
      ENERGY_VALUE_IMAGE,
      hasPV,
      hasESS,
      hasThirdParty,
      isConsumptionCT,
    };
  },
});
