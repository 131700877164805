/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import { computed } from 'vue';
import { useStore } from 'vuex';
import { AC_DEVICE_TYPE, GEN_TYPE } from '@hems/util/src/constant';
import { getBrowserTimezone } from '@hems/util/src/helper/dateHelper';
import { isEnergyPolicyType } from '@hems/util/src/helper/tsguardHelper';
// eslint-disable-next-line import/prefer-default-export
export const useUserSiteInfo = () => {
    const store = useStore();
    const deviceList = computed(() => store.state.site.deviceList);
    const masterDeviceInfo = computed(() => store.state.site.masterDeviceInfo);
    const siteId = computed(() => masterDeviceInfo.value?.deviceInfo.site_id ?? null);
    const energyControlValue = computed(() => masterDeviceInfo.value?.genType === GEN_TYPE.AC_SYS ? masterDeviceInfo.value?.deviceInfo.energy_control : '');
    const currentOperationMode = computed(() => isEnergyPolicyType(energyControlValue.value) ? energyControlValue.value : null);
    const ACESSDeviceList = computed(() => deviceList.value
        .map((device) => device.deviceInfo)
        .filter((deviceInfo) => deviceInfo.device_type_cd === AC_DEVICE_TYPE.AC_ESS));
    const ESSCount = computed(() => ACESSDeviceList.value.length);
    const timezoneId = computed(() => masterDeviceInfo.value?.deviceInfo.timezone_id ?? getBrowserTimezone());
    return {
        siteId,
        deviceList,
        masterDeviceInfo,
        currentOperationMode,
        ESSCount,
        timezoneId,
    };
};
