import { useAppMode } from '@hems/util/src/composable/appMode';
import { useLocale } from '@hems/util/src/composable/locale';
// eslint-disable-next-line import/prefer-default-export
export const useRegionCondition = () => {
    const { isAU, isUS } = useLocale();
    const { isLocal, isDevelopment, isStaging, isProduction } = useAppMode();
    const isProvisionState = ['local', 'development', 'staging'].includes(process.env.VUE_APP_ENV ?? '') ||
        (process.env.VUE_APP_ENV === 'production' && isAU);
    const showWeatherInfo = (isUS && (isDevelopment || isLocal)) || isStaging || isProduction;
    return {
        isProvisionState,
        showWeatherInfo,
    };
};
