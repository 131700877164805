import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cont1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SiteEnergyValueContainer = _resolveComponent("SiteEnergyValueContainer")!
  const _component_SiteEnergyFlowContainer = _resolveComponent("SiteEnergyFlowContainer")!
  const _component_EnergyFlowTimeoutPopup = _resolveComponent("EnergyFlowTimeoutPopup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_SiteEnergyValueContainer, {
        "realtime-data": _ctx.realtimeData,
        disconnect: _ctx.disconnect
      }, null, 8, ["realtime-data", "disconnect"]),
      _createVNode(_component_SiteEnergyFlowContainer, {
        "realtime-data": _ctx.realtimeData,
        "is-realtime-connected": _ctx.isRealtimeConnected,
        disconnect: _ctx.disconnect,
        onRetryConnection: _ctx.checkConnection
      }, null, 8, ["realtime-data", "is-realtime-connected", "disconnect", "onRetryConnection"])
    ]),
    (_ctx.isEnergyFlowTimeoutPopup)
      ? (_openBlock(), _createBlock(_component_EnergyFlowTimeoutPopup, {
          key: 0,
          onResume: _ctx.resumeEnergyFlow
        }, null, 8, ["onResume"]))
      : _createCommentVNode("", true)
  ], 64))
}