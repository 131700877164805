/** Copyright © 2024 Qcells. All rights reserved.
 *
 * This software is proprietary and confidential. Unauthorized use,
 * duplication, or distribution of software is strictly prohibited.
 */
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import isNil from 'lodash-es/isNil';
import type { ColumnOptions } from 'tui-grid';

import { withLoading, useMessageBox } from '@hems/component';
import { DeviceServiceAdmin } from '@hems/service';
import { Helper, DateHelper } from '@hems/util';
import { UTC_TIME_ZONE } from '@hems/util/src/constant';
// eslint-disable-next-line import/prefer-default-export
export const useDownloadExcel = () => {
  const { t } = useI18n();
  const messageBox = useMessageBox();

  const deviceService = new DeviceServiceAdmin(window.axiosInstance.axios);

  const localDateFormatConfig = { isTime: true, needUtcConvert: true };
  const utcDateFormatConfig = { isTime: true, needUtcConvert: true, timezone: UTC_TIME_ZONE };

  const excelColumns = computed<ColumnOptions[]>(() => {
    return [
      {
        header: t('device.connect'),
        name: 'is_connect',
      },
      {
        header: t('common.status'),
        name: 'oper_stus_str',
      },
      {
        header: t('account.user_name'),
        name: 'user_nm',
      },
      {
        header: t('common.site_id'),
        name: 'site_id',
      },
      {
        header: t('device.serial_no'),
        name: 'device_id',
      },
      {
        header: t('device.model_name'),
        name: 'product_model_nm',
      },
      {
        header: t('common.country'),
        name: 'cntry_nm_str',
      },
      {
        header: t('common.installed_dt'),
        name: 'instl_dt_str',
      },
      {
        header: `${t('common.installed_dt')} (utc+0)`,
        name: 'instl_dt_utc_str',
      },
      {
        header: t('device.ems_updated_date'),
        name: 'ems_update_dt_str',
      },
      {
        header: `${t('device.ems_updated_date')} (utc+0)`,
        name: 'ems_update_dt_utc_str',
      },
      {
        header: t('device.last_conn_dt'),
        name: 'colec_dt_str',
      },
      {
        header: `${t('device.last_conn_dt')} (utc+0)`,
        name: 'colec_dt_utc_str',
      },
      {
        header: t('device.first_conn_dt'),
        name: 'first_conn_dt_str',
      },
      {
        header: `${t('device.first_conn_dt')} (utc+0)`,
        name: 'first_conn_dt_utc_str',
      },
      {
        header: t('device.pv_capacity'),
        name: 'pv_capacity',
      },
      {
        header: t('device.batt_serial1'),
        name: 'battery1',
      },
      {
        header: t('device.batt_serial2'),
        name: 'battery2',
      },
      {
        header: t('device.batt_serial3'),
        name: 'battery3',
      },
      {
        header: t('device.pcs_ver'),
        name: 'pcs_ver',
      },
      {
        header: t('device.ems_ver'),
        name: 'ems_ver',
      },
      {
        header: t('device.bms_ver'),
        name: 'bms_ver',
      },
      {
        header: t('account.address'),
        name: 'instl_addr',
      },
      {
        header: t('control.auto_firm_update'),
        name: 'auto_fw_update_flag_str',
      },
      {
        header: t('common.latitude'),
        name: 'latitude',
      },
      {
        header: t('common.longitude'),
        name: 'longitude',
      },
    ];
  });

  const downloadExcel = withLoading(async () => {
    try {
      const response = await deviceService.getDeviceList();

      // FIXME: complexity 줄이기
      // eslint-disable-next-line complexity
      const data = response.map((item) => {
        item.cntry_nm_str = t(item.cntry_nm);
        item.oper_stus_str = t(Helper.getOperStatusName(item.oper_stus_str));
        item.auto_fw_update_flag_str = isNil(item.auto_fw_update_flag)
          ? ''
          : item.auto_fw_update_flag === 0
          ? t('common.disable')
          : t('common.enable');
        item.instl_dt_str = item.instl_dt ? DateHelper.getLocalDate(item.instl_dt, localDateFormatConfig) : '';
        item.ems_update_dt_str = item.ems_update_dt
          ? DateHelper.getLocalDate(item.ems_update_dt, localDateFormatConfig)
          : '';
        item.colec_dt_str = item.colec_dt ? DateHelper.getLocalDate(item.colec_dt, localDateFormatConfig) : '';
        item.first_conn_dt_str = item.first_conn_dt
          ? DateHelper.getLocalDate(item.first_conn_dt, localDateFormatConfig)
          : '';
        item.instl_dt_utc_str = item.instl_dt_utc
          ? DateHelper.getTimezoneDate(item.instl_dt_utc, utcDateFormatConfig)
          : '';
        item.ems_update_dt_utc_str = item.ems_update_dt_utc
          ? DateHelper.getTimezoneDate(item.ems_update_dt_utc, utcDateFormatConfig)
          : '';
        item.colec_dt_utc_str = item.colec_dt_utc
          ? DateHelper.getTimezoneDate(item.colec_dt_utc, utcDateFormatConfig)
          : '';
        item.first_conn_dt_utc_str = item.first_conn_dt_utc
          ? DateHelper.getTimezoneDate(item.first_conn_dt_utc, utcDateFormatConfig)
          : '';

        return item;
      });

      Helper.downloadCSV(data, excelColumns.value, 'DeviceList');
    } catch (e) {
      console.error(e);
      messageBox.alert(t('message.error_download')).open();
    }
  });

  return { excelColumns, downloadExcel };
};
