
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'Image2',
  props: {
    src: {
      type: String,
      required: true,
    },
    extension: {
      type: String,
      default: 'png',
      validator: (value: string) => ['png', 'gif', 'svg'].indexOf(value) !== -1,
    },
  },
});
