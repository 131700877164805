
import type { PropType } from 'vue';
import { defineComponent, onBeforeUnmount, onMounted, ref, watch, computed } from 'vue';
import { useI18n } from 'vue-i18n';

import type { Options, Chart, SeriesSplineOptions, SeriesLegendItemClickEventObject } from 'highcharts';
import { chart as highchartsChart, Series } from 'highcharts';
import every from 'lodash-es/every';
import filter from 'lodash-es/filter';
import forEach from 'lodash-es/forEach';
import isNil from 'lodash-es/isNil';

import { Panel, BasicCheckbox } from '@hems/component';
import { GRAPH_SERIES_TYPE, GRAPH_TERM_UNIT, UNIT, WATT_HOUR_UNIT } from '@hems/util/src/constant';
import { graphTooltipFormatter } from '@hems/util/src/helper/graphHelper';
import { capitalize } from '@hems/util/src/helper/helper';
import { getStringValue } from '@hems/util/src/helper/tsguardHelper';

import type { TermUnit } from 'hems';

import type { SuperFilterItem, SimpleSeries } from 'hems/device/statistics/common';
import type { GraphCheckboxFilter } from 'hems/statistics';

export default defineComponent({
  name: 'DeviceLineGraph',
  components: {
    Panel,
    BasicCheckbox,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    checkAll: {
      type: Boolean,
      default: () => false,
    },
    filterList: {
      type: Array as PropType<SuperFilterItem[]>,
      default: () => [],
    },
    xAxisCategories: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    termUnit: {
      type: String as PropType<TermUnit>,
      default: GRAPH_TERM_UNIT.MINUTE,
    },
    data: {
      type: Array as PropType<SimpleSeries[]>,
      default: () => [],
    },
  },
  setup(props) {
    const { t } = useI18n();
    const chart = ref<HTMLDivElement | null>(null);

    const chartInstance = ref<Chart | null>(null);

    const isAllChecked = ref<boolean>(props.checkAll);

    const checkboxFilterMap = ref<{ [key: string]: GraphCheckboxFilter }>(
      filter(props.filterList, (filter) => !filter.hidden).reduce<{ [key: string]: GraphCheckboxFilter }>(
        (filterMap, filter) => {
          filterMap[filter.code] = {
            text: `${t(filter.name)}${filter.postfix ? ` (${filter.postfix})` : ''}
            ${filter.unit ? ` [${filter.unit}]` : ''}`,
            value: filter.code,
            isChecked: props.checkAll ? true : filter.defaultChecked ?? false,
          };

          return filterMap;
        },
        {}
      )
    );

    const checkboxFilterList = computed(() => Object.values(checkboxFilterMap.value));

    const updateSeriesVisibleStatus = (seriesId: string, isVisible: boolean) => {
      const series = chartInstance.value?.get(seriesId);
      if (!isNil(series) && series instanceof Series) {
        series.setVisible(isVisible, false);
      }
    };

    const onCheckAll = () => {
      isAllChecked.value = !isAllChecked.value;

      forEach(checkboxFilterMap.value, (filter) => {
        filter.isChecked = isAllChecked.value;
        updateSeriesVisibleStatus(filter.value ?? '', isAllChecked.value);
      });

      chartInstance.value?.redraw();
    };

    const onClickCheckbox = (checkedId: string) => {
      if (!chartInstance.value) return;

      checkboxFilterMap.value[checkedId].isChecked = !checkboxFilterMap.value[checkedId].isChecked;

      isAllChecked.value = every(checkboxFilterMap.value, (filter) => filter.isChecked);

      updateSeriesVisibleStatus(checkedId, checkboxFilterMap.value[checkedId].isChecked);

      chartInstance.value.redraw();
    };

    const getOptions = (renderTo: HTMLDivElement): Options => {
      return {
        credits: { enabled: false },
        title: { text: '' },
        chart: {
          type: GRAPH_SERIES_TYPE.SPLINE,
          zoomType: 'x',
          renderTo,
        },
        boost: {
          useGPUTranslations: true,
        },
        yAxis: [
          {
            id: UNIT.WATT,
            title: {
              text: `${t('device.power')} (${UNIT.WATT})`,
            },
            showEmpty: false,
          },
          {
            id: UNIT.WATT_HOUR,
            title: {
              text: `${t('common.energy')} (${UNIT.WATT_HOUR})`,
            },
            min: 0,
            showEmpty: false,
          },
          {
            id: WATT_HOUR_UNIT.KILO_WATT_HOUR,
            title: {
              text: `${t('common.energy')} (${WATT_HOUR_UNIT.KILO_WATT_HOUR})`,
            },
            min: 0,
            showEmpty: false,
          },
          {
            id: UNIT.VOLT,
            title: {
              text: `${t('device.voltage')} (${UNIT.VOLT})`,
            },
            showEmpty: false,
          },
          {
            id: UNIT.VOLT_AMPERE,
            title: {
              text: `Apparent Power (${UNIT.VOLT_AMPERE})`,
            },
            showEmpty: false,
          },
          {
            id: UNIT.VOLT_AMPERE_REACTIVE,
            title: {
              text: `${t('device.voltage')} (${UNIT.VOLT_AMPERE_REACTIVE})`,
            },
            showEmpty: false,
          },
          {
            id: UNIT.AMPERE,
            title: {
              text: `${t('device.current')} (${UNIT.AMPERE})`,
            },
            showEmpty: false,
          },
          {
            id: UNIT.HERTZ,
            title: {
              text: `${t('device.frequency')} (${UNIT.HERTZ})`,
            },
            opposite: true,
            showEmpty: false,
          },
          {
            id: UNIT.FAHRENHEIT,
            title: {
              text: `${t('device.temp')} (${UNIT.FAHRENHEIT})`,
            },
            opposite: true,
            showEmpty: false,
          },
          {
            id: UNIT.PERCENT,
            title: {
              text: `${t('common.percentage')} (${UNIT.PERCENT})`,
            },
            opposite: true,
            showEmpty: false,
          },
          {
            id: UNIT.FACTOR,
            title: {
              text: capitalize(UNIT.FACTOR),
            },
            showEmpty: false,
          },
        ],
        legend: {
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'bottom',
        },
        plotOptions: {
          series: {
            lineWidth: 1,
            events: {
              legendItemClick: (evt: SeriesLegendItemClickEventObject) => {
                const id: string | null = getStringValue(evt.target.userOptions.id);
                if (isNil(id)) return;
                const visible = evt.target.visible;
                checkboxFilterMap.value[id].isChecked = !visible;
                isAllChecked.value = every(checkboxFilterMap.value, (filter) => filter.isChecked);

                return true;
              },
            },
          },
        },
        series: [],
        tooltip: {
          shared: true,
          outside: true,
          formatter: graphTooltipFormatter,
        },
      };
    };

    const getOptionForUpdate = (xAxisCategories: string[], data: SimpleSeries[]): Options => {
      const series: SeriesSplineOptions[] = data.map((item) => {
        return {
          ...item,
          boostThreshold: 1,
          turboThreshold: 1,
          visible: !item.hidden && checkboxFilterMap.value[item.id].isChecked,
          showInLegend: !item.hidden,
          type: GRAPH_SERIES_TYPE.SPLINE,
        };
      });

      return {
        xAxis: {
          categories: xAxisCategories,
          tickPositioner: () => {
            const tickX = new Set([0]);
            const regexp2hours = /(00|02|04|06|08|10|12|14|16|18|20|22|24):00$/g;
            if (props.termUnit === GRAPH_TERM_UNIT.MINUTE) {
              xAxisCategories.forEach((item, index) => {
                if (regexp2hours.test(item)) tickX.add(index);
              });
            } else if (props.termUnit === GRAPH_TERM_UNIT.HOUR) {
              const regexp4hours = /(00|04|08|12|16|20|24):00$/g;
              const temp: { index: number; item: string }[] = [];
              const maxCount = 14;
              // 일단 2시간 단위로 필터링
              xAxisCategories.forEach((item, index) => {
                if (regexp2hours.test(item)) temp.push({ index, item });
              });
              // 2시간 단위로 필터링 했을 때 maxCount보다 크면 4시간 단위로 다시 필터링
              const temp2 = temp.length > maxCount ? temp.filter((item) => regexp4hours.test(item.item)) : temp;
              // 4시간 단위로 필터링 했을 때에도 maxCount보다 크면 일단위로.,..
              const temp3 = temp2.length > maxCount ? temp.filter((item) => item.item.endsWith('00:00')) : temp2;
              temp3.map((item) => item.index).forEach(tickX.add, tickX);
            }
            tickX.add(xAxisCategories.length - 1);

            return Array.from(tickX);
          },
        },
        series,
      };
    };

    const createChart = () => {
      if (!chart.value) return;
      const options = getOptions(chart.value);
      chartInstance.value = highchartsChart(options);
    };

    const destroyChart = () => {
      if (chartInstance.value) {
        chartInstance.value.destroy();
        chartInstance.value = null;
      }
    };

    watch([() => props.xAxisCategories, () => props.data], () => {
      if (!chartInstance.value) return;
      const options = getOptionForUpdate(props.xAxisCategories, props.data);
      chartInstance.value?.update(options, true, true, false);
    });

    onMounted(() => {
      createChart();
    });

    onBeforeUnmount(() => {
      destroyChart();
    });

    return {
      isAllChecked,
      chart,
      checkboxFilterList,
      onClickCheckbox,
      onCheckAll,
    };
  },
});
