import type { AxiosInstance } from 'axios';

import { DeviceService } from '@hems/service/src/api/device/DeviceService';
import { apiWrapper } from '@hems/service/src/util/helper';

import type { CommonResponseWrapper } from 'hems';

import type { ChangeInstallerRequestBody, ChangeInstallerResponse } from 'hems/installerAccount';

export default class DeviceServiceAdmin extends DeviceService {
  constructor(axios: AxiosInstance) {
    super(axios);
  }

  changeInstaller = async (
    siteId: number,
    changeInstallerRequestBody: ChangeInstallerRequestBody
  ): Promise<CommonResponseWrapper<ChangeInstallerResponse>> => {
    return await this.put<CommonResponseWrapper<ChangeInstallerResponse>>(
      apiWrapper.managerApi(`/devices/profiles/site/${siteId}/installers`),
      changeInstallerRequestBody
    );
  };

  deleteDeviceForACSystem = async (siteId: number): Promise<CommonResponseWrapper<{ siteId: number }>> => {
    return this.delete<CommonResponseWrapper<{ siteId: number }>>(
      apiWrapper.managerApi(`/devices/installations/sqa/delete/${siteId}`)
    );
  };
}
