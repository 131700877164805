/** Copyright © 2024 Qcells. All rights reserved.
 *
 * This software is proprietary and confidential. Unauthorized use,
 * duplication, or distribution of software is strictly prohibited.
 */
import type { Module } from 'vuex';

import { Helper } from '@hems/util';
import { LANGUAGE, localStorageKey } from '@hems/util/src/constant';
import { getLanguageValue } from '@hems/util/src/helper/tsguardHelper';

import type { EnvLocale, LangCd } from 'hems';

import type { RootState } from '../index';

const localStorageLanguage = localStorage.getItem(localStorageKey.language) ?? LANGUAGE.EN;
const mobileFlatform = Helper.getMobileOperatingSystem();

export interface SimpleNavItem {
  index: number;
  code: string;
  name: string;
}

export type BgImageNumber = 1 | 2 | 3;
export interface AppCtxState {
  langCd: LangCd;
  envLocale: EnvLocale;
  termsVer: string;
  curNav: SimpleNavItem[];
  scroll: any | null;
  bgImageNum: BgImageNumber;
  mode: 'pc' | 'mobile';
  theme: string;
}

// cookie로 부터 선택된 locale 정보를 가져오고 없으면
// DB에 저장된 Locale 정보 가져오고
// 그것도 없으면 영어로 default 선택
export const initState: AppCtxState = {
  langCd: getLanguageValue(localStorageLanguage),
  envLocale: Helper.getEnvLocale(),
  termsVer: process.env[`VUE_APP_TERMS_VER_${Helper.getEnvLocale()}`] ?? '',
  curNav: [],
  scroll: null,
  bgImageNum: 1,
  mode: mobileFlatform !== 'unknown' ? 'mobile' : 'pc',
  theme: 'light',
};

export default {
  namespaced: true,
  state: initState,
  mutations: {
    setLangCd(state, payload: LangCd) {
      localStorage.setItem(localStorageKey.language, payload);
      state.langCd = payload;
    },
    setScroll(state, payload: any) {
      state.scroll = payload;
    },
    setBgImageNum(state, payload: BgImageNumber) {
      state.bgImageNum = payload;
    },
    setMode(state, payload: typeof initState.mode) {
      state.mode = payload;
    },
    setTheme(state, payload: string) {
      state.theme = payload;
    },
  },
  actions: {
    setLangCd({ commit }, payload: LangCd) {
      commit('setLangCd', payload);
    },
    setScroll({ commit }, payload: any) {
      commit('setScroll', payload);
    },
    setBgImageNum({ commit }, payload: BgImageNumber) {
      commit('setBgImageNum', payload);
    },
    setMode({ commit }, payload: typeof initState.mode) {
      commit('setMode', payload);
    },
    setTheme({ commit }, payload: string) {
      commit('setTheme', payload);
    },
  },
} as Module<AppCtxState, RootState>;
