import { defineComponent, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import isEmpty from 'lodash-es/isEmpty';
import { Image2 } from '@hems/component';
import { useLocale } from '@hems/util';
import { ENERGY_PRODUCT_TYPE, ENERGY_VALUE_IMAGE } from '@hems/util/src/constant';
import { convertWattToKilowatt, getStatus } from '@hems/util/src/helper/siteEnergyHelper';
export default defineComponent({
    name: 'EnergyValueACNoConsumptionCT',
    components: {
        Image2,
    },
    props: {
        realtimeData: {
            type: Object,
            required: true,
        },
        disconnect: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const { t } = useI18n();
        const { intlFormatLocale } = useLocale();
        const getFormattedData = (data) => {
            const status = getStatus(ENERGY_PRODUCT_TYPE.PV, data, props.disconnect, t);
            if (isEmpty(data)) {
                return { ...convertWattToKilowatt(0.0), status };
            }
            return { ...convertWattToKilowatt(data.pvPower), status };
        };
        const formattedPVData = ref(getFormattedData(props.realtimeData));
        watch(() => [intlFormatLocale.value, props.realtimeData, props.disconnect], () => {
            formattedPVData.value = getFormattedData(props.realtimeData);
        });
        return {
            formattedPVData,
            ENERGY_VALUE_IMAGE,
        };
    },
});
