import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-11b4fb78"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "value_container top" }
const _hoisted_2 = { class: "value_container bottom" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EnergyFlowACHomeLayer = _resolveComponent("EnergyFlowACHomeLayer")!
  const _component_EnergyFlowACPipeLayer = _resolveComponent("EnergyFlowACPipeLayer")!
  const _component_EnergyFlowAC = _resolveComponent("EnergyFlowAC")!
  const _component_EnergyFlowACProductLayer = _resolveComponent("EnergyFlowACProductLayer")!
  const _component_NetworkStatus = _resolveComponent("NetworkStatus")!
  const _component_ProductionInfo = _resolveComponent("ProductionInfo")!
  const _component_OperationModeInfo = _resolveComponent("OperationModeInfo")!
  const _component_GridInfo = _resolveComponent("GridInfo")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_EnergyFlowACHomeLayer),
    (_ctx.isConsumptionCT)
      ? (_openBlock(), _createBlock(_component_EnergyFlowACPipeLayer, {
          key: 0,
          "is-on-grid": _ctx.realtimeData.gridStatus ?? true,
          "show-generator": _ctx.showGenerator
        }, null, 8, ["is-on-grid", "show-generator"]))
      : _createCommentVNode("", true),
    (_ctx.isConsumptionCT)
      ? (_openBlock(), _createBlock(_component_EnergyFlowAC, {
          key: 1,
          "energy-flow": _ctx.energyFlow
        }, null, 8, ["energy-flow"]))
      : _createCommentVNode("", true),
    _createVNode(_component_EnergyFlowACProductLayer),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_NetworkStatus, {
        "network-status": _ctx.realtimeData.networkType,
        disconnect: _ctx.disconnect
      }, null, 8, ["network-status", "disconnect"]),
      _createVNode(_component_ProductionInfo, { "formatted-data": _ctx.formattedData }, null, 8, ["formatted-data"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_OperationModeInfo, {
        "ems-operation-mode": _ctx.realtimeData.energyControl,
        onOnClickOperationMode: _ctx.popup?.onHomeInfo
      }, null, 8, ["ems-operation-mode", "onOnClickOperationMode"]),
      _createVNode(_component_GridInfo, { "formatted-data": _ctx.formattedData }, null, 8, ["formatted-data"])
    ])
  ], 64))
}