
import type { PropType } from 'vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SearchOptionItem',
  props: {
    button: Object as PropType<{
      text: string;
      click: () => void;
    }>,
  },
});
