import type { ValueType } from 'hems/common/utils';

export const BUTTON_SIZE = {
  SMALL: 'sm',
  MEDIUM: 'md',
  LARGE: 'lg',
  FIT_TO_PARENT: 'fit-to-parent',
} as const;

export type ButtonSizeValue = ValueType<typeof BUTTON_SIZE>;

export const BUTTON_TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  WHITE: 'white',
} as const;

export type ButtonTypeValue = ValueType<typeof BUTTON_TYPE>;

export const BUTTON_CLASS = {
  BUTTON: 'btn_t',
  PRIMARY: 'btn_c_3',
  SECONDARY: 'btn_c_2',
  TERTIARY: 'btn_c',
  WHITE: 'btn_c_4',
} as const;

export type ButtonClassValue = ValueType<typeof BUTTON_CLASS>;

export const BUTTON_NEW_TYPE = {
  GRAY: 'gray',
  SOLID: 'solid',
  /** out line */
  OTL: 'otl',
  OTL_GRAY: 'otl-gray',
} as const;

export type ButtonNewTypeValue = ValueType<typeof BUTTON_NEW_TYPE>;

export const BUTTON_NEW_SIZE = {
  SMALL: 'small',
  LARGE: 'large',
} as const;

export type ButtonNewSizeValue = ValueType<typeof BUTTON_NEW_SIZE>;

export type ToggleButtonValue = string | number;

export type ToggleButtonItem = {
  text: string;
  value: ToggleButtonValue;
};

export type ToggleButtonItemIsDisabled = {
  key: ToggleButtonValue;
  isDisabled: boolean;
};
