
import type { PropType } from 'vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Tooltip',
  inheritAttrs: false,
  props: {
    tooltipText: {
      type: String,
    },
    tooltipPosition: {
      type: Array,
    },
    imageBaseUrl: {
      type: String,
    },
    imageName: {
      type: String,
    },
    imageExtension: {
      type: String,
    },
    width: String,
    textAlign: {
      type: String as PropType<'right' | 'left' | 'center'>,
    },
  },
});
