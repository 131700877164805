import type { AxiosInstance } from 'axios';

import { Helper } from '@hems/util';

import type { CommonResponseWrapper } from 'hems';

import type {
  GeneralAccountInfoResponse,
  GeneralAccountParams,
  InstallerAccountParams,
  InstallerAccountInfo,
  AdminAccountParams,
  AccountCountryInfo,
  AccountCountryParams,
} from 'hems/auth/account';
import type { UserId } from 'hems/user';

import { apiWrapper } from '../../util/helper';
import { Service } from '../Service';

export default class UserInfoService extends Service {
  constructor(axios: AxiosInstance) {
    super(axios);
  }

  public async getGeneralAccountInfo(): Promise<GeneralAccountInfoResponse> {
    const { data } = await this.get<CommonResponseWrapper<GeneralAccountInfoResponse>>(
      apiWrapper.managerApi('/managements/users/profiles')
    );

    return data;
  }

  public async updateGeneralAccountInfo(params: GeneralAccountParams): Promise<any> {
    const { data } = await this.put<CommonResponseWrapper<any>>(
      apiWrapper.managerApi('/managements/users/profiles'),
      Helper.convertSettingParam(params)
    );

    return data;
  }

  public async getInstallerAccountInfo(installerId: string): Promise<InstallerAccountInfo> {
    const { data } = await this.get<CommonResponseWrapper<InstallerAccountInfo>>(
      apiWrapper.managerApi('/managements/users/installer-profiles'),
      { userId: installerId }
    );

    return data;
  }

  public async updateInstallerAccountInfo(params: InstallerAccountParams): Promise<any> {
    const _params: any = {
      ...params,
      instl_user_mpn: params.mpn_no,
      instl_user_email: params.email,
      instl_user_nm: params.user_nm,
    };
    delete _params.mpn_no;
    delete _params.email;
    delete _params.user_nm;

    const { data } = await this.put<CommonResponseWrapper<any>>(
      apiWrapper.managerApi('/managements/users/installers/profiles'),
      Helper.keyToCamelCase(_params)
    );

    return data;
  }

  public async updateAdminAccountInfo(params: AdminAccountParams): Promise<any> {
    const _params: any = {
      ...params,
      admin_user_mpn: params.mpn_no,
      admin_user_email: params.email,
      admin_user_nm: params.user_nm,
    };
    delete _params.mpn_no;
    delete _params.email;
    delete _params.user_nm;

    const { data } = await this.put<CommonResponseWrapper<any>>(
      apiWrapper.managerApi('/managements/users/admins/profiles'),
      Helper.keyToCamelCase(_params)
    );

    return data;
  }

  public async updateGeneralAccountAlarmFlag(alarmFlag: 'Y' | 'N') {
    const { data } = await this.put<CommonResponseWrapper<any>>(
      apiWrapper.managerApi('/managements/users/alarm-flags'),
      { alarmMailRecvFlag: alarmFlag }
    );

    return data;
  }

  public async getAccountCountryInfo(userId: UserId, accessToken: string): Promise<AccountCountryInfo | null> {
    const { data } = await this.get<CommonResponseWrapper<AccountCountryInfo | null>>(
      apiWrapper.managerApi(`/managements/users/user-countries/${userId}/information`),
      undefined,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return data;
  }

  public async updateAccountCountryInfo(params: AccountCountryParams, accessToken: string): Promise<any> {
    const { data } = await this.put<CommonResponseWrapper<any>>(
      apiWrapper.managerApi('/managements/users/user-countries'),
      params,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return data;
  }
}
