
import type { PropType } from 'vue';
import { defineComponent } from 'vue';

import type { IndentDepthValue } from '@hems/util/src/constant';
export default defineComponent({
  name: 'SettingTableRow',
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    isIndent: {
      type: Boolean,
      default: false,
    },
    indentDepth: {
      type: Number as PropType<IndentDepthValue>,
      default: 1,
    },
    required: {
      type: Boolean,
      default: false,
    },
    isMultiValues: Boolean,
  },
});
