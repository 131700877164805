import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6ed14d8e"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "left" };
const _hoisted_2 = { class: "number" };
const _hoisted_3 = { class: "img-wrap" };
const _hoisted_4 = { class: "title" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Image2 = _resolveComponent("Image2");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                (_ctx.networkImagePath)
                    ? (_openBlock(), _createBlock(_component_Image2, {
                        key: 0,
                        src: _ctx.networkImagePath,
                        extension: "svg"
                    }, null, 8, ["src"]))
                    : _createCommentVNode("", true)
            ]),
            _createTextVNode(" " + _toDisplayString(_ctx.networkStatusText), 1)
        ]),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t(_ctx.networkConnectionStatusTextKey)), 1)
    ]));
}
