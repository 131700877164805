/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import { rest } from 'msw';
import { apiWrapper } from '@hems/service/src/util/helper';
import { errorHistoryData } from '@hems/util/src/mocks/data/alarmData';
import { getCommonResponseData, getPagingData } from '@hems/util/src/mocks/util';
const API_BASE_URL = process.env.VUE_APP_API_URL;
const ALARM_API_URL = `${API_BASE_URL}${apiWrapper.alarmApi('')}`;
const errorHistoryListHandler = rest.get(`${ALARM_API_URL}/error-history`, (req, res, ctx) => {
    const pageNum = req.url.searchParams.get('pageNumber') || 1;
    const listSize = req.url.searchParams.get('listSize') || 10;
    // TODO filtering, sorting, searching
    const pagingErrorHistoryData = getPagingData(errorHistoryData.data, Number(pageNum), Number(listSize));
    const response = getCommonResponseData({
        data: pagingErrorHistoryData,
        totalCount: errorHistoryData.totalCount,
    }, req.url.pathname);
    return res(ctx.status(200), ctx.json(response));
});
export const alarmHandlers = [errorHistoryListHandler];
