import { useModal } from 'vue-final-modal';
import { useI18n } from 'vue-i18n';
import { BasicModal } from '@hems/component';
import { BASIC_MODAL } from '@hems/util/src/constant';
export const useAlertModal = ({ content, customButtonText, callback, contentAlign }) => {
    const alertModal = useModal({
        component: BasicModal,
        attrs: {
            type: BASIC_MODAL.ALERT,
            content,
            customButtonText,
            contentAlign,
            onClose: callback,
        },
    });
    return alertModal;
};
export const useSuccessModal = ({ content, callback, contentAlign }) => {
    const { t } = useI18n();
    const successModal = useModal({
        component: BasicModal,
        attrs: {
            type: BASIC_MODAL.ALERT,
            content: content || t('message.save_success'),
            contentAlign,
            onClose: callback,
        },
    });
    return successModal;
};
export const useConfirmModal = ({ content, callback, contentAlign }) => {
    const { t } = useI18n();
    const confirmModal = useModal({
        component: BasicModal,
        attrs: {
            type: BASIC_MODAL.CONFIRM,
            content: content || t('message.apply_set_value'),
            contentAlign,
            onConfirm: callback,
        },
    });
    return confirmModal;
};
export const useFailModal = ({ content, callback, contentAlign }) => {
    const { t } = useI18n();
    const failModal = useModal({
        component: BasicModal,
        attrs: {
            type: BASIC_MODAL.ALERT,
            content: content || t('message.save_fail'),
            contentAlign,
            onClose: callback,
        },
    });
    return failModal;
};
