import { computed, defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useGenType } from '@hems/util';
export default defineComponent({
    name: 'SubTab',
    props: {
        multiTabName: {
            type: String,
            default: '',
        },
    },
    setup() {
        const store = useStore();
        const route = useRoute();
        const siteId = computed(() => route.query.siteId);
        const deviceId = computed(() => route.query.deviceId);
        const statusId = computed(() => route.query.statusId);
        const genType = computed(() => store.state.device.gen_type);
        const tabname = computed(() => route.path.replace('/remote/monitoring/details/setting/', '').split('/')[0]);
        const roleNm = computed(() => store.state.user.roleNm);
        const { isACSystem } = useGenType();
        const routerQuery = { siteId: siteId.value, deviceId: deviceId.value, statusId: statusId.value };
        return {
            siteId,
            deviceId,
            statusId,
            genType,
            tabname,
            roleNm,
            isACSystem,
            routerQuery,
        };
    },
});
