import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-bc7fc568"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "box-lft" };
const _hoisted_2 = {
    key: 3,
    class: "middle-line"
};
const _hoisted_3 = { class: "box-rt" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_TodayConsumption = _resolveComponent("TodayConsumption");
    const _component_TodayProduction = _resolveComponent("TodayProduction");
    const _component_TodayProductionACNoConsumptionCT = _resolveComponent("TodayProductionACNoConsumptionCT");
    const _component_EnergyIndependence = _resolveComponent("EnergyIndependence");
    const _component_EnergyValueAC = _resolveComponent("EnergyValueAC");
    const _component_EnergyValueACNoConsumptionCT = _resolveComponent("EnergyValueACNoConsumptionCT");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
            class: _normalizeClass(_ctx.hasPV ? 'box-lft' : 'box-ac-no-pv')
        }, [
            (!_ctx.hasPV)
                ? (_openBlock(), _createBlock(_component_TodayConsumption, {
                    key: 0,
                    "report-data": _ctx.reportData.today,
                    "show-chart": _ctx.showChart,
                    timezone: _ctx.siteTimezoneId
                }, null, 8, ["report-data", "show-chart", "timezone"]))
                : (_ctx.isConsumptionCT)
                    ? (_openBlock(), _createBlock(_component_TodayProduction, {
                        key: 1,
                        "report-data": _ctx.reportData.today,
                        "show-chart": _ctx.showChart,
                        timezone: _ctx.siteTimezoneId
                    }, null, 8, ["report-data", "show-chart", "timezone"]))
                    : (_openBlock(), _createBlock(_component_TodayProductionACNoConsumptionCT, {
                        key: 2,
                        "report-data": _ctx.reportData.today,
                        "show-chart": _ctx.showChart,
                        timezone: _ctx.siteTimezoneId
                    }, null, 8, ["report-data", "show-chart", "timezone"])),
            (_ctx.hasPV && _ctx.isConsumptionCT)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2))
                : _createCommentVNode("", true),
            (_ctx.hasPV && _ctx.isConsumptionCT)
                ? (_openBlock(), _createBlock(_component_EnergyIndependence, {
                    key: 4,
                    "energy-independence-data": _ctx.energyIndependenceData
                }, null, 8, ["energy-independence-data"]))
                : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", _hoisted_3, [
            (_ctx.isConsumptionCT)
                ? (_openBlock(), _createBlock(_component_EnergyValueAC, {
                    key: 0,
                    "realtime-data": _ctx.realtimeData,
                    disconnect: _ctx.disconnect,
                    "home-energy-data": _ctx.homeEnergyData,
                    onReloadHomeEnergyData: _ctx.loadHomeEnergyData
                }, null, 8, ["realtime-data", "disconnect", "home-energy-data", "onReloadHomeEnergyData"]))
                : (!_ctx.isConsumptionCT)
                    ? (_openBlock(), _createBlock(_component_EnergyValueACNoConsumptionCT, {
                        key: 1,
                        "realtime-data": _ctx.realtimeData
                    }, null, 8, ["realtime-data"]))
                    : _createCommentVNode("", true)
        ])
    ]));
}
