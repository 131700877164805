import type { AxiosInstance } from 'axios';

import { Service } from '../Service';

export default class InstallerAccountService extends Service {
  constructor(axios: AxiosInstance) {
    super(axios);
  }
}

export { InstallerAccountService };
