import { Service } from '@hems/service/src/api/Service';
import { apiWrapper } from '@hems/service/src/util/helper';
export default class StormCoverService extends Service {
    constructor(axios) {
        super(axios);
    }
    getStormInfo = async (siteId) => {
        const { data } = await this.get(apiWrapper.managerApi(`/managements/users/storm-cover/alerts/${siteId}`));
        return data;
    };
    sendSeenStormInfoFlag = async (siteId) => {
        const { data } = await this.post(apiWrapper.managerApi(`/managements/users/storm-cover/alerts/${siteId}`));
        return data;
    };
}
