import { computed, defineComponent } from 'vue';
import { useRoute, RouterLink } from 'vue-router';
import { useACDeviceType, useRole } from '@hems/util';
import { SITE_DETAIL_ROUTE } from '@hems/util/src/constant';
export default defineComponent({
    name: 'MajorTab',
    components: {
        RouterLink,
    },
    setup() {
        const route = useRoute();
        const siteId = computed(() => route.query.siteId);
        const deviceId = computed(() => route.query.deviceId);
        const statusId = computed(() => route.query.statusId);
        const tabname = computed(() => route.path.replace('/remote/monitoring/details/', '').split('/')[0]);
        const { hasACCombiner } = useACDeviceType();
        const { isDevOrAdminOrService } = useRole();
        const routerQuery = { siteId: siteId.value, deviceId: deviceId.value, statusId: statusId.value };
        return {
            siteId,
            deviceId,
            statusId,
            tabname,
            hasACCombiner,
            routerQuery,
            SITE_DETAIL_ROUTE,
            isDevOrAdminOrService,
        };
    },
});
