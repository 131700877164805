import { defineComponent, computed, ref } from 'vue';
import isNil from 'lodash-es/isNil';
import { Image2 } from '@hems/component';
import { getWeatherIcon } from '@hems/container/src/weather/weatherIconConfig';
import { WeatherService } from '@hems/service';
import { Helper, useLanguage, useSiteId } from '@hems/util';
import { ONE_MILE_TO_KILOMETER, INTL_NUMBER_FORMAT_UNIT, NUMBER_UNIT, UNIT } from '@hems/util/src/constant';
import { formatUnitNumber } from '@hems/util/src/helper/numberformatHelper';
export default defineComponent({
    name: 'WeatherContainer',
    components: {
        Image2,
    },
    setup() {
        const weatherService = new WeatherService(window.axiosInstance.axios);
        const iconList = getWeatherIcon();
        const weatherInfo = ref(null);
        const { languageCode } = useLanguage();
        const { siteId } = useSiteId();
        const weatherIcon = computed(() => weatherInfo.value && !isNil(iconList[weatherInfo.value.iconCode])
            ? `weather_new/${iconList[weatherInfo.value.iconCode]}`
            : '');
        const convertKilometerToMile = (value) => {
            return value / ONE_MILE_TO_KILOMETER;
        };
        const getNumberFormatterWithUnit = (options = {}) => {
            return Helper.getNumberFormatter(languageCode.value, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                style: 'unit',
                ...options,
            });
        };
        const getFormattedTemperature = () => {
            if (!weatherInfo.value || Number.isNaN(weatherInfo.value.apparentTemperature)) {
                return '';
            }
            const { formattedNumber, unit } = formatUnitNumber(weatherInfo.value.apparentTemperature, UNIT.FAHRENHEIT);
            return `${formattedNumber} ${unit}`;
        };
        const getFormattedWindSpeed = () => {
            if (!weatherInfo.value || Number.isNaN(weatherInfo.value.windspeed))
                return '';
            return getNumberFormatterWithUnit({ unit: INTL_NUMBER_FORMAT_UNIT.MILE_PER_HOUR }).format(convertKilometerToMile(weatherInfo.value.windspeed));
        };
        const temperature = computed(getFormattedTemperature);
        const humidity = computed(() => {
            if (!weatherInfo.value || Number.isNaN(weatherInfo.value.humidity))
                return '';
            return getNumberFormatterWithUnit({ unit: INTL_NUMBER_FORMAT_UNIT.PERCENT }).format(weatherInfo.value.humidity * NUMBER_UNIT.HUNDRED);
        });
        const windSpeed = computed(getFormattedWindSpeed);
        const getWeatherData = async () => {
            try {
                if (isNil(siteId.value))
                    return;
                const currentWeatherInfo = await weatherService.getCurrentWeather(String(siteId.value));
                weatherInfo.value = currentWeatherInfo;
            }
            catch (e) {
                console.error(e);
            }
        };
        getWeatherData();
        return {
            weatherInfo,
            temperature,
            humidity,
            windSpeed,
            weatherIcon,
        };
    },
});
