import { defineComponent, computed } from 'vue';
import Image2 from '@hems/component/src/image/Image2.vue';
import { BLACK, IMAGE_DESCRIPTION_COLOR, IMAGE_DESCRIPTION_ICON } from '@hems/util/src/constant';
export default defineComponent({
    name: 'ImageDescription',
    components: {
        Image2,
    },
    props: {
        messageType: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            default: '',
        },
        message: {
            type: String,
            default: '',
        },
        fontColor: {
            type: String,
            default: BLACK,
        },
        fontSize: {
            type: String,
            default: '14px',
        },
    },
    setup(props) {
        const description = computed(() => props.message);
        const background = computed(() => IMAGE_DESCRIPTION_COLOR[props.messageType]);
        const icon = computed(() => IMAGE_DESCRIPTION_ICON[props.messageType]);
        return {
            description,
            background,
            icon,
        };
    },
});
