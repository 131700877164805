import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-15fb5c82"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "flow-img"
}
const _hoisted_2 = {
  key: 1,
  class: "flow-img"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image2 = _resolveComponent("Image2")!

  return (_ctx.isConsumptionCT)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!_ctx.hasESS)
          ? (_openBlock(), _createBlock(_component_Image2, {
              key: 0,
              src: _ctx.ENERGY_FLOW_IMAGE.AC_PRODUCT_NO_BATTERY_IMAGE_PATH,
              extension: "svg"
            }, null, 8, ["src"]))
          : (!_ctx.hasPV)
            ? (_openBlock(), _createBlock(_component_Image2, {
                key: 1,
                src: _ctx.ENERGY_FLOW_IMAGE.AC_PRODUCT_NO_PV_IMAGE_PATH,
                extension: "svg"
              }, null, 8, ["src"]))
            : (_openBlock(), _createBlock(_component_Image2, {
                key: 2,
                src: _ctx.ENERGY_FLOW_IMAGE.AC_PRODUCT_IMAGE_PATH,
                extension: "svg"
              }, null, 8, ["src"]))
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_Image2, {
          src: _ctx.ENERGY_FLOW_IMAGE.AC_PRODUCT_NO_CONSUMPTION_CT_IMAGE_PATH,
          extension: "svg"
        }, null, 8, ["src"])
      ]))
}