import { defineComponent } from 'vue';
export default defineComponent({
    name: 'NavSubItem',
    props: {
        navItem: {
            type: Object,
            required: true,
        },
    },
});
