import pickBy from 'lodash-es/pickBy';
import moment from 'moment';
import { Helper } from '@hems/util';
import { UserService } from './UserService';
import { apiWrapper } from '../../util/helper';
export default class UserServiceAdmin extends UserService {
    constructor(axios) {
        super(axios);
    }
    async getRecentActivityList() {
        const { data } = await this.get(apiWrapper.managerApi('/managements/activities/dashboards'));
        return data;
    }
    async getCustomerList(params) {
        const { data } = await this.get(apiWrapper.managerApi('/managements/users'), params);
        return data;
    }
    async getCustomerDetails(params) {
        const { data } = await this.get(apiWrapper.managerApi('/managements/users/profiles'), params);
        return data;
    }
    async saveCustomerInfo(userId, params) {
        const _params = pickBy(params, (v) => v !== undefined);
        _params.userId = userId;
        const { data } = await this.put(apiWrapper.managerApi('/managements/users/profiles'), Helper.convertSettingParam(_params));
        return data;
    }
    async deleteCustomerInfo(userId) {
        const { data } = await this.delete(apiWrapper.managerApi('/managements/users/profiles'), undefined, { data: { userId } });
        return data;
    }
    async getActivityList(params) {
        let _params = { ...params };
        if (params.date) {
            const { start, end } = params.date;
            if (start && end) {
                _params = {
                    ..._params,
                    fromDate: moment(start).format('YYYYMMDD'),
                    toDate: moment(end).format('YYYYMMDD'),
                };
                delete _params.date;
            }
        }
        const { data } = await this.get(apiWrapper.managerApi('/managements/activities'), _params);
        return data;
    }
}
