import { computed } from 'vue';
import { useStore } from 'vuex';

import { LOADING_ACTION } from '@hems/util/src/stores/modules/loading';

import type { CommonRootState } from 'hems/common/store';
import type { AnyFunction } from 'hems/common/utils';

// eslint-disable-next-line import/prefer-default-export
export const useLoading = () => {
  const store = useStore<CommonRootState>();

  const isLoading = computed(() => store.getters['loading/isLoading']);

  const startLoading = () => {
    store.dispatch(LOADING_ACTION.START_LOADING);
  };

  const stopLoading = () => {
    store.dispatch(LOADING_ACTION.STOP_LOADING);
  };

  const resetLoading = () => {
    store.dispatch(LOADING_ACTION.RESET_LOADING);
  };

  const withLoading =
    (apiFunc: AnyFunction, close = true) =>
    async (...args: any[]): Promise<any> => {
      startLoading();
      try {
        await apiFunc(...args);
      } catch (e) {
        if (close) {
          stopLoading();
        }
        throw e;
      }
      if (close) {
        stopLoading();
      }
    };

  return {
    isLoading,
    startLoading,
    stopLoading,
    resetLoading,
    withLoading,
  };
};
