import type { App, Plugin } from 'vue';

import type { AxiosInstance, AxiosRequestConfig } from 'axios';
import axios from 'axios';

import type { CustomAxiosInstance } from 'hems';

class AxiosCreator {
  private isInit = false;
  private _axios: AxiosInstance;
  private _axios_request_interceptor_auth = -1;
  constructor(config: AxiosRequestConfig = {}) {
    this._axios = axios.create(config);

    this._axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          location.href = '/redirect/Unauthorized';
        }

        return Promise.reject(error);
      }
    );
  }

  get axios() {
    if (!this.isInit) console.warn('The access token is not set.');

    return this._axios;
  }

  setBaseUrl(baseUrl: string) {
    this._axios.defaults.baseURL = baseUrl;
  }

  setAccessToken(accessToken?: string) {
    if (!accessToken) return;
    if (this._axios_request_interceptor_auth >= 0) this.clearAccessToken();

    this._axios_request_interceptor_auth = this._axios.interceptors.request.use(
      (config) => {
        config.headers.Authorization = `Bearer ${accessToken}`;

        return config;
      },
      (error) => Promise.reject(error)
    );
    this.isInit = true;
  }

  clearAccessToken() {
    this._axios.interceptors.request.eject(this._axios_request_interceptor_auth);
  }
}

const defaultConfig: AxiosRequestConfig = {
  // baseURL: process.env.BASE_URL,
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const AxiosPlugin: Plugin = {
  install(app: App, config: AxiosRequestConfig = {}) {
    const instance = new AxiosCreator({ ...defaultConfig, ...config });

    window.axiosInstance = instance;
    app.config.globalProperties.$axios = instance;
    app.provide<CustomAxiosInstance>('axios', instance);
  },
};

export default AxiosPlugin;
