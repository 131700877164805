import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a21d85b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "google_map" }
const _hoisted_2 = { class: "cont_box ty_search" }
const _hoisted_3 = { class: "search_opts" }
const _hoisted_4 = { class: "opt w5" }
const _hoisted_5 = { class: "opt w5" }
const _hoisted_6 = { class: "opt w5" }
const _hoisted_7 = { class: "opt w5" }
const _hoisted_8 = { class: "opt w5" }
const _hoisted_9 = { class: "opt w5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GoogleMapWrapper = _resolveComponent("GoogleMapWrapper")!
  const _component_GoogleMapAutoComplete = _resolveComponent("GoogleMapAutoComplete")!
  const _component_LabelInput = _resolveComponent("LabelInput")!
  const _component_BasicInput = _resolveComponent("BasicInput")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_GoogleMapWrapper, {
        editable: _ctx.isEditable,
        lat: _ctx.installLocationInfo.latitude,
        lng: _ctx.installLocationInfo.longitude,
        language: _ctx.languageCode,
        "api-key": _ctx.apiKey,
        onChangePosition: _ctx.onChangeMapPosition,
        onInstance: _ctx.instance
      }, null, 8, ["editable", "lat", "lng", "language", "api-key", "onChangePosition", "onInstance"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        _createElementVNode("li", _hoisted_4, [
          (_ctx.state.googleMap)
            ? (_openBlock(), _createBlock(_component_GoogleMapAutoComplete, {
                key: 0,
                modelValue: _ctx.installLocationInfo.instl_addr,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.installLocationInfo.instl_addr) = $event)),
                label: _ctx.$t('account.address'),
                name: "instl_addr",
                readonly: !_ctx.isEditable,
                "map-obj": _ctx.state.googleMap,
                language: _ctx.languageCode,
                "api-key": _ctx.apiKey,
                onChangePosition: _ctx.onChangeAddress,
                onChangeCustomAddr: _ctx.onChangeCustomAddress
              }, null, 8, ["modelValue", "label", "readonly", "map-obj", "language", "api-key", "onChangePosition", "onChangeCustomAddr"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("li", _hoisted_5, [
          _createVNode(_component_LabelInput, {
            modelValue: _ctx.installLocationInfo.force_instl_addr,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.installLocationInfo.force_instl_addr) = $event)),
            tabindex: "-1",
            name: "force_instl_addr",
            label: _ctx.$t('account.additional_address'),
            readonly: !_ctx.isEditable
          }, null, 8, ["modelValue", "label", "readonly"])
        ]),
        _createElementVNode("li", _hoisted_6, [
          _createVNode(_component_LabelInput, {
            modelValue: _ctx.installLocationInfo.latitude,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.installLocationInfo.latitude) = $event)),
            name: "latitude",
            label: _ctx.$t('common.latitude'),
            readonly: ""
          }, null, 8, ["modelValue", "label"])
        ]),
        _createElementVNode("li", _hoisted_7, [
          _createVNode(_component_LabelInput, {
            modelValue: _ctx.installLocationInfo.longitude,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.installLocationInfo.longitude) = $event)),
            tabindex: "-1",
            name: "longitude",
            label: _ctx.$t('common.longitude'),
            readonly: ""
          }, null, 8, ["modelValue", "label"])
        ]),
        _createElementVNode("li", _hoisted_8, [
          _createVNode(_component_LabelInput, {
            modelValue: _ctx.installLocationInfo.cntry_cd,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.installLocationInfo.cntry_cd) = $event)),
            tabindex: "-1",
            name: "cntry_cd",
            label: _ctx.$t('common.country'),
            value: _ctx.countryInfo,
            readonly: ""
          }, null, 8, ["modelValue", "label", "value"])
        ]),
        _createElementVNode("li", _hoisted_9, [
          _createVNode(_component_BasicInput, {
            modelValue: _ctx.installLocationInfo.timezone_id,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.installLocationInfo.timezone_id) = $event)),
            type: _ctx.INPUT_TYPE.HIDDEN,
            name: "timezone_id"
          }, null, 8, ["modelValue", "type"])
        ])
      ])
    ])
  ], 64))
}