import { defineComponent } from 'vue';
import NavMainItem from '@hems/component/src/navigation/NavMainItem.vue';
export default defineComponent({
    name: 'Navigation',
    components: {
        NavMainItem,
    },
    props: {
        navItems: {
            type: Array,
            default: () => [],
        },
    },
});
