import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2ca478f1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "weather-info-container" }
const _hoisted_2 = { class: "weather-info" }
const _hoisted_3 = {
  key: 0,
  class: "vertical-line"
}
const _hoisted_4 = { class: "weather-info" }
const _hoisted_5 = {
  key: 1,
  class: "vertical-line"
}
const _hoisted_6 = { class: "weather-info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image2 = _resolveComponent("Image2")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.weatherIcon)
        ? (_openBlock(), _createBlock(_component_Image2, {
            key: 0,
            class: "weather-icon",
            src: _ctx.weatherIcon,
            extension: 'svg'
          }, null, 8, ["src"]))
        : _createCommentVNode("", true),
      _createTextVNode(" " + _toDisplayString(_ctx.temperature), 1)
    ]),
    (_ctx.weatherInfo)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.humidity)
        ? (_openBlock(), _createBlock(_component_Image2, {
            key: 0,
            class: "weather-icon",
            src: "weather/ic_water_drop_weather",
            extension: "svg"
          }))
        : _createCommentVNode("", true),
      _createTextVNode(" " + _toDisplayString(_ctx.humidity), 1)
    ]),
    (_ctx.weatherInfo)
      ? (_openBlock(), _createElementBlock("span", _hoisted_5))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_6, [
      (_ctx.windSpeed)
        ? (_openBlock(), _createBlock(_component_Image2, {
            key: 0,
            class: "weather-icon",
            src: "weather/ic_wind_weather",
            extension: "svg"
          }))
        : _createCommentVNode("", true),
      _createTextVNode(" " + _toDisplayString(_ctx.windSpeed), 1)
    ])
  ]))
}