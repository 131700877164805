export const IMAGE_DESCRIPTION_TYPE = {
    INFO: 'info',
    WARN: 'warn',
    NOTICE: 'notice',
};
export const IMAGE_DESCRIPTION_COLOR = {
    [IMAGE_DESCRIPTION_TYPE.INFO]: '#f6f6f6',
    [IMAGE_DESCRIPTION_TYPE.WARN]: '#FEECED',
    [IMAGE_DESCRIPTION_TYPE.NOTICE]: '#fff9f3',
};
export const IMAGE_DESCRIPTION_ICON = {
    [IMAGE_DESCRIPTION_TYPE.INFO]: 'svg/ic_re_info_default',
    [IMAGE_DESCRIPTION_TYPE.WARN]: 'svg/ic_error',
    [IMAGE_DESCRIPTION_TYPE.NOTICE]: 'svg/ic_re_info',
};
