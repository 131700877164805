import { defineComponent, computed } from 'vue';
import { Image2 } from '@hems/component';
import { convertOperationModeToNumberString } from '@hems/container/src/siteEnergy/flow/EnergyFlowFunctions';
import { useSiteConfiguration } from '@hems/util';
import { GEN_TYPE } from '@hems/util/src/constant';
import { getOperationModeTextKey } from '@hems/util/src/helper/dashboardHelper';
export default defineComponent({
    name: 'OperationModeInfo',
    components: { Image2 },
    props: {
        emsOperationMode: {
            type: Number,
            required: true,
        },
    },
    emits: ['onClickOperationMode'],
    setup(props) {
        const { hasESS } = useSiteConfiguration();
        const operationMode = computed(() => convertOperationModeToNumberString(String(props.emsOperationMode)) ?? null);
        const operationModeTextKey = computed(() => operationMode.value ? getOperationModeTextKey(hasESS.value, operationMode.value) : '');
        return {
            operationMode,
            operationModeTextKey,
            GEN_TYPE,
        };
    },
});
