import floor from 'lodash-es/floor';
import { DECIMAL_PLACES, METRIC_PREFIX, NUMBER_UNIT, UNIT } from '@hems/util/src/constant';
export const floorTwoDecimal = (value) => {
    if (typeof value === 'string') {
        return floor(Number(value), 2);
    }
    return floor(value, 2);
};
export const getDecimalPlaces = (value) => {
    const absoluteNumber = Math.abs(value);
    if (absoluteNumber === 0) {
        return DECIMAL_PLACES.ZERO;
    }
    if (absoluteNumber < 10) {
        return DECIMAL_PLACES.TWO;
    }
    if (absoluteNumber < 100) {
        return DECIMAL_PLACES.ONE;
    }
    return DECIMAL_PLACES.ZERO;
};
// eslint-disable-next-line complexity
export const formatUnitNumber = (value, unit = UNIT.WATT) => {
    const absoluteNumber = Math.abs(value);
    const isPhysicalUnit = unit !== UNIT.PERCENT &&
        unit !== UNIT.CELSIUS &&
        unit !== UNIT.FACTOR &&
        unit !== UNIT.DEGREE &&
        unit !== UNIT.FAHRENHEIT;
    if (isPhysicalUnit && absoluteNumber >= NUMBER_UNIT.MILLION) {
        const origin = value / NUMBER_UNIT.MILLION;
        return {
            origin,
            formattedNumber: origin.toFixed(getDecimalPlaces(origin)),
            unit: `${METRIC_PREFIX.MEGA}${unit}`,
        };
    }
    if (isPhysicalUnit && absoluteNumber >= NUMBER_UNIT.THOUSAND) {
        const origin = value / NUMBER_UNIT.THOUSAND;
        return {
            origin,
            formattedNumber: origin.toFixed(getDecimalPlaces(origin)),
            unit: `${METRIC_PREFIX.KILO}${unit}`,
        };
    }
    if (absoluteNumber > 0 && absoluteNumber < 0.01) {
        const origin = value < 0 ? -0.01 : 0.01;
        return {
            origin,
            formattedNumber: origin.toFixed(getDecimalPlaces(origin)),
            unit,
        };
    }
    return {
        origin: value,
        formattedNumber: value.toFixed(getDecimalPlaces(value)),
        unit,
    };
};
export const getEmptyValueUnitNumberData = (unit = UNIT.WATT) => ({
    origin: -1,
    formattedNumber: '',
    unit,
});
export const convertCelsiusToFahrenheit = (value) => value * (9 / 5) + 32;
export const getFormattedFahrenheitNumber = (value) => formatUnitNumber(convertCelsiusToFahrenheit(value), UNIT.FAHRENHEIT);
