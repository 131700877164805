/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/

import type { ValueType } from 'hems/common/utils';

export const GUIDE_TYPE = {
  GUIDE: 'guide',
  ERROR_CODE: 'errorcode',
} as const;

export type GuideTypeValue = ValueType<typeof GUIDE_TYPE>;

export const SUPPORT_GUIDE_TYPE = {
  ALL: 'all',
  HARDWARE_GUIDE: 'hardware_guide',
  SOFTWARE_GUIDE: 'software_guide',
  ERROR_GUIDE: 'error_guide',
} as const;

export type SupportGuideTypeValue = ValueType<typeof SUPPORT_GUIDE_TYPE>;

export const SUPPORT_GUIDE_CATEGORY_CODE = {
  QUICK_GUIDE: '1',
  INSTALLATION_MANUAL: '2',
  SPEC_SHEET: '3',
  USER_MANUAL: '4',
  INSTALLER_APP_GUIDE: '5',
  INSTALLER_WEB_GUIDE: '6',
} as const;

export type SupportGuideCategoryCodeValue = ValueType<typeof SUPPORT_GUIDE_CATEGORY_CODE>;

export const SUPPORT_GUIDE_FILTER_TYPE = {
  SOLAR: 'Solar',
  BATTERY: 'Battery',
  MICRO_INVERTER: 'Microinverter',
  BOX: 'Box',
} as const;

export type SupportGuideFilterTypeValue = ValueType<typeof SUPPORT_GUIDE_FILTER_TYPE>;

export const ERROR_DETAIL_POPUP_MODE = {
  DEVICE: 'device',
  GUIDE: 'guide',
} as const;

export type ErrorDetailPopupModeValue = ValueType<typeof ERROR_DETAIL_POPUP_MODE>;
