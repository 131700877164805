export const errorHistoryData = {
    totalCount: 20,
    data: [
        {
            deviceId: '342711276014L12009',
            subDeviceId: null,
            errorCode: 'H00601W',
            errorLevel: 'W',
            siteId: '100001675',
            attributes: null,
            triggerDate: '2025-01-09T06:45:46+09:00',
            clearDate: null,
            display: {
                description: 'Inv1_LOC',
                howToFix: '\n1. Check the parameters related to ESS installation (ESS installation status, Primary setting, etc.).\n2. Check the status of the Terminating Resistor switch on the Interface Board.\n3. Check the communication cables and wiring.\n4. Turn the product power OFF -> ON and check for errors.\n5. Update to the latest software.\n6. Replace the Interface Board.\n7. Replace the Control Board.\n8. Check the communication on the ESS side.',
            },
        },
        {
            deviceId: '342711276014F24030',
            subDeviceId: null,
            errorCode: 'E03103F',
            errorLevel: 'F',
            siteId: '100001311',
            attributes: null,
            triggerDate: '2025-01-08T02:07:23.874-05:00',
            clearDate: null,
            display: {
                description: 'PCS Slave core error',
                howToFix: '1) Check the CAN communication cable connection between EMS and PCS\n2) If the error persist, Try rebooting EMS/PCS\n3) If the error persist, Replace the interface board.\n4) If the error persist, Replace the main board.',
            },
        },
        {
            deviceId: '342711276014F26003',
            subDeviceId: null,
            errorCode: 'D01201A',
            errorLevel: 'A',
            siteId: '100001118',
            attributes: null,
            triggerDate: '2025-01-07T21:23:45-08:00',
            clearDate: null,
            display: {
                description: 'BMS Under SOC',
                howToFix: '1) Check if SOC is under 15% or Hysteresis Low SOC.\n2) If SOC is charged to more than 15% or Hysteresis Low SOC, Under SOC is automatically released.',
            },
        },
        {
            deviceId: '342711276014F24036',
            subDeviceId: null,
            errorCode: 'D01201A',
            errorLevel: 'A',
            siteId: '100001118',
            attributes: null,
            triggerDate: '2025-01-07T21:23:45-08:00',
            clearDate: null,
            display: {
                description: 'BMS Under SOC',
                howToFix: '1) Check if SOC is under 15% or Hysteresis Low SOC.\n2) If SOC is charged to more than 15% or Hysteresis Low SOC, Under SOC is automatically released.',
            },
        },
        {
            deviceId: '342711276014L12009',
            subDeviceId: null,
            errorCode: 'C12801W',
            errorLevel: 'W',
            siteId: '100001675',
            attributes: null,
            triggerDate: '2025-01-08T13:59:56+09:00',
            clearDate: null,
            display: {
                description: 'EMS Disconnection Error',
                howToFix: "1) Check the internet connection status and speed.\n2) Reboot the customer's home internet router\n3) Check if the router’s firewall allows communication.\n4) Check whether the LAN cable is well connected to the DHCP port of the EMS board.\n5) Check the Wifi dongle, LTE dongle connection status.\n6) If the problem persists, replace the Wifi dongle, LTE dongle\n7) If the problem persists, replace the EMS board.",
            },
        },
        {
            deviceId: '342711276014L12009',
            subDeviceId: null,
            errorCode: 'E00201F',
            errorLevel: 'F',
            siteId: '100001675',
            attributes: null,
            triggerDate: '2025-01-08T13:58:04.703+09:00',
            clearDate: null,
            display: {
                description: 'PCS Connection Error',
                howToFix: '1) Check CAN cable between EMS and PCS. If there is any damage to the cable, replace the cable.\n2) Update the product to the latest firmware.\n3) If the problem persists, replace the EMS board.\n4) If the problem persists, replace the interface board.\n5) If the problem persists, replace the main board(ESS) or Control board(Hub).',
            },
        },
        {
            deviceId: '342711276014L12009',
            subDeviceId: null,
            errorCode: 'H01202W',
            errorLevel: 'W',
            siteId: '100001675',
            attributes: null,
            triggerDate: '2025-01-08T13:54:56+09:00',
            clearDate: null,
            display: {
                description: 'Ext_PV_Relay_Err',
                howToFix: "\n1. Check the relay's operational status (measure the voltage across both ends) and compare it with the relay status on the service page.\n2. Inspect the wiring related to the relay.\n3. Update to the latest software.\n4. Replace the SMPS Board.\n5. Replace the Control Board.",
            },
        },
        {
            deviceId: '342611100004C28048',
            subDeviceId: '2M114CL00315',
            errorCode: 'M01501W',
            errorLevel: 'W',
            siteId: '100000880',
            attributes: null,
            triggerDate: '2025-01-07T17:34:53-08:00',
            clearDate: null,
            display: {
                description: 'MI Not Reporting',
                howToFix: '1) If it is occurred during sunrise/sunset time, it is normal situation due to insufficient solar radiation.\n2) If error remain more than 3 days, Check PV Module status and PV connector connection.\n3) Check PV Voltage.\n4) If there is a problem with PV status, Please replace the AC Module.\n5) If the problem persists, please replace the AC Combiner.',
            },
        },
        {
            deviceId: '342611100004C28048',
            subDeviceId: '2M114CL00190',
            errorCode: 'M01501W',
            errorLevel: 'W',
            siteId: '100000880',
            attributes: null,
            triggerDate: '2025-01-07T17:34:53-08:00',
            clearDate: null,
            display: {
                description: 'MI Not Reporting',
                howToFix: '1) If it is occurred during sunrise/sunset time, it is normal situation due to insufficient solar radiation.\n2) If error remain more than 3 days, Check PV Module status and PV connector connection.\n3) Check PV Voltage.\n4) If there is a problem with PV status, Please replace the AC Module.\n5) If the problem persists, please replace the AC Combiner.',
            },
        },
        {
            deviceId: '342611100004C28048',
            subDeviceId: '2M114CF00142',
            errorCode: 'M01501W',
            errorLevel: 'W',
            siteId: '100000880',
            attributes: null,
            triggerDate: '2025-01-07T17:34:53-08:00',
            clearDate: null,
            display: {
                description: 'MI Not Reporting',
                howToFix: '1) If it is occurred during sunrise/sunset time, it is normal situation due to insufficient solar radiation.\n2) If error remain more than 3 days, Check PV Module status and PV connector connection.\n3) Check PV Voltage.\n4) If there is a problem with PV status, Please replace the AC Module.\n5) If the problem persists, please replace the AC Combiner.',
            },
        },
        {
            deviceId: '342611100004C28048',
            subDeviceId: '2M114CL00102',
            errorCode: 'M01501W',
            errorLevel: 'W',
            siteId: '100000880',
            attributes: null,
            triggerDate: '2025-01-07T17:34:53-08:00',
            clearDate: null,
            display: {
                description: 'MI Not Reporting',
                howToFix: '1) If it is occurred during sunrise/sunset time, it is normal situation due to insufficient solar radiation.\n2) If error remain more than 3 days, Check PV Module status and PV connector connection.\n3) Check PV Voltage.\n4) If there is a problem with PV status, Please replace the AC Module.\n5) If the problem persists, please replace the AC Combiner.',
            },
        },
        {
            deviceId: '342611100004C28048',
            subDeviceId: '2M114CL00339',
            errorCode: 'M01501W',
            errorLevel: 'W',
            siteId: '100000880',
            attributes: null,
            triggerDate: '2025-01-07T17:34:53-08:00',
            clearDate: null,
            display: {
                description: 'MI Not Reporting',
                howToFix: '1) If it is occurred during sunrise/sunset time, it is normal situation due to insufficient solar radiation.\n2) If error remain more than 3 days, Check PV Module status and PV connector connection.\n3) Check PV Voltage.\n4) If there is a problem with PV status, Please replace the AC Module.\n5) If the problem persists, please replace the AC Combiner.',
            },
        },
        {
            deviceId: '342611100004C28048',
            subDeviceId: '2M114CF00019',
            errorCode: 'M01501W',
            errorLevel: 'W',
            siteId: '100000880',
            attributes: null,
            triggerDate: '2025-01-07T17:34:53-08:00',
            clearDate: null,
            display: {
                description: 'MI Not Reporting',
                howToFix: '1) If it is occurred during sunrise/sunset time, it is normal situation due to insufficient solar radiation.\n2) If error remain more than 3 days, Check PV Module status and PV connector connection.\n3) Check PV Voltage.\n4) If there is a problem with PV status, Please replace the AC Module.\n5) If the problem persists, please replace the AC Combiner.',
            },
        },
        {
            deviceId: '342611100004C28048',
            subDeviceId: '2M114CF00065',
            errorCode: 'M01501W',
            errorLevel: 'W',
            siteId: '100000880',
            attributes: null,
            triggerDate: '2025-01-07T17:34:53-08:00',
            clearDate: null,
            display: {
                description: 'MI Not Reporting',
                howToFix: '1) If it is occurred during sunrise/sunset time, it is normal situation due to insufficient solar radiation.\n2) If error remain more than 3 days, Check PV Module status and PV connector connection.\n3) Check PV Voltage.\n4) If there is a problem with PV status, Please replace the AC Module.\n5) If the problem persists, please replace the AC Combiner.',
            },
        },
        {
            deviceId: '342611100004C28048',
            subDeviceId: '2M114CL00248',
            errorCode: 'M01501W',
            errorLevel: 'W',
            siteId: '100000880',
            attributes: null,
            triggerDate: '2025-01-07T17:34:53-08:00',
            clearDate: null,
            display: {
                description: 'MI Not Reporting',
                howToFix: '1) If it is occurred during sunrise/sunset time, it is normal situation due to insufficient solar radiation.\n2) If error remain more than 3 days, Check PV Module status and PV connector connection.\n3) Check PV Voltage.\n4) If there is a problem with PV status, Please replace the AC Module.\n5) If the problem persists, please replace the AC Combiner.',
            },
        },
        {
            deviceId: '342611100004C28048',
            subDeviceId: '2M114CL00289',
            errorCode: 'M01501W',
            errorLevel: 'W',
            siteId: '100000880',
            attributes: null,
            triggerDate: '2025-01-07T17:34:53-08:00',
            clearDate: null,
            display: {
                description: 'MI Not Reporting',
                howToFix: '1) If it is occurred during sunrise/sunset time, it is normal situation due to insufficient solar radiation.\n2) If error remain more than 3 days, Check PV Module status and PV connector connection.\n3) Check PV Voltage.\n4) If there is a problem with PV status, Please replace the AC Module.\n5) If the problem persists, please replace the AC Combiner.',
            },
        },
        {
            deviceId: '342611100004C28048',
            subDeviceId: '2M114CL00361',
            errorCode: 'M01501W',
            errorLevel: 'W',
            siteId: '100000880',
            attributes: null,
            triggerDate: '2025-01-07T17:34:53-08:00',
            clearDate: null,
            display: {
                description: 'MI Not Reporting',
                howToFix: '1) If it is occurred during sunrise/sunset time, it is normal situation due to insufficient solar radiation.\n2) If error remain more than 3 days, Check PV Module status and PV connector connection.\n3) Check PV Voltage.\n4) If there is a problem with PV status, Please replace the AC Module.\n5) If the problem persists, please replace the AC Combiner.',
            },
        },
        {
            deviceId: '342611100004D01007',
            subDeviceId: '2M114CF00109',
            errorCode: 'M01501W',
            errorLevel: 'W',
            siteId: '100001117',
            attributes: null,
            triggerDate: '2025-01-07T17:31:53-08:00',
            clearDate: null,
            display: {
                description: 'MI Not Reporting',
                howToFix: '1) If it is occurred during sunrise/sunset time, it is normal situation due to insufficient solar radiation.\n2) If error remain more than 3 days, Check PV Module status and PV connector connection.\n3) Check PV Voltage.\n4) If there is a problem with PV status, Please replace the AC Module.\n5) If the problem persists, please replace the AC Combiner.',
            },
        },
        {
            deviceId: '342611100004D01007',
            subDeviceId: '2M114CF00071',
            errorCode: 'M01501W',
            errorLevel: 'W',
            siteId: '100001117',
            attributes: null,
            triggerDate: '2025-01-07T17:31:53-08:00',
            clearDate: null,
            display: {
                description: 'MI Not Reporting',
                howToFix: '1) If it is occurred during sunrise/sunset time, it is normal situation due to insufficient solar radiation.\n2) If error remain more than 3 days, Check PV Module status and PV connector connection.\n3) Check PV Voltage.\n4) If there is a problem with PV status, Please replace the AC Module.\n5) If the problem persists, please replace the AC Combiner.',
            },
        },
        {
            deviceId: '342611100004D01007',
            subDeviceId: '2M114CL00092',
            errorCode: 'M01501W',
            errorLevel: 'W',
            siteId: '100001117',
            attributes: null,
            triggerDate: '2025-01-07T17:31:53-08:00',
            clearDate: null,
            display: {
                description: 'MI Not Reporting',
                howToFix: '1) If it is occurred during sunrise/sunset time, it is normal situation due to insufficient solar radiation.\n2) If error remain more than 3 days, Check PV Module status and PV connector connection.\n3) Check PV Voltage.\n4) If there is a problem with PV status, Please replace the AC Module.\n5) If the problem persists, please replace the AC Combiner.',
            },
        },
    ],
};
