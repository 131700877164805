import type { NumberUnitValue } from '@hems/util/src/constant';
import { NUMBER_UNIT } from '@hems/util/src/constant';

import type { ValueType } from 'hems/common/utils';

/**
Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
export const ONE_MILE_TO_KILOMETER = 1.609;

export const WATT_HOUR_UNIT = {
  WATT_HOUR: 'Wh',
  KILO_WATT_HOUR: 'kWh',
  MEGA_WATT_HOUR: 'MWh',
} as const;

export type WattHourUnitValue = ValueType<typeof WATT_HOUR_UNIT>;

export const WATT_PEAK_UNIT = {
  WATT_PEAK: 'Wp',
  KILO_WATT_PEAK: 'kWp',
  MEGA_WATT_PEAK: 'MWp',
} as const;

export type WattPeakUnitValue = ValueType<typeof WATT_PEAK_UNIT>;

export type NumberUnitWattUnitMapType = { [key in NumberUnitValue]: WattHourUnitValue | WattPeakUnitValue };

export const NUMBER_UNIT_WATT_HOUR_UNIT_MAP: NumberUnitWattUnitMapType = {
  [NUMBER_UNIT.MILLION]: WATT_HOUR_UNIT.MEGA_WATT_HOUR,
  [NUMBER_UNIT.TEN_THOUSAND]: WATT_HOUR_UNIT.KILO_WATT_HOUR,
  [NUMBER_UNIT.THOUSAND]: WATT_HOUR_UNIT.KILO_WATT_HOUR,
  [NUMBER_UNIT.HUNDRED]: WATT_HOUR_UNIT.WATT_HOUR,
};

export const NUMBER_UNIT_WATT_PEAK_UNIT_MAP: NumberUnitWattUnitMapType = {
  [NUMBER_UNIT.MILLION]: WATT_PEAK_UNIT.MEGA_WATT_PEAK,
  [NUMBER_UNIT.TEN_THOUSAND]: WATT_PEAK_UNIT.KILO_WATT_PEAK,
  [NUMBER_UNIT.THOUSAND]: WATT_PEAK_UNIT.KILO_WATT_PEAK,
  [NUMBER_UNIT.HUNDRED]: WATT_PEAK_UNIT.WATT_PEAK,
};

export const CAPACITY_UINT_TYPE = {
  HOUR: 'hour',
  PEAK: 'peak',
};

export type CapacityUnitTypeValue = ValueType<typeof CAPACITY_UINT_TYPE>;

export const UNIT = {
  WATT: 'W',
  WATT_HOUR: 'Wh',
  WATT_PEAK: 'Wp',
  VOLT: 'V',
  PERCENT: '%',
  CELSIUS: '°C',
  AMPERE: 'A',
  HERTZ: 'Hz',
  /** 유효 전력 단위 */
  VOLT_AMPERE: 'VA',
  /** 무효 전력 단위 */
  VOLT_AMPERE_REACTIVE: 'VAR',
  FACTOR: 'factor',
  DEGREE: '°',
  FAHRENHEIT: '°F',
} as const;

export type UnitValueType = ValueType<typeof UNIT>;

export const INTL_NUMBER_FORMAT_UNIT = {
  FAHRENHEIT: 'fahrenheit',
  CELSIUS: 'celsius',
  PERCENT: 'percent',
  MILE_PER_HOUR: 'mile-per-hour',
  KILOMETER_PER_HOUR: 'kilometer-per-hour',
} as const;

export const METRIC_PREFIX = {
  MEGA: 'M',
  KILO: 'k',
} as const;

export type MetricPrefixValueType = ValueType<typeof METRIC_PREFIX>;

export const DECIMAL_PLACES = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
} as const;

export type BasePhysicalUnitType = Exclude<
  UnitValueType,
  typeof UNIT.CELSIUS | typeof UNIT.PERCENT | typeof UNIT.FACTOR | typeof UNIT.DEGREE | typeof UNIT.FAHRENHEIT
>;

export type PhysicalUnitType = `${MetricPrefixValueType}${BasePhysicalUnitType}` | BasePhysicalUnitType;

export type AllUnitType =
  | PhysicalUnitType
  | typeof UNIT.CELSIUS
  | typeof UNIT.PERCENT
  | typeof UNIT.FACTOR
  | typeof UNIT.DEGREE
  | typeof UNIT.FAHRENHEIT;
