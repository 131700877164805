import { defineComponent, computed } from 'vue';
import { useSiteConfiguration, useSiteMasterDeviceInfo } from '@hems/util';
import { ENERGY_VALUE_IMAGE } from '@hems/util/src/constant';
export default defineComponent({
    name: 'ProductionInfo',
    props: {
        formattedData: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const { hasPV } = useSiteConfiguration();
        const { isConsumptionCT } = useSiteMasterDeviceInfo();
        const productionValueText = computed(() => hasPV.value
            ? `${props.formattedData.pv?.pw.formattedNumber} ${props.formattedData.pv?.pw.unit}`
            : `${props.formattedData.load?.pw.formattedNumber} ${props.formattedData.load?.pw.unit}`);
        return {
            productionValueText,
            hasPV,
            isConsumptionCT,
            ENERGY_VALUE_IMAGE,
        };
    },
});
