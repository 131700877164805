import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-198c4491"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "btn_wrap_m" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InstallerAccountSearchBox = _resolveComponent("InstallerAccountSearchBox")!
  const _component_ChangeInstallerAccountDataGrid = _resolveComponent("ChangeInstallerAccountDataGrid")!
  const _component_Button = _resolveComponent("Button")!
  const _component_PopupWrapper = _resolveComponent("PopupWrapper")!

  return (_openBlock(), _createBlock(_component_PopupWrapper, {
    class: "ty_2",
    style: {"opacity":"1","left":"50%","margin-left":"-640px","top":"190px","margin-bottom":"50px"}
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_InstallerAccountSearchBox, {
          condition: _ctx.state.searchCondition,
          onSearch: _ctx.onSearch
        }, null, 8, ["condition", "onSearch"])
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_ChangeInstallerAccountDataGrid, {
          "search-condition": _ctx.state.searchCondition,
          onSelect: _ctx.onSelect
        }, null, 8, ["search-condition", "onSelect"])
      ]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Button, {
          class: "btn_m btn_c_2",
          onClick: _ctx.onCancel
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t(`common.cancel`)), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_Button, {
          class: "btn_m btn_c_3",
          onClick: _ctx.onOk
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t(`common.ok`)), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _createElementVNode("button", {
        type: "button",
        class: "close_btn_2",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onCancel && _ctx.onCancel(...args)))
      }, _toDisplayString(_ctx.$t('common.close')), 1)
    ]),
    _: 1
  }))
}