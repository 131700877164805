import { Service } from '@hems/service/src/api/Service';
import { apiWrapper } from '@hems/service/src/util/helper';
import { UNIT } from '@hems/util/src/constant';
import { getKwhData } from '@hems/util/src/helper/helper';
import { formatUnitNumber } from '@hems/util/src/helper/numberformatHelper';
export default class UserReportService extends Service {
    constructor(axios) {
        super(axios);
    }
    getServerTime = async (timezoneId) => {
        const { data } = await this.get(apiWrapper.managerApi('/managements/commons/now'), {
            timezoneId,
        });
        return data;
    };
    getReportsByInterval = async (interval, params) => {
        const { from, to, type, site_id } = params;
        const reportParams = { from, to, type, site_id, interval };
        const { data } = await this.get(apiWrapper.managerApi('/histories/user-reports'), reportParams);
        return {
            ...data,
            result: getAccumulatedData(data.result),
        };
    };
    getOverview = async (params) => {
        const overviewParams = { site_id: params.site_id };
        const { data } = await this.get(apiWrapper.managerApi('/histories/user-reports/overviews'), overviewParams);
        return data;
    };
}
const getAccumulatedData = (data) => {
    const { gen_data = [], con_data = [], core_data = [], btc_data = [], btd_data = [], feedin_data = [], demand_data = [], } = data;
    const defaultValue = formatUnitNumber(0, UNIT.WATT_HOUR);
    return {
        ...data,
        gen_kwh: gen_data ? getKwhData(gen_data) : defaultValue,
        con_kwh: con_data ? getKwhData(con_data) : defaultValue,
        core_kwh: core_data ? getKwhData(core_data) : defaultValue,
        btc_kwh: btc_data ? getKwhData(btc_data) : defaultValue,
        btd_kwh: btd_data ? getKwhData(btd_data) : defaultValue,
        feedin_kwh: feedin_data ? getKwhData(feedin_data) : defaultValue,
        demand_kwh: demand_data ? getKwhData(demand_data) : defaultValue,
    };
};
export { UserReportService };
