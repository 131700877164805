import type { AxiosInstance } from 'axios';

import { Service } from '@hems/service/src/api/Service';
import { apiWrapper } from '@hems/service/src/util/helper';

import type { CommonResponseWrapper } from 'hems';

import type { SupportInfo, SupportErrorCodeInfo, SupportGuideListQueryParam } from 'hems/support';

export default class SupportService extends Service {
  constructor(axios: AxiosInstance) {
    super(axios);
  }

  getGuideList = async (
    params: SupportGuideListQueryParam | Pick<SupportGuideListQueryParam, 'supportId'>
  ): Promise<SupportInfo[]> => {
    const { data } = await this.get<CommonResponseWrapper<SupportInfo[]>>(
      apiWrapper.managerApi('/managements/mobiles/pro-apps/support-guides'),
      params
    );

    return data;
  };

  addGuide = async (
    params: Omit<SupportInfo, 'supportId' | 'categoryName'>
  ): Promise<Omit<SupportInfo, 'supportId' | 'categoryName'>> => {
    const { data } = await this.post<CommonResponseWrapper<Omit<SupportInfo, 'supportId' | 'categoryName'>>>(
      apiWrapper.managerApi('/managements/portals/supports/guides'),
      params
    );

    return data;
  };

  updateGuide = async (
    supportId: number,
    params: Omit<SupportInfo, 'supportId' | 'categoryName'>
  ): Promise<Omit<SupportInfo, 'categoryName'>> => {
    const { data } = await this.put<CommonResponseWrapper<Omit<SupportInfo, 'categoryName'>>>(
      apiWrapper.managerApi(`/managements/portals/supports/guides/${supportId}`),
      params
    );

    return data;
  };

  deleteGuide = async (supportId: number): Promise<Pick<SupportInfo, 'supportId'>> => {
    const { data } = await this.delete<CommonResponseWrapper<Pick<SupportInfo, 'supportId'>>>(
      apiWrapper.managerApi(`/managements/portals/supports/guides/${supportId}`)
    );

    return data;
  };

  getEventcodeList = async (
    params: SupportGuideListQueryParam | { alarmCd: string }
  ): Promise<SupportErrorCodeInfo[]> => {
    const { data } = await this.get<CommonResponseWrapper<SupportErrorCodeInfo[]>>(
      apiWrapper.managerApi('/managements/mobiles/pro-apps/supports/event-codes'),
      params
    );

    return data;
  };

  addEventcode = async (
    params: Omit<SupportErrorCodeInfo, 'alarmLevel'>
  ): Promise<SupportErrorCodeInfo & { userId: string }> => {
    const { data } = await this.post<CommonResponseWrapper<SupportErrorCodeInfo & { userId: string }>>(
      apiWrapper.managerApi('/managements/portals/supports/event-codes'),
      params
    );

    return data;
  };

  updateEventcode = async (
    alarmCd: string,
    params: Omit<SupportErrorCodeInfo, 'alarmLevel'>
  ): Promise<SupportErrorCodeInfo & { userId: string }> => {
    const { data } = await this.put<CommonResponseWrapper<SupportErrorCodeInfo & { userId: string }>>(
      apiWrapper.managerApi(`/managements/portals/supports/event-codes/${alarmCd}`),
      params
    );

    return data;
  };

  deleteEventcode = async (alarmCd: string): Promise<{ eventCode: string }> => {
    const { data } = await this.delete<CommonResponseWrapper<{ eventCode: string }>>(
      apiWrapper.managerApi(`/managements/portals/supports/event-codes/${alarmCd}`)
    );

    return data;
  };
}

export { SupportService };
