
import { defineComponent, nextTick, reactive, onMounted, ref, onBeforeUpdate, computed, onBeforeMount } from 'vue';
import { useI18n } from 'vue-i18n';

import cloneDeep from 'lodash-es/cloneDeep';
import groupBy from 'lodash-es/groupBy';
import isEmpty from 'lodash-es/isEmpty';
import isNil from 'lodash-es/isNil';
import omit from 'lodash-es/omit';
import pick from 'lodash-es/pick';
import pickBy from 'lodash-es/pickBy';
import type { Dictionary } from 'tui-grid';
import type { ValidationResult } from 'vee-validate';
import { Form } from 'vee-validate';
import * as yup from 'yup';

import {
  Accordion,
  BasicInput,
  ToggleInput,
  Selector,
  SettingTable,
  SettingTableRow,
  BaseSlider,
  RadioGroup,
  ChangedMark,
  withLoading,
  TitleBanner,
} from '@hems/component';
import type { childFieldTargetInfo } from '@hems/container/src/forms/device/settings/_shared/ACSystemConfig';
import {
  settingTableRerender,
  getBasicSettingACSystemConfig,
  getTargetList,
  acSystemComputedRange,
  initACSystemBasicSettingsValue,
  getDERBreakerRatingMax,
  getBackupTypeOptionList,
  getBusbarRatingMin,
} from '@hems/container/src/forms/device/settings/_shared/ACSystemConfig';
import SettingButtonGroup from '@hems/container/src/forms/device/settings/_shared/SettingButtonGroup.vue';
import SettingConfirmPopup from '@hems/container/src/forms/device/settings/_shared/SettingConfirmPopup.vue';
import DeviceService from '@hems/service/src/api/device/DeviceService';
import {
  useACDeviceType,
  useAlertModal,
  useConfirmModal,
  useFailModal,
  useLoading,
  useRole,
  useSettingMessageBox,
  useSiteId,
  useSiteInfo,
  useSuccessModal,
} from '@hems/util';
import type { SettingTargetValue } from '@hems/util/src/constant';
import {
  ENERGY_POLICY,
  CONSUMPTION_CT_TYPE,
  SETTING_TARGET,
  MPU_AVOIDANCE,
  BANNER_TYPE,
  CS_CONTACT_NUMBER,
  CONFIG_MSG_TYPE,
  GEN_TYPE,
  INDENT_DEPTH,
  BACKUP_TYPE,
  UNIT,
  INPUT_TYPE,
} from '@hems/util/src/constant';
import { PROMISE_SETTLED_STATUS, STATUS_CODE } from '@hems/util/src/exception/exception';
import { checkConnectionStatus } from '@hems/util/src/helper/deviceHelper';
import { isNull } from '@hems/util/src/helper/helper';
import { getSettingTargetType } from '@hems/util/src/helper/tsguardHelper';

import type { ConfigMessgeType } from 'hems';

import type { ACSystemSettingGroup, ConfigurationsResponse, SettingGroup } from 'hems/device/settings';
import type { ACSystemBasicSettings } from 'hems/device/settings/smartmodule/acsys';

export default defineComponent({
  name: 'BasicSettingsACSystem',
  components: {
    Accordion,
    BasicInput,
    ToggleInput,
    Form,
    Selector,
    SettingTable,
    SettingTableRow,
    BaseSlider,
    RadioGroup,
    ChangedMark,
    SettingConfirmPopup,
    TitleBanner,
    SettingButtonGroup,
  },
  emits: ['goToList', 'save', 'cancel', 'edit'],
  setup(_props, { emit }) {
    const { t } = useI18n();
    const deviceService = new DeviceService(window.axiosInstance.axios);

    const { isSolarConfig, hasACCombiner, isBackupConfig, isGridSupportConfig, isCase4 } = useACDeviceType();
    const { masterDeviceProfile, ACCombinerId, getPrimaryESSId } = useSiteInfo();
    const { siteId } = useSiteId();

    const { isInstaller } = useRole();

    const schema = ref<yup.AnySchema>(yup.object().shape({}));

    const alertModal = useAlertModal({});
    const failModal = useFailModal({});
    const successModal = useSuccessModal({});
    const confirmModal = useConfirmModal({});
    const { settingErrorMessageBox } = useSettingMessageBox();
    const { startLoading, stopLoading } = useLoading();

    const masterDeviceInfo = computed(() =>
      masterDeviceProfile && masterDeviceProfile.gen_type === GEN_TYPE.AC_SYS ? masterDeviceProfile : null
    );

    const baseSettingParams = computed<{ msg_type: ConfigMessgeType; product_model_nm: string }>(() => ({
      msg_type: CONFIG_MSG_TYPE.BS,
      product_model_nm: masterDeviceInfo.value?.product_model_nm ?? '',
    }));

    const isInit = ref(true);

    const state = reactive<{
      editable: boolean;
      data: ACSystemBasicSettings;
      changedValueSet: Set<string>;
      confirmPopup: { on: boolean; data: SettingGroup[] };
    }>({
      editable: false,
      data: { ...initACSystemBasicSettingsValue },
      changedValueSet: new Set(),
      confirmPopup: { on: false, data: [] },
    });

    let copyData: ACSystemBasicSettings = cloneDeep(state.data);
    const enableEditFeedInLimit = ref(state.data.energy_policy !== Number(ENERGY_POLICY.ZERO_EXPORT));
    const prevFeedInLimitWatt = ref(state.data.pv_feed_in_limit_w);

    // @FIXME: isEMSVersionAtLeast 공통 함수 사용하여 버전 비교하도록 수정 필요
    const isVersionAbove4 = computed(() => state.data.version >= '4.0.0');

    const isDisableMSPBusbarRating = ref(false);
    const isDisableDERBreakerRating = ref(false);
    const isDisablePVBreakerRating = ref(false);
    const isDisableESSBreakerRating = ref(false);

    const isDisableApplyButton = computed(() => isDisableMSPBusbarRating.value || isDisableDERBreakerRating.value);

    const consumptionCTOptionList = computed(() => [
      ...(isVersionAbove4.value ? [{ text: t('common.not_installed'), value: CONSUMPTION_CT_TYPE.NOT_INSTALLED }] : []),
      { text: t('common.grid_side'), value: CONSUMPTION_CT_TYPE.GRID_SIDE },
      { text: t('common.load_side'), value: CONSUMPTION_CT_TYPE.LOAD_SIDE },
    ]);

    const MPUAvoidanceOptionList = computed(() => [
      { text: t('common.none'), value: MPU_AVOIDANCE.NONE },
      { text: t('control.backfeed_limit'), value: MPU_AVOIDANCE.BACKFEED_LIMIT },
    ]);

    const backupTypeOptionList = computed(() => getBackupTypeOptionList(t));

    const busbarRatingMin = ref(2);

    const DERBreakerRatingMax = ref(1000);

    const isBackupConfigWithPartialHome = computed(
      () => isBackupConfig && state.data.site_info_backup_config_type === BACKUP_TYPE.PARTIAL_HOME_BACKUP
    );

    const isPossibleToEditMPUAvoidance = computed(
      () => isBackupConfigWithPartialHome.value || isSolarConfig || isGridSupportConfig
    );

    const isBackfeedLimitDeactivated = computed(() => isInstaller && isPossibleToEditMPUAvoidance.value);

    const setIsDisableBackfeedLimitSettings = () => {
      if (
        !isBackfeedLimitDeactivated.value &&
        state.data.msp_busbar_protection_flag === MPU_AVOIDANCE.BACKFEED_LIMIT &&
        isNil(state.data.msp_msp_breaker_rating)
      ) {
        isDisableMSPBusbarRating.value = true;
        isDisableDERBreakerRating.value = true;
        isDisablePVBreakerRating.value = true;
        isDisableESSBreakerRating.value = true;
      }
    };

    const onChangeMSPBreakerRating = () => {
      isDisableMSPBusbarRating.value = true;
      isDisableDERBreakerRating.value = true;
      isDisablePVBreakerRating.value = true;
      isDisableESSBreakerRating.value = true;
    };

    const onBlurMSPBreakerRating = async (validateField: (field: string) => Promise<ValidationResult>) => {
      const { valid: isValidMSPBreakerRating } = await validateField('msp_msp_breaker_rating');

      if (!isValidMSPBreakerRating) return;

      isDisableMSPBusbarRating.value = false;
      state.data.msp_msp_busbar_rating = null;
      state.data.msp_der_breaker_rating = null;
      busbarRatingMin.value = getBusbarRatingMin(state.data.msp_msp_breaker_rating);
    };

    const onChangeMSPBusbarRating = () => {
      isDisableDERBreakerRating.value = true;
      state.data.msp_der_breaker_rating = null;
    };

    const onBlurMSPBusbarRating = async (validateField: (field: string) => Promise<ValidationResult>) => {
      const { valid: isValidMSPBreakerRating } = await validateField('msp_msp_breaker_rating');
      const { valid: isValidMSPBusbarRating } = await validateField('msp_msp_busbar_rating');

      if (!isValidMSPBreakerRating || !isValidMSPBusbarRating) return;

      isDisableDERBreakerRating.value = false;
      DERBreakerRatingMax.value =
        state.data.msp_msp_busbar_rating && state.data.msp_msp_breaker_rating
          ? getDERBreakerRatingMax(state.data.msp_msp_busbar_rating, state.data.msp_msp_breaker_rating)
          : 1000;
    };

    const onChangeDERBreakerRating = () => {
      isDisablePVBreakerRating.value = true;
      state.data.msp_pv_breaker_rating = null;
    };

    const onBlurDERBreakerRating = async (validateField: (field: string) => Promise<ValidationResult>) => {
      const { valid: isValidMSPBreakerRating } = await validateField('msp_msp_breaker_rating');
      const { valid: isValidDERBreakerRating } = await validateField('msp_der_breaker_rating');

      if (!isValidMSPBreakerRating || !isValidDERBreakerRating) return;

      isDisablePVBreakerRating.value = false;
    };

    const onChangeMSPPVBreakerRating = () => {
      isDisableESSBreakerRating.value = true;
      state.data.msp_ess_breaker_rating = null;
    };

    const onBlurMSPPVBreakerRating = async (validateField: (field: string) => Promise<ValidationResult>) => {
      const { valid: isValidMSPBreakerRating } = await validateField('msp_msp_breaker_rating');
      const { valid: isValidPVBreakerRating } = await validateField('msp_pv_breaker_rating');

      if (!isValidMSPBreakerRating || !isValidPVBreakerRating) return;

      isDisableESSBreakerRating.value = false;
    };

    const fieldList = getTargetList(
      getBasicSettingACSystemConfig(t, state.data, isBackfeedLimitDeactivated.value, isGridSupportConfig)
    );

    // eslint-disable-next-line complexity
    const getOmittedParam = (settingParams: Partial<ACSystemBasicSettings>) => {
      let param = { ...settingParams };

      if (!isBackupConfig) {
        param = omit(param, 'battery_backup_soc');
      }

      if (!enableEditFeedInLimit.value || !state.data.pv_feed_in_limit_flag) {
        param = omit(param, 'pv_feed_in_limit_w');
      }

      if (!isVersionAbove4.value) {
        param = omit(param, 'accb_extended_production_ct_flag');
      }

      if (!hasACCombiner) {
        param = omit(param, [
          'accb_extended_production_ct_flag',
          'accb_consumption_ct_installed_point',
          'accb_polarity_reverse_production_ct',
          'accb_polarity_reverse_consumption_l1_ct',
          'accb_polarity_reverse_consumption_l2_ct',
          'accb_polarity_reverse_ext_production_ct',
        ]);
      }

      if (isBackfeedLimitDeactivated.value || !isPossibleToEditMPUAvoidance.value) {
        param = omit(param, [
          'msp_busbar_protection_flag',
          'msp_msp_breaker_rating',
          'msp_msp_busbar_rating',
          'msp_der_breaker_rating',
          'msp_pv_breaker_rating',
          'msp_ess_breaker_rating',
        ]);
      }

      if (!state.data.msp_busbar_protection_flag || !state.data.backfeed_limit_option) {
        param = omit(param, [
          'msp_msp_breaker_rating',
          'msp_msp_busbar_rating',
          'msp_der_breaker_rating',
          'msp_pv_breaker_rating',
          'msp_ess_breaker_rating',
        ]);
      }

      if (!isCase4) {
        param = omit(param, ['pv_inverter_nameplate_power']);
      }

      if (!isGridSupportConfig) {
        param = omit(param, ['msp_pv_breaker_rating', 'msp_ess_breaker_rating']);
      }

      return param;
    };

    const getParam = (): Partial<ACSystemBasicSettings> => {
      let param: Partial<ACSystemBasicSettings> = {
        msp_busbar_protection_flag: state.data.msp_busbar_protection_flag,
        msp_msp_breaker_rating: state.data.msp_msp_breaker_rating,
        msp_msp_busbar_rating: state.data.msp_msp_busbar_rating,
        msp_der_breaker_rating: state.data.msp_der_breaker_rating,
        msp_pv_breaker_rating: state.data.msp_pv_breaker_rating,
        msp_ess_breaker_rating: state.data.msp_ess_breaker_rating,

        battery_backup_soc: state.data.battery_backup_soc,
        pv_feed_in_limit_flag: state.data.pv_feed_in_limit_flag,
        pv_feed_in_limit_w: state.data.pv_feed_in_limit_w,

        accb_extended_production_ct_flag: state.data.accb_extended_production_ct_flag,
        accb_consumption_ct_installed_point: state.data.accb_consumption_ct_installed_point,
        accb_polarity_reverse_production_ct: state.data.accb_polarity_reverse_production_ct,
        accb_polarity_reverse_consumption_l1_ct: state.data.accb_polarity_reverse_consumption_l1_ct,
        accb_polarity_reverse_consumption_l2_ct: state.data.accb_polarity_reverse_consumption_l2_ct,
        accb_polarity_reverse_ext_production_ct: state.data.accb_polarity_reverse_ext_production_ct,

        pv_inverter_nameplate_power: state.data.pv_inverter_nameplate_power,

        site_info_backup_config_type: state.data.site_info_backup_config_type,
      };

      param = getOmittedParam(param);

      const cleanedParam = pickBy(param, (v) => v !== undefined && v !== null);

      return cleanedParam;
    };

    const onSave = () => {
      const confirmItems = getParam();

      const params = getBasicSettingACSystemConfig(t, state.data, isBackfeedLimitDeactivated.value, isGridSupportConfig)
        .map((config) => {
          return {
            ...config,
            children: config.children
              .map((item) => {
                const itemValue = confirmItems[item.code as keyof ACSystemBasicSettings];
                const value = (() => {
                  if (item.type === 'number' || item.type === 'text') return itemValue;
                  if (!isNull(itemValue)) {
                    return item.options?.filter((option) => option.value == itemValue)[0]?.text;
                  }

                  return undefined;
                })();

                return {
                  ...item,
                  value,
                };
              })
              .filter((item) => !isNull(item.value)),
          };
        })
        .filter((config) => config.children.length > 0);

      state.confirmPopup = { on: true, data: params };
    };

    const getSiteSettingPromise = (siteId: number, filteredParams: Partial<ACSystemBasicSettings>) => () =>
      deviceService.controlACSystemSettingWithSiteId(siteId, {
        ...baseSettingParams.value,
        ...filteredParams,
      });

    const getDeviceSettingPromise =
      (siteId: number, filteredParams: Partial<ACSystemBasicSettings>, target: SettingTargetValue) => () =>
        deviceService.controlACSystemSettingWithSiteId(
          siteId,
          {
            ...baseSettingParams.value,
            ...filteredParams,
          },
          target
        );

    const getSettingPromiseListBySettingTarget = (
      siteId: number,
      fieldListByTarget: Dictionary<childFieldTargetInfo[]>,
      params: Partial<ACSystemBasicSettings>
    ): (() => Promise<ConfigurationsResponse>)[] =>
      Object.entries(fieldListByTarget).map(([key, value]) => {
        const target: SettingTargetValue | null = getSettingTargetType(key);
        const filteredParams = pick(
          params,
          value.map((item) => item.name)
        );

        if (!target || isEmpty(filteredParams)) {
          const result: ConfigurationsResponse = { errorCode: '', message: '', status: 200, timestamp: '' };

          return () => Promise.resolve(result);
        }

        if (key === SETTING_TARGET.SITE) {
          return getSiteSettingPromise(siteId, filteredParams);
        }

        return getDeviceSettingPromise(siteId, filteredParams, target);
      });

    const getSettingPromiseList = (
      fieldListByTarget: Dictionary<childFieldTargetInfo[]>
    ): (() => Promise<ConfigurationsResponse>)[] => {
      if (isNil(siteId.value)) return [];

      const params = getParam();

      if (isSolarConfig) {
        return [getSiteSettingPromise(siteId.value, params)];
      }

      return getSettingPromiseListBySettingTarget(siteId.value, fieldListByTarget, params);
    };

    const processPostConfig = withLoading(async () => {
      const fieldListByTarget = groupBy(fieldList, 'type');
      const settingPromiseList = getSettingPromiseList(fieldListByTarget);
      try {
        const settingResult = await Promise.allSettled(settingPromiseList.map((settingPromise) => settingPromise()));

        const isAllSuccess = settingResult.every(
          (result) => result.status === PROMISE_SETTLED_STATUS.FULFILLED && result.value.status === STATUS_CODE.OK
        );

        if (isAllSuccess) {
          successModal.open();
          state.editable = false;
          if (state.data.energy_policy !== Number(ENERGY_POLICY.ZERO_EXPORT)) {
            prevFeedInLimitWatt.value = state.data.pv_feed_in_limit_w;
          }

          return;
        }

        settingResult.forEach((result) => {
          if (result.status === PROMISE_SETTLED_STATUS.FULFILLED && result.value.status !== STATUS_CODE.OK) {
            settingErrorMessageBox(Number(result.value.errorCode), result.value.message, true);
          }
        });
      } catch (error: unknown) {
        failModal.open();

        return;
      }
    });

    const onConfirm = async () => {
      state.confirmPopup.on = false;
      confirmModal.patchOptions({
        attrs: {
          onConfirm: () => {
            processPostConfig();

            confirmModal.close();
          },
        },
      });
      confirmModal.open();
    };

    const onEdit = async () => {
      const isConnected = await checkConnectionStatus(masterDeviceInfo.value);

      if (!isConnected) {
        alertModal.patchOptions({
          attrs: {
            content: t('message.modify_when_disconnected'),
          },
        });

        alertModal.open();

        return;
      }

      copyData = cloneDeep(state.data);
      state.editable = true;

      schema.value = getSchema(
        getBasicSettingACSystemConfig(t, state.data, isBackfeedLimitDeactivated.value, isGridSupportConfig)
      ).required();
      rerender();
    };

    const onCancel = (handleReset?: () => void) => {
      state.data = copyData;
      state.editable = false;
      if (handleReset) handleReset();
      emit('cancel');
    };

    const goToList = () => {
      emit('goToList');
    };

    const onChange = (valueKey: string, targetValue?: string | number | boolean) => {
      if (state.editable) {
        if (!isNull(targetValue)) {
          // 변경 항목 key set에 저장
          state.changedValueSet.add(valueKey);
        } else {
          // 변경 항목 key set에서 제거
          state.changedValueSet.delete(valueKey);
        }
      } else {
        // cancel 버튼 클릭 시
        state.changedValueSet = new Set();
      }
    };

    // @TODO advancedSettingsUtil 에도 동일한 function이 있는데 type이 달라서 추후에 통합할 예정
    const getSchema = (settingConfig: ACSystemSettingGroup[]) => {
      const shape = settingConfig.reduce((acc, group) => {
        const groupShape = group.children.reduce((groupAcc, item) => {
          if (['text', 'number', 'selector', 'toggle'].includes(item.type) && item.schema) {
            return { ...groupAcc, [item.code]: item.schema() };
          }

          return groupAcc;
        }, {});

        return { ...acc, ...groupShape };
      }, {});

      return yup.object().shape(shape);
    };

    const rerender = () => {
      isInit.value = false;
      nextTick(() => {
        isInit.value = true;
      });
    };

    const { isCase5 } = useACDeviceType();

    const setCombinerSettingInfo = (acCombinerSettingInfo: ACSystemBasicSettings) => {
      state.data = {
        ...state.data,
        msp_busbar_protection_flag: acCombinerSettingInfo.msp_busbar_protection_flag,
        msp_msp_breaker_rating: acCombinerSettingInfo.msp_msp_breaker_rating,
        msp_msp_busbar_rating: acCombinerSettingInfo.msp_msp_busbar_rating,
        msp_der_breaker_rating: acCombinerSettingInfo.msp_der_breaker_rating,
        msp_pv_breaker_rating: acCombinerSettingInfo.msp_pv_breaker_rating,
        msp_ess_breaker_rating: acCombinerSettingInfo.msp_ess_breaker_rating,
        accb_consumption_ct_installed_point: acCombinerSettingInfo.accb_consumption_ct_installed_point,
        accb_extended_production_ct_flag: acCombinerSettingInfo.accb_extended_production_ct_flag,
        accb_polarity_reverse_production_ct: acCombinerSettingInfo.accb_polarity_reverse_production_ct,
        accb_polarity_reverse_ext_production_ct: acCombinerSettingInfo.accb_polarity_reverse_ext_production_ct,
        accb_polarity_reverse_consumption_l1_ct: acCombinerSettingInfo.accb_polarity_reverse_consumption_l1_ct,
        accb_polarity_reverse_consumption_l2_ct: acCombinerSettingInfo.accb_polarity_reverse_consumption_l2_ct,
      };
    };

    const setACCoupledPrimarySettingInfo = (acCoupledPrimarySettingInfo: ACSystemBasicSettings) => {
      state.data = {
        ...state.data,
        battery_backup_soc: acCoupledPrimarySettingInfo.battery_backup_soc,
        pv_inverter_nameplate_power: acCoupledPrimarySettingInfo.pv_inverter_nameplate_power,
        site_info_backup_config_type: acCoupledPrimarySettingInfo.site_info_backup_config_type,
      };

      if (isBackupConfig) {
        state.data = {
          ...state.data,
          msp_busbar_protection_flag: acCoupledPrimarySettingInfo.msp_busbar_protection_flag,
          msp_msp_breaker_rating: acCoupledPrimarySettingInfo.msp_msp_breaker_rating,
          msp_msp_busbar_rating: acCoupledPrimarySettingInfo.msp_msp_busbar_rating,
          msp_der_breaker_rating: acCoupledPrimarySettingInfo.msp_der_breaker_rating,
        };
      }

      /** Case5의 경우 ESS에서 MSP 제어 */
      if (isCase5) {
        state.data = {
          ...state.data,
          msp_busbar_protection_flag: acCoupledPrimarySettingInfo.msp_busbar_protection_flag,
          msp_msp_breaker_rating: acCoupledPrimarySettingInfo.msp_msp_breaker_rating,
          msp_msp_busbar_rating: acCoupledPrimarySettingInfo.msp_msp_busbar_rating,
          msp_der_breaker_rating: acCoupledPrimarySettingInfo.msp_der_breaker_rating,
          msp_pv_breaker_rating: acCoupledPrimarySettingInfo.msp_pv_breaker_rating,
          msp_ess_breaker_rating: acCoupledPrimarySettingInfo.msp_ess_breaker_rating,
        };
      }
    };

    const setSiteSettingInfoBySolarConfig = (acCombinerSettingInfo: ACSystemBasicSettings) => {
      state.data = {
        ...state.data,
        pv_feed_in_limit_flag: acCombinerSettingInfo.pv_feed_in_limit_flag,
        pv_feed_in_limit_w: acCombinerSettingInfo.pv_feed_in_limit_w,
        energy_policy: acCombinerSettingInfo.energy_policy,
        version: acCombinerSettingInfo.version,
      };
    };

    const setSiteSettingInfoByOthers = (acCoupledPrimarySettingInfo: ACSystemBasicSettings) => {
      state.data = {
        ...state.data,
        pv_feed_in_limit_flag: acCoupledPrimarySettingInfo.pv_feed_in_limit_flag,
        pv_feed_in_limit_w: acCoupledPrimarySettingInfo.pv_feed_in_limit_w,
        energy_policy: acCoupledPrimarySettingInfo.energy_policy,
        version: acCoupledPrimarySettingInfo.version,
      };
    };

    const getSettingInfo = async (ACCombinerId: string | null, ACCoupledPrimaryId: string | null) => {
      try {
        startLoading();
        let ACCombinerSettingInfo: ACSystemBasicSettings = { ...initACSystemBasicSettingsValue };

        let ACCoupledPrimarySettingInfo: ACSystemBasicSettings = {
          ...initACSystemBasicSettingsValue,
          battery_backup_soc: 0,
        };

        if (ACCombinerId && hasACCombiner) {
          ACCombinerSettingInfo = await deviceService.getSettingInfoByDeviceId(ACCombinerId);
          setCombinerSettingInfo(ACCombinerSettingInfo);
        }

        if (ACCoupledPrimaryId && (isBackupConfig || isGridSupportConfig)) {
          ACCoupledPrimarySettingInfo = await deviceService.getSettingInfoByDeviceId(ACCoupledPrimaryId);
          setACCoupledPrimarySettingInfo(ACCoupledPrimarySettingInfo);
        }

        if (isSolarConfig) {
          setSiteSettingInfoBySolarConfig(ACCombinerSettingInfo);
        } else {
          setSiteSettingInfoByOthers(ACCoupledPrimarySettingInfo);
        }
      } catch (error) {
        console.log(error);
      } finally {
        stopLoading();
      }
    };

    onBeforeMount(async () => {
      await getSettingInfo(ACCombinerId, getPrimaryESSId());
    });

    onBeforeUpdate(() => {
      nextTick(() => {
        settingTableRerender('basic-tb');
      });
    });

    onMounted(() => {
      settingTableRerender('basic-tb');
      if (state.data.energy_policy === Number(ENERGY_POLICY.ZERO_EXPORT)) {
        enableEditFeedInLimit.value = false;
      }
      setIsDisableBackfeedLimitSettings();
    });

    return {
      state,
      acSystemComputedRange,
      schema,
      titleWidth: '50%',
      isInit,
      isVersionAbove4,
      consumptionCTOptionList,
      MPUAvoidanceOptionList,
      isBackupConfig,
      BANNER_TYPE,
      CS_CONTACT_NUMBER,
      isBackfeedLimitDeactivated,
      enableEditFeedInLimit,
      prevFeedInLimitWatt,
      hasACCombiner,
      isSolarConfig,
      isGridSupportConfig,
      DERBreakerRatingMax,
      isDisableMSPBusbarRating,
      isDisableDERBreakerRating,
      isDisablePVBreakerRating,
      isDisableESSBreakerRating,
      isDisableApplyButton,
      INDENT_DEPTH,
      backupTypeOptionList,
      isCase4,
      isPossibleToEditMPUAvoidance,
      UNIT,
      INPUT_TYPE,
      busbarRatingMin,
      onSave,
      onEdit,
      onCancel,
      goToList,
      onChange,
      onConfirm,
      onChangeMSPBreakerRating,
      onBlurMSPBreakerRating,
      onChangeMSPBusbarRating,
      onBlurMSPBusbarRating,
      onChangeDERBreakerRating,
      onBlurDERBreakerRating,
      onChangeMSPPVBreakerRating,
      onBlurMSPPVBreakerRating,
      setIsDisableBackfeedLimitSettings,
    };
  },
});
