
import { defineComponent, onMounted, onUnmounted } from 'vue';

import { useLoading } from '@hems/util';
export default defineComponent({
  name: 'PopupWrapper',
  inheritAttrs: false,
  setup() {
    let scrollTop = 0;

    const { resetLoading } = useLoading();

    resetLoading();

    onMounted(() => {
      const body = document.querySelector('body');
      scrollTop = window.scrollY;
      if (body) {
        body.classList.add('open-modal');
      }
      window.scrollTo({ top: scrollTop });
    });

    onUnmounted(() => {
      const body = document.querySelector('body');
      if (body) {
        body.classList.remove('open-modal');
        window.scrollTo({ top: scrollTop });
      }
    });
  },
});
