import { computed, defineComponent, ref } from 'vue';
import { Vue3Lottie } from 'vue3-lottie';
import { useCheckMobile, useLoading } from '@hems/util';
const isLoadingOld = ref(false);
export const withLoading = (apiFunc, close = true) => async (...args) => {
    isLoadingOld.value = true;
    try {
        await apiFunc(...args);
    }
    catch (e) {
        if (close)
            isLoadingOld.value = false;
        throw e;
    }
    if (close)
        isLoadingOld.value = false;
};
export default defineComponent({
    name: 'Loading',
    components: {
        Vue3Lottie,
    },
    props: {
        active: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const { isLoading } = useLoading();
        const { isMobile } = useCheckMobile();
        const lottieName = isMobile ? 'mobile_loading' : 'loading';
        return {
            isLoadingOld: computed(() => isLoadingOld),
            lottieName,
            isLoading,
        };
    },
});
