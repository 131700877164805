
import { defineComponent, onBeforeUnmount, reactive, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';

import { Panel, BasicCheckbox } from '@hems/component';
import { useDownloadExcel } from '@hems/component/src/home/downloadExcel';
import DevicePieChartContainer from '@hems/container/src/highcharts/piechart/DevicePieChartContainer.vue';
import { StatisticsService } from '@hems/service';
import { useLoading } from '@hems/util/src/composable/loading';
import { ONE_MINUTE_VALUE_IN_MILLISECONDS } from '@hems/util/src/constant';
import { formatNumber } from '@hems/util/src/helper/helper';

import type { DevicePieChartRouteQuery } from 'hems/dashboard';
import type { DeviceCountByStatus } from 'hems/device';

export default defineComponent({
  name: 'SiteOverview',
  components: {
    Panel,
    DevicePieChartContainer,
    BasicCheckbox,
  },
  setup() {
    const { downloadExcel } = useDownloadExcel();
    const router = useRouter();
    const { startLoading, stopLoading } = useLoading();

    const statisticsService = new StatisticsService(window.axiosInstance.axios);

    const checkState = reactive<{
      complete: boolean;
      inComplete: boolean;
    }>({
      complete: true,
      inComplete: true,
    });

    const siteOverviewCountInfo = ref<{
      totalSiteCount: string | null;
      onlineCount: string | null;
      onlineDisconnectedCount: string | null;
      offlineCount: string | null;
      inCompleteCount: string | null;
      installCompletedSiteCount: string | null;
      installInCompletedSiteCount: string | null;
    } | null>(null);

    const chartData = ref<DeviceCountByStatus | null>(null);

    const lastWeekInstalledSiteCount = ref<string | null>(null);

    const getDashboardChartData = async (useLoading = false) => {
      try {
        useLoading && startLoading();

        const data = await statisticsService.getDeviceCount();

        chartData.value = data;

        const {
          totSiteCnt = 0,
          onRunCnt = 0,
          onWarnCnt = 0,
          onErrCnt = 0,
          disconRunCnt = 0,
          disconWarnCnt = 0,
          disconErrCnt = 0,
          offlineCnt = 0,
          stdCnt = 0,
          sleepCnt = 0,
        } = chartData.value;

        const onlineCount = onRunCnt + onWarnCnt + onErrCnt;
        const onlineDisconnectedCount = disconRunCnt + disconWarnCnt + disconErrCnt;

        siteOverviewCountInfo.value = {
          totalSiteCount: formatNumber(totSiteCnt || 0),
          onlineCount: formatNumber(onlineCount),
          onlineDisconnectedCount: formatNumber(onlineDisconnectedCount),
          offlineCount: formatNumber(offlineCnt),
          inCompleteCount: formatNumber(stdCnt + sleepCnt),
          installCompletedSiteCount: formatNumber(onlineCount + onlineDisconnectedCount + offlineCnt),
          installInCompletedSiteCount: formatNumber(stdCnt + sleepCnt),
        };
      } catch (e) {
        console.error(e);
      } finally {
        useLoading && stopLoading();
      }
    };

    const getLastWeekInstalledSite = async (useLoading = false) => {
      try {
        useLoading && startLoading();

        const data = await statisticsService.getLastWeekInstalledDeviceCount();

        lastWeekInstalledSiteCount.value = formatNumber(data.rct_instl_site_cnt || 0);
      } catch (e) {
        console.error(e);
      } finally {
        useLoading && stopLoading();
      }
    };

    const loadData = (useLoading = false) => {
      getDashboardChartData(useLoading);
      getLastWeekInstalledSite(useLoading);
    };

    const goMonitoringListPage = (query: DevicePieChartRouteQuery) => {
      router.push({
        name: 'MonitoringList',
        query,
      });
    };

    const interval = setInterval(() => {
      loadData();
    }, ONE_MINUTE_VALUE_IN_MILLISECONDS);

    onMounted(() => {
      window.scrollTo({ top: 0 });
    });

    onBeforeUnmount(() => {
      clearInterval(interval);
    });

    loadData(true);

    return {
      checkState,
      siteOverviewCountInfo,
      lastWeekInstalledSiteCount,
      chartData,
      goMonitoringListPage,
      downloadExcel,
    };
  },
});
