import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, Suspense as _Suspense, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4324bb12"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tab-conts" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "title_2" }
const _hoisted_5 = { class: "btn" }
const _hoisted_6 = { class: "grid" }
const _hoisted_7 = { class: "inner_container" }
const _hoisted_8 = ["href"]
const _hoisted_9 = { class: "txt" }
const _hoisted_10 = {
  key: 0,
  class: "btn_container"
}
const _hoisted_11 = ["onClick"]
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { key: 3 }
const _hoisted_14 = { class: "title_2" }
const _hoisted_15 = { class: "btn" }
const _hoisted_16 = { class: "grid" }
const _hoisted_17 = { class: "inner_container" }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = { class: "codebox" }
const _hoisted_20 = { class: "txt" }
const _hoisted_21 = { class: "codetext" }
const _hoisted_22 = {
  key: 0,
  class: "btn_container"
}
const _hoisted_23 = ["onClick"]
const _hoisted_24 = { key: 1 }
const _hoisted_25 = { style: {"margin-top":"30px"} }
const _hoisted_26 = { class: "scroll" }
const _hoisted_27 = { class: "grid" }
const _hoisted_28 = { class: "inner_container" }
const _hoisted_29 = ["onClick"]
const _hoisted_30 = { class: "codebox" }
const _hoisted_31 = { class: "txt" }
const _hoisted_32 = { class: "codetext" }
const _hoisted_33 = {
  key: 1,
  class: "btn_container"
}
const _hoisted_34 = ["onClick"]
const _hoisted_35 = { class: "inner_container" }
const _hoisted_36 = ["href"]
const _hoisted_37 = { class: "txt" }
const _hoisted_38 = {
  key: 1,
  class: "btn_container"
}
const _hoisted_39 = ["onClick"]
const _hoisted_40 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image2 = _resolveComponent("Image2")!
  const _component_GuideWrapper = _resolveComponent("GuideWrapper")!
  const _component_ListSize = _resolveComponent("ListSize")!
  const _component_Pagenation = _resolveComponent("Pagenation")!
  const _component_SupportDetailPopup = _resolveComponent("SupportDetailPopup")!
  const _component_EventDetailPopup = _resolveComponent("EventDetailPopup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isNull(_ctx.condition.searchFor))
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_ctx.tabName === _ctx.SUPPORT_GUIDE_TYPE.ALL)
              ? (_openBlock(), _createElementBlock("dt", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_Image2, {
                      src: "svg/ic_quick_guide",
                      extension: "svg"
                    }),
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('common.guide')), 1),
                      (_ctx.isAllowedEdit)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 0,
                            type: "button",
                            class: "btn_t btn_c_3",
                            name: "guideAddBtn",
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onCreate(_ctx.GUIDE_TYPE.GUIDE)))
                          }, _toDisplayString(_ctx.$t('common.add')), 1))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allGuideData, (data) => {
                      return (_openBlock(), _createElementBlock("dd", {
                        key: data.supportId,
                        class: "support_ipt"
                      }, [
                        _createElementVNode("div", _hoisted_7, [
                          _createElementVNode("a", {
                            href: data.pdfFileUrl,
                            target: "_blank",
                            class: "doc"
                          }, [
                            _createVNode(_component_Image2, {
                              src: "svg/ic_pdf",
                              extension: "svg"
                            }),
                            _createElementVNode("span", _hoisted_9, _toDisplayString(data.pdfFileName), 1)
                          ], 8, _hoisted_8),
                          (_ctx.isAllowedEdit)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                _createElementVNode("button", {
                                  class: "admin_btn",
                                  type: "button",
                                  name: "guideModifyBtn",
                                  onClick: ($event: any) => (_ctx.onEdit(data.supportId))
                                }, [
                                  _createVNode(_component_Image2, {
                                    src: "svg/icon-edit",
                                    extension: "svg",
                                    width: "20",
                                    height: "20"
                                  })
                                ], 8, _hoisted_11),
                                _createElementVNode("button", {
                                  class: "admin_btn",
                                  type: "button",
                                  name: "guideDeleteBtn",
                                  onClick: ($event: any) => (_ctx.onDelete(data.supportId))
                                }, [
                                  _createVNode(_component_Image2, {
                                    src: "temp/ico-module-delete",
                                    extension: "svg",
                                    width: "20",
                                    height: "20"
                                  })
                                ], 8, _hoisted_12)
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ]))
                    }), 128))
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.tabName == _ctx.SUPPORT_GUIDE_TYPE.HARDWARE_GUIDE)
              ? (_openBlock(), _createBlock(_component_GuideWrapper, {
                  key: 1,
                  subtitle: _ctx.tabName,
                  "hardware-data": _ctx.hardwareData,
                  role: _ctx.roleName,
                  onCreate: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onCreate(_ctx.GUIDE_TYPE.GUIDE))),
                  onEdit: _cache[2] || (_cache[2] = (supportId) => _ctx.onEdit(supportId)),
                  onDelete: _cache[3] || (_cache[3] = (supportId) => _ctx.onDelete(supportId))
                }, null, 8, ["subtitle", "hardware-data", "role"]))
              : _createCommentVNode("", true),
            (_ctx.tabName == _ctx.SUPPORT_GUIDE_TYPE.SOFTWARE_GUIDE)
              ? (_openBlock(), _createBlock(_component_GuideWrapper, {
                  key: 2,
                  subtitle: _ctx.tabName,
                  "software-data": _ctx.softwareData,
                  role: _ctx.roleName,
                  onCreate: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onCreate(_ctx.GUIDE_TYPE.GUIDE))),
                  onEdit: _cache[5] || (_cache[5] = (supportId) => _ctx.onEdit(supportId)),
                  onDelete: _cache[6] || (_cache[6] = (supportId) => _ctx.onDelete(supportId))
                }, null, 8, ["subtitle", "software-data", "role"]))
              : _createCommentVNode("", true),
            (_ctx.tabName == _ctx.SUPPORT_GUIDE_TYPE.ERROR_GUIDE)
              ? (_openBlock(), _createElementBlock("dt", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, [
                    _createVNode(_component_Image2, {
                      src: "svg/ic_error_code",
                      extension: "svg"
                    }),
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('device.err_code')), 1),
                      (_ctx.isAllowedEdit)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 0,
                            type: "button",
                            class: "btn_t btn_c_3",
                            name: "eventcodeAddBtn",
                            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.onCreate(_ctx.GUIDE_TYPE.ERROR_CODE)))
                          }, _toDisplayString(_ctx.$t('common.add')), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createVNode(_component_ListSize, {
                      modelValue: _ctx.condition.listSize,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.condition.listSize) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_16, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.eventcodeData, (data) => {
                      return (_openBlock(), _createElementBlock("dd", {
                        key: data.alarmCd,
                        class: "support_ipt"
                      }, [
                        _createElementVNode("div", _hoisted_17, [
                          _createElementVNode("button", {
                            class: "doc",
                            onClick: ($event: any) => (_ctx.onDetail(data.alarmCd))
                          }, [
                            _createElementVNode("div", _hoisted_19, _toDisplayString(data.alarmNm), 1),
                            _createElementVNode("span", _hoisted_20, _toDisplayString(data.alarmDesc), 1),
                            _createElementVNode("span", _hoisted_21, " (" + _toDisplayString(_ctx.$t('device.err_code')) + " : " + _toDisplayString(data.alarmCd) + ")", 1)
                          ], 8, _hoisted_18),
                          (_ctx.isAllowedEdit)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                                _createElementVNode("button", {
                                  class: "admin_btn",
                                  type: "button",
                                  name: "guideDeleteBtn",
                                  onClick: ($event: any) => (_ctx.onDelete(data.alarmCd))
                                }, [
                                  _createVNode(_component_Image2, {
                                    src: "temp/ico-module-delete",
                                    extension: "svg",
                                    width: "20",
                                    height: "20"
                                  })
                                ], 8, _hoisted_23)
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ]))
                    }), 128))
                  ]),
                  _createVNode(_component_Pagenation, {
                    modelValue: _ctx.condition.pageNum,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.condition.pageNum) = $event)),
                    "row-count-per-page": _ctx.condition.listSize,
                    "total-row-count": _ctx.totalCount
                  }, null, 8, ["modelValue", "row-count-per-page", "total-row-count"])
                ]))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_24, [
            _createElementVNode("dt", _hoisted_25, [
              _createElementVNode("div", _hoisted_26, [
                _createElementVNode("div", _hoisted_27, [
                  (_ctx.tabName === _ctx.SUPPORT_GUIDE_TYPE.ERROR_GUIDE)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.eventcodeData, (data) => {
                        return (_openBlock(), _createElementBlock("dd", {
                          key: data.alarmCd,
                          class: "support_ipt"
                        }, [
                          _createElementVNode("div", _hoisted_28, [
                            (data.alarmNm)
                              ? (_openBlock(), _createElementBlock("button", {
                                  key: 0,
                                  class: "doc",
                                  onClick: ($event: any) => (_ctx.onDetail(data.alarmCd))
                                }, [
                                  _createElementVNode("div", _hoisted_30, _toDisplayString(data.alarmNm), 1),
                                  _createElementVNode("span", _hoisted_31, _toDisplayString(data.alarmDesc), 1),
                                  _createElementVNode("span", _hoisted_32, " (" + _toDisplayString(_ctx.$t('device.err_code')) + " : " + _toDisplayString(data.alarmCd) + ")", 1)
                                ], 8, _hoisted_29))
                              : _createCommentVNode("", true),
                            (_ctx.isAllowedEdit)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                                  _createElementVNode("button", {
                                    class: "admin_btn",
                                    type: "button",
                                    name: "guideDeleteBtn",
                                    onClick: ($event: any) => (_ctx.onDelete(data.alarmCd))
                                  }, [
                                    _createVNode(_component_Image2, {
                                      src: "temp/ico-module-delete",
                                      extension: "svg",
                                      width: "20",
                                      height: "20"
                                    })
                                  ], 8, _hoisted_34)
                                ]))
                              : _createCommentVNode("", true)
                          ])
                        ]))
                      }), 128))
                    : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.searchedGuideData, (data) => {
                        return (_openBlock(), _createElementBlock("dd", {
                          key: data.supportId,
                          class: "support_ipt"
                        }, [
                          _createElementVNode("div", _hoisted_35, [
                            (data.pdfFileUrl)
                              ? (_openBlock(), _createElementBlock("a", {
                                  key: 0,
                                  href: data.pdfFileUrl,
                                  target: "_blank",
                                  class: "doc"
                                }, [
                                  _createVNode(_component_Image2, {
                                    src: "svg/ic_pdf",
                                    extension: "svg"
                                  }),
                                  _createElementVNode("span", _hoisted_37, _toDisplayString(data.pdfFileName), 1)
                                ], 8, _hoisted_36))
                              : _createCommentVNode("", true),
                            (_ctx.isAllowedEdit)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                                  (data.pdfFileUrl)
                                    ? (_openBlock(), _createElementBlock("button", {
                                        key: 0,
                                        class: "admin_btn",
                                        type: "button",
                                        name: "guideModifyBtn",
                                        onClick: ($event: any) => (_ctx.onEdit(data.supportId))
                                      }, [
                                        _createVNode(_component_Image2, {
                                          src: "svg/icon-edit",
                                          extension: "svg",
                                          width: "20",
                                          height: "20"
                                        })
                                      ], 8, _hoisted_39))
                                    : _createCommentVNode("", true),
                                  _createElementVNode("button", {
                                    class: "admin_btn",
                                    type: "button",
                                    name: "guideDeleteBtn",
                                    onClick: ($event: any) => (_ctx.onDelete(data.supportId))
                                  }, [
                                    _createVNode(_component_Image2, {
                                      src: "temp/ico-module-delete",
                                      extension: "svg",
                                      width: "20",
                                      height: "20"
                                    })
                                  ], 8, _hoisted_40)
                                ]))
                              : _createCommentVNode("", true)
                          ])
                        ]))
                      }), 128))
                ])
              ])
            ])
          ]))
    ]),
    (_openBlock(), _createBlock(_Suspense, null, {
      default: _withCtx(() => [
        (_ctx.guidePopup)
          ? (_openBlock(), _createBlock(_component_SupportDetailPopup, {
              key: 0,
              params: _ctx.supportId,
              onConfirm: _ctx.onConfirm,
              onClose: _ctx.onClose,
              onLoad: _cache[10] || (_cache[10] = ($event: any) => (_ctx.loadData()))
            }, null, 8, ["params", "onConfirm", "onClose"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })),
    (_openBlock(), _createBlock(_Suspense, null, {
      default: _withCtx(() => [
        (_ctx.eventPopup)
          ? (_openBlock(), _createBlock(_component_EventDetailPopup, {
              key: 0,
              editable: true,
              params: _ctx.errorCodeId,
              role: _ctx.roleName,
              onConfirm: _ctx.onConfirm,
              onClose: _ctx.onClose
            }, null, 8, ["params", "role", "onConfirm", "onClose"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }))
  ], 64))
}