import moment from 'moment';
import { GENERAL_DATE_FORMAT } from '@hems/util/src/constant';
export const getTimeStamp = () => moment().format(GENERAL_DATE_FORMAT);
export const getCommonResponseData = (data, path, options = {}) => {
    return {
        code: 200,
        status: 'OK',
        message: 'Success.',
        timestamp: getTimeStamp(),
        path,
        data,
        ...options,
    };
};
export const getListResponseData = (body, context) => {
    return {
        context,
        body,
    };
};
export const getPagingData = (listData, pageNumber, listSize) => {
    const startIndex = listSize * (pageNumber - 1);
    const endIndex = startIndex + listSize;
    return listData.slice(startIndex, endIndex);
};
