
import type { PropType } from 'vue';
import { defineComponent, computed } from 'vue';

import { Image2 } from '@hems/component';
import {
  getNetworkConnectionStatusTextKey,
  getNetworkImagePath,
} from '@hems/container/src/siteEnergy/flow/EnergyFlowFunctions';
import { useNetworkStatus } from '@hems/util';
import type { NetworkType } from '@hems/util/src/constant';
import { NETWORK_TYPE } from '@hems/util/src/constant';

export default defineComponent({
  name: 'NetworkStatus',
  components: {
    Image2,
  },
  props: {
    networkStatus: {
      type: Number as PropType<NetworkType | null>,
      default: NETWORK_TYPE.UNKNOWN,
    },
    disconnect: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const { getNetworkStatusText } = useNetworkStatus();

    const networkStatus = computed(() => props.networkStatus ?? NETWORK_TYPE.UNKNOWN);
    const networkStatusText = computed(() => getNetworkStatusText(networkStatus.value));
    const networkConnectionStatusTextKey = computed(() =>
      getNetworkConnectionStatusTextKey(networkStatus.value, props.disconnect)
    );
    const networkImagePath = computed(() => getNetworkImagePath(networkStatus.value));

    return {
      NETWORK_TYPE,
      networkStatusText,
      networkConnectionStatusTextKey,
      networkImagePath,
    };
  },
});
