/* eslint-disable import/prefer-default-export */
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useLocale } from '@hems/util/src/composable/locale';
import { DEFAULT_LANGUAGE, ENV_LOCALE, EUAULanguageOptions, LANGUAGE, USLanguageOptions, } from '@hems/util/src/constant';
export function useLanguage() {
    const store = useStore();
    const languageCode = computed(() => store.state.appCtx.langCd ?? DEFAULT_LANGUAGE);
    const { locale } = useLocale();
    const languageOptionsForManagement = computed(() => {
        switch (locale) {
            case ENV_LOCALE.US:
                return USLanguageOptions;
            case ENV_LOCALE.AU:
            case ENV_LOCALE.NZ:
                return EUAULanguageOptions.filter((lang) => lang.value === LANGUAGE.EN);
            default:
                return EUAULanguageOptions;
        }
    });
    const setLanguage = (language) => {
        store.dispatch('appCtx/setLangCd', language);
    };
    const setIntlLocale = () => {
        store.dispatch('appCtx/setLocale');
    };
    return { languageCode, languageOptionsForManagement, setLanguage, setIntlLocale };
}
