import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-72ab8fcb"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "right"
}
const _hoisted_2 = { class: "number" }
const _hoisted_3 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isConsumptionCT)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(`${_ctx.formattedData.grid?.pw.formattedNumber} ${_ctx.formattedData.grid?.pw.unit}`), 1),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.formattedData.grid?.status), 1)
      ]))
    : _createCommentVNode("", true)
}