import { defineComponent, onBeforeUnmount, onMounted, ref } from 'vue';
import { useIntervalFn } from '@vueuse/core';
import axios from 'axios';
import EventSource from 'eventsource';
import isNil from 'lodash-es/isNil';
import { withLoading } from '@hems/component';
import { CommonService, AuthService } from '@hems/service';
import DeviceService from '@hems/service/src/api/device/DeviceService';
import { useACDeviceType, useSiteId, useSiteMasterDeviceInfo } from '@hems/util';
import { FIFTY_SECONDS_VALUE_IN_MILLISECONDS, FIVE_MINUTES_VALUE_IN_MILLISECONDS, NETWORK_TYPE, TEN_SECONDS_VALUE_IN_MILLISECONDS, } from '@hems/util/src/constant';
import { NETWORK_TYPE_CODE } from '@hems/util/src/constant/network';
import { isExpired, parseJwt } from '@hems/util/src/helper/authHelper';
import { isJSON } from '@hems/util/src/helper/helper';
import { DEFAULT_SITE_ENERGY_DATA, formatToZeroByRange } from '@hems/util/src/helper/siteEnergyHelper';
// FIXME: 절대 경로로 변경 시 node_modules 내의 경로로 찾는 문제
import EnergyFlowTimeoutPopup from './EnergyFlowTimeoutPopup.vue';
import SiteEnergyFlowContainer from './flow/SiteEnergyFlowContainer.vue';
import SiteEnergyValueContainer from './value/SiteEnergyValueContainer.vue';
const REALTIME_URL = process.env.VUE_APP_REALTIME_API_URL;
export default defineComponent({
    name: 'DashboardEnergyValueFlowContainer',
    components: {
        SiteEnergyValueContainer,
        SiteEnergyFlowContainer,
        EnergyFlowTimeoutPopup,
    },
    setup() {
        const { siteId } = useSiteId();
        const daqAuthApiAxiosInstance = axios.create({
            baseURL: process.env.VUE_APP_DAQ_API_URL,
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                username: siteId.value,
                password: process.env.VUE_APP_DAQ_API_AUTH_PASSWORD,
            },
        });
        const commonService = new CommonService(window.axiosInstance.axios);
        const deviceService = new DeviceService(window.axiosInstance.axios);
        const authService = new AuthService(daqAuthApiAxiosInstance);
        const { isThirdPartyCase } = useACDeviceType();
        const isInit = ref(false);
        const disconnect = ref(false);
        const serverSentEventSource = ref(null);
        const isRealtimeConnected = ref(false);
        const isConnectThirdParty = ref(false);
        const realtimeApiAuthToken = ref('');
        const isEnergyFlowTimeoutPopup = ref(false);
        const isCellular = ref(false);
        const { masterDeviceId, isConsumptionCT } = useSiteMasterDeviceInfo();
        const transformRealtimeMonitoringData = (data) => {
            const { grid_status = true, load_main_pw = 0, load_sub_pw = 0, pv_pw = 0, ext_pv_pw = 0, bt_pw = 0, grid_pw = 0, generator_pw = 0, bt_soc = 0, } = data;
            return {
                eventTime: data.time,
                energyControl: Number(data.ems_opmode),
                pvFault: data.pv_fault,
                batteryFault: data.storage_fault,
                gridFault: data.grid_fault,
                batteryUserSoc: bt_soc,
                batteryRealSoc: bt_soc,
                consumptionPower: formatToZeroByRange(load_main_pw + load_sub_pw),
                loadPower: formatToZeroByRange(load_main_pw),
                coreLoadPower: formatToZeroByRange(load_sub_pw),
                batteryPower: formatToZeroByRange(bt_pw),
                gridPower: grid_status ? formatToZeroByRange(grid_pw) : 0,
                pvPower: formatToZeroByRange(isThirdPartyCase ? ext_pv_pw : pv_pw),
                networkType: data.network_type ?? NETWORK_TYPE.UNKNOWN,
                gridStatus: grid_status,
                generatorPower: formatToZeroByRange(generator_pw),
                externalPvPower: formatToZeroByRange(ext_pv_pw),
            };
        };
        const realtimeData = ref(DEFAULT_SITE_ENERGY_DATA);
        const checkConnection = async () => {
            try {
                const isConnected = await commonService.isConnection(masterDeviceId.value ?? '');
                disconnect.value = !isConnected;
            }
            catch (e) {
                console.error(e);
                return false;
            }
        };
        const checkIsTokenExpired = (token) => {
            const jwtObject = parseJwt(token);
            return isNil(jwtObject) || isExpired(jwtObject);
        };
        const getRealtimeApiAuthToken = async () => {
            if (realtimeApiAuthToken.value) {
                const isExpiredToken = checkIsTokenExpired(realtimeApiAuthToken.value);
                if (!isExpiredToken)
                    return realtimeApiAuthToken.value;
            }
            try {
                const tokenResponse = await authService.getRealtimeApiAuthToken();
                realtimeApiAuthToken.value = tokenResponse.data;
                return tokenResponse.data;
            }
            catch (e) {
                console.error(e);
                realtimeApiAuthToken.value = '';
                return '';
            }
        };
        const getRealtimeMonitoringData = () => {
            if (isNil(serverSentEventSource.value))
                return;
            serverSentEventSource.value.onmessage = (evt) => {
                const data = evt.data;
                if (isJSON(data)) {
                    const monitoringData = JSON.parse(data);
                    if (monitoringData.device_id !== masterDeviceId.value)
                        return;
                    realtimeData.value = transformRealtimeMonitoringData(monitoringData.monitoring_data);
                    if (realtimeData.value.networkType === NETWORK_TYPE_CODE.LTE) {
                        isCellular.value = true;
                    }
                }
            };
            serverSentEventSource.value.onerror = (e) => {
                console.error(e);
                serverSentEventSource.value?.close();
                isRealtimeConnected.value = false;
            };
        };
        const getEnergyFlowData = async () => {
            if (isNil(siteId.value))
                return;
            try {
                await checkConnection();
                if (disconnect.value)
                    return;
                const authToken = await getRealtimeApiAuthToken();
                if (isNil(authToken))
                    return;
                serverSentEventSource.value = new EventSource(`${REALTIME_URL}/${siteId.value}`, {
                    withCredentials: true,
                    headers: {
                        'X-AUTH-TOKEN': authToken,
                    },
                });
                serverSentEventSource.value.onopen = () => {
                    isRealtimeConnected.value = true;
                    getRealtimeMonitoringData();
                };
            }
            catch (e) {
                console.error(e);
            }
        };
        const loadSiteNetworkType = async () => {
            if (isNil(siteId.value))
                return;
            try {
                const { networkType } = await deviceService.getSiteNetworkType(siteId.value);
                isCellular.value = networkType === NETWORK_TYPE_CODE.LTE;
            }
            catch (e) {
                console.error(e);
            }
        };
        const startRealtimeMonitoring = () => {
            if (!isEnergyFlowTimeoutPopup.value) {
                serverSentEventSource.value?.close();
                getEnergyFlowData();
            }
        };
        const { pause: pauseRealtimeMonitoringInterval, resume: resumeRealtimeMonitoringInterval } = useIntervalFn(startRealtimeMonitoring, FIFTY_SECONDS_VALUE_IN_MILLISECONDS);
        const { pause: pauseEnergyFlowTimeout, resume: resumeEnergyFlowTimeout } = useIntervalFn(() => {
            if (!disconnect.value && isCellular.value) {
                isEnergyFlowTimeoutPopup.value = true;
                serverSentEventSource.value?.close();
                isRealtimeConnected.value = false;
                pauseRealtimeMonitoringInterval();
                pauseEnergyFlowTimeout();
            }
        }, FIVE_MINUTES_VALUE_IN_MILLISECONDS + TEN_SECONDS_VALUE_IN_MILLISECONDS);
        const resumeEnergyFlow = () => {
            isEnergyFlowTimeoutPopup.value = false;
            startRealtimeMonitoring();
            resumeRealtimeMonitoringInterval();
            resumeEnergyFlowTimeout();
        };
        onBeforeUnmount(() => {
            serverSentEventSource.value?.close();
        });
        onMounted(withLoading(async () => {
            await loadSiteNetworkType();
            getEnergyFlowData();
            isInit.value = true;
        }));
        return {
            disconnect,
            isRealtimeConnected,
            realtimeData,
            isConnectThirdParty,
            isEnergyFlowTimeoutPopup,
            isConsumptionCT,
            checkConnection,
            resumeEnergyFlow,
        };
    },
});
