import { defineComponent, onErrorCaptured, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import { DotStep, SettingTable, SettingTableRow, BasicInput, useMessageBox } from '@hems/component';
import Image2 from '@hems/component/src/image/Image2.vue';
import { JoinService } from '@hems/service';
import { Helper } from '@hems/util';
import { INPUT_TYPE } from '@hems/util/src/constant/input';
import { ROLE_NAME } from '@hems/util/src/constant/role';
export default defineComponent({
    name: 'Step2RequiredData',
    components: {
        DotStep,
        SettingTable,
        SettingTableRow,
        BasicInput,
        Form,
        Image2,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        social: Object,
    },
    emits: ['next', 'back'],
    setup(props, { emit }) {
        const messageBox = useMessageBox();
        const { t } = useI18n();
        const joinService = new JoinService(window.axiosInstance.axios);
        const state = reactive({
            user_id: props.social?.email ?? props.data.user_id,
            password: props.data.password,
            user_nm: props.social?.name ?? props.data.user_nm,
            mpn_no: props.data.mpn_no,
            email: props.social?.email ?? props.data.email,
            comn_nm: props.data.comn_nm,
            auth_type_cd: props.data.auth_type_cd,
        });
        const stateValidationCheck = reactive({
            checkValidation1: false,
            checkValidation2: false,
            checkValidation3: false,
        });
        const installerGroupOptions = ref([]);
        joinService.getInstallerGroups().then(({ result }) => {
            installerGroupOptions.value = Helper.addSelectorOptionAtFirst(result.map((item) => ({ text: item, value: item })), { text: '', value: null });
        });
        const prevUserId = {
            value: undefined,
            status: false,
        };
        const prevEmail = {
            value: undefined,
            status: false,
        };
        const schema = yup.object().shape({
            user_id: yup.string().nullable().isValidUserId(prevUserId, ROLE_NAME.INSTALLER),
            password: yup.string().when({
                is: () => !props.social,
                then: yup
                    .string()
                    .test('password', { key: '' }, function (password) {
                    const { createError } = this;
                    stateValidationCheck.checkValidation1 = false;
                    stateValidationCheck.checkValidation2 = false;
                    stateValidationCheck.checkValidation3 = false;
                    if (Helper.isNull(password)) {
                        return createError({
                            message: { key: 'message.field_required' },
                        });
                    }
                    const regexp1 = /^.{8,20}$/;
                    const regexp2 = /^(?=.*[a-zA-Z])(?=.*\d).+$/;
                    const regexp3 = /.*[`~!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?].*/;
                    if (regexp1.test(password)) {
                        stateValidationCheck.checkValidation1 = true;
                    }
                    else {
                        stateValidationCheck.checkValidation1 = false;
                    }
                    if (regexp2.test(password)) {
                        stateValidationCheck.checkValidation2 = true;
                    }
                    else {
                        stateValidationCheck.checkValidation2 = false;
                    }
                    if (regexp3.test(password)) {
                        stateValidationCheck.checkValidation3 = true;
                    }
                    else {
                        stateValidationCheck.checkValidation3 = false;
                    }
                    if (stateValidationCheck.checkValidation1 === true &&
                        stateValidationCheck.checkValidation2 === true &&
                        stateValidationCheck.checkValidation3 === true) {
                        return true;
                    }
                    return false;
                })
                    .nullable()
                    .required(),
            }),
            password_confirm: yup
                .string()
                .nullable()
                .test('password_confirm', { key: 'message.invalid' }, function (password_confirm) {
                if (props.social)
                    return true;
                const { createError } = this;
                const password = this.parent.password;
                if (Helper.isNull(password_confirm)) {
                    return createError({
                        message: { key: 'message.field_required' },
                    });
                }
                if (password !== password_confirm) {
                    return createError({
                        message: { key: 'message.password_not_match' },
                    });
                }
                return true;
            }),
            comn_nm: yup.string().min(1).max(30).nullable().required(),
            mpn_no: yup
                .string()
                .test('mpn_no', { key: 'message.invalid_phone_number' }, function (mpn_no) {
                const { createError } = this;
                if (Helper.isNull(mpn_no)) {
                    return createError({
                        message: { key: 'message.field_required' },
                    });
                }
                const regexp = /^[0-9+\-()]{8,20}$/g;
                if (!regexp.test(mpn_no)) {
                    return false;
                }
                return true;
            })
                .nullable()
                .required(),
            email: yup.string().nullable().isValidEmail(prevEmail, ROLE_NAME.INSTALLER),
            user_nm: yup.string().nullable().required().min(2, t('message.too_short')),
        });
        const passwordConfirm = ref(null);
        function onNext() {
            emit('next', 'essentialData', { ...state });
        }
        onErrorCaptured((error, instance) => {
            console.error(error, instance);
            messageBox.alert(t('message.unknown_error')).open();
            return true;
        });
        return {
            state,
            passwordConfirm,
            schema,
            installerGroupOptions,
            onNext,
            stateValidationCheck,
            INPUT_TYPE,
        };
    },
});
