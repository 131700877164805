import { defineComponent, ref } from 'vue';
import { gsap } from 'gsap';
import NavSubItem from '@hems/component/src/navigation/NavSubItem.vue';
export default defineComponent({
    name: 'NavMainItem',
    components: {
        NavSubItem,
    },
    props: {
        navItem: {
            type: Object,
            required: true,
        },
    },
    setup() {
        const subMenuBg = ref();
        const subMenuContainer = ref();
        const isShowSubMenu = ref(false);
        const showMenu = () => {
            isShowSubMenu.value = true;
            gsap.to([subMenuBg.value, subMenuContainer.value], {
                height: '4.375rem',
                duration: 0.4,
            });
        };
        const closeMenu = () => {
            isShowSubMenu.value = false;
            gsap.to([subMenuBg.value, subMenuContainer.value], {
                height: '0',
                duration: 0.4,
            });
        };
        return {
            isShowSubMenu,
            subMenuBg,
            subMenuContainer,
            showMenu,
            closeMenu,
        };
    },
});
