import 'tui-grid/dist/tui-grid.css';
import '@vueform/slider/themes/default.css';
import '@hems/component/resources/vendor/css_new/common.css';
import '@hems/component/resources/vendor/css_new/common_style.css';
import '@hems/component/resources/vendor/css_new/main.css';
import '@hems/component/resources/vendor/css_new/contents.css';
import '@hems/component/resources/styles/customer-web-style.scss';
import '@hems/component/resources/vendor/css_new2021/common_new2021.css';
import '@hems/component/resources/vendor/script/selecter/jquery.fs.selecter.css';
import '@hems/component/resources/styles/main.scss';
import '@imengyu/vue3-context-menu/lib/vue3-context-menu.css';
import 'vue-final-modal/dist/style.css';
import { defineAsyncComponent, defineComponent, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useIdle } from '@vueuse/core';
import { MainTemplate, Header, Navigation, LanguageSelector, LoginUserInfo } from '@hems/component';
import { CommonService } from '@hems/service';
import { AuthHelper, useAccessToken, useLanguage, useLogout } from '@hems/util';
import { useNavigationMenu } from '@hems/util/src/composable/navigationMenu';
import { ONE_HOUR_VALUE_IN_MILLISECONDS } from '@hems/util/src/constant';
import { isRoleCodeType } from '@hems/util/src/helper/tsguardHelper';
export default defineComponent({
    name: 'MainTemplateContainer',
    components: {
        MainTemplate,
        Header,
        Navigation,
        LanguageSelector,
        LoginUserInfo,
        IdleTimePopup: defineAsyncComponent(() => import('@hems/component/src/popup/IdleTimePopup.vue')),
    },
    props: {
        navigationConfig: {
            type: Array,
            default: () => [],
        },
    },
    setup() {
        const commonService = new CommonService(window.axiosInstance.axios);
        const { accessToken } = useAccessToken();
        const { t, locale: i18nLocale } = useI18n();
        const store = useStore();
        const { logout } = useLogout();
        const { idle } = useIdle(ONE_HOUR_VALUE_IN_MILLISECONDS); // 60 min
        const { languageCode, setLanguage, setIntlLocale } = useLanguage();
        const { navigationConfigByRole } = useNavigationMenu();
        const isInit = ref(false);
        const selectedLanguage = ref(languageCode.value);
        const isOpenIdleTimePopup = ref(false);
        const onLogout = () => {
            isOpenIdleTimePopup.value = false;
            logout();
        };
        const onContinue = () => {
            isOpenIdleTimePopup.value = false;
        };
        const changeLanguage = (selectedLanguage) => {
            i18nLocale.value = selectedLanguage;
            setLanguage(selectedLanguage);
            setIntlLocale();
        };
        watch(idle, (idleValue) => {
            if (idleValue) {
                isOpenIdleTimePopup.value = true;
            }
        });
        const init = () => {
            if (!accessToken) {
                alert(t('message.login'));
                location.href = '/';
                return;
            }
            const jwtObject = AuthHelper.parseJwt(accessToken);
            if (!jwtObject) {
                alert(t('message.invalid_token'));
                location.href = '/';
                return;
            }
            const { auth_type_cd: roleCode, preferred_username: preferredUserName } = jwtObject;
            store.dispatch('user/setUserId', preferredUserName);
            if (isRoleCodeType(roleCode)) {
                store
                    .dispatch('user/setRole', {
                    roleCd: roleCode,
                    roleNm: AuthHelper.getRoleNm(roleCode),
                })
                    .then(async () => {
                    store.dispatch('appCtx/setGoogleApiKey', await commonService.getGoogleAPIKey());
                });
            }
            // 초기화
            window.axiosInstance.setAccessToken(accessToken);
            i18nLocale.value = languageCode.value;
            isInit.value = true;
        };
        init();
        return {
            isInit,
            languageCode,
            navigationConfigByRole,
            selectedLanguage,
            isOpenIdleTimePopup,
            onLogout,
            onContinue,
            changeLanguage,
        };
    },
    errorCaptured(error, instance, info) {
        console.error('MainTemplate ErrorCaptured', error, instance, info);
        alert('An unknown error has occurred.');
    },
});
