/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import { AC_COMBINER_STATISTICS, AC_COMBINER_STATISTICS_LIST, BATTERY_STATISTICS, BATTERY_STATISTICS_LIST, ENERGY_HOUR_STATISTICS, ENERGY_HOUR_STATISTICS_LIST, ENERGY_MINUTE_STATISTICS, ENERGY_MINUTE_STATISTICS_LIST, GRAPH_GROUP_CODE, UNIT, GRID_STATISTICS, GRID_STATISTICS_LIST, POWER_STATISTICS, POWER_STATISTICS_LIST, } from '@hems/util/src/constant';
const getPowerFilters = (isSolarConfig) => [
    {
        code: POWER_STATISTICS.ACCB_PV,
        name: 'code.devicetypecd.pv',
        yAxis: UNIT.WATT,
        hidden: !isSolarConfig,
        defaultChecked: true,
    },
    {
        code: POWER_STATISTICS.PV,
        name: 'code.devicetypecd.pv',
        yAxis: UNIT.WATT,
        hidden: isSolarConfig,
        defaultChecked: true,
    },
    {
        code: POWER_STATISTICS.BATTERY,
        name: 'device.battery',
        yAxis: UNIT.WATT,
        hidden: isSolarConfig,
        defaultChecked: true,
    },
    {
        code: POWER_STATISTICS.GRID,
        name: 'device.grid',
        yAxis: UNIT.WATT,
        defaultChecked: true,
    },
    {
        code: POWER_STATISTICS.LOAD,
        name: 'device.load',
        yAxis: UNIT.WATT,
        defaultChecked: true,
    },
];
const getEnergyMinuteFilters = (isSolarConfig, isInstaller) => [
    {
        code: ENERGY_MINUTE_STATISTICS.ACCB_PV_PRODUCED,
        name: 'device.pv_produced',
        yAxis: UNIT.WATT_HOUR,
        hidden: !isSolarConfig,
        defaultChecked: true,
    },
    {
        code: ENERGY_MINUTE_STATISTICS.PV_PRODUCED,
        name: 'device.pv_produced',
        yAxis: UNIT.WATT_HOUR,
        hidden: isSolarConfig,
        defaultChecked: true,
    },
    {
        code: ENERGY_MINUTE_STATISTICS.BATTERY_CHARGED,
        name: 'device.battery_charged',
        yAxis: UNIT.WATT_HOUR,
        hidden: isSolarConfig,
        defaultChecked: true,
    },
    {
        code: ENERGY_MINUTE_STATISTICS.BATTERY_DISCHARGED,
        name: 'device.battery_discharged',
        yAxis: UNIT.WATT_HOUR,
        hidden: isSolarConfig,
        defaultChecked: true,
    },
    {
        code: ENERGY_MINUTE_STATISTICS.GRID_EXPORTED,
        name: 'device.grid_exported',
        yAxis: UNIT.WATT_HOUR,
        hidden: isSolarConfig && isInstaller,
        defaultChecked: true,
    },
    {
        code: ENERGY_MINUTE_STATISTICS.GRID_IMPORTED,
        name: 'device.grid_imported',
        yAxis: UNIT.WATT_HOUR,
        hidden: isSolarConfig && isInstaller,
        defaultChecked: true,
    },
    {
        code: ENERGY_MINUTE_STATISTICS.LOAD_CONSUMED,
        name: 'device.load_consumed',
        yAxis: UNIT.WATT_HOUR,
        defaultChecked: true,
    },
];
const acCombinerFilters = [
    {
        code: AC_COMBINER_STATISTICS.COMBINER_VOLTAGE,
        name: 'device.combiner_voltage',
        unit: UNIT.VOLT,
        yAxis: UNIT.VOLT,
        defaultChecked: true,
    },
    {
        code: AC_COMBINER_STATISTICS.COMBINER_CURRENT,
        name: 'device.combiner_current',
        unit: UNIT.AMPERE,
        yAxis: UNIT.AMPERE,
        defaultChecked: true,
    },
    {
        code: AC_COMBINER_STATISTICS.COMBINER_FREQUENCY,
        name: 'device.combiner_frequency',
        unit: UNIT.HERTZ,
        yAxis: UNIT.HERTZ,
    },
];
const getGridFilters = (isSolarConfig, isInstaller) => [
    {
        code: GRID_STATISTICS.ACTIVE_POWER,
        name: 'device.active_pw',
        unit: UNIT.WATT,
        yAxis: UNIT.WATT,
        hidden: isSolarConfig && isInstaller,
        defaultChecked: true,
    },
    {
        code: GRID_STATISTICS.REACTIVE_POWER,
        name: 'device.reactive_pw',
        unit: UNIT.VOLT_AMPERE_REACTIVE,
        yAxis: UNIT.VOLT_AMPERE_REACTIVE,
        hidden: isSolarConfig && isInstaller,
    },
    {
        code: GRID_STATISTICS.GRID_VOLTAGE_L1,
        name: 'device.grid_voltage_l1',
        unit: UNIT.VOLT,
        yAxis: UNIT.VOLT,
    },
    {
        code: GRID_STATISTICS.GRID_VOLTAGE_L2,
        name: 'device.grid_voltage_l2',
        unit: UNIT.VOLT,
        yAxis: UNIT.VOLT,
    },
    {
        code: GRID_STATISTICS.GRID_CURRENT_L1,
        name: 'device.grid_current_l1',
        unit: UNIT.AMPERE,
        yAxis: UNIT.AMPERE,
    },
    {
        code: GRID_STATISTICS.GRID_CURRENT_L2,
        name: 'device.grid_current_l2',
        unit: UNIT.AMPERE,
        yAxis: UNIT.AMPERE,
    },
    {
        code: GRID_STATISTICS.GRID_FREQUENCY,
        name: 'device.grid_frequency',
        unit: UNIT.HERTZ,
        yAxis: UNIT.HERTZ,
    },
    {
        code: GRID_STATISTICS.POWER_FACTOR,
        name: 'device.power_factor',
        yAxis: UNIT.FACTOR,
        hidden: isSolarConfig && isInstaller,
    },
];
const getESSPostfix = (ESSNumber) => `ESS ${ESSNumber}`;
const getIsHiddenByESSCount = (ESSCount, ESSNumber) => ESSCount < ESSNumber;
// eslint-disable-next-line complexity
const getBatteryFilters = (ESSCount, isInstaller) => {
    return [
        {
            code: BATTERY_STATISTICS.USER_SOC,
            name: isInstaller ? 'device.soc' : 'control.user_soc',
            unit: UNIT.PERCENT,
            yAxis: UNIT.PERCENT,
            defaultChecked: true,
        },
        {
            code: BATTERY_STATISTICS.USER_SOC_ESS1,
            name: isInstaller ? 'device.soc' : 'control.user_soc',
            postfix: getESSPostfix(1),
            unit: UNIT.PERCENT,
            yAxis: UNIT.PERCENT,
            hidden: getIsHiddenByESSCount(ESSCount, 1),
        },
        {
            code: BATTERY_STATISTICS.USER_SOC_ESS2,
            name: isInstaller ? 'device.soc' : 'control.user_soc',
            postfix: getESSPostfix(2),
            unit: UNIT.PERCENT,
            yAxis: UNIT.PERCENT,
            hidden: getIsHiddenByESSCount(ESSCount, 2),
        },
        {
            code: BATTERY_STATISTICS.USER_SOC_ESS3,
            name: isInstaller ? 'device.soc' : 'control.user_soc',
            postfix: getESSPostfix(3),
            unit: UNIT.PERCENT,
            yAxis: UNIT.PERCENT,
            hidden: getIsHiddenByESSCount(ESSCount, 3),
        },
        {
            code: BATTERY_STATISTICS.REAL_SOC,
            name: 'device.real_soc',
            unit: UNIT.PERCENT,
            yAxis: UNIT.PERCENT,
            hidden: isInstaller,
        },
        {
            code: BATTERY_STATISTICS.REAL_SOC_ESS1,
            name: 'device.real_soc',
            postfix: getESSPostfix(1),
            unit: UNIT.PERCENT,
            yAxis: UNIT.PERCENT,
            hidden: getIsHiddenByESSCount(ESSCount, 1) || isInstaller,
        },
        {
            code: BATTERY_STATISTICS.REAL_SOC_ESS2,
            name: 'device.real_soc',
            postfix: getESSPostfix(2),
            unit: UNIT.PERCENT,
            yAxis: UNIT.PERCENT,
            hidden: getIsHiddenByESSCount(ESSCount, 2) || isInstaller,
        },
        {
            code: BATTERY_STATISTICS.REAL_SOC_ESS3,
            name: 'device.real_soc',
            postfix: getESSPostfix(3),
            unit: UNIT.PERCENT,
            yAxis: UNIT.PERCENT,
            hidden: getIsHiddenByESSCount(ESSCount, 3) || isInstaller,
        },
        {
            code: BATTERY_STATISTICS.SOH1_ESS1,
            name: 'control.soh',
            postfix: getESSPostfix(1),
            unit: UNIT.PERCENT,
            yAxis: UNIT.PERCENT,
            hidden: getIsHiddenByESSCount(ESSCount, 1),
        },
        {
            code: BATTERY_STATISTICS.SOH_ESS2,
            name: 'control.soh',
            postfix: getESSPostfix(2),
            unit: UNIT.PERCENT,
            yAxis: UNIT.PERCENT,
            hidden: getIsHiddenByESSCount(ESSCount, 2),
        },
        {
            code: BATTERY_STATISTICS.SOH_ESS3,
            name: 'control.soh',
            postfix: getESSPostfix(3),
            unit: UNIT.PERCENT,
            yAxis: UNIT.PERCENT,
            hidden: getIsHiddenByESSCount(ESSCount, 3),
        },
        {
            code: BATTERY_STATISTICS.VOLTAGE_ESS1,
            name: 'device.voltage',
            postfix: getESSPostfix(1),
            unit: UNIT.VOLT,
            yAxis: UNIT.VOLT,
            hidden: getIsHiddenByESSCount(ESSCount, 1),
        },
        {
            code: BATTERY_STATISTICS.VOLTAGE_ESS2,
            name: 'device.voltage',
            postfix: getESSPostfix(2),
            unit: UNIT.VOLT,
            yAxis: UNIT.VOLT,
            hidden: getIsHiddenByESSCount(ESSCount, 2),
        },
        {
            code: BATTERY_STATISTICS.VOLTAGE_ESS3,
            name: 'device.voltage',
            postfix: getESSPostfix(3),
            unit: UNIT.VOLT,
            yAxis: UNIT.VOLT,
            hidden: getIsHiddenByESSCount(ESSCount, 3),
        },
        {
            code: BATTERY_STATISTICS.CURRENT_ESS1,
            name: 'device.current',
            postfix: getESSPostfix(1),
            unit: UNIT.AMPERE,
            yAxis: UNIT.AMPERE,
            hidden: getIsHiddenByESSCount(ESSCount, 1),
        },
        {
            code: BATTERY_STATISTICS.CURRENT_ESS2,
            name: 'device.current',
            postfix: getESSPostfix(2),
            unit: UNIT.AMPERE,
            yAxis: UNIT.AMPERE,
            hidden: getIsHiddenByESSCount(ESSCount, 2),
        },
        {
            code: BATTERY_STATISTICS.CURRENT_ESS3,
            name: 'device.current',
            postfix: getESSPostfix(3),
            unit: UNIT.AMPERE,
            yAxis: UNIT.AMPERE,
            hidden: getIsHiddenByESSCount(ESSCount, 3),
        },
        {
            code: BATTERY_STATISTICS.CELL_VOLTAGE_AVERAGE_ESS1,
            name: 'device.average_cell_voltage',
            postfix: getESSPostfix(1),
            unit: UNIT.VOLT,
            yAxis: UNIT.VOLT,
            hidden: getIsHiddenByESSCount(ESSCount, 1) || isInstaller,
        },
        {
            code: BATTERY_STATISTICS.CELL_VOLTAGE_AVERAGE_ESS2,
            name: 'device.average_cell_voltage',
            postfix: getESSPostfix(2),
            unit: UNIT.VOLT,
            yAxis: UNIT.VOLT,
            hidden: getIsHiddenByESSCount(ESSCount, 2) || isInstaller,
        },
        {
            code: BATTERY_STATISTICS.CELL_VOLTAGE_AVERAGE_ESS3,
            name: 'device.average_cell_voltage',
            postfix: getESSPostfix(3),
            unit: UNIT.VOLT,
            yAxis: UNIT.VOLT,
            hidden: getIsHiddenByESSCount(ESSCount, 3) || isInstaller,
        },
        {
            code: BATTERY_STATISTICS.CELL_VOLTAGE_MAX_ESS1,
            name: 'device.max_cell_voltage',
            postfix: getESSPostfix(1),
            unit: UNIT.VOLT,
            yAxis: UNIT.VOLT,
            hidden: getIsHiddenByESSCount(ESSCount, 1) || isInstaller,
        },
        {
            code: BATTERY_STATISTICS.CELL_VOLTAGE_MAX_ESS2,
            name: 'device.max_cell_voltage',
            postfix: getESSPostfix(2),
            unit: UNIT.VOLT,
            yAxis: UNIT.VOLT,
            hidden: getIsHiddenByESSCount(ESSCount, 2) || isInstaller,
        },
        {
            code: BATTERY_STATISTICS.CELL_VOLTAGE_MAX_ESS3,
            name: 'device.max_cell_voltage',
            postfix: getESSPostfix(3),
            unit: UNIT.VOLT,
            yAxis: UNIT.VOLT,
            hidden: getIsHiddenByESSCount(ESSCount, 3) || isInstaller,
        },
        {
            code: BATTERY_STATISTICS.CELL_VOLTAGE_MIN_ESS1,
            name: 'device.min_cell_voltage',
            postfix: getESSPostfix(1),
            unit: UNIT.VOLT,
            yAxis: UNIT.VOLT,
            hidden: getIsHiddenByESSCount(ESSCount, 1) || isInstaller,
        },
        {
            code: BATTERY_STATISTICS.CELL_VOLTAGE_MIN_ESS2,
            name: 'device.min_cell_voltage',
            postfix: getESSPostfix(2),
            unit: UNIT.VOLT,
            yAxis: UNIT.VOLT,
            hidden: getIsHiddenByESSCount(ESSCount, 2) || isInstaller,
        },
        {
            code: BATTERY_STATISTICS.CELL_VOLTAGE_MIN_ESS3,
            name: 'device.min_cell_voltage',
            postfix: getESSPostfix(3),
            unit: UNIT.VOLT,
            yAxis: UNIT.VOLT,
            hidden: getIsHiddenByESSCount(ESSCount, 3) || isInstaller,
        },
        {
            code: BATTERY_STATISTICS.CELL_TEMPERATURE_AVERAGE_ESS1,
            name: 'device.avg_temp',
            postfix: getESSPostfix(1),
            unit: UNIT.FAHRENHEIT,
            yAxis: UNIT.FAHRENHEIT,
            hidden: getIsHiddenByESSCount(ESSCount, 1) || isInstaller,
        },
        {
            code: BATTERY_STATISTICS.CELL_TEMPERATURE_AVERAGE_ESS2,
            name: 'device.avg_temp',
            postfix: getESSPostfix(2),
            unit: UNIT.FAHRENHEIT,
            yAxis: UNIT.FAHRENHEIT,
            hidden: getIsHiddenByESSCount(ESSCount, 2) || isInstaller,
        },
        {
            code: BATTERY_STATISTICS.CELL_TEMPERATURE_AVERAGE_ESS3,
            name: 'device.avg_temp',
            postfix: getESSPostfix(3),
            unit: UNIT.FAHRENHEIT,
            yAxis: UNIT.FAHRENHEIT,
            hidden: getIsHiddenByESSCount(ESSCount, 3) || isInstaller,
        },
        {
            code: BATTERY_STATISTICS.CELL_TEMPERATURE_MAX_ESS1,
            name: 'device.max_temp',
            postfix: getESSPostfix(1),
            unit: UNIT.FAHRENHEIT,
            yAxis: UNIT.FAHRENHEIT,
            hidden: getIsHiddenByESSCount(ESSCount, 1) || isInstaller,
        },
        {
            code: BATTERY_STATISTICS.CELL_TEMPERATURE_MAX_ESS2,
            name: 'device.max_temp',
            postfix: getESSPostfix(2),
            unit: UNIT.FAHRENHEIT,
            yAxis: UNIT.FAHRENHEIT,
            hidden: getIsHiddenByESSCount(ESSCount, 2) || isInstaller,
        },
        {
            code: BATTERY_STATISTICS.CELL_TEMPERATURE_MAX_ESS3,
            name: 'device.max_temp',
            postfix: getESSPostfix(3),
            unit: UNIT.FAHRENHEIT,
            yAxis: UNIT.FAHRENHEIT,
            hidden: getIsHiddenByESSCount(ESSCount, 3) || isInstaller,
        },
        {
            code: BATTERY_STATISTICS.CELL_TEMPERATURE_MIN_ESS1,
            name: 'device.min_temp',
            postfix: getESSPostfix(1),
            unit: UNIT.FAHRENHEIT,
            yAxis: UNIT.FAHRENHEIT,
            hidden: getIsHiddenByESSCount(ESSCount, 1) || isInstaller,
        },
        {
            code: BATTERY_STATISTICS.CELL_TEMPERATURE_MIN_ESS2,
            name: 'device.min_temp',
            postfix: getESSPostfix(2),
            unit: UNIT.FAHRENHEIT,
            yAxis: UNIT.FAHRENHEIT,
            hidden: getIsHiddenByESSCount(ESSCount, 2) || isInstaller,
        },
        {
            code: BATTERY_STATISTICS.CELL_TEMPERATURE_MIN_ESS3,
            name: 'device.min_temp',
            postfix: getESSPostfix(3),
            unit: UNIT.FAHRENHEIT,
            yAxis: UNIT.FAHRENHEIT,
            hidden: getIsHiddenByESSCount(ESSCount, 3) || isInstaller,
        },
    ];
};
const getEnergyHourFilters = (isSolarConfig, isInstaller) => [
    {
        code: ENERGY_HOUR_STATISTICS.ACCB_PV_PRODUCED,
        name: 'device.pv_produced',
        unit: UNIT.WATT_HOUR,
        yAxis: UNIT.WATT_HOUR,
        hidden: !isSolarConfig,
    },
    {
        code: ENERGY_HOUR_STATISTICS.PV_PRODUCED,
        name: 'device.pv_produced',
        unit: UNIT.WATT_HOUR,
        yAxis: UNIT.WATT_HOUR,
        hidden: isSolarConfig,
    },
    {
        code: ENERGY_HOUR_STATISTICS.BATTERY_CHARGED,
        name: 'device.battery_charged',
        unit: UNIT.WATT_HOUR,
        yAxis: UNIT.WATT_HOUR,
        hidden: isSolarConfig,
    },
    {
        code: ENERGY_HOUR_STATISTICS.BATTERY_DISCHARGED,
        name: 'device.battery_discharged',
        unit: UNIT.WATT_HOUR,
        yAxis: UNIT.WATT_HOUR,
        hidden: isSolarConfig,
    },
    {
        code: ENERGY_HOUR_STATISTICS.GRID_EXPORTED,
        name: 'device.grid_exported',
        unit: UNIT.WATT_HOUR,
        yAxis: UNIT.WATT_HOUR,
        hidden: isSolarConfig && isInstaller,
    },
    {
        code: ENERGY_HOUR_STATISTICS.GRID_IMPORTED,
        name: 'device.grid_imported',
        unit: UNIT.WATT_HOUR,
        yAxis: UNIT.WATT_HOUR,
        hidden: isSolarConfig && isInstaller,
    },
    {
        code: ENERGY_HOUR_STATISTICS.LOAD_CONSUMED,
        name: 'device.load_consumed',
        unit: UNIT.WATT_HOUR,
        yAxis: UNIT.WATT_HOUR,
    },
];
export const getPowerConfig = (isSolarConfig) => {
    const powerKeyList = POWER_STATISTICS_LIST;
    return {
        filterGroupCode: GRAPH_GROUP_CODE.POWER,
        filterGroupName: 'device.site_power',
        filterList: getPowerFilters(isSolarConfig).filter((filter) => powerKeyList.includes(filter.code)),
    };
};
export const getEnergyMinuteConfig = (isSolarConfig, isInstaller) => {
    const energyMinuteKeyList = ENERGY_MINUTE_STATISTICS_LIST;
    return {
        filterGroupCode: GRAPH_GROUP_CODE.ENERGY,
        filterGroupName: 'common.site_energy',
        filterList: getEnergyMinuteFilters(isSolarConfig, isInstaller).filter((filter) => energyMinuteKeyList.includes(filter.code)),
    };
};
export const getACCombinerConfig = () => {
    const ACCombinerKeyList = AC_COMBINER_STATISTICS_LIST;
    return {
        filterGroupCode: GRAPH_GROUP_CODE.AC_COMBINER,
        filterGroupName: 'device.ac_combiner',
        filterList: acCombinerFilters.filter((filter) => ACCombinerKeyList.includes(filter.code)),
    };
};
export const getGridConfig = (isSolarConfig, isInstaller) => {
    const gridKeyList = GRID_STATISTICS_LIST;
    return {
        filterGroupCode: GRAPH_GROUP_CODE.GRID,
        filterGroupName: 'device.grid',
        filterList: getGridFilters(isSolarConfig, isInstaller).filter((filter) => gridKeyList.includes(filter.code)),
    };
};
export const getBatteryConfig = (ESSCount, isInstaller) => {
    const batteryKeyList = BATTERY_STATISTICS_LIST;
    return {
        filterGroupCode: GRAPH_GROUP_CODE.BATTERY,
        filterGroupName: 'device.battery',
        filterList: getBatteryFilters(ESSCount, isInstaller).filter((filter) => batteryKeyList.includes(filter.code)),
    };
};
export const getEnergyHourConfig = (isSolarConfig, isInstaller) => {
    const energyHourKeyList = ENERGY_HOUR_STATISTICS_LIST;
    return {
        filterGroupCode: GRAPH_GROUP_CODE.ENERGY,
        filterGroupName: 'common.site_energy',
        filterList: getEnergyHourFilters(isSolarConfig, isInstaller).filter((filter) => energyHourKeyList.includes(filter.code)),
    };
};
export const getGraphMinuteConfig = (isSolarConfig, isInstaller, ESSCount = 0) => {
    const powerConfig = getPowerConfig(isSolarConfig);
    const energyMinuteConfig = getEnergyMinuteConfig(isSolarConfig, isInstaller);
    const ACCombinerConfig = getACCombinerConfig();
    const gridConfig = getGridConfig(isSolarConfig, isInstaller);
    const batteryConfig = getBatteryConfig(ESSCount, isInstaller);
    return [powerConfig, energyMinuteConfig, ACCombinerConfig, gridConfig, batteryConfig];
};
export const getGraphHourConfig = (isSolarConfig, isInstaller) => {
    return [getEnergyHourConfig(isSolarConfig, isInstaller)];
};
