import { computed, defineComponent, ref } from 'vue';
import { gsap } from 'gsap';
import { LANGUAGE, USLanguageOptions } from '@hems/util/src/constant';
export default defineComponent({
    name: 'LanguageSelector',
    props: {
        modelValue: {
            type: String,
            default: LANGUAGE.EN,
        },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const languageDropdownElement = ref(null);
        const languageElement = ref(null);
        const languageOptions = USLanguageOptions;
        const getNotSelectLanguageOptions = (optionValue) => languageOptions.filter((item) => item.value !== optionValue);
        const filteredLanguageOptions = ref(getNotSelectLanguageOptions(props.modelValue));
        const selectedLanguageOption = computed(() => languageOptions.find((item) => item.value === props.modelValue));
        const showDropdown = ref(false);
        const clickOtherLayer = (e) => {
            if (e.target instanceof HTMLElement) {
                if (e.target.closest('#language-selector') !== languageElement.value) {
                    closeDropdownList();
                }
            }
        };
        const showDropdownList = () => {
            window.addEventListener('click', clickOtherLayer);
            setTimeout(() => {
                showDropdown.value = true;
            }, 100);
            const height = 40 * (languageOptions.length - 1) + 20;
            gsap.to([languageDropdownElement.value], { height: `${height}px`, duration: 0.3 });
        };
        const closeDropdownList = () => {
            window.removeEventListener('click', clickOtherLayer);
            setTimeout(() => {
                showDropdown.value = false;
            }, 100);
            gsap.to([languageDropdownElement.value], { height: '0', duration: 0.3 });
        };
        const onSelectLanguage = (selectedLanguage) => {
            filteredLanguageOptions.value = getNotSelectLanguageOptions(selectedLanguage.value);
            emit('update:modelValue', selectedLanguage.value);
            closeDropdownList();
        };
        const toggleLocaleDropbox = () => (showDropdown.value ? closeDropdownList() : showDropdownList());
        return {
            onSelectLanguage,
            toggleLocaleDropbox,
            languageDropdownElement,
            languageElement,
            showDropdownList,
            selectedLanguageOption,
            filteredLanguageOptions,
            showDropdown,
        };
    },
});
