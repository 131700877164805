import { computed, reactive, defineComponent, watch, onMounted, nextTick, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import isNil from 'lodash-es/isNil';
import { withLoading, Pagenation, ToastGrid, ListSize, useMessageBox } from '@hems/component';
import { InstallerAccountServiceAdmin } from '@hems/service';
import { Helper, useLanguage } from '@hems/util';
import { getColumns } from './gridConfig';
export default defineComponent({
    name: 'ChangeInstallerAccountDataGrid',
    components: {
        Pagenation,
        ToastGrid,
        ListSize,
    },
    props: {
        searchCondition: {
            type: Object,
            required: true,
        },
    },
    emits: ['select'],
    setup(props, { emit }) {
        onMounted(() => {
            loadData();
        });
        const { t } = useI18n();
        const route = useRoute();
        const messageBox = useMessageBox();
        const installerService = new InstallerAccountServiceAdmin(window.axiosInstance.axios);
        // const state = reactive({
        //   condition: {
        //     ...props.searchCondition,
        //     pageNum: route.query.pageNum ? Number(route.query.pageNum as string) : 1,
        //     listSize: route.query.listSize ? Number(route.query.listSize as string) : 10,
        //     sortColumn: 'create_dt_utc desc',
        //   } as InstallerAccountSearchOptions,
        //   currCnt: 0,
        //   showGrid: false,
        // });
        const currCnt = ref(0);
        const showGrid = ref(false);
        const condition = reactive({
            ...props.searchCondition,
            pageNum: route.query.pageNum ? Number(route.query.pageNum) : 1,
            listSize: route.query.listSize ? Number(route.query.listSize) : 10,
            sortColumn: 'create_dt_utc desc',
        });
        const { languageCode } = useLanguage();
        const gridProps = reactive({
            columns: computed(() => getColumns(t)),
            data: [],
            sortState: {
                columnName: 'create_dt_utc_str',
                ascending: false,
                multiple: false,
            },
            options: {
                selectionUnit: 'row',
                bodyHeight: 'fitToParent',
            },
        });
        const dataList = computed(() => gridProps.data || []);
        const loadData = withLoading(async () => {
            try {
                gridProps.data = (await installerService.getInstallerAccountList(condition)) || [];
                currCnt.value = gridProps.data.length > 0 ? gridProps.data[0].total_records : 0;
            }
            catch (e) {
                gridProps.data = [];
            }
        });
        const handleClickEvent = (gridEvent) => {
            if (isNil(gridEvent.rowKey))
                return;
            const { instance } = gridEvent;
            const originData = instance.getData();
            const record = {
                start: [gridEvent.rowKey, 0],
                end: [gridEvent.rowKey, getColumns.length],
            };
            instance.setSelectionRange(record);
            const rowKey = gridEvent.rowKey;
            const userId = originData[rowKey].user_id;
            if (!userId) {
                messageBox.alert(t('message.not_enough_info')).open();
                return;
            }
            emit('select', originData[rowKey]);
        };
        const handleBeforeSortEvent = (gridEvent) => {
            gridEvent.stop();
            const { columnName, ascending, multiple } = gridEvent;
            gridProps.sortState = {
                columnName,
                ascending,
                multiple,
            };
            condition.sortColumn = `${columnName === 'create_dt_utc_str' ? 'create_dt_utc' : columnName} ${ascending ? 'asc' : 'desc'}`;
        };
        const events = {
            click: handleClickEvent,
            beforeSort: handleBeforeSortEvent,
        };
        watch(() => condition.listSize, () => {
            condition.pageNum = 1;
            loadData();
        });
        watch(() => [condition.pageNum, condition.sortColumn], () => {
            loadData();
        });
        watch(() => props.searchCondition, () => {
            condition.searchFor = props.searchCondition.searchFor;
            condition.pageNum = 1;
            loadData();
        });
        watch(() => languageCode, () => {
            rerenderDataGrid();
        });
        function rerenderDataGrid() {
            showGrid.value = false;
            nextTick(() => (showGrid.value = true));
        }
        showGrid.value = true;
        return {
            condition,
            showGrid,
            currCnt,
            gridProps,
            formatNumber: Helper.formatNumber,
            dataList,
            events,
        };
    },
});
