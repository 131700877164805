import { computed, defineComponent, onMounted, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useField } from 'vee-validate';
import { ErrorMessageLabel, GoogleMapApi, useMessageBox } from '@hems/component';
import { DEFAULT_LANGUAGE, INPUT_TYPE, INPUT_VALID_CLASS } from '@hems/util/src/constant';
export default defineComponent({
    name: 'GoogleMapAutoComplete',
    components: {
        ErrorMessageLabel,
    },
    props: {
        modelValue: {
            type: String,
            required: true,
        },
        mapObj: {
            type: Object,
            required: true,
        },
        name: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: INPUT_TYPE.TEXT,
        },
        apiKey: {
            type: String,
            required: true,
        },
        language: {
            type: String,
            default: DEFAULT_LANGUAGE,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue', 'changePosition', 'changeCustomAddr'],
    setup(props, { emit }) {
        const { t } = useI18n();
        const messageBox = useMessageBox();
        const googleMapApi = new GoogleMapApi({ apiKey: props.apiKey });
        const mapInput = ref(null);
        const { value: inputValue, errorMessage } = useField(props.name, '', {
            initialValue: props.modelValue,
        });
        const state = reactive({
            isBind: false,
            status: computed(() => (props.modelValue ? 'ok' : 'error')),
            errMsg: '',
            autocomplete: null,
            language: computed(() => props.language),
        });
        watch(() => props.readonly, () => {
            if (props.readonly)
                return;
            bindGoogleMap();
        });
        watch(() => props.modelValue, () => {
            if (props.modelValue !== inputValue.value)
                inputValue.value = props.modelValue || '';
        });
        function bindGoogleMap() {
            if (state.isBind)
                return;
            // const { google } = window;
            if (state.isBind)
                return;
            if (!google)
                return;
            if (!mapInput.value)
                return;
            const options = {
                fields: ['formatted_address', 'geometry'],
                bounds: props.mapObj.getBounds(),
                strictBounds: false,
                types: ['establishment', 'geocode'],
            };
            state.autocomplete = new google.maps.places.Autocomplete(mapInput.value, options);
            state.autocomplete.addListener('place_changed', async () => {
                state.isBind = true;
                changePlace();
            });
            google.maps.event.addDomListener(mapInput.value, 'keydown', (event) => {
                if (event.code === 'Enter') {
                    event.preventDefault();
                }
            });
        }
        async function changePlace() {
            if (!state.autocomplete)
                return;
            state.autocomplete.setValues({});
            let place = state.autocomplete.getPlace();
            if (!place) {
                state.status = 'error';
                state.errMsg = t('message.no_detail');
                return;
            }
            if (!place.geometry || !place.geometry.location) {
                const name = place.name || '';
                try {
                    place = await getPlacePredictions(name);
                }
                catch (e) {
                    state.status = 'error';
                    state.errMsg = t('message.no_detail_input', { name: name || '' });
                    return;
                }
            }
            if (!place.geometry) {
                state.status = 'error';
                state.errMsg = t('message.no_detail');
                return;
            }
            if (place.geometry.location) {
                props.mapObj.setCenter(place.geometry.location);
                props.mapObj.setZoom(15);
                setLocation(place.geometry.location.lat(), place.geometry.location.lng());
                inputValue.value = place.formatted_address || '';
                state.status = 'ok';
                state.errMsg = '';
            }
            else {
                state.status = 'error';
                state.errMsg = t('message.no_detail_input', { name: place.name || '' });
                return;
            }
        }
        function onBlur() {
            emit('changeCustomAddr', inputValue);
        }
        const setLocation = async (lat, lng) => {
            const locationInfo = await googleMapApi.getLocatioinInfo(lat, lng, state ? state.language : props.language);
            const statePostalInfo = await googleMapApi.getStatePostalInfo(locationInfo.address);
            const timezoneInfo = await googleMapApi.getTimezone(lat, lng);
            emit('changePosition', locationInfo, timezoneInfo, inputValue, statePostalInfo);
        };
        onMounted(() => {
            if (props.modelValue === null) {
                const userAgent = navigator.userAgent.toLowerCase();
                if (userAgent.indexOf('chrome') === -1 && userAgent.indexOf('safari') !== -1) {
                    messageBox
                        .alert([t('message.system_setting'), t('message.safari_current_location'), t('message.specify_location')])
                        .open();
                }
            }
            if (!props.readonly) {
                bindGoogleMap();
            }
        });
        function getPlacePredictions(address) {
            return new Promise((resolve, reject) => {
                const service = new google.maps.places.AutocompleteService();
                const geocoder = new google.maps.Geocoder();
                const request = {
                    input: address,
                    types: ['geocode'],
                };
                service.getPlacePredictions(request, (result) => {
                    if (!result)
                        return reject();
                    geocoder.geocode({ placeId: result[0].place_id }, (result) => {
                        if (!result)
                            return reject();
                        props.mapObj.setCenter(result[0].geometry.location);
                        resolve(result[0]);
                    });
                });
            });
        }
        return {
            state,
            mapInput,
            inputValue,
            errorMessage,
            INPUT_TYPE,
            INPUT_VALID_CLASS,
            onBlur,
        };
    },
});
