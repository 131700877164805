import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3bd329bf"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "prod_col" };
const _hoisted_2 = { class: "subj" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
            _createElementVNode("a", {
                href: "#none",
                class: "more ir ico_arrow_2",
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event) => (_ctx.$emit('clickShowMore')), ["prevent"]))
            })
        ]),
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]));
}
