
import type { PropType } from 'vue';
import { defineComponent, reactive, computed } from 'vue';

import moment from 'moment';

import { DateHelper } from '@hems/util';
import { localStorageKey } from '@hems/util/src/constant';

import type { LangCd } from 'hems';

import type { NoticeInfo } from 'hems/notice';

import PopupWrapper from './PopupWrapper.vue';
import Image2 from '../image/Image2.vue';

export default defineComponent({
  name: 'NoticePopup',
  components: {
    PopupWrapper,
    Image2,
  },
  props: {
    noticeInfo: {
      type: Object as PropType<NoticeInfo>,
      required: true,
    },
    langCd: {
      type: String as PropType<LangCd>,
      default: 'en',
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const state = reactive({
      notShowFor1Day: false,
      title: computed(() => props.noticeInfo.title ?? 'Server Maintenance'),
      contents: computed(
        () =>
          props.noticeInfo.contents ??
          'We want to let you know that the app will be undergoing a brief scheduled maintenance.'
      ),
      startTime: computed(() =>
        props.noticeInfo.notice_start_dt ? getLocalTime(moment(props.noticeInfo.notice_start_dt).toDate()) : ''
      ),
      endTime: computed(() =>
        props.noticeInfo.notice_end_dt ? getLocalTime(moment(props.noticeInfo.notice_end_dt).toDate()) : ''
      ),
    });

    function getLocalTime(time: Date) {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const dateFormatConfig = { isTime: true, needTimezoneConvert: true, timezone: timeZone };

      return DateHelper.getTimezoneDate(time, dateFormatConfig);
    }

    function onClose() {
      // close 버튼 클릭 시 하루동안 다시 보지 않기 기능 실행
      // 현재 시간 + 1 day의 timestamp를 localStorage에 저장함
      const date = new Date();
      localStorage.setItem(localStorageKey.notice, date.setDate(date.getDate() + 1).toString());
      emit('close');
    }

    return {
      state,
      onClose,
    };
  },
});
