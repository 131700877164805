import { Service } from '@hems/service/src/api/Service';
import { apiWrapper, getApiVersionHeader } from '@hems/service/src/util/helper';
export default class StatisticsService extends Service {
    constructor(axios) {
        super(axios);
    }
    getSiteStatistics = async (siteId, params) => {
        const { data } = await this.get(apiWrapper.managerApi(`/histories/graphs/${siteId}/ac-system`), params, {
            headers: getApiVersionHeader(),
        });
        return data.body;
    };
    // TODO: energy flow 개선 작업에서 삭제 예정
    getFoxESSMonitoringData = async (deviceId) => {
        const { data } = await this.get(apiWrapper.stateApi(`/devices/telemetries/realtime/${deviceId}`));
        return data;
    };
    getDeviceCount = async (params) => {
        const { data } = await this.get(apiWrapper.stateApi('/devices/charts/data/renewal'), params);
        return data;
    };
    getLastWeekInstalledDeviceCount = async (params) => {
        const { data } = await this.get(apiWrapper.managerApi('/managements/activities/instl-last-week'), params);
        return data;
    };
}
export { StatisticsService };
