import { computed, defineComponent, ref, watch } from 'vue';
import { useModal } from 'vue-final-modal';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import isNil from 'lodash-es/isNil';
import omit from 'lodash-es/omit';
import { InputTitle, SelectNew, ButtonNew, Banner, Image2, withLoading } from '@hems/component';
import ScheduleAddEditModal from '@hems/container/src/energyManagement/modal/ScheduleAddEditModal.vue';
import ScheduleList from '@hems/container/src/energyManagement/ScheduleList.vue';
import { EnergyManagementService, CommonService } from '@hems/service';
import DeviceService from '@hems/service/src/api/device/DeviceService';
import { useACDeviceType, useConfirmationModal, useEnergyManagement, useOperationOptionList, useRole, useWarningModal, useToast, useSiteMasterDeviceInfo, } from '@hems/util';
import { BUTTON_NEW_SIZE, ENERGY_POLICY, SETTING_TYPE } from '@hems/util/src/constant';
import { createScheduleEventByOperation } from '@hems/util/src/helper/energyManagement/energyManagementHelper';
import { codeNamesToSelectorOptions } from '@hems/util/src/helper/helper';
import { ENERGY_MANAGEMENT_ACTION, ENERGY_MANAGEMENT_MUTATION } from '@hems/util/src/stores/modules/energyManagement';
export default defineComponent({
    name: 'ScheduleSetting',
    components: {
        InputTitle,
        SelectNew,
        ButtonNew,
        Banner,
        Image2,
        ScheduleList,
    },
    props: {
        operationMode: {
            type: String,
            required: true,
        },
    },
    emits: ['changeOperationMode', 'moveToPreset', 'moveToScheduleDelete', 'onEdit'],
    setup(props, { emit }) {
        const commonService = new CommonService(window.axiosInstance.axios);
        const deviceService = new DeviceService(window.axiosInstance.axios);
        const energyManagementService = new EnergyManagementService(window.axiosInstance.axios);
        const { isCase1, caseNumber } = useACDeviceType();
        const { t } = useI18n();
        const store = useStore();
        const selectedOperationMode = ref(props.operationMode);
        const operationModeOptions = ref([]);
        const isTOU = computed(() => selectedOperationMode.value === ENERGY_POLICY.TIME_BASED);
        const { isUser } = useRole();
        const { siteId, productModelName, isStormCoverMode, scheduleInfoList, masterDeviceId, defaultOperation, getPresetOperationsById, updateWeeklySchedulerEventList, } = useEnergyManagement();
        const { openToastMessage } = useToast();
        const isHiddenLimitExport = computed(() => caseNumber.value && [4, 5].includes(caseNumber.value));
        const defaultMode = computed({
            get: () => defaultOperation.value,
            set: (value) => store.dispatch(ENERGY_MANAGEMENT_ACTION.SET_DEFAULT_MODE, value),
        });
        const { operationOptionList: defaultModeOptions } = useOperationOptionList();
        const operationModeText = {
            [ENERGY_POLICY.SELF_CONSUMPTION]: isCase1 ? t('device.auto') : t('control.self_consumption'),
            [ENERGY_POLICY.ZERO_EXPORT]: t('control.limit_export'),
            [ENERGY_POLICY.TIME_BASED]: t('control.time_of_use'),
        };
        const isOperationModeSettingDisabled = computed(() => isUser && (isCase1 || isStormCoverMode.value));
        const isSavable = computed(() => (!isTOU.value && selectedOperationMode.value && !isOperationModeSettingDisabled.value) || defaultMode.value);
        const { isConsumptionCT } = useSiteMasterDeviceInfo();
        const getOperationModeExcludedConditions = () => {
            if (!isConsumptionCT.value) {
                return [ENERGY_POLICY.ZERO_EXPORT, ENERGY_POLICY.TIME_BASED, ENERGY_POLICY.EXTERNAL_GENERATION];
            }
            if (isCase1) {
                return [ENERGY_POLICY.TIME_BASED, ENERGY_POLICY.EXTERNAL_GENERATION];
            }
            return [ENERGY_POLICY.EXTERNAL_GENERATION];
        };
        const loadOperationModeOptions = async () => {
            const { ENERGY_POLICY_GEN3_CD } = await commonService.getCodesByGroupCode([
                { grpCd: 'ENERGY_POLICY_GEN3_CD', exclude: getOperationModeExcludedConditions() },
            ]);
            operationModeOptions.value = codeNamesToSelectorOptions(ENERGY_POLICY_GEN3_CD, t)
                .map((selectorOption) => {
                if (selectorOption.value === ENERGY_POLICY.SELF_CONSUMPTION ||
                    selectorOption.value === ENERGY_POLICY.ZERO_EXPORT ||
                    selectorOption.value === ENERGY_POLICY.TIME_BASED) {
                    return {
                        ...selectorOption,
                        text: operationModeText[selectorOption.value],
                    };
                }
                return selectorOption;
            })
                .filter((option) => {
                if (isHiddenLimitExport.value) {
                    return option.value !== ENERGY_POLICY.ZERO_EXPORT;
                }
                return true;
            });
        };
        const onChangeOperationMode = (selectedOption) => {
            emit('changeOperationMode', selectedOption);
        };
        const addScheduleSuccessModal = (scheduleInfo) => {
            const seasonalScheduleModal = useConfirmationModal({
                title: t('control.seasonal_schedule_added_title'),
                content: t('control.seasonal_schedule_added_message', { scheduleName: scheduleInfo.name }),
                confirmButtonText: t('message.add_operation'),
                cancelButtonText: t('common.later'),
                confirmCallback: () => {
                    emit('onEdit', scheduleInfo);
                    seasonalScheduleModal.close();
                },
            });
            return seasonalScheduleModal;
        };
        const addScheduleModal = useModal({
            component: ScheduleAddEditModal,
            attrs: {
                siteId: siteId.value,
                onSave: ({ scheduleInfo, selectedPresetId }) => {
                    const operations = selectedPresetId ? getPresetOperationsById(selectedPresetId) : [];
                    const newScheduleInfo = { ...scheduleInfo, operations };
                    store.commit('energyManagement/setScheduleInfoList', [...scheduleInfoList.value, newScheduleInfo]);
                    addScheduleModal.close();
                    const scheduleSuccessModal = addScheduleSuccessModal(newScheduleInfo);
                    scheduleSuccessModal.open();
                    openToastMessage(t('control.preset_name_added', { presetName: scheduleInfo.name }));
                },
            },
        });
        const addNewSchedule = () => {
            addScheduleModal.open();
        };
        const onDetailSchedule = (scheduleName) => {
            const schedule = scheduleInfoList.value.find((schedule) => schedule.name === scheduleName) ?? null;
            if (!schedule)
                return;
            store.commit(ENERGY_MANAGEMENT_MUTATION.SET_SELECTED_SCHEDULE_INFO, schedule);
            updateWeeklySchedulerEventList(createScheduleEventByOperation(schedule.operations));
        };
        const onEditSchedule = (schedule) => {
            emit('onEdit', schedule);
        };
        const unableSaveModal = (content) => {
            const unableModal = useWarningModal({
                title: t('message.unable_to_save'),
                content,
                confirmButtonText: t('common.ok'),
                confirmCallback: () => {
                    unableModal.close();
                },
            });
            return unableModal;
        };
        const saveTOU = (siteId, defaultMode) => {
            const touRequestBody = {
                defaultOperation: defaultMode,
                schedules: scheduleInfoList.value.map((schedule) => omit(schedule, 'isSaveRequired')),
            };
            return energyManagementService.createScheduleList(siteId, touRequestBody);
        };
        const reloadTOU = () => {
            store.commit(ENERGY_MANAGEMENT_MUTATION.SET_SCHEDULE_INFO_LIST, scheduleInfoList.value.map((scheduleInfo) => ({ ...scheduleInfo, isSaveRequired: false })));
            store.commit(ENERGY_MANAGEMENT_MUTATION.UPDATE_WEEKLY_SCHEDULER_EVENT_LIST, []);
        };
        const onSave = withLoading(async () => {
            if (isNil(siteId.value)) {
                return;
            }
            try {
                // Operation Mode 세팅
                // Online 여부 체크
                const isConnect = await commonService.isConnection(masterDeviceId.value);
                if (!isConnect) {
                    unableSaveModal(t('message.system_connect_internet')).open();
                    return;
                }
                if (!isOperationModeSettingDisabled.value) {
                    const settingParams = {
                        msg_type: SETTING_TYPE.BASIC,
                        product_model_nm: productModelName.value,
                    };
                    deviceService.controlACSystemSettingWithSiteId(siteId.value, {
                        ...settingParams,
                        energy_policy: Number(selectedOperationMode.value),
                    });
                }
                if (!isTOU.value || !defaultMode.value)
                    return;
                await saveTOU(siteId.value, defaultMode.value);
                reloadTOU();
            }
            catch (e) {
                console.error(e);
                unableSaveModal(t('message.save_server_error')).open();
            }
        });
        loadOperationModeOptions();
        watch(() => props.operationMode, () => {
            selectedOperationMode.value = props.operationMode;
        });
        return {
            operationModeOptions,
            isSavable,
            BUTTON_NEW_SIZE,
            isTOU,
            defaultMode,
            defaultOperation,
            defaultModeOptions,
            selectedOperationMode,
            isOperationModeSettingDisabled,
            onChangeOperationMode,
            onSave,
            addNewSchedule,
            onDetailSchedule,
            onEditSchedule,
        };
    },
});
