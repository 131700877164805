
import { defineComponent, onBeforeUnmount, ref } from 'vue';
import { useRouter } from 'vue-router';

import TitlePanel from '@hems/component/src/panels/TitlePanel.vue';
import { ErrorCodeService } from '@hems/service';
import { useLanguage, useLoading } from '@hems/util';
import { ONE_MINUTE_VALUE_IN_MILLISECONDS, SORT_ORDER } from '@hems/util/src/constant';
import { getRecentErrorData } from '@hems/util/src/helper/errorHistoryHelper';
import { addSortOrderMark } from '@hems/util/src/helper/helper';

import type { RecentErrorHistoryInfo, ErrorHistoryParams } from 'hems/event';

const RECENT_ERROR_PAGE_NUMBER = 1;
const RECENT_ERROR_MAX_COUNT = 8;

export default defineComponent({
  name: 'EventHistory',
  components: { TitlePanel },

  setup() {
    const { languageCode } = useLanguage();
    const router = useRouter();
    const { startLoading, stopLoading } = useLoading();

    const errorList = ref<RecentErrorHistoryInfo[]>([]);

    const errorCodeService = new ErrorCodeService(window.axiosInstance.axios);

    const getErrorHistoryParams = (): ErrorHistoryParams => {
      return {
        pageNumber: RECENT_ERROR_PAGE_NUMBER,
        listSize: RECENT_ERROR_MAX_COUNT,
        sort: addSortOrderMark('triggerDate', SORT_ORDER.DESCENDING),
        cleared: null,
        language: languageCode.value,
      };
    };

    const loadRecentEventList = async (useLoading = false) => {
      try {
        useLoading && startLoading();

        const { data } = await errorCodeService.getErrorHistory(getErrorHistoryParams());

        errorList.value = getRecentErrorData(data);
      } catch (error) {
        console.error(error);
      } finally {
        useLoading && stopLoading();
      }
    };

    const onClickMoreEvent = () => {
      router.push({
        name: 'EventList',
      });
    };

    loadRecentEventList(true);

    const interval = setInterval(() => {
      loadRecentEventList();
    }, ONE_MINUTE_VALUE_IN_MILLISECONDS);

    onBeforeUnmount(() => {
      clearInterval(interval);
    });

    return {
      errorList,
      onClickMoreEvent,
    };
  },
});
