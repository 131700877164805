
import { defineComponent } from 'vue';

import { Image2 } from '@hems/component';
import { useSiteConfiguration, useSiteMasterDeviceInfo } from '@hems/util';
import { ENERGY_FLOW_IMAGE } from '@hems/util/src/constant';

export default defineComponent({
  name: 'EnergyFlowACProductLayer',
  components: {
    Image2,
  },
  setup() {
    const { hasESS, hasPV } = useSiteConfiguration();
    const { isConsumptionCT } = useSiteMasterDeviceInfo();

    return {
      isConsumptionCT,
      hasESS,
      hasPV,
      ENERGY_FLOW_IMAGE,
    };
  },
});
