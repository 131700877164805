import { computed, defineComponent, reactive, watch, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import * as yup from 'yup';
import { LabelInput, BasicInput, GoogleMapAutoComplete, GoogleMapWrapper } from '@hems/component';
import { Helper, useLanguage, useLoading } from '@hems/util';
import { INPUT_TYPE } from '@hems/util/src/constant';
export default defineComponent({
    name: 'GoogleMap',
    components: {
        LabelInput,
        BasicInput,
        GoogleMapWrapper,
        GoogleMapAutoComplete,
    },
    props: {
        installationInfo: {
            type: Object,
            required: true,
        },
        isEditable: {
            type: Boolean,
            required: true,
        },
    },
    emits: ['update:installationInfo'],
    setup(props, { emit }) {
        const { t } = useI18n();
        const store = useStore();
        const apiKey = store.state.appCtx.googleApiKey ?? '';
        const { languageCode } = useLanguage();
        const state = reactive({
            googleMap: null,
            isGoogleMapLoaded: false,
        });
        const installLocationInfo = ref({ ...props.installationInfo });
        const countryInfo = computed(() => {
            const countryCode = installLocationInfo.value.cntry_cd;
            const countryName = installLocationInfo.value.cntry_nm;
            if (Helper.isNull(countryCode)) {
                return '';
            }
            if (Helper.isNull(countryName)) {
                return countryCode;
            }
            if (countryCode.includes('code')) {
                return `${countryCode} (${t(countryName)})`;
            }
            return `${countryCode} (${countryName})`;
        });
        watch(() => state.googleMap, (status) => {
            if (status !== null)
                state.isGoogleMapLoaded = true;
        });
        let mapLoadedCount = 0;
        const maxCount = 42; // 0.7초당 1번 - 최대 30초
        const timer = (millisec) => new Promise((res) => setTimeout(res, millisec));
        const loaded = async () => {
            while (!state.isGoogleMapLoaded) {
                await timer(700);
                if (++mapLoadedCount >= maxCount) {
                    break;
                }
            }
        };
        const { withLoading } = useLoading();
        withLoading(async () => await loaded())();
        const prevLocation = {
            value: null,
            status: false,
        };
        const currentLocation = {
            value: { lat: props.installationInfo.latitude ?? 0, lon: props.installationInfo.longitude ?? 0 },
            status: true,
        };
        const schema = yup.object().shape({
            cntry_cd: yup.string().required(),
            instl_addr: yup
                .string()
                .required()
                .isValidLatLon(prevLocation, { latKey: 'latitude', lonKey: 'longitude' }, currentLocation),
            latitude: numberValiate(),
            longitude: numberValiate(),
            timezone_id: yup.string().nullable().required(),
        });
        function numberValiate() {
            return yup
                .number()
                .transform((v, o) => (o === '' ? null : v))
                .nullable()
                .required();
        }
        function onChangeMapPosition(position, timezoneInfo) {
            const { lat, lng, address, cntryCd, cntryNm } = position;
            if (!installLocationInfo.value)
                return;
            installLocationInfo.value.latitude = lat;
            installLocationInfo.value.longitude = lng;
            installLocationInfo.value.cntry_cd = cntryCd;
            installLocationInfo.value.cntry_nm = cntryNm;
            if (props.isEditable)
                installLocationInfo.value.instl_addr = address;
            installLocationInfo.value.timezone_id = timezoneInfo.timeZoneId;
        }
        const onChangeAddress = (position, timezoneInfo, address) => {
            const { lat, lng, cntryCd, cntryNm } = position;
            if (!installLocationInfo.value)
                return;
            installLocationInfo.value.latitude = lat;
            installLocationInfo.value.longitude = lng;
            installLocationInfo.value.cntry_cd = cntryCd;
            installLocationInfo.value.cntry_nm = cntryNm;
            installLocationInfo.value.instl_addr = address;
            installLocationInfo.value.timezone_id = timezoneInfo.timeZoneId;
        };
        function onChangeCustomAddress(address) {
            if (!installLocationInfo.value)
                return;
            installLocationInfo.value.instl_addr = address;
        }
        watch(() => installLocationInfo.value, () => {
            emit('update:installationInfo', installLocationInfo.value);
        }, { deep: true });
        return {
            state,
            schema,
            onChangeMapPosition,
            onChangeAddress,
            onChangeCustomAddress,
            instance: (instance) => {
                state.googleMap = instance?.map ?? null;
            },
            countryInfo,
            INPUT_TYPE,
            languageCode,
            apiKey,
            installLocationInfo,
        };
    },
});
