import type { AxiosInstance } from 'axios';

import { Service } from '@hems/service/src/api/Service';
import { apiWrapper, getApiVersionHeader } from '@hems/service/src/util/helper';
import { StorageHelper } from '@hems/util';
import { DEVICE_CONNECTION_RESPONSE_CODE, LANGUAGE } from '@hems/util/src/constant';

import type { CommonCode, LangCd, CommonResponseWrapper } from 'hems';

import type { DeviceConnectionStatus, DeviceId, GenType, ProductModelName, SiteId } from 'hems/device';
import type { SendEmailParams, SendEmailResponse } from 'hems/device/settings/installation';
import type { NoticeInfo } from 'hems/notice';
import type { CategoryResponse } from 'hems/support';

export default class CommonService extends Service {
  constructor(axios: AxiosInstance) {
    super(axios);
  }

  private getStorageKey(code: { grpCd: CommonCode.GroupCode; exclude?: string[] }): number {
    return `${code.grpCd}${code.exclude?.join('_')}`.split('').reduce((a, b) => {
      a = (a << 5) - a + b.charCodeAt(0);

      return a & a;
    }, 0);
  }

  // 코드 목록 가져오기
  getCodesByGroupCode = async <T extends CommonCode.GroupCode>(
    codes: {
      grpCd: T;
      exclude?: string[];
    }[]
  ): Promise<{ [K in T]: CommonCode.CodeMap[] }> => {
    const notExistedCodes = [] as { grpCd: T; exclude?: string[] }[];
    const result = {} as { [K in T]: CommonCode.CodeMap[] };

    // Session Storage에 있으면 result에 추가, 없으면 notExistedCodes에 파라미터 추가
    codes.forEach((item) => {
      const hashCode = this.getStorageKey(item);
      if (StorageHelper.isExistCode(hashCode)) {
        result[item.grpCd] = StorageHelper.getCode(hashCode);
      } else {
        notExistedCodes.push(item);
      }
    });

    let resResult;
    if (notExistedCodes.length > 0) {
      // notExistedCodes에 포함된 것만 API 호출

      const { data } = await this.post<CommonResponseWrapper<{ [K in T]: CommonCode.CodeMap[] }>>(
        apiWrapper.managerApi('/managements/commons/codes'),
        { codes: notExistedCodes }
      );
      resResult = data;
      for (const obj of notExistedCodes) {
        const hashCode = this.getStorageKey(obj);

        // notExistedCodes의 결과를 Session Storage에 저장
        if (resResult[obj.grpCd]) StorageHelper.setCode(hashCode, resResult[obj.grpCd]);
      }
    }

    return { ...result, ...resResult };
  };

  getGenType = async (siteId: SiteId): Promise<GenType> => {
    const { data } = await this.get<CommonResponseWrapper<GenType>>(
      apiWrapper.managerApi(`/devices/profiles/${siteId}/types`)
    );

    return data;
  };

  isConnection = async (deviceId: DeviceId): Promise<boolean> => {
    const { data } = await this.get<CommonResponseWrapper<DeviceConnectionStatus>>(
      apiWrapper.managerApi(`/devices/connections/${deviceId}/status-devices`),
      undefined,
      {
        headers: getApiVersionHeader(),
      }
    );
    if (data.responseCd === DEVICE_CONNECTION_RESPONSE_CODE.ONLINE) return true;

    return false;
  };

  healthCheck = async (): Promise<boolean> => {
    try {
      const { data } = await this.get<CommonResponseWrapper<boolean>>('/health-check');

      return data;
    } catch (error) {
      return false;
    }
  };

  getGoogleAPIKey = async (): Promise<string> => {
    const { data } = await this.get<CommonResponseWrapper<string>>(
      apiWrapper.managerApi('/managements/commons/google/api-keys')
    );

    return data;
  };

  getNotice = async (langCd: LangCd = LANGUAGE.EN): Promise<NoticeInfo> => {
    const { data } = await this.get<CommonResponseWrapper<NoticeInfo>>(
      apiWrapper.managerApi('/managements/commons/notices'),
      { lang: langCd }
    );

    return data;
  };

  getEventAlarmCodes = async (): Promise<string[]> => {
    const { data } = await this.get<CommonResponseWrapper<string[]>>(apiWrapper.managerApi('/devices/alarms/codes'));

    return data;
  };

  getGuideCategory = async (): Promise<CategoryResponse[]> => {
    const { data } = await this.get<CommonResponseWrapper<CategoryResponse[]>>(
      apiWrapper.managerApi('/managements/mobiles/pro-apps/supports/categories')
    );

    return data;
  };

  sendOwnerEmail = async (siteId: number, params: SendEmailParams): Promise<SendEmailResponse> => {
    const { data } = await this.post<CommonResponseWrapper<SendEmailResponse>>(
      apiWrapper.managerApi(`/managements/mails/${siteId}`),
      params
    );

    return data;
  };

  getBaseGenType = async (productModelNm: ProductModelName): Promise<GenType> => {
    const { data } = await this.get<CommonResponseWrapper<GenType>>(
      apiWrapper.managerApi('/devices/profiles/gen-types'),
      { productModelNm }
    );

    return data;
  };

  getPgpKeyFile = async (): Promise<Blob> => {
    return await this.get<Blob>(
      apiWrapper.managerApi('/managements/commons/s3/cyber-security/files'),
      {},
      {
        headers: {
          Accept: '*/*',
        },
        responseType: 'blob',
      }
    );
  };
}

export { CommonService };
