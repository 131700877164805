import { APP_MODE } from '@hems/util/src/constant';
import { isAppModeValueType } from '@hems/util/src/helper/tsguardHelper';
// eslint-disable-next-line import/prefer-default-export
export const useAppMode = () => {
    const appEnv = process.env.VUE_APP_ENV && isAppModeValueType(process.env.VUE_APP_ENV)
        ? process.env.VUE_APP_ENV
        : APP_MODE.DEVELOPMENT;
    const isLocal = appEnv === APP_MODE.LOCAL;
    const isDevelopment = appEnv === APP_MODE.DEVELOPMENT;
    const isProduction = appEnv === APP_MODE.PRODUCTION;
    const isStaging = appEnv === APP_MODE.STAGING;
    return {
        appEnv,
        isDevelopment,
        isProduction,
        isStaging,
        isLocal,
    };
};
