import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e70cce0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"width":"100%"} }
const _hoisted_2 = { class: "btn_wrap_t tar" }
const _hoisted_3 = { class: "ir_b ico_down_2" }
const _hoisted_4 = { class: "grid-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ToastGrid = _resolveComponent("ToastGrid")!
  const _component_ListNavigator = _resolveComponent("ListNavigator")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createBlock(_component_Panel, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.isAdminGroup)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                type: "button",
                class: "btn_t btn_c_4",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onDownload && _ctx.onDownload(...args)))
              }, [
                _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('common.download_excel')), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_ctx.showGrid)
            ? (_openBlock(), _createBlock(_component_ToastGrid, {
                key: 0,
                data: _ctx.eventList,
                columns: _ctx.toastGrid.gridOptions.columns,
                options: _ctx.toastGrid.gridOptions.options,
                "sort-state": _ctx.toastGrid.gridOptions.sortState,
                events: _ctx.events
              }, null, 8, ["data", "columns", "options", "sort-state", "events"]))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_ListNavigator, {
          "list-total-count": _ctx.totalErrorHistoryCount,
          onChangePage: _ctx.onChangePage,
          onChangeListSize: _ctx.onChangeListSize
        }, null, 8, ["list-total-count", "onChangePage", "onChangeListSize"])
      ])
    ]),
    _: 1
  }))
}