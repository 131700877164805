import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2a836796"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["value", "placeholder", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image2 = _resolveComponent("Image2")!
  const _component_InputError = _resolveComponent("InputError")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["text-input-container", { 'text-input-container-error': _ctx.errorInfo.isError, 'text-input-container-disabled': _ctx.disabled }])
    }, [
      (_ctx.beforeImage)
        ? (_openBlock(), _createBlock(_component_Image2, {
            key: 0,
            class: "before-image",
            src: _ctx.beforeImage.src,
            extension: _ctx.beforeImage.extension
          }, null, 8, ["src", "extension"]))
        : _createCommentVNode("", true),
      _createElementVNode("input", _mergeProps({ value: _ctx.modelValue }, _ctx.$attrs, {
        type: "text",
        placeholder: _ctx.placeholder,
        disabled: _ctx.disabled,
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateInputValue && _ctx.updateInputValue(...args))),
        onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('blur')))
      }), null, 16, _hoisted_1),
      (_ctx.showValidationImage && _ctx.inputValidStatus === _ctx.INPUT_VALID_STATUS.VALID)
        ? (_openBlock(), _createBlock(_component_Image2, {
            key: 1,
            class: "validation-image",
            src: "icons/ic_check",
            extension: "svg"
          }))
        : (_ctx.afterImage)
          ? (_openBlock(), _createBlock(_component_Image2, {
              key: 2,
              class: "after-image",
              src: _ctx.afterImage.src,
              extension: _ctx.afterImage.extension
            }, null, 8, ["src", "extension"]))
          : _createCommentVNode("", true)
    ], 2),
    (_ctx.errorInfo.errorMessage)
      ? (_openBlock(), _createBlock(_component_InputError, {
          key: 0,
          "error-info": _ctx.errorInfo
        }, null, 8, ["error-info"]))
      : _createCommentVNode("", true)
  ], 64))
}