
import type { PropType } from 'vue';
import { defineComponent, watch, ref, useAttrs } from 'vue';

import isNil from 'lodash-es/isNil';
import { useField } from 'vee-validate';

import Image2 from '@hems/component/src/image/Image2.vue';
import ErrorMessageLabel from '@hems/component/src/labels/ErrorMessageLabel.vue';
import type {
  InputClassNameValue,
  InputTypeValue,
  InputValidateModeValue,
  InputValidClassValue,
} from '@hems/util/src/constant/input';
import { INPUT_CLASS_NAME, INPUT_TYPE, INPUT_VALID_CLASS, INPUT_VALIDATE_MODE } from '@hems/util/src/constant/input';
import { getUUID } from '@hems/util/src/helper/helper';

export default defineComponent({
  name: 'BasicInput',
  components: {
    ErrorMessageLabel,
    Image2,
  },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: [String, Number, null] as PropType<string | number | null>,
      default: null,
    },
    validateMode: {
      type: String as PropType<InputValidateModeValue>,
      default: INPUT_VALIDATE_MODE.INPUT,
    },
    className: {
      type: String as PropType<InputClassNameValue>,
      default: INPUT_CLASS_NAME.SMALL,
    },
    name: {
      type: String,
      // name props를 설정하지 않았을 때 빈 스트링으로 처리하게 되면 input에 정상적으로 값이 표시되지 않음 (Form의 직렬화 기능 때문으로 예상됨)
      default: getUUID(),
    },
    unit: {
      type: String,
      default: '',
    },
    type: {
      type: String as PropType<InputTypeValue>,
      default: INPUT_TYPE.TEXT,
    },
    validClass: {
      type: String as PropType<InputValidClassValue>,
      default: INPUT_VALID_CLASS.DEFAULT,
    },
  },
  emits: ['update:modelValue', 'change', 'blur'],
  setup(props, { emit }) {
    const { value: inputValue, errorMessage } = useField<string | number | null>(props.name, undefined, {
      initialValue: props.modelValue,
      type: props.type,
    });

    const attrs = useAttrs();

    const showPassword = ref(false);

    const setValue = (value: number | string) => {
      if (props.type === INPUT_TYPE.NUMBER) {
        if (isNil(value)) {
          emit('update:modelValue', null);

          return;
        }
        emit('update:modelValue', Number(value));

        return;
      }
      emit('update:modelValue', value);
    };

    const onBlur = (e: Event) => {
      if (attrs.readonly) {
        return;
      }

      if (props.validateMode === INPUT_VALIDATE_MODE.BLUR && e.target instanceof HTMLInputElement) {
        setValue(e.target.value);
      }
      emit('blur');
    };

    const onChange = (e: Event) => {
      if (props.validateMode === INPUT_VALIDATE_MODE.INPUT && e.target instanceof HTMLInputElement) {
        setValue(e.target.value);
        emit('change');
      }
    };

    const displayPassword = (value: string) => {
      if (isNil(value)) return value;

      return Array.from(Array(value.length), (_, index) => (index > 2 ? '*' : value.substring(index, 1))).join('');
    };

    watch(
      () => props.modelValue,
      () => {
        if (props.modelValue !== inputValue.value) inputValue.value = props.modelValue ?? null;
      }
    );

    return {
      inputValue,
      errorMessage,
      INPUT_TYPE,
      showPassword,
      INPUT_VALID_CLASS,
      displayPassword,
      onBlur,
      onChange,
    };
  },
});
