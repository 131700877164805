import { defineComponent, computed, ref } from 'vue';
import { WeatherContainer, DashboardEnergyValueFlowContainer, AnnualReportPopup, SiteEnergyContainer, } from '@hems/container';
import { useRegionCondition, useSiteMasterDeviceInfo } from '@hems/util';
import { SITE_ENERGY_MQTT_EMS_VERSION } from '@hems/util/src/constant';
export default defineComponent({
    name: 'SiteEnergy',
    components: {
        WeatherContainer,
        DashboardEnergyValueFlowContainer,
        AnnualReportPopup,
        SiteEnergyContainer,
    },
    setup() {
        const isOpenAnnualReportPopup = ref(false);
        const { EMSVersion, isEMSVersionAtLeast } = useSiteMasterDeviceInfo();
        const { showWeatherInfo } = useRegionCondition();
        const isMQTTRealTime = computed(() => EMSVersion ? isEMSVersionAtLeast(EMSVersion.value, SITE_ENERGY_MQTT_EMS_VERSION) : false);
        const openAnnualReportPopup = () => {
            isOpenAnnualReportPopup.value = true;
        };
        const closeAnnualReportPopup = () => {
            isOpenAnnualReportPopup.value = false;
        };
        return {
            showWeatherInfo,
            isOpenAnnualReportPopup,
            isMQTTRealTime,
            openAnnualReportPopup,
            closeAnnualReportPopup,
        };
    },
});
