import { defineComponent, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import isNil from 'lodash-es/isNil';
import { Form } from 'vee-validate';
import { PopupWrapper, withLoading, SettingTable, SettingTableRow, Selector } from '@hems/component';
import { DownloadServiceUser, UserReportService } from '@hems/service';
import { useLanguage, useMessageBox, useSiteId, useSiteMasterDeviceInfo } from '@hems/util';
import { LANGUAGE } from '@hems/util/src/constant';
import { STATUS_CODE } from '@hems/util/src/exception/exception';
import { today } from '@hems/util/src/helper/dateHelper';
import { downloadFile } from '@hems/util/src/helper/helper';
const FILE_NAME = 'Report';
const FILE_TYPE = {
    PDF: 'PDF',
    EXCEL: 'EXCEL',
};
export default defineComponent({
    name: 'AnnualReportPopup',
    components: {
        PopupWrapper,
        Form,
        SettingTable,
        SettingTableRow,
        Selector,
    },
    emits: ['close'],
    setup(_props, { emit }) {
        const { alertMessageBox } = useMessageBox();
        const { t } = useI18n();
        const downloadService = new DownloadServiceUser(window.axiosInstance.axios);
        const userReportService = new UserReportService(window.axiosInstance.axios);
        const state = reactive({
            year: '',
            fileType: FILE_TYPE.PDF,
            langCd: LANGUAGE.EN,
        });
        const { siteId } = useSiteId();
        const { siteTimezoneId } = useSiteMasterDeviceInfo();
        const yearSelectorOptions = ref([]);
        const fileTypeSelectorOptions = [
            { text: FILE_TYPE.PDF, value: FILE_TYPE.PDF },
            { text: FILE_TYPE.EXCEL, value: FILE_TYPE.EXCEL },
        ];
        const { languageOptionsForManagement } = useLanguage();
        const languageSelectorOptions = languageOptionsForManagement.value.filter(({ value }) => value === LANGUAGE.EN);
        const closePopup = (e) => {
            e.preventDefault();
            emit('close');
        };
        const loadSelectorOptions = async () => {
            let currentYear = '';
            let lastYear = '';
            try {
                const serverTime = await userReportService.getServerTime(siteTimezoneId.value);
                currentYear = serverTime.substring(0, 4);
            }
            catch (e) {
                console.error(e);
                currentYear = String(today().getFullYear());
            }
            finally {
                lastYear = String(Number(currentYear) - 1);
                yearSelectorOptions.value = [
                    { text: lastYear, value: lastYear },
                    { text: currentYear, value: currentYear },
                ];
                state.year = currentYear;
            }
        };
        const getParams = () => {
            return {
                year: state.year,
                fileType: state.fileType,
                langCd: state.langCd,
            };
        };
        const fileDownload = withLoading(async () => {
            if (isNil(siteId.value))
                return;
            try {
                const annualReportBlobFile = await downloadService.annualReportFileDownload(String(siteId.value), getParams());
                if (annualReportBlobFile.size === 0) {
                    alertMessageBox(t('message.data_not_exist'));
                    return;
                }
                const fileExtension = state.fileType === FILE_TYPE.EXCEL ? 'xlsx' : state.fileType.toLowerCase();
                downloadFile(annualReportBlobFile, FILE_NAME, fileExtension);
            }
            catch (e) {
                alertMessageBox(t('message.error_download'));
                return;
            }
        });
        const sendEmail = withLoading(async () => {
            if (isNil(siteId.value))
                return;
            try {
                const { status } = await downloadService.annualReportSendEmail(String(siteId.value), getParams());
                if (status === STATUS_CODE.NO_CONTENT) {
                    alertMessageBox(t('message.data_not_exist'));
                    return;
                }
                alertMessageBox(t('message.email_sent'));
                return;
            }
            catch (e) {
                alertMessageBox(t('message.email_delivery_failed'));
                return;
            }
        });
        loadSelectorOptions();
        return {
            state,
            closePopup,
            fileDownload,
            sendEmail,
            yearSelectorOptions,
            fileTypeSelectorOptions,
            languageSelectorOptions,
        };
    },
});
