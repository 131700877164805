
import type { PropType } from 'vue';
import { defineComponent, computed, watch, ref } from 'vue';

import { GraphChart } from '@hems/component';
import { getTodayProductionChartOptions } from '@hems/container/src/siteEnergy/value/energyIndendenceChartOptions';
import { UNIT } from '@hems/util/src/constant';
import { getBrowserTimezone } from '@hems/util/src/helper/dateHelper';
import { formatUnitNumber } from '@hems/util/src/helper/numberformatHelper';
import { createChartData } from '@hems/util/src/helper/siteEnergyHelper';

import type { ReportIntervalResult, TodayEnergyChartData } from 'hems/report';

const CHART_HEIGHT = 240;

export default defineComponent({
  name: 'TodayProductionACNoConsumptionCT',
  components: {
    GraphChart,
  },
  props: {
    reportData: {
      type: Object as PropType<ReportIntervalResult | null>,
      required: true,
    },
    showChart: {
      type: Boolean,
      default: false,
    },
    timezone: {
      type: String,
      default: getBrowserTimezone(),
    },
  },
  setup(props) {
    const chartData = ref<TodayEnergyChartData[]>([]);
    const todayProduction = computed(() =>
      props.reportData ? props.reportData.gen_kwh : formatUnitNumber(0, UNIT.WATT_HOUR)
    );

    const options = computed(() => getTodayProductionChartOptions({ data: chartData.value, height: CHART_HEIGHT }));

    watch([() => props.showChart, () => props.reportData], () => {
      if (props.reportData) {
        chartData.value = createChartData(
          todayProduction.value.origin,
          props.reportData.gen_data,
          props.reportData.colec_time,
          props.timezone
        );
      }
    });

    return {
      todayProduction,
      options,
    };
  },
});
