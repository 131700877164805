/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import { rest } from 'msw';
import { apiWrapper } from '@hems/service/src/util/helper';
import { case1DeviceConfiguration } from '@hems/util/src/mocks/data/deviceData';
import { getCommonResponseData } from '@hems/util/src/mocks/util';
const API_BASE_URL = process.env.VUE_APP_API_URL;
const DEVICE_API_URL = `${API_BASE_URL}${apiWrapper.managerApi('/devices')}`;
const deviceConfigurationHandler = rest.get(`${DEVICE_API_URL}/profiles/id/:id/configurations`, (req, res, ctx) => {
    const response = getCommonResponseData(case1DeviceConfiguration, req.url.pathname);
    return res(ctx.status(200), ctx.json(response));
});
export const deviceHandlers = [deviceConfigurationHandler];
