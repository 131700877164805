
import type { PropType } from 'vue';
import { defineComponent, computed, onMounted, ref } from 'vue';

import { useIntervalFn } from '@vueuse/core';
import isNil from 'lodash-es/isNil';
import reduce from 'lodash-es/reduce';
import sum from 'lodash-es/sum';

import EnergyIndependence from '@hems/container/src/siteEnergy/value/EnergyIndependence.vue';
import EnergyValueAC from '@hems/container/src/siteEnergy/value/EnergyValueAC.vue';
import EnergyValueACNoConsumptionCT from '@hems/container/src/siteEnergy/value/EnergyValueACNoConsumptionCT.vue';
import TodayConsumption from '@hems/container/src/siteEnergy/value/TodayConsumption.vue';
import TodayProduction from '@hems/container/src/siteEnergy/value/TodayProduction.vue';
import TodayProductionACNoConsumptionCT from '@hems/container/src/siteEnergy/value/TodayProductionACNoConsumptionCT.vue';
import { HomeApplianceService, UserReportService } from '@hems/service';
import { Constant, useSiteConfiguration, useSiteId, useSiteMasterDeviceInfo } from '@hems/util';
import type { UserReportDateType } from '@hems/util/src/constant';
import {
  GRAPH_INTERVAL,
  ONE_MINUTE_VALUE_IN_MILLISECONDS,
  USER_REPORT_DATE,
  USER_REPORT_TYPE,
} from '@hems/util/src/constant';
import { STATUS_TEXT } from '@hems/util/src/exception/exception';
import {
  formatDateByInterval,
  getHourlyEndValue,
  getStartOfDate,
  getTime,
  getYesterday,
  subtractFromDate,
  today,
} from '@hems/util/src/helper/dateHelper';

import type { EnergyIndependenceInfo } from 'hems/dashboard';
import type { SiteEnergyData } from 'hems/energyFlow';
import type { ReportIntervalResult } from 'hems/report';

export default defineComponent({
  name: 'SiteEnergyValueContainer',
  components: {
    TodayProduction,
    TodayConsumption,
    EnergyIndependence,
    EnergyValueAC,
    TodayProductionACNoConsumptionCT,
    EnergyValueACNoConsumptionCT,
  },
  props: {
    realtimeData: {
      type: Object as PropType<SiteEnergyData>,
      required: true,
    },
    disconnect: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const homeApplianceService = new HomeApplianceService(window.axiosInstance.axios);
    const userReportService = new UserReportService(window.axiosInstance.axios);

    const { siteId } = useSiteId();

    const { siteTimezoneId, masterDeviceId, masterDeviceProductModelName, isConsumptionCT } = useSiteMasterDeviceInfo();

    const showChart = ref<boolean>(false);
    const homeEnergyData = ref<{ isHomeAppliance: boolean; value: number }>({ isHomeAppliance: false, value: 0.0 });
    const hourDate = ref<{ today: Date; yesterday: Date }>({
      today: today(),
      yesterday: getYesterday(),
    });

    const { hasPV } = useSiteConfiguration();

    const reportData = ref<{ today: ReportIntervalResult | null; yesterday: ReportIntervalResult | null }>({
      today: null,
      yesterday: null,
    });

    const energyIndependenceData = computed<EnergyIndependenceInfo>(() => ({
      today: calculateEnergyIndependence(reportData.value.today ?? null),
      yesterday: calculateEnergyIndependence(reportData.value.yesterday ?? null),
    }));

    const getReportDataParams = (
      dayType: UserReportDateType = USER_REPORT_DATE.TODAY,
      deviceId: string,
      siteId: number
    ) => {
      const date = dayType === USER_REPORT_DATE.TODAY ? hourDate.value.today : hourDate.value.yesterday;
      const start = formatDateByInterval(date, GRAPH_INTERVAL.HOURLY);
      const end = formatDateByInterval(getHourlyEndValue(date), GRAPH_INTERVAL.HOURLY);

      return {
        from: start,
        to: end,
        type: `${USER_REPORT_TYPE.GENERATION},${USER_REPORT_TYPE.CONSUMPTION},${USER_REPORT_TYPE.DEMAND}`,
        device_id: deviceId,
        site_id: siteId,
        product_model_nm: masterDeviceProductModelName.value ?? '',
        timezone_id: siteTimezoneId.value,
      };
    };

    const calculateEnergyIndependence = (data: ReportIntervalResult | null) => {
      if (!data || data.con_kwh.origin === 0) {
        return { percent: 0, value: 0 };
      }
      const energyIndependence = convertAbs((data.con_kwh.origin - data.demand_kwh.origin) / data.con_kwh.origin);

      return { percent: convertAbs(energyIndependence * 100), value: energyIndependence };
    };

    const convertAbs = (num: number): number => {
      if (num === null || num === undefined || isNaN(num)) {
        return 0;
      }
      if (num < 0) {
        return 0;
      }

      return num;
    };

    const getApplianceCount = async (): Promise<number> => {
      if (isNil(siteId.value)) return 0;

      try {
        const applianceList = await homeApplianceService.getApplianceList(Number(siteId.value));
        const filteredApplianceList = applianceList.filter(
          (item) =>
            item.profile_cd === Constant.APPLIANCE_TYPE.HEAT_PUMP ||
            item.profile_cd === Constant.APPLIANCE_TYPE.AIR_CONDITIONER ||
            item.profile_cd === Constant.APPLIANCE_TYPE.SMART_PLUG ||
            item.profile_cd === Constant.APPLIANCE_TYPE.TV
        );

        return filteredApplianceList.length;
      } catch (e) {
        console.error(e);

        return 0;
      }
    };

    const getApplianceEnergyData = async (): Promise<number> => {
      if (isNil(masterDeviceId.value) || isNil(siteId.value)) return 0;

      const dateParams = getReportDataParams(USER_REPORT_DATE.TODAY, masterDeviceId.value, siteId.value);

      try {
        const applianceChartData = await homeApplianceService.getApplianceChart(Number(siteId.value), {
          interval: GRAPH_INTERVAL.HOURLY,
          from: dateParams.from,
          to: dateParams.to,
          timezoneId: dateParams.timezone_id,
        });

        const sumApplianceEnergy = reduce(
          applianceChartData,
          (sumAppliance, value) => {
            sumAppliance = sumAppliance + sum(value.map((item) => item.gen));

            return sumAppliance;
          },
          0
        );

        return sumApplianceEnergy;
      } catch (e) {
        console.error(e);

        return 0;
      }
    };

    const loadHomeEnergyData = async () => {
      const applianceEnergyData = await getApplianceEnergyData();
      homeEnergyData.value = { isHomeAppliance: true, value: applianceEnergyData };
    };

    const loadReportData = async () => {
      if (isNil(masterDeviceId.value) || isNil(siteId.value)) return;

      try {
        const serverTime = await userReportService.getServerTime(siteTimezoneId.value);
        hourDate.value.today = getStartOfDate(getTime(serverTime, GRAPH_INTERVAL.HOURLY));
        hourDate.value.yesterday = getStartOfDate(
          subtractFromDate(getTime(serverTime, GRAPH_INTERVAL.HOURLY), 1, 'days')
        );
        const todayReportData = await userReportService.getReportsByInterval(
          GRAPH_INTERVAL.HOURLY,
          getReportDataParams(USER_REPORT_DATE.TODAY, masterDeviceId.value, siteId.value)
        );

        if (todayReportData.status === STATUS_TEXT.OK) {
          reportData.value.today = todayReportData.result;
        }

        const yesterdayReportData = await userReportService.getReportsByInterval(
          GRAPH_INTERVAL.HOURLY,
          getReportDataParams(USER_REPORT_DATE.YESTERDAY, masterDeviceId.value, siteId.value)
        );

        if (yesterdayReportData.status === STATUS_TEXT.OK) {
          reportData.value.yesterday = yesterdayReportData.result;
        }
      } catch (e) {
        console.error(e);
      }
    };

    useIntervalFn(loadReportData, ONE_MINUTE_VALUE_IN_MILLISECONDS);

    onMounted(async () => {
      showChart.value = true;
      const applianceCount = await getApplianceCount();
      if (applianceCount > 0) {
        loadHomeEnergyData();
      }
      loadReportData();
    });

    return {
      showChart,
      hasPV,
      energyIndependenceData,
      homeEnergyData,
      reportData,
      siteTimezoneId,
      isConsumptionCT,
      loadHomeEnergyData,
    };
  },
});
