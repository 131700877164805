import { defineComponent } from 'vue';
import { Image2 } from '@hems/component';
import EnergyFlowACContainer from './EnergyFlowACContainer.vue';
export default defineComponent({
    name: 'DashboardEnergyFlowContainer',
    components: {
        Image2,
        EnergyFlowACContainer,
    },
    props: {
        realtimeData: {
            type: Object,
            required: true,
        },
        isRealtimeConnected: {
            type: Boolean,
            required: false,
            default: false,
        },
        disconnect: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    emits: ['retryConnection'],
});
