import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-313689de"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "tooltip_wrap" };
const _hoisted_2 = { class: "tooltip_icon" };
const _hoisted_3 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("span", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, [
            (_ctx.imageName)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: require(`@hems/component/resources/vendor/image/${_ctx.imageBaseUrl || ''}/${_ctx.imageName}.${_ctx.imageExtension || 'png'}`)
                }, null, 8, _hoisted_3))
                : _createCommentVNode("", true),
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _createElementVNode("div", {
            class: _normalizeClass(["tooltip_text", _ctx.tooltipPosition?.join(' ')])
        }, [
            (_ctx.tooltipText)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "text",
                    style: _normalizeStyle({ width: _ctx.width, textAlign: _ctx.textAlign })
                }, _toDisplayString(_ctx.tooltipText), 5))
                : (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "text",
                    style: _normalizeStyle({ width: _ctx.width, textAlign: _ctx.textAlign })
                }, [
                    _renderSlot(_ctx.$slots, "tooltipText", {}, undefined, true)
                ], 4))
        ], 2)
    ]));
}
