export function getColumns(t) {
    return [
        {
            header: t('account.id'),
            name: 'user_id',
            align: 'left',
            resizable: true,
            whiteSpace: 'normal',
            sortable: true,
        },
        {
            header: t('account.installer_name'),
            name: 'user_nm',
            align: 'left',
            resizable: true,
            whiteSpace: 'normal',
            sortable: true,
        },
        {
            header: t('common.company'),
            name: 'comn_nm',
            align: 'left',
            resizable: true,
            whiteSpace: 'normal',
            sortable: true,
        },
        {
            header: t('account.phone'),
            name: 'mpn_no',
            align: 'left',
            resizable: true,
            whiteSpace: 'normal',
            sortable: true,
        },
        {
            header: t('account.email'),
            name: 'email',
            align: 'left',
            resizable: true,
            whiteSpace: 'normal',
            sortable: true,
        },
        {
            header: `${t('common.create_dt')} (utc+0)`,
            name: 'create_dt_utc_str',
            align: 'left',
            resizable: true,
            whiteSpace: 'normal',
            sortable: true,
            hidden: true,
        },
    ];
}
